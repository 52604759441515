import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  BooleanInputField,
  BoxTitle,
  TextInputEmailField,
  TextInputField,
  TextInputSiteField,
} from "../components";

const OrganizationDashboardFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.dashboard_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <BooleanInputField
              source="show_dashboard_dashboard"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInputField
              source="show_dashboard_knowledge_base"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInputField
              source="show_dashboard_search"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInputField
              source="show_dashboard_network"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInputField
              source="show_dashboard_applications"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={6}>
            <BooleanInputField
              source="search_only_premium"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={6}>
            <BooleanInputField
              source="booking_only_premium"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInputEmailField
              source="hr_email"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInputField
              source="employee_email"
              resource={props.resource}
              disabled={disabled_level_2}
              helperText={translate(`sentence.employee_official_email`)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputSiteField
              source="meeting_link"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationDashboardFields;
