import * as React from "react";
import {
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  Labeled,
} from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BoxTitle,
  RichTextInputField,
  TextInputSiteField,
} from "../components";

const OrganizationTeaserFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.teaser`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <p>{translate("sentence.organization_infobox")}</p>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {disabled_level_2 === false && (
              <ArrayInput source="teaser">
                <SimpleFormIterator>
                  <TextInputField
                    source="title"
                    label={translate(`organizations.fields.teaser.title`)}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                  <TextInputSiteField
                    source="link"
                    label={translate(`organizations.fields.teaser.link`)}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                  <RichTextInputField
                    source="description"
                    label={translate(`organizations.fields.teaser.description`)}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            )}
            {disabled_level_2 === true &&
              props !== undefined &&
              props.record !== undefined &&
              props.record.teaser !== undefined &&
              Array.isArray(props.record.teaser) &&
              props.record.teaser.map((item, index) => (
                <div key={index}>
                  <Grid item xs={12}>
                    <Labeled
                      resource={props.resource}
                      label={translate(`organizations.fields.teaser.title`)}
                    >
                      <>{item.title}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled
                      resource={props.resource}
                      label={translate(`organizations.fields.teaser.link`)}
                    >
                      <>{item.link}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.teaser.description`
                      )}
                    >
                      <div
                        className="tag-temp-text"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </Labeled>
                  </Grid>
                </div>
              ))}
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationTeaserFields;
