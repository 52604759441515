import * as React from "react";
import { useTranslate, useSetLocale } from "react-admin";
import MyLocalStorage from "../utils/MyLocalStorage";
import { ModelLocaleSelect } from "../components";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { SelectStyle } from "../styles";

const Configuration = () => {
  const translate = useTranslate();
  const locale = MyLocalStorage.getCookie("locale") ?? "de";
  const setLocale = useSetLocale();
  const classes = SelectStyle();

  const handleUpdateLocale = (event: React.ChangeEvent<{ value: unknown }>) => {
    MyLocalStorage.setCookie("locale", event.target.value as string);
    setLocale(event.target.value as string);
  };

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item sm={6} md={3} lg={2}>
          <p>{translate("pos.language")} </p>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label"> </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={locale}
              onChange={handleUpdateLocale}
            >
              <MenuItem value={`de`}>{translate("languages.de")}</MenuItem>
              <MenuItem value={`fr`}>{translate("languages.fr")}</MenuItem>
              <MenuItem value={`en`}>{translate("languages.en")}</MenuItem>
              <MenuItem value={`it`}>{translate("languages.it")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center">
        <Grid item sm={6} md={3} lg={2}>
          <p>{translate("pos.model_locale")} </p>
        </Grid>
        <Grid item xs={6}>
          <ModelLocaleSelect label={" "} />
        </Grid>
      </Grid>
    </>
  );
};

export default Configuration;
