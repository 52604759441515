import React from "react";
import { FC } from "react";
import { Typography, Grid, Card, CardContent, Avatar } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CardWithIconProps } from "./types";
import { CardWithIconStyle } from "../styles";

const CardWithIcon: FC<CardWithIconProps> = (props) => {
  const classes = CardWithIconStyle();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" spacing={1} alignItems="center">
          <Grid item sm container>
            <Typography color="textSecondary" gutterBottom>
              {props.title}
            </Typography>
            {props.value === undefined && <CircularProgress />}
            {props.value !== undefined && (
              <Typography color="textPrimary" variant="h3">
                {props.value}
              </Typography>
            )}
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {props.note}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar>{props.icon}</Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardWithIcon;
