import * as React from "react";

import { Grid } from "@material-ui/core";
import {
  TextInputField,
  RichTextInputField,
  SelectInputField,
  BooleanInputField,
} from "../components";

import ReferenceDataTable from "./ReferenceDataTable";

const AwardEditCreateForm = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="private_title"
          resource={"awards"}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <BooleanInputField
          source={"can_change_by_admin"}
          resource={"awards"}
          disabled={false}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectInputField
          source="type"
          resource={"awards"}
          choices={[
            { id: 1, name: "static" },
            { id: 2, name: "dynamic" },
          ]}
          disabled={false}
          optionText="name"
          optionValue="name"
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={"awards"}
          disabled={false}
          validate={false}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextInputField
          source="description"
          resource={"awards"}
          disabled={false}
          validate={false}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          source="icon"
          resource={"awards"}
          disabled={false}
          validate={false}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          source="url"
          resource={"awards"}
          disabled={false}
          validate={false}
        />
      </Grid>
    </Grid>
  );
};
const AwardForm = ({ props }: any) => {
  const fields = ["private_title", "title"];
  const reference_data_fields = [
    "private_title",
    "title",
    "description",
    "icon",
    "url",
    "type",
    "can_change_by_admin",
  ];

  return (
    <ReferenceDataTable
      resource={"awards"}
      table_fields={fields}
      reference_data_fields={reference_data_fields}
      reference_data_images={["logo_image"]}
      {...props}
      editForm={<AwardEditCreateForm />}
      createForm={<AwardEditCreateForm />}
    />
  );
};

export default AwardForm;
