import { makeStyles, Theme } from "@material-ui/core/styles";

export const SelectStyle = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  small: {
    margin: theme.spacing(1),
    minWidth: 70,
  },
  full: {
    margin: theme.spacing(1),
    width: "100%",
  },
  noMargin: {
    margin: 0,
  },
}));
