import * as React from "react";
import {
  FormWithRedirect,
  useTranslate,
  useGetList,
  Labeled,
  useRefresh,
} from "react-admin";
import { StyledTableCell, StyledTableRow } from "../styles";
import {
  Collapse,
  Grid,
  Typography,
  Box,
  Tabs,
  AppBar,
  Tab,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import {
  Check,
  Clear,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@material-ui/icons/";
import PropTypes from "prop-types";
import { LinearLoading, ConfirmationModal } from "../components";
import { AlertsStyle } from "../styles";
import { apiUrl, httpClient } from "../dataProvider";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const table_fields = [
  "first_name",
  "email",
  "source",
  "total_price",
  "total_schools",
  "total_courses",
  "created_at",
];

export const Sender = ({ status, contact }: any) => {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(true);
  const classes = AlertsStyle();

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <h3>Sender</h3>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </IconButton>
      </Grid>
      <Collapse in={open}>
        <Grid container spacing={1}>
          {Array.isArray(contact["dangers"]) && contact["dangers"].length > 0 && (
            <Grid item xs={12}>
              <Labeled
                label={translate(`contacts.fields.dangers`)}
                className={classes.danger}
              >
                <>
                  {contact["dangers"].map((danger, index) => (
                    <Typography gutterBottom>{danger}</Typography>
                  ))}
                </>
              </Labeled>
            </Grid>
          )}
          {Array.isArray(contact["warnings"]) &&
            contact["warnings"].length > 0 && (
              <Grid item xs={12}>
                <Labeled
                  label={translate(`contacts.fields.warnings`)}
                  className={classes.warning}
                >
                  <>
                    {contact["warnings"].map((warning, index) => (
                      <Typography gutterBottom>{warning}</Typography>
                    ))}
                  </>
                </Labeled>
              </Grid>
            )}
          <Grid item xs={6} sm={5}>
            <Labeled label={translate(`contacts.fields.name`)}>
              <Typography gutterBottom>
                {contact["first_name"]} {contact["last_name"]}
              </Typography>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Labeled label={translate(`contacts.fields.email`)}>
              <Typography gutterBottom>{contact["email"]}</Typography>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Labeled label={translate(`contacts.fields.phone`)}>
              <Typography gutterBottom>{contact["phone"]}</Typography>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={5}>
            <Labeled label={translate(`contacts.fields.street`)}>
              <Typography gutterBottom>{contact["street"]}</Typography>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Labeled label={translate(`contacts.fields.zip`)}>
              <Typography gutterBottom>{contact["zip"]}</Typography>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Labeled label={translate(`contacts.fields.city`)}>
              <Typography gutterBottom>{contact["city"]}</Typography>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Labeled label={translate(`contacts.fields.job_title`)}>
              <Typography gutterBottom>{contact["job_title"]}</Typography>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Labeled label={translate(`contacts.fields.work_experience`)}>
              <Typography gutterBottom>{contact["work_experience"]}</Typography>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Labeled label={translate(`contacts.fields.birthday_year`)}>
              <Typography gutterBottom>{contact["birthday_year"]}</Typography>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Labeled label={translate(`contacts.fields.user_degree_types`)}>
              <Typography gutterBottom>
                {contact["user_degree_types"]}
              </Typography>
            </Labeled>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export const Lead = ({ status, contact }: any) => {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(true);
  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <h3>Lead</h3>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </IconButton>
      </Grid>
      <Collapse in={open}>
        <LeadDetail contact={contact} />
      </Collapse>
    </Grid>
  );
};

export const LeadDetail = ({ status, contact, created_date = false }: any) => {
  const translate = useTranslate();
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={2}>
        <Labeled label={translate(`contacts.fields.lead_contact_option`)}>
          <Typography gutterBottom>{contact["lead_contact_option"]}</Typography>
        </Labeled>
      </Grid>
      {!created_date && (
        <Grid item xs={6} sm={4}>
          <Labeled label={translate(`contacts.fields.lead_contact_options`)}>
            <Typography gutterBottom>
              {contact["lead_contact_options"]}
            </Typography>
          </Labeled>
        </Grid>
      )}
      <Grid item xs={6} sm={2}>
        <Labeled label={translate(`contacts.fields.model`)}>
          <Typography gutterBottom>{contact["model"]}</Typography>
        </Labeled>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Labeled label={translate(`contacts.fields.source`)}>
          <Typography gutterBottom>{contact["source"]}</Typography>
        </Labeled>
      </Grid>
      {created_date && (
        <Grid item xs={6} sm={4}>
          <Labeled label={translate(`contacts.fields.created_at`)}>
            <Typography gutterBottom>{contact["created_at"]}</Typography>
          </Labeled>
        </Grid>
      )}
    </Grid>
  );
};

export const Criteria = ({ status, contact }: any) => {
  const [open, setOpen] = React.useState(true);
  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <h3>Criteria</h3>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={open}>
          <Grid container spacing={1}>
            {Object.keys(contact["criteria"]).map((key) => {
              return (
                <Grid item xs={6} sm={4}>
                  <p>
                    <b>
                      {Object.values<any>(key)}
                      {": "}
                    </b>
                    {typeof contact["criteria"][key] != "boolean" ? (
                      contact["criteria"][key]
                    ) : contact["criteria"][key] ? (
                      <Check />
                    ) : (
                      <Clear />
                    )}
                  </p>
                </Grid>
              );
            })}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export const AddReceiver = ({ contact }: any) => {
  const [addSchoolModel, setAddSchoolModel] = React.useState(false);
  const [schools, setSchools] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [course, setCourse] = React.useState(undefined);
  const [error, setError] = React.useState<any>(undefined);

  const refresh = useRefresh();

  const getSchools = () => {
    if (schools.length === 0) {
      const url = `${apiUrl}/schools?show_only[]=id&show_only[]=name`;
      return httpClient(url, {
        method: "GET",
      }).then(({ headers, json }: any) => {
        setSchools(json);
      });
    }
  };

  const getCourses = (event) => {
    const url = `${apiUrl}/schools/${event.target.value}/courses?show_only[]=id&show_only[]=shot_name`;
    return httpClient(url, {
      method: "GET",
    }).then(({ headers, json }: any) => {
      setCourses(json);
    });
  };

  const selectCourse = (event) => {
    setCourse(event.target.value);
  };

  const addCourse = () => {
    if (course !== undefined) {
      const url = `${apiUrl}/contacts/${contact["id"]}/add_course`;
      return httpClient(url, {
        method: "PUT",
        body: JSON.stringify({ course_id: course }),
      })
        .then(({ headers, json }: any) => {
          console.log(json);
          if (typeof json === "string" || json instanceof String) {
            setError(json);
          } else {
            setError(undefined);
            refresh();
          }
        })
        .catch((error) => {
          console.log(error.status);
        });
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        style={{ color: "blue" }}
        onClick={() => setAddSchoolModel(true)}
      >
        {"Add Receiver"}
      </Button>

      <ConfirmationModal
        open={addSchoolModel}
        handleClose={() => {
          setAddSchoolModel(false);
        }}
        handleAgree={() => addCourse()}
        handleDisagree={() => {
          setAddSchoolModel(false);
        }}
        message={
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel id="user-type-label">School</InputLabel>
                <Select
                  labelId={"user-type-label"}
                  id="user_type"
                  onMouseOver={getSchools}
                  onChange={getCourses}
                >
                  {/* @ts-ignore */}
                  {schools.map((school) => {
                    return (
                      <MenuItem value={school["id"]}>{school["name"]}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel id="user-type-label">Courses</InputLabel>
                <Select
                  labelId={"user-type-label"}
                  id="user_type"
                  onChange={selectCourse}
                >
                  {/* @ts-ignore */}
                  {courses.map((course) => {
                    return (
                      <MenuItem value={course["id"]}>
                        {course["short_name"]}
                      </MenuItem>
                    );
                  })}
                </Select>
                {error && <p>{error}</p>}
              </FormControl>
            </Grid>
          </Grid>
        }
        title={`Contact`}
      />
    </>
  );
};

export const Receivers = ({ status, contactEmails, hide_archived }: any) => {
  const translate = useTranslate();
  const classes = AlertsStyle();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <h3>Receiver(s)</h3>
        </Grid>
        {contactEmails.map((contactEmail, index) => (
          <>
            {hide_archived && contactEmail["status"] === "archived" ? (
              <></>
            ) : (
              <>
                <Grid item xs={6} sm={3}>
                  <Labeled label={translate(`contacts.fields.to_email`)}>
                    <Typography gutterBottom>
                      {contactEmail["to_email"]}
                    </Typography>
                  </Labeled>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Labeled label={translate(`contacts.fields.cc_emails`)}>
                    <Typography gutterBottom>
                      {contactEmail["cc_emails"]}
                    </Typography>
                  </Labeled>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <Labeled label={translate(`contacts.fields.status`)}>
                    <Typography gutterBottom>
                      {contactEmail["status"]}
                    </Typography>
                  </Labeled>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <Labeled label={translate(`contacts.fields.price`)}>
                    <Typography gutterBottom>
                      {contactEmail["price"]}
                    </Typography>
                  </Labeled>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <Labeled label={translate(`contacts.fields.archived`)}>
                    <Typography gutterBottom>
                      {contactEmail["archived"] ? <Check /> : <Clear />}
                    </Typography>
                  </Labeled>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <Labeled label={translate(`contacts.fields.anonymous`)}>
                    {contactEmail["anonymous"] ? <Check /> : <Clear />}
                  </Labeled>
                </Grid>
                {Array.isArray(contactEmail["dangers"]) &&
                  contactEmail["dangers"].length > 0 && (
                    <Grid item xs={12}>
                      <Labeled
                        label={translate(`contacts.fields.dangers`)}
                        className={classes.danger}
                      >
                        <>
                          {contactEmail["dangers"].map((danger, index) => (
                            <Typography gutterBottom>{danger}</Typography>
                          ))}
                        </>
                      </Labeled>
                    </Grid>
                  )}
                {Array.isArray(contactEmail["warnings"]) &&
                  contactEmail["warnings"].length > 0 && (
                    <Grid item xs={12}>
                      <Labeled
                        label={translate(`contacts.fields.warnings`)}
                        className={classes.warning}
                      >
                        <>
                          {contactEmail["warnings"].map((warning, index) => (
                            <Typography gutterBottom>{warning}</Typography>
                          ))}
                        </>
                      </Labeled>
                    </Grid>
                  )}
                <Models contactModels={contactEmail["contact_model"]} />
              </>
            )}
          </>
        ))}
      </Grid>
    </>
  );
};

export const Models = ({ status, contactModels, hide_archived }: any) => {
  const translate = useTranslate();
  const classes = AlertsStyle();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Grid item xs={1}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </IconButton>
      </Grid>
      <Collapse in={open}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <p>Name</p>
          </Grid>
          <Grid item xs={1}>
            <p>Rating</p>
          </Grid>
          <Grid item xs={1}>
            <p>Contract</p>
          </Grid>
          <Grid item xs={1}>
            <p>Premium</p>
          </Grid>
          <Grid item xs={1}>
            <p>Info Material</p>
          </Grid>
          <Grid item xs={1}>
            <p>Has Counseling</p>
          </Grid>
          <Grid item xs={1}>
            <p>Open Spots</p>
          </Grid>
          <Grid item xs={1}>
            <p>Reservations</p>
          </Grid>
          <Grid item xs={1}>
            <p>Events Open Spots</p>
          </Grid>
          <Grid item xs={1}>
            <p>Government Subsidies</p>
          </Grid>
          <Grid item xs={1}>
            <p>Reimbursements</p>
          </Grid>
          {contactModels.map((contactModel, index) => (
            <>
              <Grid item xs={2}>
                {contactModel["rejected"] ? (
                  <del>
                    {contactModel["course_short_name"]} (
                    {contactModel["school_abbreviation"]})
                  </del>
                ) : (
                  `${contactModel["course_short_name"]} (
                    ${contactModel["school_abbreviation"]}
                  )`
                )}
              </Grid>
              <Grid item xs={1}>
                {contactModel["rating"]}
              </Grid>
              <Grid item xs={1}>
                {contactModel["course_contract_type_id"]}
              </Grid>
              <Grid item xs={1}>
                {contactModel["course_premium"] ? <Check /> : <Clear />}
              </Grid>
              <Grid item xs={1}>
                {contactModel["course_info_material"] ? <Check /> : <Clear />}
              </Grid>
              <Grid item xs={1}>
                {contactModel["course_has_counseling"] ? <Check /> : <Clear />}
              </Grid>
              <Grid item xs={1}>
                {contactModel["course_has_courses_open_spots"] ? (
                  <Check />
                ) : (
                  <Clear />
                )}
              </Grid>
              <Grid item xs={1}>
                {contactModel["course_has_course_reservations"] ? (
                  <Check />
                ) : (
                  <Clear />
                )}
              </Grid>
              <Grid item xs={1}>
                {contactModel["course_has_events_open_spots"] ? (
                  <Check />
                ) : (
                  <Clear />
                )}
              </Grid>
              <Grid item xs={1}>
                {contactModel["course_has_government_subsidies"] ? (
                  <Check />
                ) : (
                  <Clear />
                )}
              </Grid>
              <Grid item xs={1}>
                {contactModel["course_has_eduwo_reimbursements"] ? (
                  <Check />
                ) : (
                  <Clear />
                )}
              </Grid>
              {Array.isArray(contactModel["dangers"]) &&
                contactModel["dangers"].length > 0 && (
                  <Grid item xs={12}>
                    <Labeled
                      label={translate(`contacts.fields.dangers`)}
                      className={classes.danger}
                    >
                      <>
                        {contactModel["dangers"].map((danger, index) => (
                          <Typography gutterBottom>{danger}</Typography>
                        ))}
                      </>
                    </Labeled>
                  </Grid>
                )}
              {Array.isArray(contactModel["warnings"]) &&
                contactModel["warnings"].length > 0 && (
                  <Grid item xs={12}>
                    <Labeled
                      label={translate(`contacts.fields.warnings`)}
                      className={classes.warning}
                    >
                      <>
                        {contactModel["warnings"].map((warning, index) => (
                          <Typography gutterBottom>{warning}</Typography>
                        ))}
                      </>
                    </Labeled>
                  </Grid>
                )}
            </>
          ))}
        </Grid>
      </Collapse>
    </>
  );
};

const Row = ({ status, row }: any) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </StyledTableCell>
        {table_fields.map((table_field) => (
          <StyledTableCell>{row[table_field]}</StyledTableCell>
        ))}
      </StyledTableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={open}>
          <Grid container spacing={1}>
            <Sender contact={row} />
            <Lead contact={row} />
            {row["source"].indexOf("wizard") >= 0 && (
              <>
                <AddReceiver contact={row} />
                <Criteria contact={row} />
              </>
            )}
            <Receivers
              contact={row}
              contactEmails={row["contact_emails"]}
              status={status}
            />

            {/* TODO BUTTONS */}
            {/*{status === "pending" && (
              <>
                <Grid item xs={3}>
                  <p>Reject All</p>
                </Grid>
                <Grid item xs={3}>
                  <p>Send Pending</p>
                </Grid>
                <Grid item xs={3}>
                  <p>Send All</p>
                </Grid>
              </>
            )}
            {status === "sent" && (
              <>
                <Grid item xs={3}>
                  <p>Send All Rejected</p>
                </Grid>
                <Grid item xs={3}>
                  <p>Archived</p>
                </Grid>
              </>
            )}
            {status === "archived" && (
              <>
                <Grid item xs={3}>
                  <p></p>
                </Grid>
                <Grid item xs={3}>
                  <p>Unarchived</p>
                </Grid>
              </>
            )}*/}
          </Grid>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
};

const EmailsList = ({ status, archived = false }: any) => {
  const translate = useTranslate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const filters = { status: status, archived: archived };
  const sort = { field: "created_at", order: "DESC" };
  const { total, /*error,*/ data, loading } = useGetList(
    "contacts",
    { perPage: rowsPerPage, page: page + 1 },
    sort,
    filters
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LinearLoading />;
  }

  let sortedData = data !== undefined ? Object.keys(data).reverse() : [];

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {table_fields.map((table_field, index) => (
                <TableCell key={index} sortDirection={false}>
                  {translate(`contacts.fields.${table_field}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData !== undefined &&
              sortedData.map((key, index) => {
                return <Row key={key} status={status} row={data[key]} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

const Contacts = ({ ...props }: any) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function a11yProps(index: any) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const tabs = {
    0: {
      tab: "Pending",
      tab_panel: <EmailsList status={"pending"} />,
    },
    1: {
      tab: "Sent / Rejected",
      tab_panel: <EmailsList status={"sent"} />,
    },
    2: {
      tab: "Archived",
      tab_panel: <EmailsList archived={true} />,
    },
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
            >
              {Object.keys(tabs).map((key) => {
                return (
                  <Tab
                    tabIndex={Number(key)}
                    label={`${tabs[key].tab}`}
                    {...a11yProps(Number(key))}
                  />
                );
              })}
            </Tabs>

            {Object.keys(tabs).map((key) => {
              return (
                <TabPanel value={value} index={Number(key)}>
                  {tabs[key].tab_panel}
                </TabPanel>
              );
            })}
          </AppBar>
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default Contacts;
