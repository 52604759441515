import * as React from "react";
import { usePermissions } from "react-admin";
import { SuccessStoriesList } from "../components";
import { OrganizationTitle } from "./OrganizationForm";
import {
  USER_ROLE_ORGANIZATION_ADMIN,
  USER_ROLE_SUPER_ADMIN,
} from "../Constants";

const OrganizationSuccessStories = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <SuccessStoriesList
      target={"related_organization_id"}
      reference={"organization_success_stories"}
      title={<OrganizationTitle />}
      resources={"organizations"}
      organization_id={props.id}
      canAdd={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_ORGANIZATION_ADMIN)
      }
      canDelete={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_ORGANIZATION_ADMIN)
      }
    />
  );
};

export default OrganizationSuccessStories;
