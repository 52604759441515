import * as React from "react";
import { useTranslate } from "react-admin";
import {
  BoxTitle,
  ReferenceTags,
  ReferenceAwards,
} from "../components";
import { Grid, Collapse } from "@material-ui/core";

const OrganizationRelationFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.relation_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ReferenceTags disabled={disabled_level_1} />
          </Grid>
          <Grid item xs={12}>
            <ReferenceAwards disabled={disabled_level_1} />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationRelationFields;
