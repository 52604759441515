import { TableRow, TableCell } from "@material-ui/core";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export const StyledTableCellBorder = makeStyles((theme: Theme) =>
  createStyles({
    left: {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
    right: {
      borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
  })
);
