import * as React from "react";
import { FC } from "react";
import { BooleanInput, useTranslate, required } from "react-admin";
import { ResourceFieldProps } from "./types";

const BooleanInputField: FC<ResourceFieldProps> = (props) => {
  const translate = useTranslate();
  return (
    <BooleanInput
      source={props.source}
      label={
        props.label ?? translate(`${props.resource}.fields.${props.source}`)
      }
      resource={props.resource}
      record={props.record}
      fullWidth
      disabled={props.disabled}
      validate={props.validate ? [required()] : []}
      helperText={props.helperText ?? <p></p>}
      onChange={props.onChange}
    />
  );
};

export default BooleanInputField;
