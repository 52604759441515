import * as React from "react";
import {
  ArrayInput,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  useTranslate,
} from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BoxTitle,
  NumberInputField,
  BooleanInputField,
} from "../components";

const UserExpertFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`users.boxes.expert_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <BooleanInputField
              source="is_expert"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <BooleanInputField
              source="professional"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <BooleanInputField
              source="pay_fee"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="expert_biography"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              multiline={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="expert_motivation"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              multiline={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="expert_linkedin_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="expert_facebook_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="expert_twitter_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInputField
              source="price"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>

          {disabled_level_1 === false && (
            <Grid item xs={12}>
              <ArrayInput source={"expert_for"}>
                <SimpleFormIterator>
                  <ReferenceInput
                    source={"study_field_id"}
                    reference={"study_fields"}
                    label={translate(`resources.study_fields.name_singular`)}
                    perPage={1000}
                  >
                    <SelectInput
                      fullWidth
                      optionText={"title"}
                      optionValue={"id"}
                    />
                  </ReferenceInput>
                  <NumberInputField
                    source={"skill_value"}
                    label={translate(`user.fields.expert_for.skill_value`)}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_1}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          )}
          {disabled_level_1 === true &&
            props.record !== null &&
            props.record !== undefined &&
            props.record.expert_for !== null &&
            props.record.expert_for !== undefined &&
            props.record.expert_for.map((item, index) => (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Labeled
                    resource={props.resource}
                    label={translate(`resources.study_fields.name_singular`)}
                  >
                    <>{item.study_field_title}</>
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Labeled
                    resource={props.resource}
                    label={translate(`users.fields.expert_for.skill_value`)}
                  >
                    <>{item.skill_value}</>
                  </Labeled>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Collapse>
    </>
  );
};

export default UserExpertFields;
