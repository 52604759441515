import * as React from "react";
import { Layout, LayoutProps, Sidebar } from "react-admin";
import Menu from "./Menu";
import AppBar from "./AppBar";

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

const MyLayout = (props: LayoutProps) => {
  return (
    <Layout {...props} appBar={AppBar} sidebar={CustomSidebar} menu={Menu} />
  );
};

export default MyLayout;
