import * as React from "react";
import { FC } from "react";
import {
  useTranslate,
  FormWithRedirect,
  SimpleShowLayout,
  Show,
  Edit,
  SimpleForm,
  usePermissions,
} from "react-admin";
import { Storage } from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Tabs, AppBar, Tab, Box, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";
import { MyActionShowBar, MyActionEditBar } from "../components";
import WizardTabSummary from "./WizardTabSummary";

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const WizardsTitle: FC<Omit<any, "children">> = (props: any) => {
  const translate = useTranslate();
  return (
    <span>
      {translate(`resources.wizards.name_singular`)}{" "}
      {props.record ? props.record.name : ""}
    </span>
  );
};

const WizardsForm = ({ action, ...props }: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { permissions = [] } = usePermissions();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const disabled_level_1 = !permissions.includes(USER_ROLE_SUPER_ADMIN);

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                tabIndex={0}
                label={translate(`wizards.tabs.summary`)}
                {...a11yProps(0)}
                icon={<Storage />}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              {action === "show" && (
                <Show
                  {...props}
                  title={<WizardsTitle />}
                  actions={
                    isXSmall ? (
                      <></>
                    ) : (
                      <MyActionShowBar disabledEdit={disabled_level_1} />
                    )
                  }
                  component="div"
                >
                  <SimpleShowLayout>
                    <WizardTabSummary
                      {...props}
                      disabled_level_1={
                        action === "show" ? true : disabled_level_1
                      }
                      disabled_level_2={
                        action === "show" ? true : disabled_level_1
                      }
                      action={action}
                    />
                  </SimpleShowLayout>
                </Show>
              )}
              {action === "edit" && (
                <Edit
                  {...props}
                  title={<WizardsTitle />}
                  actions={<></>}
                  component="div"
                >
                  <SimpleForm
                    toolbar={<MyActionEditBar {...props} />}
                    submitOnEnter={false}
                    redirect="show"
                  >
                    <WizardTabSummary
                      disabled_level_1={
                        action === "show" ? true : disabled_level_1
                      }
                      disabled_level_2={
                        action === "show" ? true : disabled_level_1
                      }
                      action={action}
                      {...props}
                    />
                  </SimpleForm>
                </Edit>
              )}
            </TabPanel>
          </AppBar>
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default WizardsForm;
