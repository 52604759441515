import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PeopleOutlined, Http, AccessTime } from "@material-ui/icons";
import { Grid, Collapse } from "@material-ui/core";
import { BoxTitle, CardWithIcon, LineChart, PieChart } from "../components";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  alignCenter: { textAlign: "center" },
}));

export default function EduwoStatistics({
  loading,
  eduwo_stats,
  eduwo_stats_details,
  google_stats,
  line_chart_data,
  line_chart_labels,
}: any) {
  const classes = useStyles();
  const translate = useTranslate();
  const [collapse, setCollapse] = React.useState(true);

  return (
    <div>
      <BoxTitle
        title={translate(`statistics.eduwo.title`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        {loading !== false && <p>Not Loaded</p>}
        {loading === false &&
          eduwo_stats !== null &&
          eduwo_stats !== undefined && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h2 className={classes.alignCenter}>School</h2>
                </Grid>
                <Grid item xs={4}>
                  <CardWithIcon
                    title="TOTAL VISITORS"
                    value={parseInt(eduwo_stats.total.views)}
                    icon={<PeopleOutlined />}
                    note={
                      google_stats !== null && google_stats !== undefined
                        ? `Value in google: ${google_stats.total.views}`
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <CardWithIcon
                    title="TOTAL UNIQUE VISITORS"
                    value={parseInt(eduwo_stats.total.total_unique_users)}
                    icon={<PeopleOutlined />}
                    note={
                      google_stats !== null && google_stats !== undefined
                        ? `Value in google: ${google_stats.total.total_unique_users}`
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <CardWithIcon
                    title="AVERAGE TIME"
                    value={
                      google_stats !== null && google_stats !== undefined
                        ? google_stats.total.avg_time
                        : "NA"
                    }
                    icon={<AccessTime />}
                    note={`Value from google`}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CardWithIcon
                    title="TOTAL SITE URL CLICKS"
                    value={parseInt(eduwo_stats.site_url_clicks)}
                    icon={<Http />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CardWithIcon
                    title="TOTAL DOWNLOADS"
                    value={"NA"}
                    icon={<Http />}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CardWithIcon
                    title="TOTAL VIDEO CLICKS"
                    value={"NA"}
                    icon={<Http />}
                  />
                </Grid>
              </Grid>
                {loading === false &&
                  ((eduwo_stats_details !== null &&
                    eduwo_stats_details !== undefined) ||
                    google_stats !== undefined) && (
                    <Grid item xs={12}>
                      <LineChart
                        data={line_chart_data}
                        labels={line_chart_labels}
                        legend={true}
                      />
                    </Grid>
                  )}
                {/* TODO */}
                {/* <Grid container spacing={1}>
                  <Grid item sm={12} md={6}>
                    <PieChart
                      title={"Gender DE"}
                      labels={Object.keys(eduwo_stats.gender_de)}
                      data={Object.values<any>(eduwo_stats.gender_de)}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <PieChart
                      title={"Gender FR"}
                      labels={Object.keys(eduwo_stats.gender_fr)}
                      data={Object.values<any>(eduwo_stats.gender_fr)}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <PieChart
                      title={"Age DE"}
                      labels={Object.keys(eduwo_stats.age_de)}
                      data={Object.values<any>(eduwo_stats.age_de)}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <PieChart
                      title={"Age FR"}
                      labels={Object.keys(eduwo_stats.age_fr)}
                      data={Object.values<any>(eduwo_stats.age_fr)}
                    />
                  </Grid>
                </Grid> */}
            </>
          )}
      </Collapse>
    </div>
  );
}
