import * as React from "react";
import { FC } from "react";
import {
  ListProps,
  useTranslate,
  useGetList,
  Labeled,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  NullableBooleanInput,
} from "react-admin";
import {
  Collapse,
  Grid,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  TablePagination,
  Tabs,
  AppBar,
  Tab,
  TableSortLabel,
  Button,
} from "@material-ui/core";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  DateInputField,
  GoToShowUrl,
  LinearLoading,
  TextInputField,
} from "../components";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { apiUrl } from "../dataProvider";
import { stringify } from "query-string";
import MyLocalStorage from "../utils/MyLocalStorage";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const table_fields = ["main_order", "sender", "receiver", "created_at"];

export const TabbedDatagrid = ({
  type,
  school_id,
  course_id,
  end_date,
  start_date,
  lead_id,
  pay_per_lead,
  term,
}: any) => {
  const translate = useTranslate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const filters = { type: type };
  if (school_id !== undefined && course_id === undefined) {
    filters["school_id"] = school_id;
  }
  if (course_id !== undefined) {
    filters["course_id"] = course_id;
  }
  if (end_date !== undefined) {
    filters["end_date"] = end_date;
  }
  if (start_date !== undefined) {
    filters["start_date"] = start_date;
  }
  if (lead_id !== undefined) {
    filters["lead_id"] = lead_id;
  }
  if (term !== undefined) {
    filters["term"] = term;
  }
  if (pay_per_lead !== undefined) {
    filters["pay_per_lead"] = pay_per_lead;
  }
  const sort = { field: "created_at", order: "DESC" };
  const { total, /*error,*/ data, loading } = useGetList(
    "leads",
    { perPage: rowsPerPage, page: page + 1 },
    sort,
    filters
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExport = () => {
    const query = {
      filter: JSON.stringify({
        type: type,
        school_id: school_id,
        course_id: course_id,
        start_date: start_date,
        end_date: end_date,
        lead_id: lead_id,
        term: term,
      }),
      range: "[0,1000]",
      sort: ["created_at", "DESC"],
    };

    const url = `${apiUrl}/leads/${type}/export?${stringify(query)}`;

    const headers = new Headers({ Accept: "application/json" });
    headers.set(
      "Authorization",
      `Bearer ${MyLocalStorage.getCookie("access_token")}`
    );
    headers.set("Content-Type", `application/json`);

    let options = { headers: headers };

    return fetch(url, options)
      .then(function (response) {
        if (response !== null) {
          return response.blob();
        }
      })
      .then(function (blob) {
        var file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      });
  };

  if (loading) {
    return <LinearLoading />;
  }

  function Row({ row }: any) {
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <StyledTableRow>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
            </IconButton>
          </StyledTableCell>
          {table_fields.map((table_field) => (
            <StyledTableCell>{row[table_field]}</StyledTableCell>
          ))}
          {type !== "bulk" &&
            Object.keys(row["school_and_course_names"]) !== undefined && (
              <>
                <StyledTableCell>
                  <GoToShowUrl
                    id={row["school_id"]}
                    resource={"schools"}
                    label={row["school_and_course_names"].split(",")[0]}
                  />
                </StyledTableCell>
                {row["school_and_course_names"].split(",").length > 1 ? (
                  <StyledTableCell>
                    <GoToShowUrl
                      id={row["course_id"]}
                      resource={"courses"}
                      label={row["school_and_course_names"].split(",")[1]}
                    />
                  </StyledTableCell>
                ) : (
                  <StyledTableCell>{""}</StyledTableCell>
                )}
              </>
            )}
          {type === "bulk" && (
            <StyledTableCell>{row["total_receiver"]}</StyledTableCell>
          )}
        </StyledTableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={type !== "bulk" ? 6 : 4}
        >
          <Collapse in={open}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4}>
                <Labeled label={translate(`leads.fields.date`)}>
                  <Typography gutterBottom>
                    {new Date(row["created_at"]).toLocaleDateString()}{" "}
                    {new Date(row["created_at"]).toLocaleTimeString()}
                  </Typography>
                </Labeled>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Labeled label={translate(`leads.fields.main_order`)}>
                  <Typography gutterBottom>{row["main_order"]}</Typography>
                </Labeled>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Labeled label={translate(`leads.fields.request_order`)}>
                  <Typography gutterBottom>{row["request_order"]}</Typography>
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Labeled label={translate(`leads.fields.sender`)}>
                  <Typography gutterBottom>{row["sender"]}</Typography>
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Labeled label={translate(`leads.fields.receiver`)}>
                  <Typography gutterBottom>
                    {row["total_receiver"] ? `[${row["total_receiver"]}] ` : ""}
                    {row["receiver"]}
                  </Typography>
                </Labeled>
              </Grid>
              {type === "bulk" && (
                <Grid item xs={12}>
                  <Labeled
                    label={translate(`leads.fields.schools_and_courses`)}
                  >
                    <dl>
                      {Object.keys(row["school_and_course_names"]).map(
                        (key) => {
                          return (
                            <dl>
                              <dt>{Object.values<any>(key)}</dt>
                              {row["school_and_course_names"][key].map(
                                (value) => {
                                  return <dd>{value}</dd>;
                                }
                              )}
                            </dl>
                          );
                        }
                      )}
                    </dl>
                  </Labeled>
                </Grid>
              )}
              {type !== "bulk" && (
                <Grid item xs={12}>
                  <Labeled
                    label={translate(`leads.fields.schools_and_courses`)}
                  >
                    <p>{row["school_and_course_names"]}</p>
                  </Labeled>
                </Grid>
              )}
              {type !== "bulk" && (
                <>
                  <Grid item xs={12}>
                    <Labeled label={translate(`leads.fields.title`)}>
                      <Typography gutterBottom>{row["title"]}</Typography>
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled label={translate(`leads.fields.message`)}>
                      <Typography gutterBottom>{row["message"]}</Typography>
                    </Labeled>
                  </Grid>
                </>
              )}
              {type === "bulk" && (
                <>
                  <Grid item xs={6} sm={4}>
                    <Labeled label={translate(`resources.degree_types.name`)}>
                      <>{row["data"]["degree_types"]}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Labeled label={translate(`resources.school_types.name`)}>
                      <>{row["data"]["school_types"]}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Labeled
                      label={translate(`resources.study_field_categories.name`)}
                    >
                      <>{row["data"]["study_field_categories"]}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Labeled label={translate(`resources.study_fields.name`)}>
                      <>{row["data"]["study_fields"]}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Labeled label={translate(`resources.regions.name`)}>
                      <>{row["data"]["regions"]}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Labeled label={"Other"}>
                      <>{row["data"]["other"]}</>
                    </Labeled>
                  </Grid>
                </>
              )}
              {type === "reimbursement" && (
                <Grid item xs={6} sm={4}>
                  <Labeled label={translate(`leads.fields.iban`)}>
                    <>{row["iban"]}</>
                  </Labeled>
                </Grid>
              )}
            </Grid>
          </Collapse>
        </TableCell>
      </React.Fragment>
    );
  }

  function RowSummary({ type }: any) {
    const [openSummary, setOpenSummary] = React.useState(false);
    let total_leads = 0;
    let total_pay_per_lead = 0;
    let total_premium = 0;
    let total_non_premium = 0;

    let total_courses = 0;
    let total_distinct_courses = 0;

    let pay_per_lead_per_lead_type = <></>;
    let premium_per_lead_type = <></>;
    let non_premium_per_lead_type = <></>;

    let remaining_emails = <></>;

    function LeadType({ lead_type }: any): JSX.Element {
      return (
        <>
          {Object.keys(data[1][lead_type]).map((key) => {
            return (
              <p>
                {Object.values<any>(key)}
                {": "}
                {data[1][lead_type][key]}
              </p>
            );
          })}
        </>
      );
    }

    if (!data || data[1] === undefined) {
      return <></>;
    }

    switch (type) {
      case "total":
        total_leads =
          Number(data[1]["inquiry_total_courses"]) +
          Number(data[1]["bulk_total_leads"]);
        total_pay_per_lead =
          Number(data[1]["total_pay_per_lead"]) +
          Number(data[1]["total_deleted_pay_per_lead"]) +
          Number(data[1]["bulk_total_pay_per_lead"]) +
          Number(data[1]["bulk_deleted_total_pay_per_lead"]) +
          Number(data[1]["total_pay_per_lead_schools"]) +
          Number(data[1]["total_deleted_pay_per_lead_schools"]);
        total_premium =
          Number(data[1]["total_premium"]) +
          Number(data[1]["total_deleted_premium"]) +
          Number(data[1]["bulk_total_premium"]) +
          Number(data[1]["bulk_deleted_total_premium"]) +
          Number(data[1]["total_premium_schools"]) +
          Number(data[1]["total_deleted_premium_schools"]);
        total_non_premium =
          Number(data[1]["total_non_premium"]) +
          Number(data[1]["total_deleted_non_premium"]) +
          Number(data[1]["bulk_total_non_premium"]) +
          Number(data[1]["bulk_deleted_total_non_premium"]) +
          Number(data[1]["total_deleted_non_premium"]) +
          Number(data[1]["total_deleted_non_premium_schools"]);
        pay_per_lead_per_lead_type = (
          <>
            <LeadType lead_type="total_pay_per_lead_per_lead_type" />
            <LeadType lead_type="total_deleted_pay_per_lead_per_lead_type" />
            <LeadType lead_type="total_pay_per_lead_per_lead_type_schools" />
            <LeadType lead_type="total_deleted_pay_per_lead_per_lead_type_schools" />
            <LeadType lead_type="bulk_total_pay_per_lead_per_lead_type" />
            <LeadType lead_type="bulk_deleted_total_pay_per_lead_per_lead_type" />
          </>
        );
        premium_per_lead_type = (
          <>
            <LeadType lead_type="total_premium_per_lead_type" />
            <LeadType lead_type="total_deleted_premium_per_lead_type" />
            <LeadType lead_type="total_premium_per_lead_type_schools" />
            <LeadType lead_type="total_deleted_premium_per_lead_type_schools" />
            <LeadType lead_type="bulk_total_premium_per_lead_type" />
            <LeadType lead_type="bulk_deleted_total_premium_per_lead_type" />
          </>
        );
        non_premium_per_lead_type = (
          <>
            <LeadType lead_type="total_non_premium_per_lead_type" />
            <LeadType lead_type="total_deleted_non_premium_per_lead_type" />
            <LeadType lead_type="total_non_premium_per_lead_type_schools" />
            <LeadType lead_type="total_deleted_non_premium_per_lead_type_schools" />
            <LeadType lead_type="bulk_total_non_premium_per_lead_type" />
            <LeadType lead_type="bulk_deleted_total_non_premium_per_lead_type" />
          </>
        );
        remaining_emails = (
          <Typography gutterBottom>
            {data[1]["remaining_emails"].map((remaining_email) => {
              return <p>{remaining_email}</p>;
            })}
          </Typography>
        );
        total_courses =
          Number(data[1]["inquiry_total_courses"]) +
          Number(data[1]["bulk_total_courses"]);
        total_distinct_courses = data[1]["total_distinct_courses"];
        break;
      case "bulk":
        total_leads = Number(data[1]["bulk_total_leads"]);
        total_pay_per_lead =
          Number(data[1]["bulk_total_pay_per_lead"]) +
          Number(data[1]["bulk_deleted_total_pay_per_lead"]);
        total_premium =
          Number(data[1]["bulk_total_premium"]) +
          Number(data[1]["bulk_deleted_total_premium"]);
        total_non_premium =
          Number(data[1]["bulk_total_non_premium"]) +
          Number(data[1]["bulk_deleted_total_non_premium"]);
        pay_per_lead_per_lead_type = (
          <>
            <LeadType lead_type="bulk_total_pay_per_lead_per_lead_type" />
            <LeadType lead_type="bulk_deleted_total_pay_per_lead_per_lead_type" />
          </>
        );
        premium_per_lead_type = (
          <>
            <LeadType lead_type="bulk_total_premium_per_lead_type" />
            <LeadType lead_type="bulk_deleted_total_premium_per_lead_type" />
          </>
        );
        non_premium_per_lead_type = (
          <>
            <LeadType lead_type="bulk_total_non_premium_per_lead_type" />
            <LeadType lead_type="bulk_deleted_total_non_premium_per_lead_type" />
          </>
        );
        remaining_emails = (
          <Typography gutterBottom>
            {data[1]["remaining_emails"].map((remaining_email) => {
              return <p>{remaining_email}</p>;
            })}
          </Typography>
        );
        total_courses = Number(data[1]["bulk_total_courses"]);
        total_distinct_courses = data[1]["bulk_total_distinct_courses"];
        break;
      case "inquiry":
        total_leads = Number(data[1]["inquiry_total_courses"]);
        total_pay_per_lead =
          Number(data[1]["total_pay_per_lead"]) +
          Number(data[1]["total_deleted_pay_per_lead"]) +
          Number(data[1]["total_pay_per_lead_schools"]) +
          Number(data[1]["total_deleted_pay_per_lead_schools"]);
        total_premium =
          Number(data[1]["total_premium"]) +
          Number(data[1]["total_deleted_premium"]) +
          Number(data[1]["total_premium_schools"]) +
          Number(data[1]["total_deleted_premium_schools"]);
        total_non_premium =
          Number(data[1]["total_non_premium"]) +
          Number(data[1]["total_deleted_non_premium"]) +
          Number(data[1]["total_deleted_non_premium"]) +
          Number(data[1]["total_deleted_non_premium_schools"]);
        pay_per_lead_per_lead_type = (
          <>
            <LeadType lead_type="total_pay_per_lead_per_lead_type" />
            <LeadType lead_type="total_deleted_pay_per_lead_per_lead_type" />
            <LeadType lead_type="total_pay_per_lead_per_lead_type_schools" />
            <LeadType lead_type="total_deleted_pay_per_lead_per_lead_type_schools" />
          </>
        );
        premium_per_lead_type = (
          <>
            <LeadType lead_type="total_premium_per_lead_type" />
            <LeadType lead_type="total_deleted_premium_per_lead_type" />
            <LeadType lead_type="total_premium_per_lead_type_schools" />
            <LeadType lead_type="total_deleted_premium_per_lead_type_schools" />
          </>
        );
        non_premium_per_lead_type = (
          <>
            <LeadType lead_type="total_non_premium_per_lead_type" />
            <LeadType lead_type="total_deleted_non_premium_per_lead_type" />
            <LeadType lead_type="total_non_premium_per_lead_type_schools" />
            <LeadType lead_type="total_deleted_non_premium_per_lead_type_schools" />
          </>
        );
        total_courses = Number(data[1]["inquiry_total_courses"]);
        total_distinct_courses = data[1]["inquiry_total_distinct_courses"];
        break;
    }
    return (
      <>
        <StyledTableRow>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenSummary(!openSummary)}
            >
              {openSummary ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell>{type}</StyledTableCell>
          <StyledTableCell>{total_leads}</StyledTableCell>
        </StyledTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={openSummary}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={4}>
                <Labeled label={"Pay per lead"}>
                  <Typography gutterBottom>{total_pay_per_lead}</Typography>
                </Labeled>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Labeled label={"Premium"}>
                  <Typography gutterBottom>{total_premium}</Typography>
                </Labeled>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Labeled label={"Non premium"}>
                  <Typography gutterBottom>{total_non_premium}</Typography>
                </Labeled>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Labeled label={"Pay per lead"}>
                  <Typography gutterBottom>
                    {pay_per_lead_per_lead_type}
                  </Typography>
                </Labeled>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Labeled label={"Premium"}>
                  <Typography gutterBottom>{premium_per_lead_type}</Typography>
                </Labeled>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Labeled label={"Non Premium"}>
                  <Typography gutterBottom>
                    {non_premium_per_lead_type}
                  </Typography>
                </Labeled>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Labeled label={"Remaining Emails"}>
                  <Typography gutterBottom>{remaining_emails}</Typography>
                </Labeled>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Labeled label={"Total Courses"}>
                  <Typography gutterBottom>{total_courses}</Typography>
                </Labeled>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Labeled label={"Total Distinct Courses"}>
                  <Typography gutterBottom>{total_distinct_courses}</Typography>
                </Labeled>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </>
    );
  }

  return (
    <>
      {type !== "summary" ? (
        <>
          <Grid item xs={12}>
            <Grid item xs={4}>
              <Button variant="outlined" onClick={() => handleExport()}>
                {"EXPORT"}
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  {table_fields.map((table_field, index) => (
                    <TableCell key={index}>
                      {table_field === "created_at" ? (
                        <TableSortLabel active={true} direction={"desc"}>
                          {translate(`leads.fields.${table_field}`)}
                        </TableSortLabel>
                      ) : (
                        translate(`leads.fields.${table_field}`)
                      )}
                    </TableCell>
                  ))}
                  {type !== "bulk" && (
                    <>
                      <TableCell>
                        {translate(`resources.schools.name`)}
                      </TableCell>
                      <TableCell>
                        {translate(`resources.courses.name`)}
                      </TableCell>
                    </>
                  )}
                  {type === "bulk" && (
                    <TableCell>
                      {translate(`leads.fields.total_receiver`)}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data !== undefined &&
                  Object.keys(data).map((key, index) => {
                    return <Row row={data[key]} />;
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Title</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <React.Fragment>
                  <RowSummary type={"total"} />
                  <RowSummary type={"inquiry"} />
                  <RowSummary type={"bulk"} />
                </React.Fragment>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const tabs = [
  {
    id: "inquiries",
    name: "inquiries",
    tab_panel: <TabbedDatagrid type={"inquiries"} />,
  },
  { id: "bulk", name: "bulk", tab_panel: <TabbedDatagrid type={"bulk"} /> },
  {
    id: "reimbursement",
    name: "reimbursement",
    tab_panel: <TabbedDatagrid type={"reimbursement"} />,
  },
  {
    id: "summary",
    name: "summary",
    tab_panel: <TabbedDatagrid type={"summary"} />,
  },
];

export const LeadsTabs = ({
  school_id_input,
  course_id_input,
  ...props
}: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [filter, setFilter] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [school_id, setSchoolId] = React.useState(school_id_input);
  const [course_id, setCourseId] = React.useState(course_id_input);
  const [lead_id, setLeadId] = React.useState(undefined);
  const [pay_per_lead, setPayPerLead] = React.useState(undefined);
  const [end_date, setEndDate] = React.useState(undefined);
  const [term, setTerm] = React.useState(undefined);

  const today = new Date();
  const last_month = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate()
  );

  const [start_date, setStartDate] = React.useState<Date | undefined>(
    last_month
  );

  function a11yProps(index: any) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <div>
      <FormWithRedirect
        {...props}
        render={(formProps: any) => (
          <div className={classes.root}>
            {course_id_input === undefined && (
              <Button
                variant="outlined"
                onClick={() => {
                  setSchoolId(school_id_input);
                  setCourseId(course_id_input);
                  setFilter(!filter);
                }}
              >
                {filter === false
                  ? translate(`sentence.more_filters`)
                  : translate(`sentence.less_filters`)}
              </Button>
            )}
            {filter === true && (
              <Grid container spacing={1}>
                {school_id_input === undefined &&
                  course_id_input === undefined && (
                    <Grid item xs={12} sm={6}>
                      <ReferenceInput
                        perPage={1000}
                        source="school_id"
                        reference="schools"
                        sort={{ field: "name", order: "ASC" }}
                        filter={{
                          show_only: ["id", "name_and_abbreviation"],
                        }}
                        inputProps={{
                          onChange: (e) => {
                            if (e.target.value === "") {
                              setSchoolId(undefined);
                            } else {
                              setSchoolId(e.target.value);
                            }
                          },
                        }}
                        allowEmpty={true}
                        fullWidth
                      >
                        <SelectInput
                          optionText="name_and_abbreviation"
                          optionValue="id"
                        />
                      </ReferenceInput>
                    </Grid>
                  )}
                {school_id !== undefined && (
                  <Grid
                    item
                    xs={12}
                    sm={
                      school_id_input === undefined &&
                      course_id_input === undefined
                        ? 12
                        : 6
                    }
                  >
                    <ReferenceInput
                      perPage={1000}
                      source="course_id"
                      reference="courses"
                      filter={{
                        school_id: school_id,
                        show_only: ["id", "short_name"],
                      }}
                      sort={{ field: "short_name", order: "ASC" }}
                      inputProps={{
                        onChange: (e) => {
                          if (e.target.value === "") {
                            setCourseId(undefined);
                          } else {
                            setCourseId(e.target.value);
                          }
                        },
                      }}
                      allowEmpty={true}
                      fullWidth
                    >
                      <SelectInput optionText="short_name" optionValue="id" />
                    </ReferenceInput>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <DateInputField
                  resource={"leads"}
                  source={"start_date"}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value === "") {
                      setStartDate(undefined);
                    } else {
                      setStartDate(value);
                    }
                  }}
                  allowEmpty={true}
                  defaultValue={start_date}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DateInputField
                  resource={"leads"}
                  source={"end_date"}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value === "") {
                      setEndDate(undefined);
                    } else {
                      setEndDate(value);
                    }
                  }}
                  allowEmpty={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ReferenceInput
                  perPage={1000}
                  source="lead_id"
                  reference="lead_options"
                  filter={{
                    school_id: school_id,
                    show_only: ["id", "title"],
                  }}
                  sort={{ field: "title", order: "ASC" }}
                  inputProps={{
                    onChange: (e) => {
                      if (e.target.value === "") {
                        setLeadId(undefined);
                      } else {
                        setLeadId(e.target.value);
                      }
                    },
                  }}
                  allowEmpty={true}
                  fullWidth
                >
                  <SelectInput optionText="title" optionValue="id" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} sm={3}>
                <NullableBooleanInput
                  source="pay_per_lead"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setPayPerLead(undefined);
                    } else {
                      setPayPerLead(e.target.value);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInputField
                  resource={"leads"}
                  source={"term"}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value.length < 3) {
                      setTerm(undefined);
                    } else {
                      setTerm(value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
              >
                {Object.keys(tabs).map((key) => {
                  return (
                    <Tab
                      key={key}
                      tabIndex={Number(key)}
                      label={translate(`leads.${tabs[key].name}.name`)}
                      {...a11yProps(Number(key))}
                      icon={tabs[key].icon}
                    />
                  );
                })}
              </Tabs>

              <TabPanel value={value} index={0}>
                <TabbedDatagrid
                  type={"inquiries"}
                  school_id={school_id}
                  course_id={course_id}
                  end_date={end_date}
                  start_date={start_date}
                  lead_id={lead_id}
                  pay_per_lead={pay_per_lead}
                  term={term}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TabbedDatagrid
                  type={"bulk"}
                  school_id={school_id}
                  course_id={course_id}
                  end_date={end_date}
                  start_date={start_date}
                  lead_id={lead_id}
                  pay_per_lead={pay_per_lead}
                  term={term}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <TabbedDatagrid
                  type={"reimbursement"}
                  school_id={school_id}
                  course_id={course_id}
                  end_date={end_date}
                  start_date={start_date}
                  lead_id={lead_id}
                  pay_per_lead={pay_per_lead}
                  term={term}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <TabbedDatagrid
                  type={"summary"}
                  school_id={school_id}
                  course_id={course_id}
                  end_date={end_date}
                  start_date={start_date}
                  lead_id={lead_id}
                  pay_per_lead={pay_per_lead}
                  term={term}
                />
              </TabPanel>
            </AppBar>
          </div>
        )}
      />
    </div>
  );
};

const Leads: FC<ListProps> = (props) => <LeadsTabs />;

export default Leads;
