import WizardIcon from "@material-ui/icons/DynamicFeed";
import WizardList from "./WizardList";
import WizardShow from "./WizardShow";
import WizardEdit from "./WizardEdit";
import WizardCreate from "./WizardCreate";

const resource = {
  icon: WizardIcon,
  list: WizardList,
  show: WizardShow,
  edit: WizardEdit,
  create: WizardCreate,
};

export default resource;
