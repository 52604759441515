import * as React from "react";
import {
  FormWithRedirect,
  Labeled,
  useGetList,
  useTranslate,
  useRefresh,
  SelectInput,
} from "react-admin";
import { SelectStyle, StyledTableCell, StyledTableRow } from "../styles";
import {
  Collapse,
  Grid,
  Box,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons/";
import PropTypes from "prop-types";
import { LinearLoading, TextInputField, DateInputField } from "../components";
import { apiUrl, httpClient } from "../dataProvider";
import { Check, Clear } from "@material-ui/icons/";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const table_fields = [
  "user",
  "comment",
  "created_at",
  "created_by",
  "priority",
  "due_date",
  "done",
];

const Row = ({ row }: any) => {
  const translate = useTranslate();
  const selectClasses = SelectStyle();
  const [open, setOpen] = React.useState(false);
  const [user_type, setUserType] = React.useState(row["model"]["type"]);
  const [lead_status, setLeadStatus] = React.useState(
    row["model"]["lead_status"]
  );
  const [done, setDone] = React.useState(row["done"]);
  const [due_date, setDueDate] = React.useState(row["due_date"]);
  const [start_date, setStartDate] = React.useState(row["start_date"]);

  const handleChangeUpdateUserType = (event) => {
    setUserType(event.target.value);
    const url = `${apiUrl}/users/${row["model"]["id"]}/type`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ user_type: event.target.value }),
    }).then(({ headers, json }: any) => {
      // Do nothing
    });
  };

  const handleChangeUpdateUserStatus = (event) => {
    setLeadStatus(event.target.value);
    const url = `${apiUrl}/users/${row["model"]["id"]}/status`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ user_status: event.target.value }),
    }).then(({ headers, json }: any) => {
      // Do nothing
    });
  };

  const handleMaskAsDone = (event) => {
    const url = `${apiUrl}/users/${row["id"]}/todos/${row["id"]}/mark_as_done`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ done: !done }),
    }).then(({ headers, json }: any) => {
      setDone(!done);
    });
  };

  const updateStartDate = (startDate) => {
    const url = `${apiUrl}/users/${row["id"]}/todos/${row["id"]}/start_date`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ date: startDate }),
    }).then(({ headers, json }: any) => {
      // Do nothing
    });
  };

  const updateEndDate = (endDate) => {
    const url = `${apiUrl}/users/${row["id"]}/todos/${row["id"]}/due_date`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ date: endDate }),
    }).then(({ headers, json }: any) => {
      // Do nothing
    });
  };
  console.log(due_date);
  console.log(row["due_date"]);
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{`${row["model"]["first_name"]} ${row["model"]["last_name"]}`}</StyledTableCell>
        <StyledTableCell>{row[table_fields[1]]}</StyledTableCell>
        <StyledTableCell>{row[table_fields[2]]}</StyledTableCell>
        <StyledTableCell>{`${row["created_user"]["first_name"]} ${row["created_user"]["last_name"]}`}</StyledTableCell>
        <StyledTableCell>{row[table_fields[4]]}</StyledTableCell>
        <StyledTableCell>{row[table_fields[5]]}</StyledTableCell>
        <StyledTableCell>{done ? <Check /> : <Clear />}</StyledTableCell>
      </StyledTableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={open}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Labeled label={translate(`todos.fields.first_name`)}>
                <Typography gutterBottom>
                  {row["model"]["first_name"]}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled label={translate(`todos.fields.last_name`)}>
                <Typography gutterBottom>
                  {row["model"]["last_name"]}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled label={translate(`todos.fields.email`)}>
                <Typography gutterBottom>{row["model"]["email"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <InputLabel id="user-type-label">
                  {translate(`users.fields.user_type`)}
                </InputLabel>
                <Select
                  className={selectClasses.full}
                  labelId={"user-type-label"}
                  id="user_type"
                  value={user_type}
                  onChange={handleChangeUpdateUserType}
                >
                  <MenuItem value={"Not set"}>Not set</MenuItem>
                  <MenuItem value={"A"}>A</MenuItem>
                  <MenuItem value={"B"}>B</MenuItem>
                  <MenuItem value={"C"}>C</MenuItem>
                  <MenuItem value={"D"}>D</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <InputLabel id="user-status-label">
                  {translate(`users.fields.user_status`)}
                </InputLabel>
                <Select
                  className={selectClasses.full}
                  labelId={"user-status-label"}
                  id="user_status"
                  value={lead_status}
                  onChange={handleChangeUpdateUserStatus}
                >
                  <MenuItem value={undefined}> </MenuItem>
                  <MenuItem value={"New"}>New</MenuItem>
                  <MenuItem value={"Updated"}>Updated</MenuItem>
                  <MenuItem value={"Not reached"}>Not reached</MenuItem>
                  <MenuItem value={"Call not wished"}>Call not wished</MenuItem>
                  <MenuItem value={"Not called"}>Not called</MenuItem>
                  <MenuItem value={"Contacted"}>Contacted</MenuItem>
                  <MenuItem value={"Stopped searching"}>
                    Stopped searching
                  </MenuItem>
                  <MenuItem value={"Want to book"}>Want to book</MenuItem>
                  <MenuItem value={"Booked without us"}>
                    Booked without us
                  </MenuItem>
                  <MenuItem value={"Booked with us"}>Booked with us</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Labeled label={translate(`todos.fields.comment`)}>
                <Typography gutterBottom>{row["comment"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Labeled label={translate(`todos.fields.start_date`)}>
                    <Typography gutterBottom>{start_date}</Typography>
                  </Labeled>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="date"
                    label="Update start date"
                    type="date"
                    defaultValue={start_date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      const { value } = event.target;
                      if (value === "") {
                        setStartDate(undefined);
                      } else {
                        setStartDate(value);
                        updateStartDate(value);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Labeled label={translate(`todos.fields.due_date`)}>
                    <Typography gutterBottom>{due_date}</Typography>
                  </Labeled>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="date"
                    label="Update Due date"
                    type="date"
                    defaultValue={due_date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      const { value } = event.target;
                      if (value === "") {
                        setDueDate(undefined);
                      } else {
                        setDueDate(value);
                        updateEndDate(value);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Button color="secondary" size="small" onClick={handleMaskAsDone}>
                {done ? "Deselect as done" : "Mark as done"}
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
};

const TodoList = ({
  lead_status,
  term,
  start_date,
  due_date,
  priority,
  hide_archived,
  status,
}: any) => {
  const translate = useTranslate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const filters = {
    lead_status: lead_status,
    term: term,
    start_date: start_date,
    due_date: due_date,
    priority: priority,
    status: status,
  };

  const sort = { field: "created_at", order: "DESC" };
  const { total, error, data, loading } = useGetList(
    "todos",
    { perPage: rowsPerPage, page: page + 1 },
    sort,
    filters
  );
  console.log(data, loading, total, error);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LinearLoading />;
  }

  let sortedData = data !== undefined ? Object.keys(data).reverse() : [];
  return (
    <>
      <Grid container spacing={1}>
        {/* <Grid item xs={12}>
          <Button variant="outlined" onClick={() => handleExport()}>
            {"EXPORT"}
          </Button>
        </Grid> */}
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {table_fields.map((table_field, index) => (
                <TableCell key={index} sortDirection={false}>
                  {translate(`todos.fields.${table_field}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData !== undefined &&
              sortedData.map((key, index) => {
                return (
                  <Row
                    key={key}
                    row={data[key]}
                    hide_archived={hide_archived}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

const ContactTodo = ({ ...props }: any) => {
  const classesSelect = SelectStyle();
  const classes = useStyles();
  const [user_type, setUserType] = React.useState<string | undefined>(
    undefined
  );
  const [lead_status, setLeadStatus] = React.useState<string | undefined>(
    undefined
  );
  const [term, setTerm] = React.useState(undefined);
  const [start_date, setStartDate] = React.useState(undefined);
  const [due_date, setDueDate] = React.useState(undefined);
  const [priority, setPriority] = React.useState<string | undefined>(undefined);
  const [status, setStatus] = React.useState<string | undefined>("open");

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextInputField
                resource={"todos"}
                source={"term"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value.length < 3) {
                    setTerm(undefined);
                  } else {
                    setTerm(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                className={classesSelect.small}
                fullWidth
                resource={"users"}
                source={"user_type"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setUserType(undefined);
                  } else {
                    setUserType(value);
                  }
                }}
                choices={[
                  { id: null, name: "" },
                  { id: "Not set", name: "Not set" },
                  { id: "A", name: "A" },
                  { id: "B", name: "B" },
                  { id: "C", name: "C" },
                  { id: "D", name: "D" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                className={classesSelect.small}
                fullWidth
                resource={"todos"}
                source={"lead_status"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setLeadStatus(undefined);
                  } else {
                    setLeadStatus(value);
                  }
                }}
                choices={[
                  { id: null, name: "" },
                  { id: "New", name: "New" },
                  { id: "Updated", name: "Updated" },
                  { id: "Not reached", name: "Not reached" },
                  { id: "Call not wished", name: "Call not wished" },
                  { id: "Not called", name: "Not called" },
                  { id: "Contacted", name: "Contacted" },
                  { id: "Stopped searching", name: "Stopped searching" },
                  { id: "Want to book", name: "Want to book" },
                  { id: "Booked without us", name: "Booked without us" },
                  { id: "Booked with us", name: "Booked with us" },
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <DateInputField
                resource={"todos"}
                source={"start_date"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setStartDate(undefined);
                  } else {
                    setStartDate(value);
                  }
                }}
                allowEmpty={true}
              />
            </Grid>
            <Grid item xs={3}>
              <DateInputField
                resource={"todos"}
                source={"due_date"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setDueDate(undefined);
                  } else {
                    setDueDate(value);
                  }
                }}
                allowEmpty={true}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                className={classesSelect.small}
                fullWidth
                resource={"todos"}
                source={"priority"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setPriority(undefined);
                  } else {
                    setPriority(value);
                  }
                }}
                choices={[
                  { id: null, name: "" },
                  { id: "low", name: "Low" },
                  { id: "medium", name: "Medium" },
                  { id: "high", name: "High" },
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                className={classesSelect.small}
                fullWidth
                resource={"leads"}
                source={"status"}
                defaultValue={"open"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setStatus(undefined);
                  } else {
                    setStatus(value);
                  }
                }}
                choices={[
                  { id: null, name: "" },
                  { id: "open", name: "open" },
                  { id: "closed", name: "closed" },
                  { id: "open_expired", name: "Open and expired" },
                ]}
              />
            </Grid>
          </Grid>
          <TodoList
            user_type={user_type}
            lead_status={lead_status}
            term={term}
            start_date={start_date}
            due_date={due_date}
            priority={priority}
            status={status}
          />
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default ContactTodo;
