import * as React from "react";
import { FC } from "react";
import { ShowButton, Toolbar, useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppState } from "../types";
import CancelIcon from "@material-ui/icons/Cancel";
import { ModelLocaleSelect } from ".";
import { ButtonStyle } from "../styles";
import { MySaveButton } from "../components";

const MyActionEditBar: FC<Omit<any, "children">> = ({
  basePath,
  data,
  disableModelLocal = undefined,
  forceModelLocale = undefined,
  ...props
}) => {
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  const buttonClasses = ButtonStyle();
  const translate = useTranslate();
  return (
    <div
      className={open ? "sticky-bottom-menu-open" : "sticky-bottom-menu-close"}
    >
      <Toolbar {...props}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <ModelLocaleSelect
              disableModelLocal={disableModelLocal}
              forceModelLocale={forceModelLocale}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <MySaveButton {...props} />
              </Grid>
              <Grid item>
                <ShowButton
                  label={translate(`ra.action.cancel`)}
                  icon={<CancelIcon />}
                  basePath={basePath}
                  record={props.record}
                  className={buttonClasses.cancelTextButton}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </div>
  );
};
export default MyActionEditBar;
