import * as React from "react";
import { ReviewsList } from "../components";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";
import { UserTitle } from "./UserForm";
import { usePermissions } from "react-admin";

const UserReviews = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <ReviewsList
      target={"related_user_id"}
      title={<UserTitle />}
      resources={"users"}
      reference={"reviews"}
      model_id={props.id}
      canApprove={permissions.includes(USER_ROLE_SUPER_ADMIN)}
      canReply={permissions.includes(USER_ROLE_SUPER_ADMIN)}
      isExpert={true}
    />
  );
};

export default UserReviews;
