import * as React from "react";

import { Grid } from "@material-ui/core";
import { TextInputField, RichTextInputField } from "../components";

import ReferenceDataTable from "../referenceData/ReferenceDataTable";
import KbUnitUploadsFields from "./KbUnitUploadsFields";
const resource = "kb_units";

const KbUnitsFormEditCreateForm = ({ ...props }: any) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextInputField
          source="description"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          source="video_url"
          resource={resource}
          disabled={false}
          validate={false}
        />
      </Grid>
    </Grid>
  );
};
const KbUnitsForm = ({ props }: any) => {
  const fields = ["title"];
  const reference_data_fields = ["title", "description", "video_url"];

  return (
    <ReferenceDataTable
      resource={resource}
      table_fields={fields}
      reference_data_fields={reference_data_fields}
      {...props}
      editForm={<KbUnitsFormEditCreateForm type={"edit"} />}
      createForm={<KbUnitsFormEditCreateForm type={"create"} />}
      reference_data_files={"uploads"}
    />
  );
};

export default KbUnitsForm;
