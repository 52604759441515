import * as React from "react";
import { FC } from "react";
import { TextInput, useTranslate, email, required } from "react-admin";
import { TextProps } from "./types";

const TextInputEmailField: FC<TextProps> = (props) => {
  const translate = useTranslate();
  return (
    <TextInput
      source={props.source}
      label={
        props.label ?? translate(`${props.resource}.fields.${props.source}`)
      }
      resource={props.resource}
      record={props.record}
      fullWidth
      disabled={props.disabled}
      type="email"
      validate={props.validate ? [required(), email()] : email()}
      helperText={props.helperText ?? <p></p>}
    />
  );
};

export default TextInputEmailField;
