import * as React from "react";
import {
  useTranslate,
  usePermissions,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import {
  BoxTitle,
  ReferenceTags,
  ReferenceAwards,
  ReferenceStudyFields,
  ReferenceSubStudyFields,
} from "../components";
import { Grid, Collapse } from "@material-ui/core";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  warningSpacing: {
    marginTop: "-20px",
  },
}));

export const CourseStudyFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [studyFieldCategory, setStudyFieldCategory] = React.useState<Number>(0);
  const [studyField, setStudyField] = React.useState<Number>(0);
  const translate = useTranslate();

  return (
    <>
      <p>Field of studies</p>
      {disabled_level_2 ? (
        <>
          {props !== undefined &&
            props.record !== undefined &&
            props.record.course_field_of_studies !== undefined &&
            Array.isArray(props.record.course_field_of_studies) &&
            props.record.course_field_of_studies.map((item, index) =>
              !item.study_field_category ? (
                ""
              ) : (
                <p>{`${
                  item.study_field_category
                    ? item.study_field_category.title
                    : ""
                } -> ${item.study_field ? item.study_field.title : ""} ${
                  item.sub_study_field
                    ? " -> " + item.sub_study_field.title
                    : ""
                }`}</p>
              )
            )}
        </>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ArrayInput source={"course_field_of_studies"} label={null}>
              <SimpleFormIterator>
                <ReferenceInput
                  source={"study_field_category_id"}
                  reference={"study_field_categories"}
                  label={translate(
                    `resources.study_field_categories.name_singular`
                  )}
                  perPage={1000}
                  fullWidth
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value === "") {
                      setStudyFieldCategory(0);
                      setStudyField(0);
                    } else {
                      setStudyFieldCategory(value);
                      setStudyField(0);
                    }
                  }}
                  isRequired={true}
                >
                  <SelectInput
                    fullWidth
                    optionText={"title"}
                    optionValue={"id"}
                  />
                </ReferenceInput>
                <ReferenceInput
                  source={"study_field_id"}
                  reference={"study_fields"}
                  label={translate(`resources.study_fields.name_singular`)}
                  perPage={1000}
                  fullWidth
                  filter={{ study_field_category_id: studyFieldCategory }}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value === "") {
                      setStudyField(0);
                    } else {
                      setStudyField(value);
                    }
                  }}
                  isRequired={true}
                >
                  <SelectInput
                    fullWidth
                    optionText={"title"}
                    optionValue={"id"}
                  />
                </ReferenceInput>
                <ReferenceInput
                  source={"sub_study_field_id"}
                  reference={"sub_study_fields"}
                  label={translate(`resources.sub_study_fields.name_singular`)}
                  perPage={1000}
                  fullWidth
                  filter={{ study_field_id: studyField }}
                  isRequired={false}
                  allowEmpty={true}
                >
                  <SelectInput
                    fullWidth
                    optionText={"title"}
                    optionValue={"id"}
                  />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const CourseRelationFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`courses.boxes.relation_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CourseStudyFields
              disabled_level_1={disabled_level_1}
              disabled_level_2={disabled_level_2}
              {...props}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceTags disabled={disabled_level_2} />
          </Grid>
          {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
            <>
              <Grid item xs={12}>
                <ReferenceAwards disabled={disabled_level_1} />
              </Grid>
            </>
          )}
        </Grid>
      </Collapse>
    </>
  );
};

export default CourseRelationFields;
