import CourseIcon from "@material-ui/icons/LocalLibrary";

import CourseList from "./CourseList";
import CourseEdit from "./CourseEdit";
import CourseShow from "./CourseShow";
import CourseCreate from "./CourseCreate";

const resource = {
  list: CourseList,
  edit: CourseEdit,
  show: CourseShow,
  create: CourseCreate,
  icon: CourseIcon,
};

export default resource;
