import * as React from "react";
import { useTranslate, usePermissions } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BooleanInputField,
  ReferenceSelectInputField,
  BoxTitle,
  RichTextInputField,
  NumberInputField,
  LogoBackgroundImages,
  NullableBooleanInputField,
  GoToShowUrl,
} from "../components";
import SchoolSoaGeneralFields from "./SchoolSoaGeneralFields";
import { USER_ROLE_SOA_ADMIN } from "../Constants";

const SchoolGeneralFields = ({
  disabled_level_1,
  disabled_level_2,
  disabled_level_3,
  disabled_level_4,
  specialSoaRules,
  disableModelLocal = undefined,
  ...props
}: any) => {
  const { permissions = [] } = usePermissions();
  const [is_soa, setIsSoa] = React.useState(props.record.school_type_id === 6);
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  const changeSchoolType = {
    onChange: (e: any) => {
      if (e.target.value === 6) {
        setIsSoa(true);
      } else {
        setIsSoa(false);
      }
    },
  };

  return (
    <>
      <BoxTitle
        title={translate(`schools.boxes.general`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <>
          <LogoBackgroundImages
            resource={props.resource}
            disabled={disabled_level_2}
            item_logo={props.record.logo_image}
            item_background={props.record.profile_image}
            collection_background="profile_image"
            image_alt_background="Profile Image"
            {...props}
          />
          {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
            <Grid container spacing={1} justify="center" alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="premium"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ReferenceSelectInputField
                  source="contract_type_id"
                  reference="contract_types"
                  optionText="title"
                  optionValue="id"
                  disabled={disabled_level_1}
                  allowEmpty={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="soa_elite_sport"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="reviews_section"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="only_verified"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="online_courses"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="is_public"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <NullableBooleanInputField
                  source="is_updated"
                  resource={props.resource}
                  disabled={disabled_level_2}
                  helperText={translate(`sentence.school_is_updated`)}
                />
              </Grid>
            </Grid>
          )}
        </>
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item xs={11}>
            <ReferenceSelectInputField
              source="organization_id"
              reference="organizations"
              optionText="name"
              optionValue="id"
              disabled={true}
              inputProps={null}
              pagination={{ page: 1, perPage: 0 }}
            />
          </Grid>
          <Grid item xs={1}>
            <GoToShowUrl
              resource={"organizations"}
              id={props.record.organization_id}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputField
              source="name"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              validate={true}
              translation={disableModelLocal ? false : true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputField
              source="subtitle"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              translation={disableModelLocal ? false : true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputField
              source="abbreviation"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              translation={disableModelLocal ? false : true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputField
              source="slug"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              validate={true}
              translation={disableModelLocal ? false : true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReferenceSelectInputField
              source="school_type_id"
              reference="school_types"
              optionText="title"
              optionValue="id"
              disabled={disabled_level_1}
              inputProps={changeSchoolType}
            />
          </Grid>
          {(!permissions.includes(USER_ROLE_SOA_ADMIN) || specialSoaRules) && (
            <>
              <Grid item xs={12} sm={6}>
                <ReferenceSelectInputField
                  source="initial_language_id"
                  reference="initial_languages"
                  optionText="title"
                  optionValue="id"
                  disabled={disabled_level_1}
                  inputProps={changeSchoolType}
                  allowEmpty={true}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={2}>
                <NullableBooleanInputField
                  source="sport_facility"
                  resource={props.resource}
                  disabled={disabled_level_2}
                />
              </Grid>
              <Grid item xs={12} sm={8} lg={10}>
                <TextInputField
                  source="sport_facility_description"
                  resource={props.resource}
                  record={props.record}
                  disabled={disabled_level_2}
                  translation={true}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={2}>
                <NullableBooleanInputField
                  source="food_facility"
                  resource={props.resource}
                  disabled={disabled_level_2}
                />
              </Grid>
              <Grid item xs={12} sm={8} lg={10}>
                <TextInputField
                  source="food_facility_description"
                  resource={props.resource}
                  record={props.record}
                  disabled={disabled_level_2}
                  translation={true}
                />
              </Grid>
              <Grid item xs={12}>
                <RichTextInputField
                  source="description"
                  disabled={disabled_level_2}
                  resource={props.resource}
                  record={props.record}
                  translation={disableModelLocal ? false : true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberInputField
                  source="founding_year"
                  resource={props.resource}
                  record={props.record}
                  disabled={disabled_level_2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberInputField
                  source="students_count"
                  resource={props.resource}
                  record={props.record}
                  disabled={disabled_level_2}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Collapse>
      {is_soa && (
        <SchoolSoaGeneralFields
          disabled_level_1={disabled_level_1}
          disabled_level_2={disabled_level_2}
          {...props}
        />
      )}
    </>
  );
};

export default SchoolGeneralFields;
