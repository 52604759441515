import * as React from "react";
import {
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  Labeled,
  DateInput,
  BooleanInput,
} from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import { BoxTitle } from "../components";
import { Check, Clear } from "@material-ui/icons/";

const CourseStartDateFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`courses.boxes.start_dates`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        {disabled_level_2 === false && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ArrayInput source="start_dates">
                <SimpleFormIterator>
                  <DateInput
                    source={"start_date"}
                    label={translate(`courses.fields.start_dates.start_date`)}
                  />
                  <BooleanInput
                    source="taking_place_definitely"
                    label={translate(
                      `courses.fields.start_dates.taking_place_definitely`
                    )}
                    disabled={disabled_level_2}
                    defaultValue={false}
                  />
                  <BooleanInput
                    source="has_open_spots"
                    label={translate(
                      `courses.fields.start_dates.has_open_spots`
                    )}
                    disabled={disabled_level_2}
                    defaultValue={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        )}
        {disabled_level_2 === true &&
          props !== undefined &&
          props.record !== undefined &&
          props.record.start_dates !== undefined &&
          Array.isArray(props.record.start_dates) &&
          props.record.start_dates.map((item, index) => (
            <div key={index}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Labeled
                    resource={props.resource}
                    label={translate(`courses.fields.start_dates.start_date`)}
                  >
                    <p>{item.start_date}</p>
                  </Labeled>
                </Grid>
                <Grid item xs={4}>
                  <Labeled
                    resource={props.resource}
                    label={translate(
                      `courses.fields.start_dates.taking_place_definitely`
                    )}
                  >
                    <>
                      {item.taking_place_definitely === true && <Check />}
                      {item.taking_place_definitely === false && <Clear />}
                    </>
                  </Labeled>
                </Grid>
                <Grid item xs={4}>
                  <Labeled
                    resource={props.resource}
                    label={translate(
                      `courses.fields.start_dates.has_open_spots`
                    )}
                  >
                    <>
                      {item.has_open_spots === true && <Check />}
                      {item.has_open_spots === false && <Clear />}
                    </>
                  </Labeled>
                </Grid>
              </Grid>
              <hr />
            </div>
          ))}
      </Collapse>
    </>
  );
};

export default CourseStartDateFields;
