import OrganizationIcon from "@material-ui/icons/Business";

import OrganizationList from "./OrganizationList";
import OrganizationEdit from "./OrganizationEdit";
import OrganizationCreate from "./OrganizationCreate";
import OrganizationShow from "./OrganizationShow";

const resource = {
  list: OrganizationList,
  edit: OrganizationEdit,
  show: OrganizationShow,
  create: OrganizationCreate,
  icon: OrganizationIcon,
};

export default resource;
