import React from "react";
import { Bar } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  alignCenter: { textAlign: "center" },
}));

const BarChart = ({ title, data, labels, legend }: any) => {
  const colors = [
    "rgba(0,0,0,1)",
    "rgba(0,0,128,1)",
    "rgba(0,0,255,1)",
    "rgba(0,128,0,1)",
    "rgba(0,128,128,1)",
    "rgba(0,255,0,1)",
    "rgba(0,255,255,1)",
    "rgba(128,0,0,1)",
    "rgba(128,0,128,1)",
    "rgba(128,128,0,1)",
    "rgba(128,128,128,1)",
    "rgba(192,192,192,1)",
    "rgba(255,0,0,1)",
    "rgba(255,0,255,1)",
    "rgba(255,255,0,1)",
    "rgba(255,255,255,1)",
  ];

  const datasets = data.map(function (d, index) {
    d.backgroundColor = colors[index];
    return d;
  });

  const classes = useStyles();

  return (
    <>
      {title !== undefined && <h2 className={classes.alignCenter}>{title}</h2>}
      <Bar
        data={{
          labels: labels,
          datasets: datasets,
        }}
        options={{
          title: {
            display: false,
            text: title,
            fontSize: 20,
          },
        }}
      />
    </>
  );
};

export default BarChart;
