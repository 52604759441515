import * as React from "react";
import { FC } from "react";
import {
  useTranslate,
  FormWithRedirect,
  Edit,
  SimpleForm,
  Show,
  usePermissions,
} from "react-admin";
import CourseTabSummary from "./CourseTabSummary";
import CourseEvents from "./CourseEvents";
import CourseAdministrators from "./CourseAdministrators";
import CourseExperts from "./CourseExperts";
import CourseSuccessStories from "./CourseSuccessStories";
import CourseReviews from "./CourseReviews";
// import { CourseStatistics } from "../statistics";
import CourseLeads from "./CourseLeads";
import { MyActionShowBar, MyActionEditBar } from "../components";
import { useMediaQuery } from "@material-ui/core";
import {
  Storage,
  Event,
  SupervisorAccount,
  BarChart,
  SentimentSatisfiedAlt,
  HowToReg,
  Message,
  RateReview,
} from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Tabs, AppBar, Tab, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import {
  USER_ROLE_COURSE_ADMIN,
  USER_ROLE_SCHOOL_ADMIN,
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_SOA_ADMIN,
} from "../Constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const CourseTitle: FC<Omit<any, "children">> = (props: any) => {
  const translate = useTranslate();
  return (
    <span>
      {translate(`resources.courses.name_singular`)}{" "}
      {props.record ? props.record.short_name : ""}
    </span>
  );
};

const CourseForm = ({ action, ...props }: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { permissions = [] } = usePermissions();
  const [value, setValue] = React.useState(0);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  function a11yProps(index: any) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const disabled_level_1 = !permissions.includes(USER_ROLE_SUPER_ADMIN);
  var disabled_level_2 = !(
    permissions.includes(USER_ROLE_SUPER_ADMIN) ||
    permissions.includes(USER_ROLE_SCHOOL_ADMIN) ||
    permissions.includes(USER_ROLE_COURSE_ADMIN)
  );
  const disabled_level_3 = !(
    permissions.includes(USER_ROLE_SUPER_ADMIN) ||
    ((permissions.includes(USER_ROLE_SCHOOL_ADMIN) ||
      permissions.includes(USER_ROLE_COURSE_ADMIN)) &&
      props !== undefined &&
      props.record !== undefined &&
      props.record.premium !== null &&
      props.record.premium === true)
  );
  // SOA admin
  const disabled_level_4 = !(
    permissions.includes(USER_ROLE_SUPER_ADMIN) ||
    permissions.includes(USER_ROLE_SOA_ADMIN)
  );

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                tabIndex={0}
                label={translate(`courses.tabs.summary`)}
                {...a11yProps(0)}
                icon={<Storage />}
              />
              {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
                <Tab
                  value={1}
                  tabIndex={1}
                  label={translate(`courses.tabs.experts`)}
                  {...a11yProps(1)}
                  icon={<HowToReg />}
                  disabled={action !== "show"}
                />
              )}
              {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
                <Tab
                  value={2}
                  tabIndex={2}
                  label={translate(`courses.tabs.events`)}
                  {...a11yProps(2)}
                  icon={<Event />}
                  disabled={action !== "show"}
                />
              )}
              {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
                <Tab
                  value={3}
                  tabIndex={3}
                  label={translate(`courses.tabs.success_stories`)}
                  {...a11yProps(3)}
                  icon={<SentimentSatisfiedAlt />}
                  disabled={action !== "show"}
                />
              )}
              {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
                <Tab
                  value={7}
                  label={translate(`courses.tabs.reviews`)}
                  {...a11yProps(7)}
                  icon={<RateReview />}
                  disabled={action !== "show"}
                />
              )}
              {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
                <Tab
                  value={4}
                  tabIndex={4}
                  label={translate(`courses.tabs.administrators`)}
                  {...a11yProps(4)}
                  icon={<SupervisorAccount />}
                  disabled={action !== "show"}
                />
              )}
              {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
                <Tab
                  value={5}
                  tabIndex={5}
                  label={translate(`courses.tabs.statistics`)}
                  {...a11yProps(5)}
                  icon={<BarChart />}
                  disabled={action !== "show"}
                />
              )}
              {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
                <Tab
                  value={6}
                  tabIndex={6}
                  label={translate(`courses.tabs.leads`)}
                  {...a11yProps(6)}
                  icon={<Message />}
                  disabled={action !== "show"}
                />
              )}
            </Tabs>
            <TabPanel value={value} index={0}>
              {action === "show" && (
                <Show
                  {...props}
                  title={<CourseTitle />}
                  actions={
                    isXSmall ? (
                      <></>
                    ) : (
                      <MyActionShowBar
                        disabledEdit={!(!disabled_level_2 || !disabled_level_4)}
                      />
                    )
                  }
                  component="div"
                >
                  <SimpleForm
                    toolbar={
                      <MyActionShowBar
                        disabledEdit={!(!disabled_level_2 || !disabled_level_4)}
                      />
                    }
                    submitOnEnter={false}
                  >
                    <CourseTabSummary
                      disabled_level_1={
                        action === "show" ? true : disabled_level_1
                      }
                      disabled_level_2={
                        action === "show" ? true : disabled_level_2
                      }
                      disabled_level_3={
                        action === "show" ? true : disabled_level_3
                      }
                      disabled_level_4={
                        action === "show" ? true : disabled_level_4
                      }
                      action={action}
                      {...props}
                    />
                  </SimpleForm>
                </Show>
              )}
              {action === "edit" && (
                <Edit
                  {...props}
                  title={<CourseTitle />}
                  actions={<></>}
                  component="div"
                >
                  <SimpleForm
                    toolbar={<MyActionEditBar {...props} />}
                    submitOnEnter={false}
                    redirect="show"
                  >
                    <CourseTabSummary
                      disabled_level_1={
                        action === "show" ? true : disabled_level_1
                      }
                      disabled_level_2={
                        action === "show" ? true : disabled_level_2
                      }
                      disabled_level_3={
                        action === "show" ? true : disabled_level_3
                      }
                      disabled_level_4={
                        action === "show" ? true : disabled_level_4
                      }
                      action={action}
                      {...props}
                    />
                  </SimpleForm>
                </Edit>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CourseExperts {...props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CourseEvents {...props} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <CourseSuccessStories {...props} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <CourseAdministrators {...props} />
            </TabPanel>
            {/* <TabPanel value={value} index={6}>
              <CourseStatistics {...props} />
            </TabPanel>
            */}
            <TabPanel value={value} index={6}>
              <CourseLeads {...props} />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <CourseReviews {...props} />
            </TabPanel>
          </AppBar>
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default CourseForm;
