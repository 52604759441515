import * as React from "react";
import { ReviewsList } from "../components";
import { USER_ROLE_SCHOOL_ADMIN, USER_ROLE_SUPER_ADMIN } from "../Constants";
import { SchoolTitle } from "./SchoolForm";
import { usePermissions } from "react-admin";

const SchoolReviews = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <ReviewsList
      target={"related_school_id"}
      title={<SchoolTitle />}
      resources={"schools"}
      reference={"reviews"}
      model_id={props.id}
      canApprove={permissions.includes(USER_ROLE_SUPER_ADMIN)}
      canReply={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN)
      }
    />
  );
};

export default SchoolReviews;
