import * as React from "react";
import {
  usePermissions,
  ArrayInput,
  SimpleFormIterator,
  useTranslate,
  SelectInput,
  required,
  FormDataConsumer,
} from "react-admin";
import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import {
  TextInputField,
  BooleanInputField,
  NumberInputField,
} from "../components";

const WizardTabSummary = ({
  action,
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const translate = useTranslate();
  const [personalInfoData, setPersonalInfoData] = React.useState<any>({
    first_name: { mandatory: true, visible: true },
    last_name: { mandatory: true, visible: true },
    email: { mandatory: true, visible: true },
    street: { mandatory: false, visible: false },
    zip: { mandatory: false, visible: false },
    city: { mandatory: false, visible: false },
    phone: { mandatory: false, visible: false },
  });

  return (
    <>
      <Grid item xs={12}>
        <TextInputField
          source="slug"
          resource={props.resource}
          record={props.record}
          disabled={disabled_level_1}
          validate={true}
          translation={true}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={props.resource}
          record={props.record}
          disabled={disabled_level_1}
          validate={true}
          translation={true}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          source="sub_title"
          resource={props.resource}
          record={props.record}
          disabled={disabled_level_1}
          validate={false}
          translation={true}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInputField
          source="description"
          resource={props.resource}
          record={props.record}
          disabled={disabled_level_1}
          validate={false}
          translation={true}
        />
      </Grid>
      <Grid item xs={12}>
        <p>
          <b>Intro:</b> Intro is 100% frontend, only step is mandatory;
        </p>
        <p>
          <b>Personal Info:</b> It's not working properly;
        </p>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ArrayInput source={"wizard_pages"}>
            <SimpleFormIterator>
              <NumberInputField
                source={"page"}
                label={translate(`wizards.fields.page`)}
                resource={props.resource}
                record={props.record}
                disabled={disabled_level_1}
                validate={true}
              />
              <SelectInput
                fullWidth
                source={`style`}
                label={translate(`wizards.fields.style`)}
                placeholder={"style"}
                resource={props.resource}
                record={props.record}
                validate={required()}
                onChange={props.onChange}
                choices={[
                  { id: "intro", name: "Intro" },
                  { id: "dropdown", name: "Dropdown" },
                  { id: "rectangule_selection", name: "Rectangule Selection" },
                  { id: "square_selection", name: "Square Selection" },
                  { id: "free_text", name: "Free Text" },
                  { id: "range_selection", name: "Range Selection" },
                  { id: "personal_info", name: "Personal Info" },
                ]}
                disabled={disabled_level_1}
              />
              {/* <TextInputField
                source={"style"}
                label={translate(`wizards.fields.style`)}
                resource={props.resource}
                record={props.record}
                disabled={disabled_level_1}
              /> */}
              <TextInputField
                source={"step"}
                label={translate(`wizards.fields.step`)}
                resource={props.resource}
                record={props.record}
                disabled={disabled_level_1}
                validate={true}
              />
              <FormDataConsumer>
                {({ getSource, scopedFormData }) => {
                  if (scopedFormData === undefined) {
                    return <></>;
                  }

                  return (
                    <>
                      {scopedFormData.style !== "intro" && (
                        <>
                          {scopedFormData.style !== "personal_info" && (
                            <>
                              <TextInputField
                                // @ts-ignore
                                source={getSource("field")}
                                label={translate(`wizards.fields.field`)}
                                resource={props.resource}
                                record={scopedFormData}
                                disabled={disabled_level_1}
                                validate={
                                  scopedFormData.style !== "personal_info"
                                }
                              />
                              <BooleanInputField
                                // @ts-ignore
                                source={getSource("total_courses")}
                                label={translate(
                                  `wizards.fields.total_courses`
                                )}
                                resource={props.resource}
                                record={scopedFormData}
                                disabled={disabled_level_1}
                              />
                              <TextInputField
                                // @ts-ignore
                                source={getSource("title")}
                                label={translate(`wizards.fields.title`)}
                                resource={props.resource}
                                record={scopedFormData}
                                disabled={disabled_level_1}
                              />
                              <TextInputField
                                // @ts-ignore
                                source={getSource("sub_title")}
                                label={translate(`wizards.fields.sub_title`)}
                                resource={props.resource}
                                record={scopedFormData}
                                disabled={disabled_level_1}
                              />
                              <TextInputField
                                // @ts-ignore
                                source={getSource("description")}
                                label={translate(`wizards.fields.description`)}
                                resource={props.resource}
                                record={scopedFormData}
                                disabled={disabled_level_1}
                              />
                            </>
                          )}
                          {(scopedFormData.style === "dropdown" ||
                            scopedFormData.style === "free_text") && (
                            <TextInputField
                              // @ts-ignore
                              source={getSource("placeholder")}
                              label={translate(`wizards.fields.placeholder`)}
                              resource={props.resource}
                              record={scopedFormData}
                              disabled={disabled_level_1}
                            />
                          )}
                          {scopedFormData.style !== "dropdown" &&
                            scopedFormData.style !== "free_text" &&
                            scopedFormData.style !== "range_selection" &&
                            scopedFormData.style !== "personal_info" && (
                              <>
                                <NumberInputField
                                  // @ts-ignore
                                  source={getSource("minimum")}
                                  label={translate(`wizards.fields.minimum`)}
                                  resource={props.resource}
                                  record={scopedFormData}
                                  disabled={disabled_level_1}
                                />
                                <NumberInputField
                                  // @ts-ignore
                                  source={getSource("maximum")}
                                  label={translate(`wizards.fields.maximum`)}
                                  resource={props.resource}
                                  record={scopedFormData}
                                  disabled={disabled_level_1}
                                />
                                <NumberInputField
                                  // @ts-ignore
                                  source={getSource("columns")}
                                  label={translate(`wizards.fields.columns`)}
                                  resource={props.resource}
                                  record={scopedFormData}
                                  disabled={disabled_level_1}
                                />
                              </>
                            )}
                          {scopedFormData.style !== "personal_info" && (
                            <BooleanInputField
                              // @ts-ignore
                              source={getSource("mandatory")}
                              label={translate(`wizards.fields.mandatory`)}
                              resource={props.resource}
                              record={scopedFormData}
                              disabled={disabled_level_1}
                            />
                          )}
                          {scopedFormData.style !== "dropdown" &&
                            scopedFormData.style !== "free_text" &&
                            scopedFormData.style !== "personal_info" && (
                              <BooleanInputField
                                // @ts-ignore
                                source={getSource("data_with_total_courses")}
                                label={translate(
                                  `wizards.fields.data_with_total_courses`
                                )}
                                resource={props.resource}
                                record={scopedFormData}
                                disabled={disabled_level_1}
                              />
                            )}
                          {scopedFormData.style !== "free_text" &&
                            scopedFormData.style !== "personal_info" && (
                              <>
                                <BooleanInputField
                                  // @ts-ignore
                                  source={getSource("remove_zero_courses")}
                                  label={translate(
                                    `wizards.fields.remove_zero_courses`
                                  )}
                                  resource={props.resource}
                                  record={scopedFormData}
                                  disabled={disabled_level_1}
                                />
                                <TextInputField
                                  // @ts-ignore
                                  source={getSource("data")}
                                  label={translate(`wizards.fields.data`)}
                                  resource={props.resource}
                                  record={scopedFormData}
                                  disabled={disabled_level_1}
                                  validate={scopedFormData.style === "dropdown"}
                                  helperText="Split the data by coma if you want to insert the data manually, i.e. 2000,2001,2002,2003,2004.
                            If the data belongs to the reference data add the reference data name with the prefix App\Models\V2\ReferenceData\, i. e. App\Models\V2\ReferenceData\Region"
                                />
                                <TextInputField
                                  // @ts-ignore
                                  source={getSource("order_by")}
                                  label={translate(`wizards.fields.order_by`)}
                                  resource={props.resource}
                                  record={scopedFormData}
                                  disabled={disabled_level_1}
                                />
                                <TextInputField
                                  // @ts-ignore
                                  source={getSource("related_field")}
                                  label={translate(
                                    `wizards.fields.related_field`
                                  )}
                                  resource={props.resource}
                                  record={scopedFormData}
                                  disabled={disabled_level_1}
                                />
                              </>
                            )}
                          {scopedFormData.style === "personal_info" && (
                            <>
                              {/* TODO: Create a loop per field. Update database with the data. */}
                              <p>Personal Info: Not working</p>
                              <p>{scopedFormData.data}</p>
                              <FormControlLabel
                                value="read"
                                control={
                                  <Checkbox
                                    color="primary"
                                    // onChange={Read}
                                    checked={
                                      personalInfoData.first_name.visible
                                    }
                                  />
                                }
                                label={<p>First Name Visible</p>}
                                labelPlacement="end"
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
      </Grid>
    </>
  );
};

export default WizardTabSummary;
