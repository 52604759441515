import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  BoxTitle,
  RichTextInputField,
  NumberInputField,
  ReferenceSelectInputField,
} from "../components";

const CourseAdmissionFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`courses.boxes.admission`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <p>{translate(`sentence.admmission_description`)}</p>
        <Grid>
          <Grid item xs={12}>
            <RichTextInputField
              source="admission"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
            />
          </Grid>
          <p>{translate(`sentence.admmission_conditions_description`)}</p>
          <Grid item xs={12} sm={6}>
            <ReferenceSelectInputField
              source="work_experience_id"
              reference="work_experiences"
              optionText="title"
              optionValue="id"
              disabled={disabled_level_2}
              inputProps={null}
              validate={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInputField
              source="minimum_age"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
              min={0}
              max={99}
              helperText={"0 = All"}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default CourseAdmissionFields;
