import * as React from "react";
import { FC } from "react";
import { Remove, Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { HorizontalLine } from ".";
import { BoxTitleProps } from "./types";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "15px",
  },
}));

const BoxTitle: FC<BoxTitleProps> = (props) => {
  const classes = useStyles();
  const ArrowColapse = () => {
    if (!props.collapse) {
      return <Add onClick={() => onClickArrow()} />;
    }
    return <Remove onClick={() => onClickArrow()} />;
  };

  const onClickArrow = () => {
    props.setCollapse((collapse) => !collapse);
  };

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h5">{props.title}</Typography>
        <ArrowColapse />
      </div>
      <HorizontalLine />
    </>
  );
};

export default BoxTitle;
