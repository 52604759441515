import SchoolIcon from "@material-ui/icons/School";

import SchoolList from "./SchoolList";
import SchoolEdit from "./SchoolEdit";
import SchoolShow from "./SchoolShow";
import SchoolCreate from "./SchoolCreate";

const resource = {
  list: SchoolList,
  edit: SchoolEdit,
  show: SchoolShow,
  create: SchoolCreate,
  icon: SchoolIcon,
};

export default resource;
