import * as React from "react";
import {
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  Labeled,
  ReferenceInput,
} from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import { TextInputField, BoxTitle } from "../components";
import { Check, Clear } from "@material-ui/icons/";

const OrganizationJobFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const [studyFieldCategory, setStudyFieldCategory] = React.useState<Number>(0);
  const [studyField, setStudyField] = React.useState<Number>(0);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.dashboard_job_finder`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {(disabled_level_2 === false || disabled_level_2 === undefined) && (
              <ArrayInput source="jobs">
                <SimpleFormIterator>
                  <TextInputField
                    source="title"
                    label={translate(`organizations.fields.job_fields.title`)}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                    validate={true}
                  />
                  <ArrayInput source="organization_job_field_of_studies">
                    <SimpleFormIterator>
                      <ReferenceInput
                        source={"study_field_category_id"}
                        reference={"study_field_categories"}
                        label={translate(
                          `resources.study_field_categories.name_singular`
                        )}
                        perPage={1000}
                        fullWidth
                        onChange={(event) => {
                          const { value } = event.target;
                          if (value === "") {
                            setStudyFieldCategory(0);
                            setStudyField(0);
                          } else {
                            setStudyFieldCategory(value);
                            setStudyField(0);
                          }
                        }}
                        isRequired={true}
                      >
                        <SelectInput
                          fullWidth
                          optionText={"title"}
                          optionValue={"id"}
                        />
                      </ReferenceInput>
                      <ReferenceInput
                        source={"study_field_id"}
                        reference={"study_fields"}
                        label={translate(
                          `resources.study_fields.name_singular`
                        )}
                        perPage={1000}
                        fullWidth
                        filter={{ study_field_category_id: studyFieldCategory }}
                        onChange={(event) => {
                          const { value } = event.target;
                          if (value === "") {
                            setStudyField(0);
                          } else {
                            setStudyField(value);
                          }
                        }}
                        isRequired={true}
                      >
                        <SelectInput
                          fullWidth
                          optionText={"title"}
                          optionValue={"id"}
                        />
                      </ReferenceInput>
                      <ReferenceInput
                        source={"sub_study_field_id"}
                        reference={"sub_study_fields"}
                        label={translate(
                          `resources.sub_study_fields.name_singular`
                        )}
                        perPage={1000}
                        fullWidth
                        filter={{ study_field_id: studyField }}
                        isRequired={false}
                        allowEmpty={true}
                      >
                        <SelectInput
                          fullWidth
                          optionText={"title"}
                          optionValue={"id"}
                        />
                      </ReferenceInput>
                    </SimpleFormIterator>
                  </ArrayInput>
                </SimpleFormIterator>
              </ArrayInput>
            )}
            {disabled_level_2 === true &&
              props !== undefined &&
              props.record !== undefined &&
              props.record.questions !== undefined &&
              Array.isArray(props.record.questions) &&
              props.record.jobs.map((item, index) => (
                <div key={index}>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Labeled
                        resource={props.resource}
                        label={translate(
                          `organizations.fields.job_fields.title`
                        )}
                      >
                        <>{item.title}</>
                      </Labeled>
                    </Grid>
                    <Grid item xs={9}>
                      {item.organization_job_field_of_studies.map(
                        (item, index) => (
                          <div key={index}>
                            <Labeled
                              resource={props.resource}
                              label={translate(
                                `resources.study_field_categories.name_singular`
                              )}
                            >
                              <>{item.study_field_category.title}</>
                            </Labeled>
                            <Labeled
                              resource={props.resource}
                              label={translate(
                                `resources.study_fields.name_singular`
                              )}
                            >
                              <>{item.study_field.title}</>
                            </Labeled>
                            <Labeled
                              resource={props.resource}
                              label={translate(
                                `resources.sub_study_fields.name_singular`
                              )}
                            >
                              <>
                                {item.sub_study_field
                                  ? item.sub_study_field.title
                                  : ""}
                              </>
                            </Labeled>
                          </div>
                        )
                      )}
                    </Grid>
                  </Grid>
                </div>
              ))}
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationJobFields;
