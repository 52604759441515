import { makeStyles } from "@material-ui/core/styles";
import { DARK_BLUE, LIGHT_BLUE } from "../Constants";

export const ButtonStyle = makeStyles({
  textButton: {
    color: DARK_BLUE,
  },
  outlinedButton: {},
  containedButton: {
    backgroundColor: DARK_BLUE,
    color: "white",
  },
  cancelTextButton: {
    color: "#292d30",
  },
  cancelOutlinedButton: {},
  cancelContainedButton: {
    backgroundColor: LIGHT_BLUE,
    color: "white",
  },
  addRelationButton: {
    color: DARK_BLUE,
    fontSize: "0.8125rem",
  },
  saveTextButton: {
    backgroundColor: DARK_BLUE,
    color: "white",
  },
});
