import LeadsIcon from "@material-ui/icons/Message";

import LeadsList from "./LeadsList";
import LeadShow from "./LeadShow";

const resource = {
  list: LeadsList,
  icon: LeadsIcon,
  show: LeadShow,
};

export default resource;
