import ContactIcon from "@material-ui/icons/Email";

import ContactsList from "./ContactsList";
// import LeadShow from "./LeadShow";

const resource = {
  list: ContactsList,
  icon: ContactIcon,
  // show: LeadShow,
};

export default resource;
