import React from "react";
import { ReferenceArrayAutocompleteInputField } from "../components";
import { Grid } from "@material-ui/core";

export default function ReferenceAwardsSchoolAdmin({
  disabled,
  reference = "awards",
  resource = "",
  source = "admin_award_ids",
  sort = { field: "title", order: "ASC" },
  perPage = 1000,
  optionText = "private_title",
  optionValue = "id",
}) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ReferenceArrayAutocompleteInputField
          reference={reference}
          source={source}
          sort={sort}
          optionText={optionText}
          optionValue={optionValue}
          disabled={disabled}
          filter={{
            can_change_by_admin: true,
          }}
        />
      </Grid>
    </Grid>
  );
}
