import * as React from "react";
import { useTranslate } from "react-admin";
import { Collapse } from "@material-ui/core";
import { BoxTitle, Infobox } from "../components";

const SchoolInfoboxFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`schools.boxes.infobox`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <p>{translate("sentence.infobox_warning")}</p>
        <Infobox
          disabled={disabled_level_2}
          infobox_array={
            props !== undefined &&
            props.record !== undefined &&
            props.record.infobox !== undefined &&
            props.record.infobox !== null &&
            Array.isArray(props.record.infobox)
              ? props.record.infobox
              : undefined
          }
          translation={true}
          {...props}
        />
      </Collapse>
    </>
  );
};

export default SchoolInfoboxFields;
