import * as React from "react";
import { FC } from "react";
import {
  AutocompleteArrayInput,
  useTranslate,
  useGetList,
  required,
  ReferenceArrayInput,
} from "react-admin";
import { LinearLoading } from "../components";
import MyLocalStoraqge from "../utils/MyLocalStorage";
import { makeStyles } from "@material-ui/core/styles";
import { ReferenceArrayProps } from "./types";

const useStyles = makeStyles(() => ({
  root: {
    pointerEvents: "none",
  },
}));

const ReferenceArrayAutocompleteInputFieldLocalStorage: FC<ReferenceArrayProps> =
  (props) => {
    const renderSugestions = props.renderSugestions ?? 0;
    const translate = useTranslate();

    var choices = MyLocalStoraqge.getWithExpiry(props.reference, props.filter);

    return (
      <AutocompleteArrayInput
        label={props.label ?? translate(`resources.${props.reference}.name`)}
        source={props.source}
        optionText="name"
        optionValue={props.optionValue}
        fullWidth
        disabled={props.disabled}
        shouldRenderSuggestions={(val) => {
          if (props.disabled === true) {
            return val.trim().length > 10;
          }
          return val.trim().length >= renderSugestions;
        }}
        choices={choices}
        validate={props.validate ? [required()] : []}
        helperText={props.helperText ?? <p></p>}
      />
    );
  };

const ReferenceArrayAutocompleteInputFieldGetList: FC<ReferenceArrayProps> = (
  props
) => {
  const translate = useTranslate();

  // Avoid calling always this method using global variables
  const { data, ids, loading, error } = useGetList(
    props.reference,
    props.pagination ?? { page: 1, perPage: 1000 },
    props.sort ?? { field: "id", order: "ASC" },
    props.filter ?? {}
  );
  if (loading) {
    return <LinearLoading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  // @ts-ignore: Object is possibly 'undefined'.
  var choices = ids.map((id) => ({
    id: id,
    // @ts-ignore: Object is possibly 'undefined'.
    name: data[id][props.optionText],
  }));
  MyLocalStoraqge.setWithExpiry(props.reference, choices, props.filter);

  return (
    <AutocompleteArrayInput
      label={props.label ?? translate(`resources.${props.reference}.name`)}
      source={props.source}
      optionText="name"
      optionValue={props.optionValue}
      fullWidth
      disabled={props.disabled}
      shouldRenderSuggestions={(val) => {
        if (props.disabled === true) {
          return val.trim().length > 10;
        }
        return val.trim().length >= (props.renderSugestions ?? 0);
      }}
      choices={choices}
      validate={props.validate ? [required()] : []}
      helperText={props.helperText ?? <p></p>}
    />
  );
};

const ReferenceArrayAutocompleteInputField: FC<ReferenceArrayProps> = (
  props
) => {
  const classes = useStyles();
  const translate = useTranslate();
  if (!props.dynamic) {
    var choices = MyLocalStoraqge.getWithExpiry(props.reference, props.filter);
    if (!props.forceRefresh && choices !== null && choices !== undefined) {
      return (
        <div className={props.disabled ? classes.root : ""}>
          <ReferenceArrayAutocompleteInputFieldLocalStorage {...props} />
        </div>
      );
    }

    return (
      <div className={props.disabled ? classes.root : ""}>
        <ReferenceArrayAutocompleteInputFieldGetList {...props} />
      </div>
    );
  }
  return (
    <ReferenceArrayInput
      label={props.label ?? translate(`resources.${props.reference}.name`)}
      reference={props.reference}
      source={props.source}
      filterToQuery={(searchText) => ({ term: searchText })}
      filter={props.filter}
      resource=""
    >
      <AutocompleteArrayInput
        label={props.label ?? translate(`resources.${props.reference}.name`)}
        source={props.source}
        optionText={props.optionText}
        optionValue={props.optionValue}
        fullWidth
        disabled={props.disabled}
        shouldRenderSuggestions={(val) => {
          if (props.disabled === true) {
            return val.trim().length > 10;
          }
          return val.trim().length >= (props.renderSugestions ?? 0);
        }}
        validate={props.validate ? [required()] : []}
        helperText={props.helperText ?? <p></p>}
      />
    </ReferenceArrayInput>
  );
};

export default ReferenceArrayAutocompleteInputField;
