import * as React from "react";
import { useTranslate, usePermissions } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BooleanInputField,
  BoxTitle,
  NumberInputField,
  ReferenceSelectInputField,
  GoToShowUrl,
  LogoBackgroundImages,
  CardWithIcon,
  NullableBooleanInputField,
} from "../components";
import WarningIcon from "@material-ui/icons/Warning";
import { USER_ROLE_SOA_ADMIN } from "../Constants";

const CourseGeneralFields = ({
  disabled_level_1,
  disabled_level_2,
  disabled_level_3,
  disabled_level_4,
  ...props
}: any) => {
  const { permissions = [] } = usePermissions();
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`courses.boxes.general`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
          <>
            {props.record.premium === true ? (
              <LogoBackgroundImages
                resource={props.resource}
                disabled={disabled_level_2}
                item_logo={undefined}
                item_background={props.record.background_image_admin}
                {...props}
              />
            ) : (
              <Grid item xs={12}>
                <CardWithIcon
                  title={translate(`sentence.images_premium_courses`)}
                  value=" "
                  icon={<WarningIcon />}
                />
              </Grid>
            )}
            <Grid container spacing={1} justify="center" alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="premium"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ReferenceSelectInputField
                  source="contract_type_id"
                  reference="contract_types"
                  optionText="title"
                  optionValue="id"
                  disabled={disabled_level_1}
                  allowEmpty={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="only_verified"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="question_block"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="online_course"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="onsite_course"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="hidde_updated"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="is_public"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <BooleanInputField
                  source="inhouse_course"
                  resource={props.resource}
                  disabled={disabled_level_1}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NullableBooleanInputField
                  source="is_updated"
                  resource={props.resource}
                  disabled={disabled_level_1}
                  helperText={translate(`sentence.course_is_updated`)}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <ReferenceSelectInputField
              source="school_id"
              reference="schools"
              optionText="name"
              optionValue="id"
              disabled={true}
              inputProps={null}
              pagination={{ page: 1, perPage: 0 }}
              validate={true}
            />
          </Grid>
          <Grid item xs={1}>
            <GoToShowUrl resource={"schools"} id={props.record.school_id} />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} lg={6}>
            <TextInputField
              source="short_name"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              validate={true}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputField
              source="full_name"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              validate={true}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInputField
              source="subtitle"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInputField
              source="slug"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              validate={true}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReferenceSelectInputField
              source="study_type_id"
              reference="study_types"
              optionText="title"
              optionValue="id"
              disabled={disabled_level_2}
              inputProps={null}
              validate={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReferenceSelectInputField
              source="degree_type_id"
              reference="degree_types"
              optionText="title"
              optionValue="id"
              disabled={disabled_level_2}
              inputProps={null}
              validate={true}
            />
          </Grid>
          {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
            <>
              <Grid item xs={12} sm={6}>
                <ReferenceSelectInputField
                  source="duration_id"
                  reference="course_durations"
                  optionText="title"
                  optionValue="id"
                  disabled={disabled_level_2}
                  inputProps={null}
                  validate={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReferenceSelectInputField
                  source="cost_id"
                  reference="course_costs"
                  optionText="title"
                  optionValue="id"
                  disabled={disabled_level_2}
                  inputProps={null}
                  validate={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberInputField
                  source="ects_credits"
                  resource={props.resource}
                  record={props.record}
                  disabled={disabled_level_2}
                  min={0}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Collapse>
    </>
  );
};

export default CourseGeneralFields;
