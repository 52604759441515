import * as React from "react";
import { FC } from "react";
import { Visibility } from "@material-ui/icons";
import { IdFieldProps } from "./types";

const GoToShowUrl: FC<IdFieldProps> = (props) => (
  <a href={`/#/${props.resource}/${props.id}/show`}>
    {props.label ?? <Visibility />}
  </a>
);

export default GoToShowUrl;
