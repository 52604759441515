import * as React from "react";

import { Grid } from "@material-ui/core";
import {
  TextInputField,
  ReferenceSelectInputField,
  RichTextInputField,
} from "../components";

import ReferenceDataTable from "./ReferenceDataTable";

const RegionsEditCreateForm = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={"regions"}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextInputField
          source="description"
          resource={"regions"}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceSelectInputField
          source="country_id"
          reference="countries"
          optionText="title"
          optionValue="id"
          allowEmpty={false}
          validate={true}
        />
      </Grid>
    </Grid>
  );
};
const RegionsForm = ({ props }: any) => {
  const fields = ["title"];
  const reference_data_fields = ["title", "country_id", "description"];

  return (
    <ReferenceDataTable
      resource={"regions"}
      table_fields={fields}
      reference_data_fields={reference_data_fields}
      {...props}
      editForm={<RegionsEditCreateForm />}
      createForm={<RegionsEditCreateForm />}
      sort={{}}
    />
  );
};

export default RegionsForm;
