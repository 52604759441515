import * as React from "react";
import {
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  required,
  Labeled,
} from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  BoxTitle,
  NumberInputField,
  ReferenceSelectInputField,
  HorizontalLine,
} from "../components";

const OrganizationBookingStages = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const [type, setType] = React.useState(undefined);
  const translate = useTranslate();

  const changeType = (event) => {
    setType(event.target.value);
  };

  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.dashboard_approval_process`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {(disabled_level_2 === false || disabled_level_2 === undefined) && (
              <ArrayInput source="booking_stages">
                <SimpleFormIterator>
                  <NumberInputField
                    source="order"
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                  <SelectInput
                    fullWidth
                    source={`type`}
                    label={translate(
                      `organizations.fields.booking_stages.type`
                    )}
                    placeholder={"question_type"}
                    resource={props.resource}
                    record={props.record}
                    validate={required()}
                    onChange={changeType}
                    choices={[
                      { id: "free_email", name: "Free Email" },
                      { id: "approver_list", name: "Approver List" },
                    ]}
                    disabled={disabled_level_2}
                  />
                  <ArrayInput source="approvers">
                    <SimpleFormIterator>
                      <ReferenceSelectInputField
                        source="user_id"
                        reference="organization_employees"
                        filter={{ organization_id: props.id }}
                        optionText="full_name_and_email"
                        optionValue="id"
                        disabled={disabled_level_2}
                        allowEmpty={false}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </SimpleFormIterator>
              </ArrayInput>
            )}

            {disabled_level_2 === true &&
              props !== undefined &&
              props.record !== undefined &&
              props.record.booking_stages !== undefined &&
              Array.isArray(props.record.booking_stages) &&
              props.record.booking_stages.map((item, index) => (
                <div key={index}>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.booking_stages.order`
                      )}
                    >
                      <>{item.order}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.booking_stages.type`
                      )}
                    >
                      <>{item.type}</>
                    </Labeled>
                  </Grid>
                  {item.approvers.map((approver, index) => (
                    <Grid item xs={6}>
                      <Labeled
                        resource={props.resource}
                        label={translate(
                          `organizations.fields.booking_stages.approver`
                        )}
                      >
                        <>{approver.approver.full_name_and_email}</>
                      </Labeled>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <HorizontalLine size={"small"} />
                  </Grid>
                </div>
              ))}
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationBookingStages;
