import * as React from "react";
import { FC } from "react";
import {
  useTranslate,
  FormWithRedirect,
  Edit,
  SimpleForm,
  Show,
  usePermissions,
} from "react-admin";
import SchoolTabSummary from "./SchoolTabSummary";
import SchoolCourses from "./SchoolCourses";
import SchoolEvents from "./SchoolEvents";
import SchoolAdministrators from "./SchoolAdministrators";
import SchoolExperts from "./SchoolExperts";
import { SchoolStatistics } from "../statistics";
import SchoolLeads from "./SchoolLeads";
import SchoolReviews from "./SchoolReviews";
import SchoolSuccessStories from "./SchoolSuccessStories";
import {
  Storage,
  LocalLibrary,
  Event,
  SupervisorAccount,
  BarChart,
  SentimentSatisfiedAlt,
  HowToReg,
  Message,
  RateReview,
  Work,
} from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Tabs, AppBar, Tab, Box, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import {
  USER_ROLE_SOA_ADMIN,
  USER_ROLE_SCHOOL_ADMIN,
  USER_ROLE_SUPER_ADMIN,
} from "../Constants";
import { MyActionShowBar, MyActionEditBar } from "../components";
import MyLocalStorage from "../utils/MyLocalStorage";
import SchoolEmployees from "./SchoolEmployees";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const SchoolTitle: FC<Omit<any, "children">> = (props: any) => {
  const translate = useTranslate();
  return (
    <span>
      {translate(`resources.schools.name_singular`)}{" "}
      {props.record ? props.record.name : ""}
    </span>
  );
};

const SchoolForm = ({ action, ...props }: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { permissions = [] } = usePermissions();
  const [value, setValue] = React.useState(0);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  function a11yProps(index: any) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const disabled_level_1 = !permissions.includes(USER_ROLE_SUPER_ADMIN);
  let disabled_level_2 = !(
    permissions.includes(USER_ROLE_SUPER_ADMIN) ||
    permissions.includes(USER_ROLE_SCHOOL_ADMIN)
  );
  const disabled_level_3 = !(
    permissions.includes(USER_ROLE_SUPER_ADMIN) ||
    (permissions.includes(USER_ROLE_SCHOOL_ADMIN) &&
      props !== undefined &&
      props.record !== undefined &&
      props.record.premium !== null &&
      props.record.premium === true)
  );
  // SOA admin
  let specialSoaRules = false;
  if (
    permissions.includes(USER_ROLE_SOA_ADMIN) &&
    props !== undefined &&
    props.record !== undefined
  ) {
    const school_ids = MyLocalStorage.getCookie("school_ids");
    if (school_ids !== null) {
      if (school_ids.includes(props.record.id)) {
        disabled_level_2 = false;
        specialSoaRules = true;
      } else {
        disabled_level_2 = true;
      }
    }
  }
  const disabled_level_4 = !(
    permissions.includes(USER_ROLE_SUPER_ADMIN) ||
    permissions.includes(USER_ROLE_SOA_ADMIN)
  );

  // Check school initial language
  // const disableModelLocal =
  //   props !== undefined &&
  //   props.record !== undefined &&
  //   props.record.initial_language !== null &&
  //   props.record.initial_language.use_translation === false;

  // const forceModelLocale =
  //   props !== undefined &&
  //   props.record !== undefined &&
  //   props.record.initial_language !== null &&
  //   props.record.initial_language.use_translation === false
  //     ? props.record.initial_language.locale
  //     : undefined;

  const disableModelLocal =
    props !== undefined &&
    props.record !== undefined &&
    props.record.use_translation === false;

  const forceModelLocale =
    props !== undefined &&
    props.record !== undefined &&
    props.record.use_translation === false
      ? props.record.initial_language
      : undefined;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                tabIndex={0}
                label={translate(`schools.tabs.summary`)}
                {...a11yProps(0)}
                icon={<Storage />}
              />
              {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
                <Tab
                  value={1}
                  label={translate(`schools.tabs.experts`)}
                  {...a11yProps(1)}
                  icon={<HowToReg />}
                  disabled={action !== "show"}
                />
              )}
              {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
                <Tab
                  value={9}
                  label={translate(`schools.tabs.employees`)}
                  {...a11yProps(9)}
                  icon={<Work />}
                  disabled={action !== "show"}
                />
              )}
              {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
                <Tab
                  value={2}
                  label={translate(`schools.tabs.courses`)}
                  {...a11yProps(2)}
                  icon={<LocalLibrary />}
                  disabled={action !== "show"}
                />
              )}
              {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
                <Tab
                  value={3}
                  label={translate(`schools.tabs.events`)}
                  {...a11yProps(3)}
                  icon={<Event />}
                  disabled={action !== "show"}
                />
              )}
              {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
                <Tab
                  value={4}
                  label={translate(`schools.tabs.success_stories`)}
                  {...a11yProps(4)}
                  icon={<SentimentSatisfiedAlt />}
                  disabled={action !== "show"}
                />
              )}
              {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
                <Tab
                  value={8}
                  label={translate(`schools.tabs.reviews`)}
                  {...a11yProps(8)}
                  icon={<RateReview />}
                  disabled={action !== "show"}
                />
              )}
              {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
                <Tab
                  value={5}
                  label={translate(`schools.tabs.administrators`)}
                  {...a11yProps(5)}
                  icon={<SupervisorAccount />}
                  disabled={action !== "show"}
                />
              )}
              {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
                <Tab
                  value={6}
                  label={translate(`schools.tabs.statistics`)}
                  {...a11yProps(6)}
                  icon={<BarChart />}
                  disabled={action !== "show"}
                />
              )}
              {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
                <Tab
                  value={7}
                  label={translate(`schools.tabs.leads`)}
                  {...a11yProps(7)}
                  icon={<Message />}
                  disabled={action !== "show"}
                />
              )}
            </Tabs>
            <TabPanel value={value} index={0}>
              {action === "show" && (
                <Show
                  {...props}
                  title={<SchoolTitle />}
                  actions={
                    isXSmall ? (
                      <></>
                    ) : (
                      <MyActionShowBar
                        disabledEdit={!(!disabled_level_2 || !disabled_level_4)}
                        disableModelLocal={disableModelLocal}
                        forceModelLocale={forceModelLocale}
                      />
                    )
                  }
                  component="div"
                >
                  <SimpleForm
                    toolbar={
                      <MyActionShowBar
                        disabledEdit={
                          !disabled_level_2 ||
                          !(disabled_level_2 || disabled_level_4)
                        }
                        disableModelLocal={disableModelLocal}
                        forceModelLocale={forceModelLocale}
                      />
                    }
                    submitOnEnter={false}
                  >
                    <SchoolTabSummary
                      disabled_level_1={
                        action === "show" ? true : disabled_level_1
                      }
                      disabled_level_2={
                        action === "show" ? true : disabled_level_2
                      }
                      disabled_level_3={
                        action === "show" ? true : disabled_level_3
                      }
                      disabled_level_4={
                        action === "show" ? true : disabled_level_4
                      }
                      specialSoaRules={specialSoaRules}
                      action={action}
                      disableModelLocal={disableModelLocal}
                      {...props}
                    />
                  </SimpleForm>
                </Show>
              )}
              {action === "edit" && (
                <Edit
                  {...props}
                  title={<SchoolTitle />}
                  actions={<></>}
                  component="div"
                >
                  <SimpleForm
                    toolbar={
                      <MyActionEditBar
                        {...props}
                        disableModelLocal={disableModelLocal}
                        forceModelLocale={forceModelLocale}
                      />
                    }
                    submitOnEnter={false}
                    redirect="show"
                  >
                    <SchoolTabSummary
                      disabled_level_1={
                        action === "show" ? true : disabled_level_1
                      }
                      disabled_level_2={
                        action === "show" ? true : disabled_level_2
                      }
                      disabled_level_3={
                        action === "show" ? true : disabled_level_3
                      }
                      disabled_level_4={
                        action === "show" ? true : disabled_level_4
                      }
                      specialSoaRules={specialSoaRules}
                      action={action}
                      disableModelLocal={disableModelLocal}
                      {...props}
                    />
                  </SimpleForm>
                </Edit>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SchoolExperts {...props} />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <SchoolEmployees {...props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <SchoolCourses
                disabled_level_1={disabled_level_1}
                disabled_level_2={disabled_level_2}
                {...props}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <SchoolEvents {...props} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <SchoolSuccessStories {...props} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <SchoolAdministrators {...props} />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <SchoolStatistics
                disabled_level_1={disabled_level_1}
                disabled_level_2={disabled_level_2}
                {...props}
              />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <SchoolLeads {...props} />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <SchoolReviews {...props} />
            </TabPanel>
          </AppBar>
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default SchoolForm;
