import * as React from "react";
import {
  useTranslate,
  Labeled,
  InputHelperText,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { BoxTitle, TextInputField } from "../components";
import { Grid, Collapse } from "@material-ui/core";

const SchoolLocationFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`schools.boxes.locations`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item xs={12}>
            <Labeled
              resource={props.resource}
              label={translate(`schools.fields.cities`)}
            >
              <>
                <InputHelperText
                  touched
                  helperText={translate(`sentence.school_cities_warning`)}
                />
                {disabled_level_1 === true &&
                  props !== undefined &&
                  props.record !== undefined &&
                  props.record.cities !== undefined &&
                  Array.isArray(props.record.cities) &&
                  props.record.cities.map((item, index) => (
                    <div key={index}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} md={6}>
                          <Labeled
                            resource={props.resource}
                            label={translate(`resources.cities.name_singular`)}
                          >
                            <>{item.city ? item.city.title : ""}</>
                          </Labeled>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Labeled
                            resource={props.resource}
                            label={translate(`schools.fields.email`)}
                          >
                            <>{item.email}</>
                          </Labeled>
                        </Grid>
                      </Grid>
                      <hr />
                    </div>
                  ))}
              </>
            </Labeled>
          </Grid>
          {disabled_level_1 === false && (
            <Grid item xs={12}>
              <ArrayInput source={"cities"} label={null}>
                <SimpleFormIterator>
                  <ReferenceInput
                    source={"city_id"}
                    reference={"cities"}
                    label={translate(`resources.cities.name_singular`)}
                    perPage={1000}
                    fullWidth
                  >
                    <SelectInput
                      fullWidth
                      optionText={"title"}
                      optionValue={"id"}
                    />
                  </ReferenceInput>
                  <TextInputField
                    source={"email"}
                    label={translate(`schools.fields.email`)}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_1}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </>
  );
};

export default SchoolLocationFields;
