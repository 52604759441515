import * as React from "react";
import { FC } from "react";
import { NumberInput, useTranslate, required } from "react-admin";

import { NumberProps } from "./types";

const NumberInputField: FC<NumberProps> = (props) => {
  const translate = useTranslate();
  return (
    <NumberInput
      source={props.source}
      label={
        props.label ?? translate(`${props.resource}.fields.${props.source}`)
      }
      resource={props.resource}
      record={props.record}
      fullWidth
      disabled={props.disabled}
      validate={props.validate ? [required()] : []}
      helperText={props.helperText ?? <p></p>}
      min={props.min ?? undefined}
      max={props.max ?? undefined}
    />
  );
};

export default NumberInputField;
