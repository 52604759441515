import * as React from "react";
import { usePermissions } from "react-admin";
import { AdministratorsList } from "../components";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";
import { SchoolTitle } from "./SchoolForm";

const SchoolAdministrators = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <AdministratorsList
      target={"related_school_id"}
      title={<SchoolTitle />}
      resources={"schools"}
      model_id={props.id}
      canAdd={permissions.includes(USER_ROLE_SUPER_ADMIN)}
      canDelete={permissions.includes(USER_ROLE_SUPER_ADMIN)}
    />
  );
};
export default SchoolAdministrators;
