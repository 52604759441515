import * as React from "react";
import { usePermissions, useTranslate } from "react-admin";
import { OrganizationTitle } from "./OrganizationForm";
import { EmployeesList } from "../components";
import {
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_ORGANIZATION_ADMIN,
} from "../Constants";

const OrganizationExperts = ({ ...props }) => {
  const { permissions = [] } = usePermissions();
  const translate = useTranslate();
  console.log(props);
  return (
    <>
      <p>{translate(`sentence.employee_copy_link`)}</p>
      <p>
        {`https://eduwo.ch/de/register?organization_id=${props.record.id}&employee_token=${props.record.employee_token}`}
      </p>
      <EmployeesList
        target={"related_organization_id"}
        title={<OrganizationTitle />}
        resources={"organizations"}
        reference={"experts"}
        model_id={props.id}
        canAdd={false}
        canDelete={
          permissions.includes(USER_ROLE_SUPER_ADMIN) ||
          permissions.includes(USER_ROLE_ORGANIZATION_ADMIN)
        }
        isOrganization={true}
      />
    </>
  );
};

export default OrganizationExperts;
