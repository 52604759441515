import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  ReferenceSelectInputField,
  BoxTitle,
  ReferenceArrayAutocompleteInputField,
} from "../components";

const SchoolSoaGeneralFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`schools.boxes.soa`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ReferenceSelectInputField
              source="soa_school_type_id"
              reference="soa_school_types"
              optionText="title"
              optionValue="id"
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference="soa_school_levels"
              source="soa_school_level_ids"
              sort={{ field: "title", order: "ASC" }}
              optionText="title"
              optionValue="id"
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="soa_financing_type"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="soa_students_count"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="soa_certification_year"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default SchoolSoaGeneralFields;
