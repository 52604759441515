import * as React from "react";
import { cloneElement, FC } from "react";
import {
  List,
  Datagrid,
  TextField,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  ShowButton,
  useTranslate,
  ListProps,
  usePermissions,
  Responsive,
} from "react-admin";
import { CheckRow, ListBulkActions, ListPagination } from "../components";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";

export const WizardDatagrid: any = ({ deletedMode, ...props }) => {
  const { permissions = [] } = usePermissions();
  const translate = useTranslate();
  return (
    <Datagrid rowClick={undefined}>
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && <CheckRow {...props} />}
      <TextField source="title" label={translate(`wizards.fields.title`)} />
      <TextField source="slug" label={translate(`wizards.fields.slug`)} />
      {!deletedMode && permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <ShowButton />
      )}
    </Datagrid>
  );
};

const useDeleteMode = () => {
  const [deletedMode, setDeletedMode] = React.useState(false);
  return {
    deletedMode,
    setDeletedMode,
  };
};

const WizardList: FC<ListProps> = (props) => {
  const { deletedMode, setDeletedMode } = useDeleteMode();
  const { permissions = [] } = usePermissions();

  const WizardActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      // hasCreate,
      basePath,
      // selectedIds,
      showFilter,
      total,
    } = useListContext();
    setDeletedMode(filterValues.deleted ?? false);

    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton basePath={basePath} />
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        />
      </TopToolbar>
    );
  };
  return (
    <List
      {...props}
      bulkActionButtons={
        <ListBulkActions deletedMode={deletedMode} {...props} />
      }
      actions={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ? <WizardActions /> : <></>
      }
      pagination={<ListPagination />}
      // Sort by translated fields decrease performance a lot
      // sort={{ field: "name", order: "ASC" }}
    >
      <Responsive
        medium={<WizardDatagrid deletedMode={deletedMode} {...props} />}
      />
    </List>
  );
};

export default WizardList;
