import * as React from "react";
import { useShowController, useTranslate, Labeled } from "react-admin";
import { Grid, Typography } from "@material-ui/core";

const LeadShow = (props: any) => {
  const { record } = useShowController<any>(props);
  const translate = useTranslate();

  if (!record) return null;

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={4}>
        <Labeled label={translate(`leads.fields.sender`)}>
          <Typography gutterBottom>{record.sender}</Typography>
        </Labeled>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Labeled label={translate(`leads.fields.receiver`)}>
          <Typography gutterBottom>{record.receiver}</Typography>
        </Labeled>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Labeled label={translate(`leads.fields.date`)}>
          <Typography gutterBottom>
            {new Date(record.created_at).toLocaleDateString()}
          </Typography>
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label={translate(`leads.fields.main_order`)}>
          <Typography gutterBottom>{record.main_order}</Typography>
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label={translate(`leads.fields.request_order`)}>
          <Typography gutterBottom>{record.request_order}</Typography>
        </Labeled>
      </Grid>
      {record.bulk === true && (
        <>
          <Grid item xs={6} sm={4}>
            <Labeled label={translate(`resources.degree_types.name`)}>
              <>
                {record.data["degree_type_ids"].map((key) => {
                  return <>{key} </>;
                })}
              </>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Labeled label={translate(`resources.school_types.name`)}>
              <>
                {record.data["school_type_ids"].map((key) => {
                  return <>{key} </>;
                })}
              </>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Labeled label={translate(`resources.study_field_categories.name`)}>
              <>
                {record.data["study_field_category_ids"].map((key) => {
                  return <>{key} </>;
                })}
              </>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Labeled label={translate(`resources.study_fields.name`)}>
              <>
                {record.data["study_field_ids"].map((key) => {
                  return <>{key} </>;
                })}
              </>
            </Labeled>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Labeled label={translate(`resources.regions.name`)}>
              <>
                {record.data["region_ids"].map((key) => {
                  return <>{key} </>;
                })}
              </>
            </Labeled>
          </Grid>
        </>
      )}
      {record.request_order === "Reimbursement" && (
        <Grid item xs={6} sm={4}>
          <Labeled label={translate(`leads.fields.iban`)}>
            <>{record.iban}</>
          </Labeled>
        </Grid>
      )}
      <Grid item xs={12}>
        <Labeled label={translate(`leads.fields.schools_and_courses`)}>
          <dl>
            {Object.keys(record.school_and_course_names).map((key) => {
              return (
                <dl>
                  <dt>{Object.values<any>(key)}</dt>
                  {record.school_and_course_names[key].map((value) => {
                    return <dd>{value}</dd>;
                  })}
                </dl>
              );
            })}
          </dl>
        </Labeled>
      </Grid>
      <Grid item xs={12}>
        <Labeled label={translate(`leads.fields.title`)}>
          <Typography gutterBottom>{record.title}</Typography>
        </Labeled>
      </Grid>
      <Grid item xs={12}>
        <Labeled label={translate(`leads.fields.message`)}>
          <Typography gutterBottom>{record.message}</Typography>
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default LeadShow;
