import * as React from "react";
import {
  CircularProgress,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  Chip,
  Button,
} from "@material-ui/core";
import githubProvider from "../githubProvider";
import { Done, Clear, Cached } from "@material-ui/icons";
import { StyledTableCell, StyledTableRow } from "../styles";

const DeploySummaryForm = ({ ...props }: any) => {
  const [runsDevelopment, setRunsDevelopment] = React.useState<any[]>([]);
  const [runsFrontendDevelopment, setRunsFrontendDevelopment] = React.useState<
    any[]
  >([]);
  const [runsAdminUIDevelopment, setRunsAdminUIDevelopment] = React.useState<
    any[]
  >([]);
  const [runsStaging, setRunsStaging] = React.useState<any[]>([]);
  const [runsFrontendStaging, setRunsFrontendStaging] = React.useState<any[]>(
    []
  );
  const [runsAdminUIStaging, setRunsAdminUIStaging] = React.useState<any[]>([]);
  const [runsMaster, setRunsMaster] = React.useState<any[]>([]);
  const [runsFrontendMaster, setRunsFrontendMaster] = React.useState<any[]>([]);
  const [runsAdminUIMaster, setRunsAdminUIMater] = React.useState<any[]>([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    refreshDevelopmentRuns();
    refreshStagingRuns();
    refreshMasterRuns();
  };

  const refreshDevelopmentRuns = () => {
    githubProvider.runsDevelopment().then((runs) => {
      setRunsDevelopment(runs.workflow_runs.slice(0, 3));
    });
    githubProvider.runsFrontendDevelopment().then((runs) => {
      setRunsFrontendDevelopment(runs.workflow_runs.slice(0, 3));
    });
    githubProvider.runsAdminUIDevelopment().then((runs) => {
      setRunsAdminUIDevelopment(runs.workflow_runs.slice(0, 3));
    });
  };

  const refreshStagingRuns = () => {
    githubProvider.runsStaging().then((runs) => {
      setRunsStaging(runs.workflow_runs.slice(0, 3));
    });
    githubProvider.runsFrontendStaging().then((runs) => {
      setRunsFrontendStaging(runs.workflow_runs.slice(0, 3));
    });
    githubProvider.runsAdminUIStaging().then((runs) => {
      setRunsAdminUIStaging(runs.workflow_runs.slice(0, 3));
    });
  };

  const refreshMasterRuns = () => {
    githubProvider.runsMaster().then((runs) => {
      setRunsMaster(runs.workflow_runs.slice(0, 3));
    });
    githubProvider.runsFrontendMaster().then((runs) => {
      setRunsFrontendMaster(runs.workflow_runs.slice(0, 3));
    });
    githubProvider.runsAdminUIMaster().then((runs) => {
      setRunsAdminUIMater(runs.workflow_runs.slice(0, 3));
    });
  };

  const refresh = () => {
    setRunsDevelopment([]);
    setRunsStaging([]);
    setRunsMaster([]);
    setRunsFrontendDevelopment([]);
    setRunsFrontendStaging([]);
    setRunsFrontendMaster([]);
    setRunsAdminUIDevelopment([]);
    setRunsAdminUIStaging([]);
    setRunsAdminUIMater([]);
    refreshDevelopmentRuns();
    refreshStagingRuns();
    refreshMasterRuns();
  };

  const CellStatus = ({ run }: any) => {
    if (run.conclusion === `success`) {
      return (
        <Chip
          icon={<Done style={{ color: "white" }} />}
          label={run.conclusion}
          style={{ backgroundColor: "green", color: "white" }}
        />
      );
    }

    if (run.conclusion !== null && run.conclusion !== "success") {
      return (
        <Chip
          icon={<Clear />}
          label={run.conclusion}
          style={{ backgroundColor: "red", color: "white" }}
        />
      );
    }

    return <Chip icon={<Cached />} label={run.status} color="primary" />;
  };

  const DeployStatus = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button variant="outlined" onClick={refresh}>
            {"Refresh"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">{``}</StyledTableCell>
                  <StyledTableCell
                    align="center"
                    colSpan={2}
                  >{`DEVELOPMENT`}</StyledTableCell>
                  <StyledTableCell
                    align="center"
                    colSpan={2}
                  >{`STAGING`}</StyledTableCell>
                  <StyledTableCell
                    align="center"
                    colSpan={2}
                  >{`LIVE`}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="center">{``}</StyledTableCell>
                  <StyledTableCell align="center">{`TIME`}</StyledTableCell>
                  <StyledTableCell align="center">{`STATUS`}</StyledTableCell>
                  <StyledTableCell align="center">{`TIME`}</StyledTableCell>
                  <StyledTableCell align="center">{`STATUS`}</StyledTableCell>
                  <StyledTableCell align="center">{`TIME`}</StyledTableCell>
                  <StyledTableCell align="center">{`STATUS`}</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="center">{`Backend`}</StyledTableCell>
                  <StyledTableCell align="center">
                    {runsDevelopment !== undefined &&
                    runsDevelopment[0] !== undefined ? (
                      runsDevelopment[0].created_at
                        .replace("T", " ")
                        .replace("Z", "")
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsDevelopment !== undefined && runsDevelopment[0] ? (
                      <CellStatus run={runsDevelopment[0]} />
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsStaging !== undefined &&
                    runsStaging[0] !== undefined ? (
                      runsStaging[0].created_at
                        .replace("T", " ")
                        .replace("Z", "")
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsStaging !== undefined &&
                    runsStaging[0] !== undefined ? (
                      <CellStatus run={runsStaging[0]} />
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsMaster !== undefined && runsMaster[0] ? (
                      runsMaster[0].created_at
                        .replace("T", " ")
                        .replace("Z", "")
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsMaster !== undefined && runsMaster[0] ? (
                      <CellStatus run={runsMaster[0]} />
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="center">{`Frontend`}</StyledTableCell>
                  <StyledTableCell align="center">
                    {runsFrontendDevelopment !== undefined &&
                    runsFrontendDevelopment[0] !== undefined ? (
                      runsFrontendDevelopment[0].created_at
                        .replace("T", " ")
                        .replace("Z", "")
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsFrontendDevelopment !== undefined &&
                    runsFrontendDevelopment[0] ? (
                      <CellStatus run={runsFrontendDevelopment[0]} />
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsFrontendStaging !== undefined &&
                    runsFrontendStaging[0] !== undefined ? (
                      runsFrontendStaging[0].created_at
                        .replace("T", " ")
                        .replace("Z", "")
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsFrontendStaging !== undefined &&
                    runsFrontendStaging[0] !== undefined ? (
                      <CellStatus run={runsFrontendStaging[0]} />
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsFrontendMaster !== undefined &&
                    runsFrontendMaster[0] ? (
                      runsFrontendMaster[0].created_at
                        .replace("T", " ")
                        .replace("Z", "")
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsFrontendMaster !== undefined &&
                    runsFrontendMaster[0] ? (
                      <CellStatus run={runsFrontendMaster[0]} />
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="center">{`Admin UI`}</StyledTableCell>
                  <StyledTableCell align="center">
                    {runsAdminUIDevelopment !== undefined &&
                    runsAdminUIDevelopment[0] !== undefined ? (
                      runsAdminUIDevelopment[0].created_at
                        .replace("T", " ")
                        .replace("Z", "")
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsAdminUIDevelopment !== undefined &&
                    runsAdminUIDevelopment[0] ? (
                      <CellStatus run={runsAdminUIDevelopment[0]} />
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsAdminUIStaging !== undefined &&
                    runsAdminUIStaging[0] !== undefined ? (
                      runsAdminUIStaging[0].created_at
                        .replace("T", " ")
                        .replace("Z", "")
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsAdminUIStaging !== undefined &&
                    runsAdminUIStaging[0] !== undefined ? (
                      <CellStatus run={runsAdminUIStaging[0]} />
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsAdminUIMaster !== undefined && runsAdminUIMaster[0] ? (
                      runsAdminUIMaster[0].created_at
                        .replace("T", " ")
                        .replace("Z", "")
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {runsAdminUIMaster !== undefined && runsAdminUIMaster[0] ? (
                      <CellStatus run={runsAdminUIMaster[0]} />
                    ) : (
                      <CircularProgress />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={4}>
          <a href={`https://github.com/eduwo/gc-cd/actions`} target={`_blank`}>
            More details
          </a>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <DeployStatus />
      </Grid>
    </Grid>
  );
};

export default DeploySummaryForm;
