import * as React from "react";

import { Grid } from "@material-ui/core";
import { TextInputField, RichTextInputField } from "../components";

import ReferenceDataTable from "./ReferenceDataTable";
import { ArrayInput, SimpleFormIterator, useTranslate } from "react-admin";
const resource = "school_types";

const SchoolTypeFormEditCreateForm = ({ type }) => {
  const translate = useTranslate();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextInputField
          source="description"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      {type !== "create" && (
        <Grid item xs={12}>
          <TextInputField
            source="slug"
            resource={resource}
            disabled={false}
            validate={true}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ArrayInput source={"faqs"}>
          <SimpleFormIterator>
            <TextInputField
              source={"question"}
              label={translate(`${resource}.fields.faqs.question`)}
              resource={resource}
              disabled={false}
              validate={true}
            />
            <RichTextInputField
              source={"answer"}
              label={translate(`${resource}.fields.faqs.answer`)}
              resource={resource}
              disabled={false}
              validate={true}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};
const SchoolTypeForm = ({ props }: any) => {
  const fields = ["title", "slug"];
  const reference_data_fields = ["title", "description", "slug", "faqs"];

  return (
    <ReferenceDataTable
      resource={resource}
      table_fields={fields}
      reference_data_fields={reference_data_fields}
      reference_data_images={["background_image"]}
      {...props}
      editForm={<SchoolTypeFormEditCreateForm type={"edit"} />}
      createForm={<SchoolTypeFormEditCreateForm type={"create"} />}
    />
  );
};

export default SchoolTypeForm;
