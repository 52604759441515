import SubscriptionsIcon from "@material-ui/icons/Subscriptions";

import KnowledgeBaseList from "./KnowledgeBaseList";

const resource = {
  list: KnowledgeBaseList,
  icon: SubscriptionsIcon,
  // show: LeadShow,
};

export default resource;
