import * as React from "react";
import { usePermissions } from "react-admin";
import { EventsList } from "../components";
import { CourseTitle } from "./CourseForm";
import {
  USER_ROLE_COURSE_ADMIN,
  USER_ROLE_SCHOOL_ADMIN,
  USER_ROLE_SUPER_ADMIN,
} from "../Constants";

const CourseEvents = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <EventsList
      target={"related_course_id"}
      reference={"course_events"}
      title={<CourseTitle />}
      resources={"courses"}
      model_id={props.id}
      canAdd={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN) ||
        permissions.includes(USER_ROLE_COURSE_ADMIN)
      }
      canDelete={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN) ||
        permissions.includes(USER_ROLE_COURSE_ADMIN)
      }
      modelNameField={"short_name"}
    />
  );
};

export default CourseEvents;
