import UsersIcon from "@material-ui/icons/People";

import UserList from "./UserList";
import UserEdit from "./UserEdit";
import UserShow from "./UserShow";

const resource = {
  list: UserList,
  edit: UserEdit,
  show: UserShow,
  icon: UsersIcon,
};

export default resource;
