import React from "react";
import {
  TextInputField,
  LinearLoading,
  RichTextInputField,
  // eslint-disable-next-line
  TranslationButton,
} from "../components";
import {
  useTranslate,
  Labeled,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { Grid } from "@material-ui/core";

export default function Infobox({
  disabled,
  infobox_array,
  source = "infobox",
  source_title = "title",
  source_content = "content",
  translation = false,
  ...props
}) {
  const translate = useTranslate();

  return (
    <>
      <Grid container spacing={1}>
        {(props === undefined || props.record === undefined) && (
          <Grid item xs={12}>
            <LinearLoading />
          </Grid>
        )}
      </Grid>
      {props !== undefined && props.record !== undefined && disabled === false && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ArrayInput source={source}>
              <SimpleFormIterator>
                <TextInputField
                  source={source_title}
                  label={translate(
                    `${props.resource}.fields.${source}.${source_title}`
                  )}
                  resource={props.resource}
                  record={props.record}
                  disabled={disabled}
                />
                <RichTextInputField
                  source={source_content}
                  label={translate(
                    `${props.resource}.fields.${source}.${source_content}`
                  )}
                  resource={props.resource}
                  record={props.record}
                  disabled={disabled}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      )}
      {props !== undefined &&
        props.record !== undefined &&
        disabled === true &&
        infobox_array !== undefined &&
        infobox_array.map((item, index) => (
          <div key={index}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={translation ? 11 : 12}>
                <Labeled
                  resource={props.resource}
                  label={translate(
                    `${props.resource}.fields.${source}.${source_title}`
                  )}
                >
                  <>{item.title}</>
                </Labeled>
              </Grid>
              {translation && (
                <Grid item xs={1}>
                  {/* TODO */}
                  {/* <TranslationButton
                    label={""}
                    record={props.record}
                    source={source}
                    resource={props.resource}
                  /> */}
                </Grid>
              )}
              <Grid item xs={12}>
                <Labeled
                  resource={props.resource}
                  label={translate(
                    `${props.resource}.fields.${source}.${source_content}`
                  )}
                >
                  <div
                    className="tag-temp-text"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </Labeled>
              </Grid>
            </Grid>
            <hr />
          </div>
        ))}
    </>
  );
}
