import * as React from "react";
import {
  FormWithRedirect,
  Labeled,
  useGetList,
  useTranslate,
  useRefresh,
  SelectInput,
} from "react-admin";
import { SelectStyle, StyledTableCell, StyledTableRow } from "../styles";
import {
  Collapse,
  Grid,
  Box,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardActions,
  CardContent,
  TextField,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons/";
import PropTypes from "prop-types";
import {
  LinearLoading,
  HorizontalLine,
  TextInputField,
  NullableBooleanInputField,
  DateInputField,
} from "../components";
import { apiUrl, httpClient } from "../dataProvider";
import { AddReceiver, Criteria, LeadDetail, Receivers } from "./Contacts";
import { LeadButtons } from "./ContactEmails";
import RichTextInput from "ra-input-rich-text";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const table_fields = [
  "first_name",
  "last_name",
  "email",
  "contacts_count",
  "pendings_count",
  "last_contact",
];

const Row = ({ status, row, hide_archived }: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const selectClasses = SelectStyle();
  const [open, setOpen] = React.useState(false);
  const [user_type, setUserType] = React.useState(row["type"]);
  const [user_status, setUserStatus] = React.useState(row["lead_status"]);
  const [update_comment, setUpdateComments] = React.useState(null);
  const [add_comments, setAddComments] = React.useState(false);
  const [add_todos, setAddTodos] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [todo, setTodo] = React.useState("");

  const handleChangeUpdateUserType = (event) => {
    setUserType(event.target.value);
    const url = `${apiUrl}/users/${row["id"]}/type`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ user_type: event.target.value }),
    }).then(({ headers, json }: any) => {
      // Do nothing
    });
  };

  const handleChangeUpdateUserStatus = (event) => {
    setUserStatus(event.target.value);
    const url = `${apiUrl}/users/${row["id"]}/status`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ user_status: event.target.value }),
    }).then(({ headers, json }: any) => {
      // Do nothing
    });
  };

  const saveComment = () => {
    const url = `${apiUrl}/users/${row["id"]}/comments`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ comment: comment }),
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const configureQuillMain = (quill) =>
    quill.on("editor-change", function (eventName, ...args) {
      if (eventName === "text-change") {
        setComment(quill.root.innerHTML);
      }
    });

  const saveTodo = () => {
    const url = `${apiUrl}/users/${row["id"]}/todos`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ comment: todo }),
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </StyledTableCell>
        {table_fields.map((table_field) => (
          <StyledTableCell>{row[table_field]}</StyledTableCell>
        ))}
      </StyledTableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={open}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Labeled label={translate(`contacts.fields.first_name`)}>
                <Typography gutterBottom>{row["first_name"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled label={translate(`contacts.fields.last_name`)}>
                <Typography gutterBottom>{row["last_name"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Labeled label={translate(`contacts.fields.email`)}>
                <Typography gutterBottom>{row["email"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={5}>
              <Labeled label={translate(`contacts.fields.street`)}>
                <Typography gutterBottom>{row["street"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={2}>
              <Labeled label={translate(`contacts.fields.zip`)}>
                <Typography gutterBottom>{row["zip"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={2}>
              <Labeled label={translate(`contacts.fields.city`)}>
                <Typography gutterBottom>{row["city"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={3}>
              <Labeled label={translate(`contacts.fields.phone`)}>
                <Typography gutterBottom>{row["phone"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={3}>
              <Labeled label={translate(`contacts.fields.birthday_day`)}>
                <Typography gutterBottom>{row["birthday_day"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={3}>
              <Labeled label={translate(`contacts.fields.birthday_month`)}>
                <Typography gutterBottom>{row["birthday_month"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={3}>
              <Labeled label={translate(`contacts.fields.birthday_year`)}>
                <Typography gutterBottom>{row["birthday_year"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={3}>
              <Labeled label={translate(`contacts.fields.job_title`)}>
                <Typography gutterBottom>{row["job_title"]}</Typography>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <InputLabel id="user-type-label">
                  {translate(`contacts.fields.user_type`)}
                </InputLabel>
                <Select
                  className={selectClasses.full}
                  labelId={"user-type-label"}
                  id="user_type"
                  value={user_type}
                  onChange={handleChangeUpdateUserType}
                >
                  <MenuItem value={"Not set"}>Not set</MenuItem>
                  <MenuItem value={"A"}>A</MenuItem>
                  <MenuItem value={"B"}>B</MenuItem>
                  <MenuItem value={"C"}>C</MenuItem>
                  <MenuItem value={"D"}>D</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <InputLabel id="user-status-label">
                  {translate(`contacts.fields.user_status`)}
                </InputLabel>
                <Select
                  className={selectClasses.full}
                  labelId={"user-status-label"}
                  id="user_status"
                  value={user_status}
                  onChange={handleChangeUpdateUserStatus}
                >
                  <MenuItem value={undefined}> </MenuItem>
                  <MenuItem value={"New"}>New</MenuItem>
                  <MenuItem value={"Updated"}>Updated</MenuItem>
                  <MenuItem value={"Not reached"}>Not reached</MenuItem>
                  <MenuItem value={"Call not wished"}>Call not wished</MenuItem>
                  <MenuItem value={"Not called"}>Not called</MenuItem>
                  <MenuItem value={"Contacted"}>Contacted</MenuItem>
                  <MenuItem value={"Stopped searching"}>
                    Stopped searching
                  </MenuItem>
                  <MenuItem value={"Want to book"}>Want to book</MenuItem>
                  <MenuItem value={"Booked without us"}>
                    Booked without us
                  </MenuItem>
                  <MenuItem value={"Booked with us"}>Booked with us</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <h3>Notes</h3>
            </Grid>
            {add_comments && (
              <>
                <Grid item xs={12}>
                  <RichTextInput
                    source="text"
                    configureQuill={configureQuillMain}
                    label={"New comment"}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={add_comments ? 6 : 12}>
              <Button
                size="small"
                color="primary"
                onClick={() => setAddComments(!add_comments)}
              >
                {add_comments ? `Cancel` : `Add Note`}
              </Button>
            </Grid>
            {add_comments && (
              <Grid item xs={6}>
                <Button size="small" color="primary" onClick={saveComment}>
                  Save
                </Button>
              </Grid>
            )}
            {Array.isArray(row["comments"]) && row["comments"].length > 0 && (
              <>
                {row["comments"].map((comment, index) => (
                  <Grid key={`comment-grid-${comment["id"]}`} item xs={12}>
                    <Comment
                      key={`comment-${comment["id"]}`}
                      row={row}
                      comment={comment}
                      add_mode={add_comments}
                      update_comment={update_comment}
                      setUpdateComments={setUpdateComments}
                    />
                  </Grid>
                ))}
              </>
            )}
            <Grid item xs={12}>
              <h3>TODOs</h3>
            </Grid>
            {add_todos && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Todo"
                    variant="outlined"
                    onChange={(event) => setTodo(event.target.value)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={add_todos ? 6 : 12}>
              <Button
                size="small"
                color="primary"
                onClick={() => setAddTodos(!add_todos)}
              >
                {add_todos ? `Cancel` : `Add Todo`}
              </Button>
            </Grid>
            {add_todos && (
              <Grid item xs={6}>
                <Button size="small" color="primary" onClick={saveTodo}>
                  Save
                </Button>
              </Grid>
            )}
            {Array.isArray(row["todos"]) && row["todos"].length > 0 && (
              <>
                {row["todos"].map((todo, index) => (
                  <Grid item xs={12}>
                    <Todo key={`todo-${todo["id"]}`} row={row} todo={todo} />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <HorizontalLine />
          </Grid>
          {row["contacts_summary"].map((contact_summary) => (
            <Grid item xs={12}>
              <LeadContactEmails
                contact_summary={contact_summary}
                hide_archived={hide_archived}
              />
            </Grid>
          ))}
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
};

const useStyles2 = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  createdText: {
    marginBottom: 12,
    fontSize: "12px",
  },
  cardContentStyle: {
    padding: "0px",
    paddingBottom: "0px",
  },
  width25: {
    width: "25%",
  },
});

const Comment = ({
  row,
  comment,
  add_mode,
  update_comment,
  setUpdateComments,
}: any) => {
  const classes = useStyles2();
  const refresh = useRefresh();
  const [update_mode, setUpdateMode] = React.useState(false);
  const [commentComment, setComment] = React.useState<string>(
    comment["comment"]
  );

  const updateComment = () => {
    setUpdateMode(true);
    setUpdateComments(comment["id"]);
    console.log(commentComment);
    setComment(commentComment);
  };

  const deleteComment = () => {
    const url = `${apiUrl}/users/${row["id"]}/comments/${comment["id"]}`;
    return httpClient(url, {
      method: "DELETE",
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  return (
    <Grid container spacing={1}>
      <>
        <Grid item xs={10}>
          <Card className={classes.root}>
            <CardContent className={classes.cardContentStyle}>
              <div
                className="tag-temp-text"
                dangerouslySetInnerHTML={{ __html: commentComment }}
              />
              {!update_mode && (
                <Typography
                  className={classes.createdText}
                  color="textSecondary"
                >
                  {`Created by ${comment["created_user"]["first_name"]} ${comment["created_user"]["last_name"]} at ${comment["created_at"]}`}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
        {update_mode && (
          <UpdateComment
            row={row}
            comment={comment}
            commentComment={commentComment}
            update_mode={update_mode}
            setUpdateMode={setUpdateMode}
            setUpdateComments={setUpdateComments}
            setComment={setComment}
          />
        )}
        <Grid item xs={2}>
          <Grid container spacing={1}>
            {!add_mode && update_comment == null && (
              <>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={deleteComment}
                  >
                    Delete
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button color="primary" size="small" onClick={updateComment}>
                    Update
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </>
      <Grid item xs={12}>
        <HorizontalLine size="small" />
      </Grid>
    </Grid>
  );
};

const UpdateComment = ({
  row,
  comment,
  commentComment,
  update_mode,
  setUpdateMode,
  setUpdateComments,
  setComment,
}): any => {
  const [new_comment, setNewComment] = React.useState(true);

  const configureQuill = (quill) =>
    quill.on("editor-change", function (eventName, ...args) {
      console.log(eventName);
      console.log(commentComment);
      if (eventName === "text-change") {
        setNewComment(quill.root.innerHTML);
      }
    });

  const updateComment = () => {
    const url = `${apiUrl}/users/${row["id"]}/comments/${comment["id"]}`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ comment: new_comment }),
    }).then(({ headers, json }: any) => {
      setUpdateMode(false);
      setComment(new_comment);
      setUpdateComments(null);
      // refresh();
    });
  };

  const cancelUpdateComment = () => {
    setUpdateMode(false);
    setComment(commentComment);
    setComment(comment["comment"]);
    setUpdateComments(null);
  };

  return (
    <>
      <Grid item xs={10}>
        <RichTextInput
          key={`comment-text-${comment["id"]}`}
          source="text"
          configureQuill={configureQuill}
          label={"Update comment"}
        />
      </Grid>
      <Grid item xs={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              color="secondary"
              size="small"
              onClick={cancelUpdateComment}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" size="small" onClick={updateComment}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const Todo = ({ row, todo }: any) => {
  const classes = useStyles2();
  const selectClasses = SelectStyle();
  const translate = useTranslate();
  const [priority, setPriority] = React.useState(todo["priority"]);
  const [due_date, setDueDate] = React.useState(todo["due_date"]);
  const [start_date, setStartDate] = React.useState(todo["start_date"]);
  const [done, setDone] = React.useState(todo["done"]);

  const handleMaskAsDone = (event) => {
    const url = `${apiUrl}/users/${row["id"]}/todos/${todo["id"]}/mark_as_done`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ done: !done }),
    }).then(({ headers, json }: any) => {
      setDone(!done);
    });
  };

  const handleChangePriority = (event) => {
    setPriority(event.target.value);
    const url = `${apiUrl}/users/${row["id"]}/todos/${todo["id"]}/priority`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ priority: event.target.value }),
    }).then(({ headers, json }: any) => {
      // Do nothing
    });
  };

  const updateStartDate = (startDate) => {
    const url = `${apiUrl}/users/${row["id"]}/todos/${todo["id"]}/start_date`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ date: startDate }),
    }).then(({ headers, json }: any) => {
      // Do nothing
    });
  };

  const updateEndDate = (endDate) => {
    const url = `${apiUrl}/users/${row["id"]}/todos/${todo["id"]}/due_date`;
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify({ date: endDate }),
    }).then(({ headers, json }: any) => {
      // Do nothing
    });
  };

  let comment = todo["comment"];
  if (done) {
    comment = `<s>${comment}</s>`;
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContentStyle}>
        <div
          className="tag-temp-text"
          dangerouslySetInnerHTML={{ __html: comment }}
        />
        <Typography className={classes.createdText} color="textSecondary">
          {`Created by ${todo["created_user"]["first_name"]} ${todo["created_user"]["last_name"]} at ${todo["created_at"]}`}
        </Typography>
      </CardContent>
      <CardActions className={selectClasses.noMargin}>
        <Button
          className={classes.width25}
          color="secondary"
          size="small"
          onClick={handleMaskAsDone}
        >
          {done ? "Deselect as done" : "Mark as done"}
        </Button>
        <FormControl className={selectClasses.full}>
          <InputLabel id="user-type-label">
            {translate(`contacts.fields.todo`)}
          </InputLabel>
          <Select
            labelId={"user-type-label"}
            id="priority"
            value={priority}
            onChange={handleChangePriority}
          >
            <MenuItem value={"low"}>Low</MenuItem>
            <MenuItem value={"medium"}>Medium</MenuItem>
            <MenuItem value={"high"}>High</MenuItem>
          </Select>
        </FormControl>
        <div className={classes.width25}>
          <TextField
            id="date"
            label="start_date"
            type="date"
            defaultValue={start_date}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              const { value } = event.target;
              if (value === "") {
                setStartDate(undefined);
              } else {
                setStartDate(value);
                updateStartDate(value);
              }
            }}
          />
        </div>
        <div className={classes.width25}>
          <TextField
            id="date"
            label="due_date"
            type="date"
            defaultValue={due_date}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              const { value } = event.target;
              if (value === "") {
                setDueDate(undefined);
              } else {
                setDueDate(value);
                updateEndDate(value);
              }
            }}
          />
        </div>
      </CardActions>
    </Card>
  );
};

const LeadContactEmails = ({ status, contact_summary, hide_archived }: any) => {
  const [show_details, setShowDetails] = React.useState(false);
  const [details, setDetails] = React.useState<any>(null);

  const loadContactDetails = (id, force) => {
    const url = `${apiUrl}/contacts/${id}`;
    if (details !== null && !force) {
      setShowDetails(!show_details);
      return;
    }
    return httpClient(url, {
      method: "GET",
    }).then(({ headers, json }: any) => {
      setShowDetails(true);
      setDetails(json);
    });
  };

  return (
    <Grid key={`grid-${contact_summary["id"]}`} container spacing={1}>
      <LeadDetail contact={contact_summary} created_date={true} />
      <Grid item xs={12}>
        <Button
          size="small"
          key={`button-${contact_summary["id"]}`}
          onClick={() =>
            loadContactDetails(contact_summary["id"], !show_details)
          }
        >
          {show_details === true && details !== null
            ? `Hide details`
            : `Show details`}
        </Button>
      </Grid>
      {show_details === true && details !== null && (
        <>
          <Grid item xs={12}>
            {contact_summary["source"].indexOf("wizard") >= 0 &&
              details["criteria"] !== null && (
                <>
                  <AddReceiver contact={details} />
                  <Criteria
                    key={`criteria-${contact_summary["id"]}`}
                    contact={details}
                  />
                </>
              )}
          </Grid>
          {Array.isArray(details["contact_emails"]) &&
            details["contact_emails"].map((contact_email, index) => (
              <>
                {contact_email["archived"] && hide_archived ? (
                  <></>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Receivers
                        key={`receivers-${contact_summary["id"]}`}
                        contactEmails={[contact_email]}
                        hide_archived={hide_archived}
                      />
                    </Grid>
                    {details["total_courses"] > 0 && (
                      <LeadButtons
                        row={contact_email}
                        status={contact_email["status"]}
                      />
                    )}
                  </>
                )}
              </>
            ))}
        </>
      )}
      <Grid item xs={12}>
        <HorizontalLine size="small" />
      </Grid>
    </Grid>
  );
};

const UsersList = ({
  user_type,
  user_status,
  term,
  start_date,
  end_date,
  only_pending,
  hide_archived,
}: any) => {
  const translate = useTranslate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const filters = {
    user_type: user_type,
    user_status: user_status,
    term: term,
    start_date: start_date,
    end_date: end_date,
    only_pending: only_pending,
  };

  const sort = { field: "created_at", order: "DESC" };
  const { total, /*error,*/ data, loading } = useGetList(
    "users/contacts",
    { perPage: rowsPerPage, page: page + 1 },
    sort,
    filters
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LinearLoading />;
  }

  // const handleExport = () => {
  //   const query = {
  //     type: "user_details",
  //   };
  //   const url = `${apiUrl}/contacts/export?${stringify(filters)}&${stringify(
  //     query
  //   )}`;

  //   const headers = new Headers({ Accept: "application/json" });
  //   headers.set(
  //     "Authorization",
  //     `Bearer ${MyLocalStorage.getCookie("access_token")}`
  //   );
  //   headers.set("Content-Type", `application/json`);

  //   let options = { headers: headers };

  //   return fetch(url, options)
  //     .then(function (response) {
  //       if (response !== null) {
  //         return response.blob();
  //       }
  //     })
  //     .then(function (blob) {
  //       var file = window.URL.createObjectURL(blob);
  //       window.location.assign(file);
  //     });
  // };

  let sortedData = data !== undefined ? Object.keys(data).reverse() : [];

  return (
    <>
      <Grid container spacing={1}>
        {/* <Grid item xs={12}>
          <Button variant="outlined" onClick={() => handleExport()}>
            {"EXPORT"}
          </Button>
        </Grid> */}
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {table_fields.map((table_field, index) => (
                <TableCell key={index} sortDirection={false}>
                  {translate(`contacts.fields.${table_field}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData !== undefined &&
              sortedData.map((key, index) => {
                return (
                  <Row
                    key={key}
                    row={data[key]}
                    hide_archived={hide_archived}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

const ContactUsers = ({ ...props }: any) => {
  const classesSelect = SelectStyle();
  const classes = useStyles();
  const [user_type, setUserType] = React.useState<string | undefined>(
    undefined
  );
  const [user_status, setUserStatus] = React.useState<string | undefined>(
    undefined
  );
  const [term, setTerm] = React.useState(undefined);
  const [start_date, setStartDate] = React.useState(undefined);
  const [end_date, setEndDate] = React.useState(undefined);
  const [only_pending, setOnlyPending] = React.useState(undefined);
  const [hide_archived, setHideArchived] = React.useState(undefined);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextInputField
                resource={"leads"}
                source={"term"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value.length < 3) {
                    setTerm(undefined);
                  } else {
                    setTerm(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                className={classesSelect.small}
                fullWidth
                resource={"leads"}
                source={"user_type"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setUserType(undefined);
                  } else {
                    setUserType(value);
                  }
                }}
                choices={[
                  { id: null, name: "" },
                  { id: "Not set", name: "Not set" },
                  { id: "A", name: "A" },
                  { id: "B", name: "B" },
                  { id: "C", name: "C" },
                  { id: "D", name: "D" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                className={classesSelect.small}
                fullWidth
                resource={"leads"}
                source={"user_status"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setUserStatus(undefined);
                  } else {
                    setUserStatus(value);
                  }
                }}
                choices={[
                  { id: null, name: "" },
                  { id: "New", name: "New" },
                  { id: "Updated", name: "Updated" },
                  { id: "Not reached", name: "Not reached" },
                  { id: "Call not wished", name: "Call not wished" },
                  { id: "Not called", name: "Not called" },
                  { id: "Contacted", name: "Contacted" },
                  { id: "Stopped searching", name: "Stopped searching" },
                  { id: "Want to book", name: "Want to book" },
                  { id: "Booked without us", name: "Booked without us" },
                  { id: "Booked with us", name: "Booked with us" },
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <DateInputField
                resource={"leads"}
                source={"start_date"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setStartDate(undefined);
                  } else {
                    setStartDate(value);
                  }
                }}
                allowEmpty={true}
              />
            </Grid>
            <Grid item xs={3}>
              <DateInputField
                resource={"leads"}
                source={"end_date"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setEndDate(undefined);
                  } else {
                    setEndDate(value);
                  }
                }}
                allowEmpty={true}
              />
            </Grid>
            <Grid item xs={3}>
              <NullableBooleanInputField
                resource={"leads"}
                source={"only_pending"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setOnlyPending(undefined);
                  } else {
                    setOnlyPending(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <NullableBooleanInputField
                resource={"leads"}
                source={"hide_archived"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setHideArchived(undefined);
                  } else {
                    setHideArchived(value);
                  }
                }}
              />
            </Grid>
          </Grid>
          <UsersList
            user_type={user_type}
            user_status={user_status}
            term={term}
            start_date={start_date}
            end_date={end_date}
            only_pending={only_pending}
            hide_archived={hide_archived}
          />
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default ContactUsers;
