import * as React from "react";
import { FC } from "react";
import {
  ReferenceManyField,
  SimpleShowLayout,
  TopToolbar,
  Datagrid,
  TextField,
  ShowView,
  FormWithRedirect,
  useCreate,
  useNotify,
  useRefresh,
  Labeled,
  useTranslate,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  required,
  usePermissions,
} from "react-admin";
import {
  ListPagination,
  NumberInputField,
  TextInputField,
  DeleteButtonList,
  MySaveButton,
  CardWithIcon,
  GoToPublicUrl,
} from "../components";
import { Grid, Switch, Button, useMediaQuery, Theme } from "@material-ui/core";
import { apiUrl, httpClient } from "../dataProvider";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";
import WarningIcon from "@material-ui/icons/Warning";

const EmployeesList = ({
  target, //Example: "related_school_id"
  reference,
  title,
  resources,
  model_id,
  canAdd,
  canDelete,
  isOrganization = false,
  ...props
}: any) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const [create_mode, setCreateMode] = React.useState(false);
  const [create, { loading }] = useCreate(`${resources}/${model_id}/employees`);
  const notify = useNotify();
  const { permissions = [] } = usePermissions();

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  const handleClickOpen = () => {
    setCreateMode(true);
  };

  const handleClickClose = () => {
    setCreateMode(false);
  };

  const EmployeeActions: FC<Omit<any, "children">> = () => {
    return canAdd === true ? (
      <TopToolbar>
        {canAdd === true && (
          <Button onClick={() => handleClickOpen()}>
            + {translate(`resources.employees.add`)}
          </Button>
        )}
      </TopToolbar>
    ) : (
      <></>
    );
  };

  const EmployeeExpand = (props) => {
    const [verified, setVerified] = React.useState(
      props !== null &&
        props !== undefined &&
        props.record !== null &&
        props.record !== undefined &&
        props.record.verified === true
    );
    const [expert, setExpert] = React.useState(
      props !== null &&
        props !== undefined &&
        props.record !== null &&
        props.record !== undefined &&
        props.record.is_expert === true
    );

    const handleChangeVerified = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setVerified(event.target.checked);
      const url = `${apiUrl}/${resources}/${model_id}/employees/${props.record.user_id}/verified`;
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify({ verified: event.target.checked }),
      }).then(({ headers, json }: any) => {
        refresh();
      });
    };

    const handleChangeExpert = (event: React.ChangeEvent<HTMLInputElement>) => {
      setExpert(event.target.checked);
      const url = `${apiUrl}/${resources}/${model_id}/employees/${props.record.user_id}/expert`;
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify({ verified: event.target.checked }),
      }).then(({ headers, json }: any) => {
        refresh();
      });
    };

    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Labeled label={translate(`${reference}.fields.verified`)}>
            <Switch
              checked={verified}
              onChange={handleChangeVerified}
              color="primary"
              name="verified"
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={!canDelete}
            />
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <Labeled label={translate(`${reference}.fields.is_expert`)}>
            <Switch
              checked={expert}
              onChange={handleChangeExpert}
              color="primary"
              name="verified"
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={!canDelete}
            />
          </Labeled>
        </Grid>
      </Grid>
    );
  };

  const handleDelete = async (employee_id) => {
    const url = `${apiUrl}/${reference}/${employee_id}?${target}=${model_id}`;
    return httpClient(url, {
      method: "DELETE",
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const MyDeleteButton = (props) => {
    return (
      <DeleteButtonList handleClick={() => handleDelete(props.record.id)} />
    );
  };

  const GoToPubUrl = (props) => {
    if (props.record.is_expert) {
      return <GoToPublicUrl record={props.record} source="public_url" />;
    }
    return <></>;
  };

  return (
    <div>
      {create_mode === false && (
        <ShowView
          {...props}
          component="div"
          title={title}
          actions={
            <EmployeeActions canAdd={canAdd} model_id={model_id} {...props} />
          }
        >
          <SimpleShowLayout>
            <ReferenceManyField
              label=""
              reference="employees"
              target={target}
              pagination={<ListPagination />}
              sort={{ field: "first_name", order: "ASC" }}
            >
              <Datagrid
                rowClick={undefined}
                expand={<EmployeeExpand {...props} />}
              >
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label={translate(`users.fields.first_name`)}
                  link={false}
                >
                  <TextField
                    source={
                      permissions.includes(USER_ROLE_SUPER_ADMIN)
                        ? "full_name_and_full_email"
                        : "full_name_and_email"
                    }
                  />
                </ReferenceField>
                <TextField source="job_title" />
                <TextField source="start_date" />
                <TextField source="end_date" />
                <BooleanField source="verified" />
                <BooleanField source="is_expert" />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label={translate(`users.fields.is_expert`)}
                  link={false}
                >
                  <BooleanField source="is_expert" />
                </ReferenceField>
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label={translate(`users.fields.public_url`)}
                  link={false}
                >
                  <GoToPubUrl {...props} />
                </ReferenceField>
              </Datagrid>
            </ReferenceManyField>
          </SimpleShowLayout>
        </ShowView>
      )}
      {create_mode === true && canAdd && (
        <>
          <FormWithRedirect
            resource={props.resource}
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CardWithIcon
                      title={
                        <p>
                          {translate("sentence.search_related_user_begin")}{" "}
                          <a
                            href="https://eduwo.ch/de/register"
                            target={"_blanck"}
                          >
                            eduwo.ch/de/register
                          </a>{" "}
                          {translate("sentence.search_related_user_end")}
                        </p>
                      }
                      value=" "
                      icon={<WarningIcon />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReferenceInput
                      label={translate(`resources.users.name_singular`)}
                      source="user_id"
                      reference="users"
                      filter={{
                        show_only: [
                          "id",
                          "full_name_and_full_email",
                          "full_name_and_email",
                          "first_name",
                          "last_name",
                        ],
                      }}
                      validate={[required()]}
                      filterToQuery={(searchText) => ({ term: searchText })}
                    >
                      <AutocompleteInput
                        fullWidth
                        optionText={
                          permissions.includes(USER_ROLE_SUPER_ADMIN)
                            ? "full_name_and_full_email"
                            : "full_name_and_email"
                        }
                        matchSuggestion={(options, state) => options}
                      />
                    </ReferenceInput>
                  </Grid>
                  {isOrganization === false && (
                    <Grid item xs={12}>
                      <NumberInputField
                        source={"graduation_year"}
                        resource={reference}
                        disabled={false}
                        validate={true}
                      />
                    </Grid>
                  )}
                  {isOrganization === true && (
                    <Grid item xs={12}>
                      <TextInputField
                        source={"job_title"}
                        resource={reference}
                        disabled={false}
                        validate={false}
                      />
                    </Grid>
                  )}
                  <Grid item xs={2}>
                    <MySaveButton
                      label="Add"
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      saving={saving}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={() => handleClickClose()}
                    >
                      {translate(`common.cancel`)}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </>
      )}
    </div>
  );
};

export default EmployeesList;
