import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Collapse,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Button, useTranslate } from "react-admin";
import { BoxTitle } from "../components";
import statisticsProvider from "./statisticsProvider";
import EduwoStatistics from "./EduwoStatistics";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  button: {
    marginTop: "1em",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  alignCenter: { textAlign: "center" },
}));

export default function Statistics({ ...props }) {
  const [group_by, setGroupBy] = React.useState("day");
  const [collapse, setCollapse] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [eduwo_stats, setEduwoStats] = React.useState(null);
  const [google_stats, setGoogleStats] = React.useState(null);
  const [line_chart_labels, setLineChartsLabels] = React.useState([]);
  const translate = useTranslate();

  let today = new Date();
  let endDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  );
  let endDateMonth =
    endDate.getMonth() + 1 < 10
      ? "0" + (endDate.getMonth() + 1)
      : endDate.getMonth() + 1;
  let endDateDay =
    endDate.getDate() < 10 ? "0" + (endDate.getDate() + 1) : endDate.getDate();

  let lastWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  let lastWeekMonth =
    lastWeek.getMonth() + 1 < 10
      ? "0" + lastWeek.getMonth() + 1
      : lastWeek.getMonth() + 1;
  let lastWeekDay =
    lastWeek.getDate() < 10 ? "0" + lastWeek.getDate() : lastWeek.getDate();
  const [end_date, setEndDate] = React.useState(
    today.getFullYear() + "-" + endDateMonth + "-" + endDateDay
  );
  const [start_date, setStartDate] = React.useState(
    lastWeek.getFullYear() + "-" + lastWeekMonth + "-" + lastWeekDay
  );

  const handleChangeGroupBy = (event) => {
    setGroupBy(event.target.value);
  };

  const handleRefreshClick = () => {
    setLoading(true);
    statisticsProvider
      .getAll("organizations", props.id, {
        start_date: start_date,
        end_date: end_date,
        group_by: group_by,
      })
      .then((json: any) => {
        setEduwoStats(json.data.eduwo);
        setGoogleStats(json.data.google !== null ? json.data.google : null);

        setLineChartsLabels(
          json.data.eduwo.details_de.map((entry) => {
            return Object.keys(entry)[0];
          })
        );
        setLoading(false);
      });
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BoxTitle
        title={translate(`statistics.parameters`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={3}>
            <TextField
              id="start_date"
              label="Start Date"
              type="date"
              defaultValue={start_date}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                const { value } = event.target;
                setStartDate(value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              id="end_date"
              label="End Date"
              type="date"
              defaultValue={end_date}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                const { value } = event.target;
                setEndDate(value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                Group By
              </InputLabel>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={group_by}
                onChange={handleChangeGroupBy}
                className={classes.selectEmpty}
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button label="Refresh All" onClick={() => handleRefreshClick()} />
          </Grid>
        </Grid>
      </Collapse>
      <EduwoStatistics
        eduwo_stats={eduwo_stats}
        google_stats={google_stats}
        loading={loading}
        line_chart_labels={line_chart_labels}
      />
    </div>
  );
}
