import * as React from "react";
import { FC } from "react";
import { Edit, ShowProps } from "react-admin";
import WizardForm from "./WizardForm";

const WizardEdit: FC<ShowProps> = ({ ...props }) => {
  return (
    <Edit {...props} actions={<></>} title={" "}>
      <WizardForm {...props} action="edit" />
    </Edit>
  );
};

export default WizardEdit;
