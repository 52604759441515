import * as React from "react";
import { usePermissions } from "react-admin";
import { EventsList } from "../components";
import { OrganizationTitle } from "./OrganizationForm";
import {
  USER_ROLE_ORGANIZATION_ADMIN,
  USER_ROLE_SUPER_ADMIN,
} from "../Constants";

const OrganizationEvents = ({ ...props }) => {
  const { permissions = [] } = usePermissions();
  return (
    <EventsList
      target={"related_organization_id"}
      reference={"organization_events"}
      title={<OrganizationTitle />}
      resources={"organizations"}
      model_id={props.id}
      canAdd={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_ORGANIZATION_ADMIN)
      }
      canDelete={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_ORGANIZATION_ADMIN)
      }
    />
  );
};

export default OrganizationEvents;
