import { makeStyles } from "@material-ui/core/styles";

export const AlertsStyle = makeStyles({
  warning: {
    backgroundColor: "yellow",
  },
  danger: {
    backgroundColor: "red",
  },
});
