import * as React from "react";

import { Grid } from "@material-ui/core";
import { TextInputField, ReferenceSelectInputField } from "../components";

import ReferenceDataTable from "./ReferenceDataTable";

const DistrictsEditCreateForm = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={"districts"}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceSelectInputField
          source="region_id"
          reference="regions"
          optionText="title_with_country"
          optionValue="id"
          allowEmpty={false}
          validate={true}
        />
      </Grid>
    </Grid>
  );
};
const DistrictsForm = ({ props }: any) => {
  const fields = ["title"];
  const reference_data_fields = ["title", "region_id"];

  return (
    <ReferenceDataTable
      resource={"districts"}
      table_fields={fields}
      reference_data_fields={reference_data_fields}
      {...props}
      editForm={<DistrictsEditCreateForm />}
      createForm={<DistrictsEditCreateForm />}
      sort={{}}
    />
  );
};

export default DistrictsForm;
