import * as React from "react";
import { FC } from "react";
import {
  ReferenceManyField,
  SimpleShowLayout,
  TopToolbar,
  Datagrid,
  TextField,
  ShowView,
  useRefresh,
  DateField,
  BooleanField,
  // ShowButton,
  useTranslate,
  FormWithRedirect,
  useNotify,
  useCreate,
  ReferenceInput,
  SelectInput,
  Labeled,
} from "react-admin";
import {
  ListPagination,
  DeleteButtonList,
  TextInputField,
  BooleanInputField,
  DateTimeInputField,
  AddRelationButtonIn,
  RichTextInputField,
  MySaveButton,
} from "../components";
import { apiUrl, httpClient } from "../dataProvider";
import { Grid, Typography, Switch, Button } from "@material-ui/core";
import { RelatedToolbarStyle } from "../styles";
import { useMediaQuery, Theme } from "@material-ui/core";

// TODO: Edit event

const EventsList = ({
  target,
  reference,
  title,
  resources,
  model_id,
  canAdd,
  canDelete,
  modelNameField = "name",
  ...props
}: any) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const [create, { loading }] = useCreate(reference);
  const notify = useNotify();
  const [create_mode, setCreateMode] = React.useState(false);
  const classes = RelatedToolbarStyle();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const EventShowActions: FC<Omit<any, "children">> = () => {
    return (
      <TopToolbar className={classes.toolbar}>
        <AddRelationButtonIn
          resource={"events"}
          handleClickOpen={handleClickOpen}
        />
      </TopToolbar>
    );
  };

  const EventExpand = (props) => {
    const [published, setPublished] = React.useState(
      props !== undefined &&
        props !== null &&
        props.record !== undefined &&
        props.record !== null &&
        props.record.published === true
    );
    const handleChangePublished = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setPublished(event.target.checked);
      const url = `${apiUrl}/${reference}/${props.record.id}/published`;
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify({ published: event.target.checked }),
      }).then(({ headers, json }: any) => {
        refresh();
      });
    };

    if (!props.record) {
      return <></>;
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4}>
          <Labeled label={translate(`${reference}.fields.starts_on`)}>
            <Typography gutterBottom>{props.record.starts_on}</Typography>
          </Labeled>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Labeled label={translate(`${reference}.fields.ends_on`)}>
            <Typography gutterBottom>{props.record.ends_on}</Typography>
          </Labeled>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Labeled label={translate(`${reference}.fields.published`)}>
            <Switch
              checked={published}
              onChange={handleChangePublished}
              color="primary"
              name="published"
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={!canDelete}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={translate(`${reference}.fields.title`)}>
            <Typography gutterBottom>{props.record.title}</Typography>
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={translate(`${reference}.fields.description`)}>
            <div
              className="tag-temp-text"
              dangerouslySetInnerHTML={{ __html: props.record["description"] }}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={translate(`${reference}.fields.link`)}>
            <Typography gutterBottom>{props.record.link}</Typography>
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={translate(`${reference}.fields.slug`)}>
            <Typography gutterBottom>{props.record.slug}</Typography>
          </Labeled>
        </Grid>
      </Grid>
    );
  };

  const handleClickOpen = () => {
    setCreateMode(true);
  };

  const handleClickClose = () => {
    setCreateMode(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setCreateMode(false);
          // refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
          setCreateMode(false);
        },
      }
    );
  };

  const handleDelete = async (event_id) => {
    const url = `${apiUrl}/${reference}/${event_id}`;
    return httpClient(url, {
      method: "DELETE",
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const MyDeleteButton = (props) => {
    return (
      <DeleteButtonList handleClick={() => handleDelete(props.record.id)} />
    );
  };

  const school_id = model_id;
  const course_id = model_id;
  const organization_id = model_id;

  return (
    <div>
      {create_mode === false && (
        <ShowView
          {...props}
          component="div"
          title={title}
          actions={canAdd === true ? <EventShowActions /> : null}
        >
          <SimpleShowLayout>
            <ReferenceManyField
              label=""
              reference={reference}
              target={target}
              pagination={<ListPagination />}
              sort={{ field: "starts_on", order: "ASC" }}
            >
              <Datagrid
                rowClick={undefined}
                expand={<EventExpand {...props} />}
                style={{ tableLayout: isXSmall ? "fixed" : "initial" }}
              >
                <TextField source="title" />
                <DateField source="starts_on" showTime />
                {!isXSmall && <DateField source="ends_on" showTime />}
                {!isXSmall && <BooleanField source="published" />}
                {/* <ShowButton /> */}
                {canDelete && <MyDeleteButton {...props} />}
              </Datagrid>
            </ReferenceManyField>
          </SimpleShowLayout>
        </ShowView>
      )}
      {create_mode === true && (
        <FormWithRedirect
          resource={resources}
          initialValues={{
            school_id,
            course_id,
            organization_id,
          }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ReferenceInput
                    source={target.substring(target.indexOf("_") + 1)}
                    reference={resources}
                  >
                    <SelectInput
                      optionText={modelNameField}
                      fullWidth
                      disabled
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DateTimeInputField
                    source="starts_on"
                    resource={reference}
                    disabled={false}
                    validate={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DateTimeInputField
                    source="ends_on"
                    resource={reference}
                    disabled={false}
                    validate={true}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <BooleanInputField
                    source={"published"}
                    resource={reference}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInputField
                    source="title"
                    resource={reference}
                    disabled={false}
                    validate={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichTextInputField
                    source="description"
                    resource={reference}
                    disabled={false}
                    validate={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInputField
                    source="link"
                    resource={reference}
                    disabled={false}
                    validate={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInputField
                    source="location"
                    resource={reference}
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={2}>
                  <MySaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    saving={saving}
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    onClick={() => handleClickClose()}
                  >
                    {translate(`common.cancel`)}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        />
      )}
    </div>
  );
};

export default EventsList;
