import * as React from "react";
import { CreateUpdateInfo } from "../components";
import UserGeneralFields from "./UserGeneralFields";
import UserContactFields from "./UserContactFields";
import UserExpertFields from "./UserExpertFields";
import UserRelationFields from "./UserRelationFields";

const UserTabSummary = ({
  action,
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  if (action === undefined) {
    action = "show";
  }

  return (
    <>
      <CreateUpdateInfo {...props} />
      <UserGeneralFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      <UserContactFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      <UserExpertFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      <UserRelationFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
    </>
  );
};

export default UserTabSummary;
