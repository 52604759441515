import * as React from "react";
import { ReviewsList } from "../components";
import {
  USER_ROLE_COURSE_ADMIN,
  USER_ROLE_SCHOOL_ADMIN,
  USER_ROLE_SUPER_ADMIN,
} from "../Constants";
import { CourseTitle } from "./CourseForm";
import { usePermissions } from "react-admin";

const CourseReviews = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();

  return (
    <ReviewsList
      target={"related_course_id"}
      title={<CourseTitle />}
      resources={"courses"}
      reference={"reviews"}
      model_id={props.id}
      canApprove={permissions.includes(USER_ROLE_SUPER_ADMIN)}
      canReply={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN) ||
        permissions.includes(USER_ROLE_COURSE_ADMIN)
      }
    />
  );
};

export default CourseReviews;
