import React from "react";
import {
  ShowImageUrl,
  Loading,
  UploadButtonWithIcon,
  FileDownloadUrl,
} from "../components";
import { Grid } from "@material-ui/core";
import ImagesProvider from "../dataProvider/ImagesProvider";
import { useTranslate } from "react-admin";

export default function Gallery({
  disabled,
  gallery_array,
  type = "image",
  collection = "gallery",
  accepted_files = ["image/*"],
  files_limit = 6,
  fullUrl = false,
  ...props
}) {
  const aux: any = [];
  const [uploaded, setUploaded] = React.useState(aux);
  const [loading, setLoading] = React.useState(false);
  const translate = useTranslate();

  const uploadFilesOneByOne = async (
    resource,
    identifier,
    collection,
    file
  ) => {
    var index = file.length;
    var new_uploaded = uploaded;
    file.map((item) => {
      ImagesProvider.uploadMany(resource, identifier, collection, [item]).then(
        (json: any) => {
          index--;
          new_uploaded.push(json);
          setUploaded(new_uploaded);
          if (index === 0) {
            setLoading(false);
          }
          return json;
        }
      );
    });
  };

  const handleUploadClick = (resource, identifier, collection, file) => {
    setLoading(true);
    uploadFilesOneByOne(resource, identifier, collection, file);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {disabled === false && loading === true && <Loading />}
        {disabled === false && loading === false ? (
          <UploadButtonWithIcon
            handleClick={(file) =>
              handleUploadClick(props.resource, props.id, collection, file)
            }
            files_limit={files_limit}
            accepted_files={accepted_files}
          />
        ) : (
          <p>{translate("sentence.only_premium")}</p>
        )}
      </Grid>
      {uploaded.map((item, index) => {
        return item.map((details, index) => {
          return (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={6}>
                {type === "image" && (
                  <ShowImageUrl
                    title={details.name}
                    resource={props.resource}
                    collection={collection}
                    name={details.name}
                    image_src={details.url}
                    image_alt={"uploaded"}
                    disabled={false}
                    only_delete={true}
                    id={props.id}
                  />
                )}
                {type === "file" && (
                  <FileDownloadUrl
                    disabled={false}
                    resource={props.resource}
                    identifier={props.id}
                    collection="uploads"
                    title={details.name}
                    href={details.url}
                    file_name={details.name}
                  />
                )}
              </Grid>
            </React.Fragment>
          );
        });
      })}
      {gallery_array !== undefined &&
        gallery_array.map((item, index) => {
          const url =
            item !== null && item !== undefined
              ? fullUrl
                ? item.full_url
                : item.small_url
              : "";
          return (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={6}>
                {type === "image" && (
                  <ShowImageUrl
                    title={item.name}
                    image_src={url}
                    image_alt={item.name}
                    disabled={disabled}
                    only_delete={true}
                    name={item.name}
                    resource={props.resource}
                    id={props.id}
                    collection={collection}
                  />
                )}
                {type === "file" && (
                  <Grid item xs={12} sm={6}>
                    <FileDownloadUrl
                      disabled={disabled}
                      resource={props.resource}
                      identifier={props.id}
                      collection="uploads"
                      title={item.file_name}
                      href={url}
                      file_name={item.name}
                    />
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          );
        })}
    </Grid>
  );
}
