import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { Toolbar, EditButton, useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppState } from "../types";
import { ModelLocaleSelect } from ".";
import { ButtonStyle, TopToolbarStyle } from "../styles";

const MyActionShowBar: FC<Omit<any, "children">> = ({
  basePath,
  data,
  disabledEdit,
  disableModelLocal = false,
  forceModelLocale = undefined,
  ...props
}) => {
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      // @ts-ignore
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };
  const translate = useTranslate();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const classes = TopToolbarStyle();
  const bClasses = ButtonStyle();
  return (
    <div ref={ref}>
      <div
        className={
          isSticky
            ? open
              ? "sticky-top-menu-open"
              : "sticky-top-menu-close"
            : ""
        }
      >
        <Toolbar {...props} classes={classes}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
                <ModelLocaleSelect
                  label={translate("sentence.show_in")}
                  forceModelLocale={forceModelLocale}
                  disableModelLocal={disableModelLocal}
                  {...props}
                />
            </Grid>
            <Grid item>
              {disabledEdit === false && (
                <EditButton
                  className={bClasses.textButton}
                  variant="text"
                  basePath={basePath}
                  record={props.record ?? data}
                  size={"large"}
                />
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </div>
    </div>
  );
};
export default MyActionShowBar;
