import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import { TextInputField, BoxTitle } from "../components";

const CourseSocialNetworkFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`courses.boxes.social_network_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <p>{translate("sentence.only_premium")}</p>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextInputField
              source="site_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="youtube_video"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default CourseSocialNetworkFields;
