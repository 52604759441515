import * as React from "react";
import { FC } from "react";
import { Link } from "@material-ui/icons";
import { SourceFieldProps } from "./types";
import { LinearLoading } from ".";

const GoToPublicUrl: FC<SourceFieldProps> = (props) => {
  if (props.record === undefined) {
    return <LinearLoading />;
  }
  return (
    <a
      href={`${process.env.REACT_APP_BASE_URL}/${props.record[props.source]}`}
      target="_blanck"
    >
      <Link />
    </a>
  );
};

export default GoToPublicUrl;
