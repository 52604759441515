import * as React from "react";
import { usePermissions } from "react-admin";
import { AdministratorsList } from "../components";
import { USER_ROLE_SCHOOL_ADMIN, USER_ROLE_SUPER_ADMIN } from "../Constants";
import { CourseTitle } from "./CourseForm";

const CourseAdministrators = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <AdministratorsList
      disabled={disabled_level_1}
      target={"related_course_id"}
      title={<CourseTitle />}
      resources={"courses"}
      model_id={props.id}
      canAdd={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN)
      }
      canDelete={
        props.permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        props.permissions.includes(USER_ROLE_SCHOOL_ADMIN)
      }
    />
  );
};
export default CourseAdministrators;
