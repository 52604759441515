import * as React from "react";
import { FC } from "react";
import {
  ReferenceManyField,
  SimpleShowLayout,
  TopToolbar,
  Datagrid,
  TextField,
  ShowView,
  // ShowButton,
  useTranslate,
  FormWithRedirect,
  useNotify,
  useCreate,
  ReferenceInput,
  SelectInput,
  Labeled,
  useRefresh,
} from "react-admin";
import {
  ListPagination,
  DeleteButtonList,
  TextInputField,
  AddRelationButtonIn,
  MySaveButton,
  CardWithIcon,
} from ".";
import { apiUrl, httpClient } from "../dataProvider";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { ShowImageUrl } from "../components";
import { useMediaQuery, Theme } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

// TODO: Edit success story

const SuccessStoriesList = ({
  target,
  reference,
  title,
  resources,
  school_id = undefined,
  course_id = undefined,
  organization_id = undefined,
  canAdd,
  canDelete,
  ...props
}: any) => {
  const translate = useTranslate();
  const [create, { loading }] = useCreate(reference);
  const notify = useNotify();
  const [create_mode, setCreateMode] = React.useState(false);
  const refresh = useRefresh();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const SucceStoriesShowActions: FC<Omit<any, "children">> = () => {
    return (
      <TopToolbar>
        <AddRelationButtonIn
          resource={"success_stories"}
          handleClickOpen={handleClickOpen}
        />
      </TopToolbar>
    );
  };

  const SucceStoriesExpand = (props) => {
    const logo_image_url =
      props !== null &&
      props !== undefined &&
      props.record !== null &&
      props.record !== undefined &&
      props.record.photo !== null &&
      props.record.photo !== undefined
        ? props.record.photo.small_url !== undefined
          ? props.record.photo.small_url
          : props.record.photo.full_url
        : "";
    return props !== null &&
      props !== undefined &&
      props.record !== null &&
      props.record !== undefined ? (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ShowImageUrl
            title={translate(`${props.resource}.fields.photo`)}
            image_src={logo_image_url}
            disabled={!canDelete}
            resource={props.resource}
            id={props.id}
            collection={"photo"}
          />
        </Grid>
        <Grid item xs={12}>
          <Labeled label={translate(`${reference}.fields.title`)}>
            <Typography gutterBottom>{props.record.title}</Typography>
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <Labeled label={translate(`${reference}.fields.first_name`)}>
            <Typography gutterBottom>{props.record.first_name}</Typography>
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <Labeled label={translate(`${reference}.fields.last_name`)}>
            <Typography gutterBottom>{props.record.last_name}</Typography>
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={translate(`${reference}.fields.description`)}>
            <Typography gutterBottom>{props.record.description}</Typography>
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={translate(`${reference}.fields.slug`)}>
            <Typography gutterBottom>{props.record.slug}</Typography>
          </Labeled>
        </Grid>
      </Grid>
    ) : (
      <></>
    );
  };

  const handleClickOpen = () => {
    setCreateMode(true);
  };

  const handleClickClose = () => {
    setCreateMode(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setCreateMode(false);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
          setCreateMode(false);
        },
      }
    );
  };

  const handleDelete = async (success_story_id) => {
    const url = `${apiUrl}/${reference}/${success_story_id}`;
    return httpClient(url, {
      method: "DELETE",
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const MyDeleteButton = (props) => {
    return (
      <DeleteButtonList handleClick={() => handleDelete(props.record.id)} />
    );
  };

  return (
    <div>
      {create_mode === false && (
        <ShowView
          {...props}
          component="div"
          title={title}
          actions={canAdd === true ? <SucceStoriesShowActions /> : null}
        >
          <SimpleShowLayout>
            <ReferenceManyField
              label=""
              reference={reference}
              target={target}
              pagination={<ListPagination />}
            >
              <Datagrid
                rowClick={undefined}
                expand={<SucceStoriesExpand {...props} />}
              >
                <TextField source="title" />
                <TextField source="first_name" />!
                {!isXSmall && <TextField source="last_name" />}
                {/* <ShowButton /> */}
                {canDelete && <MyDeleteButton {...props} />}
              </Datagrid>
            </ReferenceManyField>
          </SimpleShowLayout>
        </ShowView>
      )}
      {create_mode === true && (
        <>
          <FormWithRedirect
            resource={resources}
            defaultValue={{ school_id, course_id, organization_id }}
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CardWithIcon
                      title={translate(`sentence.create_success_story_warning`)}
                      value=" "
                      icon={<WarningIcon />}
                    />
                  </Grid>
                  {school_id !== undefined && school_id !== null && (
                    <Grid item xs={12}>
                      <ReferenceInput
                        source={"school_id"}
                        reference={"schools"}
                      >
                        <SelectInput optionText={"name"} fullWidth disabled />
                      </ReferenceInput>
                    </Grid>
                  )}
                  {course_id !== undefined && course_id !== null && (
                    <Grid item xs={12}>
                      <ReferenceInput
                        source={"course_id"}
                        reference={"courses"}
                      >
                        <SelectInput
                          optionText={"short_name"}
                          fullWidth
                          disabled
                        />
                      </ReferenceInput>
                    </Grid>
                  )}
                  {organization_id !== undefined && organization_id !== null && (
                    <Grid item xs={12}>
                      <ReferenceInput
                        source={"organization_id"}
                        reference={"organizations"}
                      >
                        <SelectInput optionText={"name"} fullWidth disabled />
                      </ReferenceInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextInputField
                      source="title"
                      resource={reference}
                      disabled={false}
                      validate={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputField
                      source="first_name"
                      resource={reference}
                      disabled={false}
                      validate={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputField
                      source="last_name"
                      resource={reference}
                      disabled={false}
                      validate={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputField
                      source="description"
                      resource={reference}
                      disabled={false}
                      validate={true}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={2}>
                    <MySaveButton
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      saving={saving}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={() => handleClickClose()}
                    >
                      {translate(`common.cancel`)}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </>
      )}
    </div>
  );
};

export default SuccessStoriesList;
