import React from "react";
import { Fragment } from "react";
import { useRefresh, useTranslate, useUnselectAll } from "react-admin";
import { apiUrl, httpClient } from "../dataProvider";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { ConfirmationModal } from "../components";
import { DARK_BLUE } from "../Constants";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: DARK_BLUE,
    },
    secondary: {
      main: "#b71c1c",
    },
  },
});

const ListBulkActions = ({ deletedMode, ...props }) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const unselectAll = useUnselectAll();
  const [open, setOpen] = React.useState(false);
  const [open_restore, setOpenRestore] = React.useState(false);

  const array_ids =
    props.resource === "schools"
      ? { school_ids: props.selectedIds }
      : props.resource === "courses"
      ? { course_ids: props.selectedIds }
      : props.resource === "organizations"
      ? { organization_ids: props.selectedIds }
      : props.resource === "users"
      ? { user_ids: props.selectedIds }
      : { ids: props.selectedIds };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseRestore = () => {
    setOpenRestore(false);
  };

  const handleBulkDelete = () => {
    const url = `${apiUrl}/${props.resource}/bulk/delete`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(array_ids),
    }).then(({ headers, json }: any) => {
      unselectAll(props.resource);
      refresh();
    });
  };

  const handleBulkRestore = () => {
    const url = `${apiUrl}/${props.resource}/bulk/restore`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(array_ids),
    }).then(({ headers, json }: any) => {
      unselectAll(props.resource);
      refresh();
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        {!deletedMode && (
          <Button
            onClick={() => {
              setOpen(true);
            }}
            color="secondary"
          >
            <DeleteIcon />
            {translate(`button.delete`)}
          </Button>
        )}
        {deletedMode && (
          <Button
            onClick={() => {
              setOpenRestore(true);
            }}
            color="primary"
          >
            <RestoreIcon />
            {translate(`button.restore`)}
          </Button>
        )}
      </Fragment>

      <ConfirmationModal
        open={open}
        handleClose={() => handleClose()}
        handleAgree={() => handleBulkDelete()}
        handleDisagree={() => handleClose()}
        message={translate(`ra.message.delete_content`)}
        title={translate(`ra.action.delete`)}
      />

      <ConfirmationModal
        open={open_restore}
        handleClose={() => handleCloseRestore()}
        handleAgree={() => handleBulkRestore()}
        handleDisagree={() => handleCloseRestore()}
        message={translate(`sentence.restore_content`)}
        title={translate(`common.restore`)}
      />
    </ThemeProvider>
  );
};

export default ListBulkActions;
