import React from "react";
import {
  required,
  Button,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  useTranslate,
} from "react-admin";
import {
  MySaveButton,
  ReferenceArrayAutocompleteInputField,
} from "../components";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Add, Cancel } from "@material-ui/icons";

export default function ReferenceTags({
  disabled,
  reference = "tags",
  resource = "",
  source = "tag_titles",
  sort = { field: "title", order: "ASC" },
  perPage = 1000,
  optionText = "title",
  optionValue = "name",
}) {
  const [showDialog, setShowDialog] = React.useState(false);
  const [forceRefresh, setForceRefresh] = React.useState(false);
  const [create, { loading }] = useCreate("tags");
  const notify = useNotify();
  const translate = useTranslate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          setForceRefresh(true);
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={disabled ? 12 : 11}
        sm={disabled ? 12 : 9}
        md={disabled ? 12 : 10}
        lg={disabled ? 12 : 11}
      >
        <ReferenceArrayAutocompleteInputField
          reference={reference}
          source={source}
          sort={sort}
          optionText={optionText}
          optionValue={optionValue}
          disabled={disabled}
          filter={{
            empty: "empty",
          }}
          forceRefresh={forceRefresh}
        />
      </Grid>
      {disabled === false && (
        <Grid item xs={1} sm={3} md={2} lg={1}>
          <Button onClick={handleClick} label="ra.action.create">
            <Add />
          </Button>
          <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
            <DialogTitle>{translate(`title.create_tag`)}</DialogTitle>
            <FormWithRedirect
              resource="tags"
              save={handleSubmit}
              render={({ handleSubmitWithRedirect, pristine, saving }) => (
                <>
                  <DialogContent>
                    <TextInput source="title" validate={required()} fullWidth />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      label="ra.action.cancel"
                      onClick={handleCloseClick}
                      disabled={loading}
                    >
                      <Cancel />
                    </Button>
                    <MySaveButton
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      saving={saving}
                      disabled={loading}
                    />
                  </DialogActions>
                </>
              )}
            />
          </Dialog>
        </Grid>
      )}
    </Grid>
  );
}
