import * as React from "react";
import { Grid, Collapse } from "@material-ui/core";
import { BoxTitle } from "../components";
import DeployToolsForm from "./DeployToolsForm";
import DeploySummaryForm from "./DeploySummaryForm";

const ITForm = () => {
  const [collapse, setCollapse] = React.useState(true);
  return (
    <>
      <BoxTitle
        title={"Deploy Tools"}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DeploySummaryForm />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <DeployToolsForm />
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default ITForm;
