import * as React from "react";
import { SimpleForm, Create } from "react-admin";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextInputField } from "../components";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  warningSpacing: {
    marginTop: "-20px",
  },
}));

const Elements = ({ ...props }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextInputField
            source="slug"
            resource={props.resource}
            record={props.record}
            validate={true}
            translation={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputField
            source="title"
            resource={props.resource}
            record={props.record}
            translation={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputField
            source="sub_title"
            resource={props.resource}
            record={props.record}
            translation={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputField
            source="description"
            resource={props.resource}
            record={props.record}
            translation={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const WizardCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <Elements />
      </SimpleForm>
    </Create>
  );
};

export default WizardCreate;
