import * as React from "react";
import { FC } from "react";
import {
  useTranslate,
  required,
  SelectInput,
  DateInput,
  Labeled,
} from "react-admin";
import { ResourceDateTimeProps } from "./types";
import { Grid } from "@material-ui/core";
import { SelectStyle } from "../styles";

const DateTimeInputField: FC<ResourceDateTimeProps> = (props) => {
  const classes = SelectStyle();
  const translate = useTranslate();
  const minutesStep: number = props.minutesStep ?? 15;

  let minute: number = 0;
  let choiceMinutes = [{ id: "00", name: "00" }];
  minute += minutesStep;
  while (minute < 60) {
    let minuteString: string =
      minute < 10 ? "0" + String(minute) : String(minute);
    choiceMinutes.push({ id: minuteString, name: minuteString });
    minute += minutesStep;
  }

  return (
    <Labeled
      fullWidth
      source={props.source}
      resource={props.resource}
      label={
        props.label ?? translate(`${props.resource}.fields.${props.source}`)
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={7}>
          <DateInput
            source={`${props.source}_date`}
            label={""}
            resource={props.resource}
            record={props.record}
            fullWidth
            disabled={props.disabled}
            validate={props.validate ? [required()] : []}
            onChange={props.onChange}
            helperText={"date"}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            className={classes.small}
            fullWidth
            source={`${props.source}_hour`}
            label={""}
            placeholder="hh"
            resource={props.resource}
            record={props.record}
            validate={props.validate ? [required()] : []}
            onChange={props.onChange}
            helperText={"hour"}
            choices={[
              { id: "00", name: "00" },
              { id: "01", name: "01" },
              { id: "02", name: "02" },
              { id: "03", name: "03" },
              { id: "04", name: "04" },
              { id: "05", name: "05" },
              { id: "06", name: "06" },
              { id: "07", name: "07" },
              { id: "08", name: "08" },
              { id: "09", name: "09" },
              { id: "10", name: "10" },
              { id: "11", name: "11" },
              { id: "12", name: "12" },
              { id: "13", name: "13" },
              { id: "14", name: "14" },
              { id: "15", name: "15" },
              { id: "16", name: "16" },
              { id: "17", name: "17" },
              { id: "18", name: "18" },
              { id: "19", name: "19" },
              { id: "20", name: "20" },
              { id: "21", name: "21" },
              { id: "22", name: "22" },
              { id: "23", name: "23" },
            ]}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            className={classes.small}
            fullWidth
            source={`${props.source}_minutes`}
            label={""}
            placeholder={"mm"}
            resource={props.resource}
            record={props.record}
            validate={props.validate ? [required()] : []}
            onChange={props.onChange}
            helperText={"minutes"}
            choices={choiceMinutes}
          />
        </Grid>
      </Grid>
    </Labeled>
  );
};

export default DateTimeInputField;
