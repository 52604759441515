import { TranslationMessages } from "react-admin";
import frenchMessages from "ra-language-french";

const customFrenchMessages: TranslationMessages = {
  ...frenchMessages,
  pos: {
    search: "Rechercher",
    configuration: "Configuration",
    language: "Langue",
    model_locale: "Modèle locale",
    email: "Adresse e-mail",
  },
  button: {
    refreshSelected: "Rafraîchir sélectionné",
    loadSelected: "Télécharger sélectionné",
    reply: "Actualiser réponse",
    restore: "Restaurer",
    delete: "Supprimer",
    validate: "Validate", // TODO
    submit: "Submit", // TODO
    reset: "Reset", // TODO
    regist: "Regist", // TODO
    reset_email: "Reset", // TODO
    login: "Login", // TODO
  },
  title: {
    create_tag: "Créer Tag",
    create_study_field: "Créer un champ d'étude",
  },
  common: {
    fields: {
      created_at: "Créé à",
      last_update: "Dernière mise à jour",
    },
    not_available: "Pas disponible",
    agree: "Accepter",
    disagree: "Désaccord",
    accept: "Accepter",
    cancel: "annuler",
    submit: "Soumettre",
    restore: "Restaurer",
  },
  sentence: {
    images_premium_courses:
      "Le logo et les images de fond ne sont visibles uniquement pour les cours de qualité supérieure.",
    delete_with_name: "Supprimer %{name}",
    confirm_all: "veuillez confirmer si:",
    show_in: "Montrer dans:",
    edit_in: "éditer dans:",
    more_filters: "Plus de filtres",
    less_filters: "Moins de filtres",
    reply: "Réponse",
    reply_placeholder: "Voulons insérer ton réponse",
    create_success_story_warning:
      "Lors de la saisie d'une nouvelle histoire à succès, celle-ci doit être enregistrée et sauvegardée une fois. Ce n'est qu'ensuite que la photo correspondante peut être ajoutée. Merci.",
    login_info_title: "Pas encore de connexion backend ?",
    login_info_body_part1: "Veuillez d'abord vous inscrire sur ",
    login_info_body_part2:
      " en tant qu'utilisateur normal et n'hésitez pas à vous connecter par la suite ",
    login_info_body_part3:
      " afin que nous puissions vous attribuer les autorisations appropriées.",
    login_info_body_part4:
      "Wenn Sie bereits einen Zugang haben, aber das Passwort vergessen haben, dann können Sie es hier zurücksetzen: ", // TODO
    login_email_subject: "Registration Admin-Bereich", // TODO
    login_email_body:
      "Guten Tag eduwo-Team,%0D%0A%0D%0AIch habe ein normales User-Profil auf eduwo erstellt und möchte nun bitte Zugang zum Admin-Backend.%0D%0A%0D%0AVielen Dank und herzliche Grüsse", // TODO
    search_related_user_begin:
      "Pour pouvoir ajouter un utilisateur en tant qu’expert, il doit déjà avoir un profil utilisateur. L’utilisateur peut s’inscrire sur ",
    search_related_user_end: ".",
    school_cities_warning:
      "Ce champ peut également être laissé vide.\nSi ce champ est vide, toutes les villes enregistrées comme emplacements dans les cours sont affichées sur le profil de l'école.",
    restore_content: "Voulez-vous vraiment restaurer cet élément?",
    organization_infobox:
      "Ici, vous pouvez placer plusieurs infobox sur votre profil, chacune avec un titre et une description.",
    organization_teaser:
      "Vous avez la possibilité de placer un teaser avec un titre, une description et un lien d'appel à l'action.",
    organization_focus_1: "Eine Übersicht aller Schulen finden Sie hier:", // TODO
    organization_focus_2:
      "Und eine Übersicht aller Studienbereich (High level) und Studienrichtungen (Deep dive) finden Sie hier:", // TODO
    focus_topics:
      "Vous pouvez enregistrer ici les sujets sur lesquels vous vous concentrez en tant qu'entreprise ou les sujets sur lesquels vous pouvez proposer des informations.",
    only_premium: "This function is only available to premium customers.",
    infobox_warning:
      "If your school has top sport-friendly offers, the corresponding information has been listed in the info boxes below in cooperation with Swiss Olympic in order to inform potential students about it. We ask you not to adapt or remove this information. Please feel free to contact us directly if you have any questions or would like to make any changes.",
    course_locations: "Several locations can be entered for each course.",
    admmission_description:
      "Il s'agit d'un champ de texte et il n'est soumis à aucune condition. Ce qui est saisi peut être vu sur le profil.",
    admmission_conditions_description:
      "Des conditions de filtrage sont attachées à ces champs. Ces éléments sont importants pour la génération de prospects. Moins l'expérience professionnelle est importante et moins l'âge est élevé, plus le nombre de personnes pouvant être jumelées est élevé.",
    school_is_updated:
      "When empty, the school is considered not to be updated after 6 months without any update.", // TODO
    course_is_updated:
      "When empty, the course is considered not to be updated after expiration of the degree type validations.", // TODO
    employee_copy_link:
      "Veuillez copier le lien suivant et le partager avec vos employés pour être enregistré en tant qu'employé vérifié.",
    employee_official_email:
      "All users with this official email address will be considered automatically verified", // TODO
  },
  dashboard: {
    title: "Bienvenue dans la zone d'administration d'eduwo",
    user_name: "Votre nom:",
    role: "Votre rôle:",
    admin_schools: "Organisation (s) qui vous sont attribuées",
    explanation: {
      title: "Déclaration",
      body: "Dans ce backend, en tant qu'administrateur, vous pouvez apporter diverses modifications aux profils qui vous sont assignés. Si vous avez besoin d'assistance, vous pouvez nous joindre au 079 278 32 94 ou par email à raphael@eduwo.ch. NLa zone surlignée en gris ne peuvent pas être modifiés. Ceux-ci ne peuvent être modifiés que par eduwo. Si vous pensez que quelque chose doit être ajusté dans ce domaine, veuillez nous envoyer un court e-mail à raphael@eduwo.ch. ",
    },
    premium_profile: {
      title: "Profil Premium",
      body: "Si vous ne souhaitez pas gérer vous-même votre profil, vous pouvez à tout moment nous envoyer un message avec les demandes de modification à patrick@eduwo.ch et nous nous ferons un plaisir d'effectuer les modifications pour vous.",
    },
    non_premium_profile: {
      title: "Pas encore de profil premium?",
      body: "Si votre école n'a pas encore de profil premium sur eduwo, toutes les zones visibles ici dans la zone d'administration ne seront pas affichées sur le profil public. Ceci est réservé aux clients premium. Si vous pensez à un profil premium, veuillez le faire n'hésitez pas à nous contacter au 079 278 32 94 ou raphael@eduwo.ch.",
    },
  },
  languages: {
    de: "Allemand",
    fr: "Français",
    en: "Anglais",
    it: "Italian",
  },
  resources: {
    schools: {
      name: "Écoles",
      name_singular: "École",
      create: "Créer École",
      add: "Ajouter des École",
    },
    courses: {
      name: "Cours",
      name_singular: "Cours",
      create: "Créer cours",
      add: "Ajouter des cours",
    },
    organizations: {
      name: "Organisations",
      name_singular: "Organisation",
      create: "Créer Organisation",
      add: "Ajouter Organisation",
    },
    users: {
      name: "Utilisateurs",
      name_singular: "Utilisateur",
      create: "Créer utilisateur",
      add: "Ajouter utilisateur",
    },
    experts: {
      name: "Experts",
      name_singular: "Expert",
      create: "Créer Expert",
      add: "Ajouter Expert",
    },
    administrators: {
      name: "Administrateurs",
      name_singular: "Administrateur",
      create: "Créer Administrateur",
      add: "Ajouter Administrateur",
    },
    reference_data: {
      name: "Reference Data",
      name_singular: "Reference Data",
      create: "Créer Reference Data",
      add: "Ajouter Reference Data",
    },
    school_types: {
      name: "Types d'écoles",
      name_singular: "Type d'école",
      create: "Créer type d'école",
      add: "Ajouter type d'école",
    },
    soa_school_types: {
      name: "Soa types d'écoles",
      name_singular: "Soa type d'école",
      create: "Créer Soa type d'école",
      add: "Ajouter Soa type d'école",
    },
    soa_school_levels: {
      name: "Soa Niveaux scolaires",
      name_singular: "Soa Niveau scolaire",
      create: "Créer Soa Niveau scolaire",
      add: "Ajouter Soa Niveau scolaire",
    },
    cities: {
      name: "Villes",
      name_singular: "Ville",
      create: "Créer Ville",
      add: "Ajouter Ville",
    },
    districts: {
      name: "Districts",
      name_singular: "District",
      create: "Créer District",
      add: "Ajouter District",
    },
    regions: {
      name: "Regions",
      name_singular: "Region",
      create: "Créer Region",
      add: "Ajouter Region",
    },
    countries: {
      name: "Countries", // TODO
      name_singular: "Country", // TODO
      create: "Create Country", // TODO
      add: "Add Country", // TODO
    },
    study_types: {
      name: "Types d'études",
      name_singular: "type d'étude",
      create: "Créer Type d'étude",
      add: "Ajouter Type d'étude",
    },
    degree_types: {
      name: "Types diplômes",
      name_singular: "Type diplôme",
      create: "Créer Type diplôme",
      add: "Ajouter Type diplôme",
    },
    school_events: {
      name: "événements scolaires",
      name_singular: "événement scolaire",
      create: "Créer événement scolaire",
      add: "Ajouter événement scolaire",
    },
    course_events: {
      name: "Événements des cours",
      name_singular: "Événements de cours",
      create: "Créer événement de cours",
      add: "Ajouter événement de cours",
    },
    organization_events: {
      name: "Événements d'organisations",
      name_singular: "Événement d'organisation",
      create: "Créer événement d'organisation",
      add: "Ajouter événement d'organisation",
    },
    events: {
      name: "Événements",
      name_singular: "Événement",
      create: "Créer événement",
      add: "Ajouter événement",
    },
    organization_types: {
      name: "Types d'organisations",
      name_singular: "Type d'organisation",
      create: "Créer type d'organisatio",
      add: "Ajouter type d'organisatio",
    },
    leads: {
      name: "Leads",
      name_singular: "Lead",
      create: "Créer Lead",
      add: "Ajouter Lead",
    },
    course_languages: {
      name: "Langues de course",
      name_singular: "Langue de course",
      create: "Créer Langue de course",
      add: "Ajouter Langue de course",
    },
    course_costs: {
      name: "Les frais des cours",
      name_singular: "Frais de cours",
      create: "Créer Frais de cours",
      add: "Ajouter Frais de cours",
    },
    course_durations: {
      name: "Durées des cours",
      name_singular: "Durée de cours",
      create: "Créer Durée de cours",
      add: "Ajouter Durée de cours",
    },
    study_fields: {
      name: "Domaines d'études",
      name_singular: "Domaine d'étude",
      create: "Créer domaine d'étude",
      add: "Ajouter domaine d'étude",
    },
    sub_study_fields: {
      name: "Sub Domaines d'études",
      name_singular: "Sub Domaine d'étude",
      create: "Créer sub domaine d'étude",
      add: "Ajouter sub domaine d'étude",
    },
    study_field_categories: {
      name: "Catégories de domaines d'études",
      name_singular: "Catégorie de domaine d'étude",
      create: "Créer catégorie de domaine d'étude",
      add: "Ajouter catégorie de domaine d'étude",
    },
    tags: {
      name: "Tags",
      name_singular: "Tag",
      create: "Créer Tag",
      add: "Ajouter Tag",
    },
    legal_forms: {
      name: "Les formes juridiques",
      name_singular: "Le forme juridique",
      create: "Créer Le forme juridique",
      add: "Ajouter Le forme juridique",
    },
    branches: {
      name: "Branches",
      name_singular: "Branch",
      create: "Créer Branch",
      add: "Ajouter Branch",
    },
    awards: {
      name: "Prix",
      name_singular: "Prix",
      create: "Créer prix",
      add: "Ajouter prix",
    },
    job_types: {
      name: "Types d'emplois",
      name_singular: "Type d'emploi",
      create: "Créer Type d'emploi",
      add: "Ajouter Type d'emploi",
    },
    success_stories: {
      name: "Réuissites",
      name_singular: "Réuissite",
      create: "Créer réussite",
      add: "Ajouter réussite",
    },
    languages: {
      name: "Languages",
      name_singular: "Language",
      create: "Créer Language",
      add: "Ajouter Languages",
    },
    user_looking_fors: {
      name: "Utilisateurs recherchant",
      name_singular: "Utilisatuer recherchant",
      create: "Créer utilisateur recherchant",
      add: "Ajouter utilisateur recherchant",
    },
    sport_types: {
      name: "Types de sport",
      name_singular: "Type de sport",
      create: "Créer type de sport",
      add: "Ajouter type de sport",
    },
    initial_languages: {
      name: "Initial Languages",
      name_singular: "Initial Language",
      create: "Créer Initial Language",
      add: "Ajouter Initial Language",
    },
    publicities: {
      name: "Publicités",
      name_singular: "Publicité",
      create: "Créer Publicité",
      add: "Ajouter Publicité",
    },
    advertisement_categories: {
      name: "Catégories de publicité",
      name_singular: "Catégorie de publicité",
      create: "Créer Catégorie de publicité",
      add: "Ajouter Catégorie de publicité",
    },
    user_degree_types: {
      name: "Types de diplômes utilisateur",
      name_singular: "Type de diplôme utilisateur",
      create: "Créer Type de diplôme utilisateur",
      add: "Ajouter Type de diplôme utilisateur",
    },
    work_experiences: {
      name: "Work Experiences", // TODO
      name_singular: "Work Experience", // TODO
      create: "Create Work Experience", // TODO
      add: "Add Work Experience", // TODO
    },
    contacts: {
      name: "Contacts", // TODO
      name_singular: "Contact", // TODO
      create: "Create Contact", // TODO
      add: "Add Contact", // TODO
    },
    wizards: {
      name: "Wizards", // TODO
      name_singular: "Wizard", // TODO
      create: "Create Wizard", // TODO
      add: "Add Wizard", // TODO
    },
    knowledge_base: {
      name: "Knowledge Bases", // TODO
      name_singular: "Knowledge Base", // TODO
      create: "Create Knowledge Base", // TODO
      add: "Add Knowledge Base", // TODO
    },
    kb_modules: {
      name: "Modules", // TODO
      name_singular: "Module", // TODO
      create: "Create Module", // TODO
      add: "Add Module", // TODO
    },
    kb_sections: {
      name: "Sections", // TODO
      name_singular: "Section", // TODO
      create: "Create Section", // TODO
      add: "Add Section", // TODO
    },
    kb_units: {
      name: "Units", // TODO
      name_singular: "Unit", // TODO
      create: "Create Unit", // TODO
      add: "Add Unit", // TODO
    },
  },
  schools: {
    fields: {
      show_link: "Afficher le lien",
      has_phone_number_mandatory: "Numéro de téléphone obligatoire",
      has_government_subsidies: "A des subventions gouvernementales",
      amount_of_subsidies: "Montant des subventions",
      has_eduwo_reimbursements: "A des remboursements Eduwos",
      amount_eduwo_reimbursements: "Montant reimbursements Eduwo",
      has_counseling: "A des conseils",
      has_courses_open_spots: "A des courses places ouvertes",
      has_course_reservations: "A des réservations de cours",
      has_events_open_spots: "A des événements ouverts",
      info_material: "Matériel d'information",
      name: "Nom",
      subtitle: "Sous-titre",
      abbreviation: "Abréviation",
      slug: "Slug",
      description: "Description",
      founding_year: "Année de fondation",
      students_count: "Nombre d'étudiants",
      logo_image: "Image du logo",
      profile_image: "Image de profil",
      premium: "Premium",
      soa_elite_sport: "Soa Sport d'élite",
      reviews_section: "Reviews Section",
      only_verified: "Uniquement vérifié",
      soa_financing_type: "Soa type de financement",
      soa_students_count: "Soa nombre d'étudiants",
      soa_certification_year: "Soa l'année de certification",
      phone: "Téléphone",
      fax: "Fax",
      public_email: "E-mail public",
      qa_email: "AQ e-mail",
      reviews_email: "E-mail d'avis",
      requests_email: "Requests Email",
      street: "Rue",
      postcode: "Code postal",
      cities: "Villes",
      city: "Ville",
      site_url: "Site Url",
      youtube_url: "Url Youtube",
      facebook_url: " Url Facebook",
      twitter_url: "Url Twitter",
      instagram_url: "Url Instagram",
      rss: "RSS",
      youtube_video: "Vidéo de Youtube",
      facebook_embed_code: "Code intégré à Facebook",
      twitter_embed_code: "Code intégré à Twitter",
      contact_person_name: "Nom de la personne de contact",
      contact_person_email: "E-mail de la personne de contact",
      contact_person_phone: "Téléphone de la personne de contact",
      contact_person_position: "La position de la personne de contact",
      contact_person_image: "Image de la personne de contact",
      infobox: { title: "Title", content: "Content" },
      infobox_soa: { title: "Title", content: "Content" },
      online_courses: "Course en ligne",
      updated_at: "mis à jour à",
      pay_per_lead: "Payer par lead",
      deleted_at: "supprimé à",
      sport_facility: "Installations sportives",
      food_facility: "Installations de restauration",
      sport_facility_description: "Installations sportives Description",
      food_facility_description:
        "Description des installations de restauration",
      email: "email",
      has_course_starting_date: "Has Starting Data Mandatory",
      is_updated: "Is Updated", // TODO
      cc_email: "CC Email", // TODO
      has_address_mandatory: "Address obligatoire", // TODO
      has_gender_mandatory: "Gender obligatoire", // TODO
      sales_force_email: "Sales Force Email", //TODO
    },
    boxes: {
      reimbursement_fields: "Remboursement",
      lead_management_fields: "Lead Management",
      general: "Général",
      soa: "Soa",
      contact_fields: "Contacts",
      social_network_fields: "Réseaux sociaux",
      contact_person_fields: "personne de contact",
      infobox: "Boîte d'information",
      relation_fields: "Relation",
      gallery: "Galerie",
      uploads: "PDF Téléchargement",
      locations: "Emplacements",
      infobox_soa: "Boîte d'information SOA",
    },
    tabs: {
      summary: "Résumé",
      images: "Images",
      courses: "Courses",
      success_stories: "Réussites",
      administrators: "Administrateurs",
      events: "Événements",
      statistics: "Statistiques",
      experts: "Alumni / Experts",
      employees: "Employees / Experts",
      leads: "Leads",
      reviews: "Revues",
    },
  },
  courses: {
    fields: {
      show_link: "Afficher le lien",
      short_name: "Nom court",
      full_name: "Nom complet",
      slug: "Slug",
      subtitle: "Sous-titre",
      premium: "Premium",
      only_verified: "Uniquement vérifié",
      description: "Description",
      admission: "Adminssion",
      duration_description: "description de la durée",
      cost_description: "Description des coûts",
      study_begins_at: "L'étude commence à",
      registration_fees: "Les frais d'inscription",
      registration_deadline: "date limite d'inscription",
      ects_credits: "Ects crédits",
      info_material: "Matériel d'information",
      question_block: "Livre de questions",
      logo_image: "Image du logo",
      background_image: "Image de fond",
      public_email: "E-mail public",
      qa_email: "AQ e-mail",
      reviews_email: "E-mail d'avis",
      requests_email: "Requests Email",
      street: "Rue",
      postcode: "Code postale",
      city: "Ville",
      director_name: "Nom du directeur",
      director_email: "E-mail du directeur",
      director_phone: "Téléphone du directeur",
      director_image: "Image du directeur",
      director_position: "Position du directeur",
      site_url: "Site Url",
      youtube_video: "Vidéo YouTube",
      facebook_embed: "Code d'intégration de Facebooke",
      twitter_embed: "Code d'intégration de Twitter",
      has_government_subsidies: "A des subventions gouvernementales",
      amount_of_subsidies: "Montant des subventions",
      has_eduwo_reimbursements: "A des remboursements Eduwos",
      amount_eduwo_reimbursements: "Montant reimbursements Eduwo",
      has_counseling: "A des conseils",
      has_courses_open_spots: "A des courses places ouvertes",
      has_course_reservations: "A des réservations de cours",
      has_events_open_spots: "A des événements ouverts",
      infobox: { title: "Title", content: "Content" },
      infobox_soa: { title: "Title", content: "Content" },
      start_dates: {
        start_date: "Date de début",
        taking_place_definitely: "Se réaliser définitivement",
        has_open_spots: "A des places libres",
      },
      onsite_course: "Cours sur place",
      online_course: "Course en ligne",
      updated_at: "mis à jour à",
      pay_per_lead: "Payer par lead",
      deleted_at: "supprimé à",
      hidde_updated: "cacher mis à jour",
      email: "Email",
      cities: "Villes",
      has_phone_number_mandatory: "Numéro de téléphone obligatoire",
      has_course_starting_date: "Has Starting Data Mandatory",
      minimum_age: "Minimum age for admission",
      is_updated: "Is Updated", // TODO
      has_address_mandatory: "Address obligatoire", // TODO
      has_gender_mandatory: "Gender obligatoire", // TODO
      sales_force_code: "Sales Force Code", // TODO
      sales_force_email: "Sales Force Email", // TODO
      is_public: "Is Public", // TODO
      inhouse_course: "Inhouse Course", // TODO
    },
    boxes: {
      lead_management_fields: "Lead Management",
      general: "Général",
      contact_fields: "Contacts",
      director_fields: "Directeur",
      social_network_fields: "Réseau social",
      reimbursement_fields: "Remboursement",
      relation_fields: "Relation",
      infobox: "Boîte d'info",
      gallery: "Galerie",
      uploads: "PDF Téléchargements",
      start_dates: "Dates de début",
      locations: "Course places",
      infobox_soa: "Boîte d'information SOA",
    },
    tabs: {
      summary: "Résumé",
      images: "Images",
      success_stories: "Réuissites",
      administrators: "Administrateurs",
      events: "Événements",
      statistics: "Statistiques",
      experts: "Alumni / Experts",
      leads: "Leads",
      relations: "Relations",
      reviews: "Revues",
    },
  },
  school_events: {
    fields: {
      starts_on: "Commence à",
      ends_on: "Se termine sur",
      published: "Publié",
      title: "Titre",
      description: "Description",
      link: "Link",
      slug: "URL-Slug",
      location: "Location",
    },
  },
  course_events: {
    fields: {
      starts_on: "Commence à",
      ends_on: "Se termine sur",
      published: "Publié",
      title: "Titre",
      description: "Description",
      link: "Link",
      slug: "Slug",
      location: "Location",
    },
  },
  organization_events: {
    fields: {
      starts_on: "Commence à",
      ends_on: "Se termine à",
      published: "Publié",
      title: "Titre",
      description: "Description",
      link: "Link",
      slug: "Slug",
      location: "Location",
    },
  },
  users: {
    fields: {
      id: "Id",
      title: "Title",
      first_name: "Prénom",
      last_name: "Nom de famille",
      email: "e-mail",
      verified: "verifiér",
      is_interested_in_ubs_promo: "S'intéresse à ubs promo",
      password: "Mote de passe",
      remember_token: "Se souvenir du jeton",
      created_at: "créé à",
      updated_at: "mis à jour à",
      role: "rôle",
      deleted_at: "supprimé à",
      gender: "sexe",
      birthday: "anniversaire",
      uid: "uid",
      phone: "téléphone",
      street: "rue",
      zip: "code postale",
      known: "connu",
      provider: "fournisseur",
      social_id: "identité sociale",
      city_id: "identité ville",
      language: "language",
      is_expert: "Expert",
      expert_biography: "biographie de l'expert",
      expert_motivation: "Motivation des experts",
      expert_linkedin_url: "expert_linkedin_url",
      expert_facebook_url: "expert_facebook_url",
      expert_twitter_url: "expert_twitter_url",
      slug: "slug",
      premium: "premium",
      price: "prix",
      professional: "professionnel",
      pay_fee: "payer les frais",
      user_looking_for_id: "utilisateur à la recherche d'un identifiant",
      views: "vues",
      form_contacts: "les contrats de formulaires",
      compare_count: "Comparer les comptes",
      login_count: "Nombre de connexion",
      order_infomaterial: "Commander du matériel d'information",
      user_degree_type_id: "ID du degré d'utilisateur",
      job_title: "Titre de l'emploi",
      organization_id: "Organisation id",
      public_url: "URL publique",
      sport_type: "Type de sport",
      photo: "Photo",
      background_picture: "Image d'arrière-plan",
      user_type: "Type",
      user_status: "Status",
    },
    boxes: {
      general: "Général",
      contact_fields: "Contacts",
      social_login: "Logins sociaux",
      expert_fields: "Domaine d'expertise",
      relation_fields: "Domaines de relation",
    },
    tabs: {
      summary: "Résumé",
      reviews: "Revues",
    },
  },
  statistics: {
    parameters: "Paramètres",
    eduwo: { title: "Eduwo statique" },
    schools: { courses: { title: "Statistiques sur les cours scolaires" } },
  },
  organizations: {
    boxes: {
      general: "Généralites",
      contact_fields: "Contacts",
      social_network_fields: "Réseau social",
      contact_person_fields: "Personne de contact",
      infobox: "Boîte d'info",
      focus: "Focus",
      follow: "Suivre",
      teaser_study_field: "Résumé domaine d'étude",
      teaser: "Résumé",
      gallery: "Galerie",
      uploads: "PDF Téléchargements",
      locations: "Locations",
      relation_fields: "Domaines de relation",
      dashboard_fields: "Dashboard", // TODO
      dashboard_questions: "Dashboard Questions", // TODO
      faqs: "Faqs", // TODO
      dashboard_approval_process: "Approval Process", // TODO
      dashboard_job_finder: "Job Fields", // TODO
      dashbard_recommended_courses: "Favourite Courses",
    },
    fields: {
      name: "Nom",
      short_name: "Nom court",
      subtitle: "Sous-titre",
      slug: "Slug",
      premium: "Premium",
      only_verified: "Uniquement vérifié",
      public: "Publique",
      description: "Description",
      number_of_employees: "Nombre d'émployés ",
      foundation_year: "Année de fondation",
      turnover: "Chiffre d'affaires",
      site_url: "Site url",
      public_email: "E-mail publique",
      hr_email: "HR Email",
      youtube_account_url: "Url du compte YouTube",
      facebook_account_url: "Url du compte Facebook",
      twitter_account_url: "Url du compte Twitter",
      instagram_account_url: "Url du compte Instagram",
      linkedin_account_url: "Url du compte Linkedin",
      video_url: "Url du video",
      contact_person_image: "Personne de contact image",
      contact_person_name: "Nom de la personne de contact",
      contact_person_email: "Personne de contact e-mail",
      contact_person_phone: "Téléphone de la personne de contact",
      contact_person_position: "Position de la personne de contact",
      follow_expert_tab: "Suivre Expert Tab",
      follow_school_tab: "Suivre School Tab",
      follow_organization_tab: "Suivre Organization Tab",
      follow_course_tab: "Suivre Course Tab",
      follow_schools_description: "Suivre la description des écoles",
      follow_courses_description: "Suivre la description des cours",
      follow_organizations_description:
        "Suivre la description des organisations",
      background_image: "Image de fond",
      logo_image: "Image du logo",
      info_box: { title: "Title", content: "Content" },
      teaser: { title: "Title", link: "Link", description: "Description" },
      teaser_study_field: { title: "Title", lead: "Lead" },
      deleted_at: "supprimé à",
      locations: {
        street: "Rue",
        city: "Ville",
        public_email: "E-mail publique",
        website: "Site web",
      },
      focus_topics: {
        name: "Sujets prioritaires",
        topic: "Topic",
        percentage: "Percentage",
      },
      updated_at: "mis à jour à",
      allow_external_experts: "Allow external experts", // TODO
      allow_coach_experts: "Allow coaches", // TODO
      show_dashboard_dashboard: "Show Dashboard", // TODO
      show_dashboard_knowledge_base: "Show Knowledge Base", // TODO
      show_dashboard_search: "Show Search", // TODO
      show_dashboard_network: "Show Network", // TODO
      show_dashboard_applications: "Show Applications", // TODO
      questions: {
        question_type: "Question type", // TODO
        style: "Question style", // TODO
        question: "Question", // TODO
        description: "Question description", // TODO
        placeholder: "Placeholder", // TODO
        data: "Question data", // TODO
        mandatory: "Mandatory", // TODO
        dataFields: { title: "Option" },
      },
      search_only_premium: "Search Only Premium", // TODO
      booking_only_premium: "Booking Only Premium", // TODO
      faqs: {
        question: "Question", // TODO
        answer: "Answer", // TODODO
      },
      employee_email: "Official email", // TODO
      meeting_link: "Meetlink Link", // TODO
      booking_stages: {
        order: "Order", // TODO
        type: "Type", // TODO
        approver: "Approver", // TODO
      },
      job_fields: {
        title: "Title",
      },
    },
    tabs: {
      summary: "Résumé",
      success_stories: "Réussites",
      administrators: "Administrateurs",
      events: "Événements",
      statistics: "Statistiques",
      experts: "Employees / Experts",
      matching: "Matching",
      dashboard: "Dashboard",
    },
  },
  leads: {
    fields: {
      message: "Message",
      title: "Titre",
      request_order: "Les demandes de commandes",
      created_at: "Créé à",
      receiver: "Récepteur",
      sender: "Expéditeur",
      date: "Date",
      bulk: "Ordre matériel d'information globale",
      main_order: "Ordre principal",
      course_name: "Nom du cours",
      school_name: "Nom d'école",
      schools_and_courses: "Écoles et courses",
      total_receiver: "Récepteur total",
      start_date: "Date de début",
      end_date: "Date de fin",
      iban: "IBAN",
      term: "Term",
      total_inquiries: "Direct contacts", // TODO
      total_bulks: "Contacts through wizard", // TODO
      inquiry_course_ids_count: "Inquiry Course Ids Count", // TODO
      inquiry_unique_course_ids_count:
        "Total unique courses contacted directly", // TODO
      bulk_course_ids_count: "Bulk Course Ids Count", // TODO
      bulk_unique_course_ids_count:
        "Total unique courses contacted through wizard", // TODO
      total_courses: "Total courses in this category", // TODO
      inquiry_course_ids_sum: "Top 30 courses direct contacted", // TODO
      bulk_course_ids_sum: "Top 30 courses contacted through wizard", // TODO
      bulk_same_course_ids_count:
        "Total unique courses contacted through wizard", // TODO
      bulk_same_school_ids: "Total unique schools contacted through wizard", // TODO
      inquiry_unique_school_ids_count:
        "Total unique schools contacted directly", // TODO
      bulk_same_school_ids_count:
        "Total unique schools contacted through wizard", // TODO
      inquiry_school_ids_sum: "Top 30 schools direct contacted", // TODO
      bulk_school_ids_sum: "Top 30 schools contacted through wizard", // TODO
      inquiry_main_order: "Top direct contacted orders", // TODO
      bulk_main_order: "Top direct contacted through wizard", // TODO

      premium: "Premium", // TODO
      pay_per_lead: "Pay Per Lead", // TODO
      infomaterial: "Infomaterial", // TODO
      only_pending: "Only Pending", // TODO
      hide_archived: "Hide Archived", // TODO
    },
    summary: {
      title: "Résume",
      total: "Total",
      total_schools: "Total des écoles",
      total_courses: "Total des courses",
      total_anmeldung: "Total des enregistrements",
      total_beratungsgespräch: "Consultation totale",
      total_infotag: "Total journée d'information",
      total_infomaterial: "Total matériel d'information",
      total_unknown: "Total inconnu",
      // Tab
      name: "Summary",
    },
    list: {
      title: "Leads",
    },
    inquiries: {
      name: "Renseignements",
    },
    bulk: {
      name: "Ordre matériel d'information globale",
    },
    reimbursement: {
      name: "Reimbursement",
    },
  },
  course_success_stories: {
    fields: {
      title: "Titre",
      first_name: "Prénom",
      last_name: "nom de famille",
      description: "Description",
      slug: "Slug",
      photo: "Photo", // TODO
    },
  },
  school_success_stories: {
    fields: {
      title: "Titre",
      first_name: "Prénom",
      last_name: "Nom de famille",
      description: "Description",
      slug: "URL-Slug",
      photo: "Photo", // TODO
    },
  },
  organization_success_stories: {
    fields: {
      title: "Titre",
      first_name: "Prénom",
      last_name: "Nom de famille",
      description: "Description",
      slug: "URL-Slug",
      photo: "Photo", // TODO
    },
  },
  experts: {
    fields: {
      verified: "Vérifié",
      is_expert: "Expert", // TODO
      graduation_year: "Année d'obtention du diplôme",
      job_title: "Titre d'emploi",
    },
  },
  reference_data: {
    tabs: {
      awards: "Prix",
    },
    fields: {
      title: "Title",
    },
  },
  awards: {
    fields: {
      private_title: "Titre privé",
      title: "Titre",
      type: "Type",
      description: "Description",
      icon: "Icône",
      url: "Url",
      can_change_by_admin: "Can change by admin", // TODO
    },
  },
  study_field_categories: {
    fields: {
      title: "Titre",
      slug: "Slug",
      description: "Description",
      popular: "Popular",
      order: "Order",
      faqs: {
        question: "Question", // TODO
        answer: "Answer", // TODO
      },
    },
  },
  study_fields: {
    fields: {
      title: "Title",
      slug: "Slug",
      description: "Description",
      popular: "Populaire",
      icon: "Icôn",
      faqs: {
        question: "Question", // TODO
        answer: "Answer", // TODO
      },
    },
  },
  sub_study_fields: {
    fields: {
      title: "Title",
      slug: "Slug",
      description: "Description",
      popular: "Populaire",
      icon: "Icôn",
      faqs: {
        question: "Question", // TODO
        answer: "Answer", // TODO
      },
    },
  },
  school_types: {
    fields: {
      title: "Titre",
      slug: "Slug",
      description: "Description",
    },
  },
  reviews: {
    fields: {
      year: "Année",
      approved: "Approuvé",
      created_at: "Crée à",
      rating_avg: "Note moyenne",
      rating_content: "Évaluation contenu",
      rating_environment: "Évaluation environnement ",
      rating_equipment: "Évaluation équipement",
      rating_organisation: "Évaluation organisation",
      rating_teachers: "Évaluations enseignants",
      recommend: "Recommander",
      title: "Titre",
      message: "Message",
      pros: "Pour",
      cons: "Contre",
      reply: "Réponse",
      rating_communication: "Évaluation communication",
      rating_competence: "Évaluation compétence",
      rating_performance: "Évaluation performance",
    },
  },
  publicities: {
    fields: {
      link: "link", // TODO
    },
  },
  degree_types: {
    fields: {
      title: "Title", // TODO
      validation_month: "Validation Month", // TODO
      order: "Order", // TODO
    },
  },
  user_degree_types: {
    fields: {
      title: "Title", // TODO
      order: "Order", // TODO
      degree_type: "Diese Abschlusstypen werden empfohlen", // TODO
      exclude_degree_type:
        "Diese Abschlusstypen machen wenig Sinn (ausschliessen/exclude)", // TODO
    },
  },
  course_costs: {
    fields: {
      title: "Title", // TODO
    },
  },
  course_durations: {
    fields: {
      title: "Title", // TODO
    },
  },
  course_languages: {
    fields: {
      title: "Title", // TODO
    },
  },
  cities: {
    fields: {
      title: "Title", // TODO
    },
  },
  districts: {
    fields: {
      title: "Title", // TODO
    },
  },
  regions: {
    fields: {
      title: "Title", // TODO
    },
  },
  countries: {
    fields: {
      title: "Title", // TODO
    },
  },
  study_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  branches: {
    fields: {
      title: "Title", // TODO
    },
  },
  job_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  organization_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  soa_school_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  work_experiences: {
    fields: {
      title: "Title", // TODO
    },
  },
  contacts: {
    fields: {
      first_name: "First Name", // TODO
      last_name: "Last Name", // TODO
      email: "Email", // TODO
      source: "Source", // TODO
      total_price: "Total Price", // TODO
      total_schools: "Total Schools", // TODO
      total_courses: "Total Courses", // TODO
      created_at: "Created At", // TODO
      school_name: "School Name", // TODO
      premium: "P", // TODO
      pay_per_lead: "PPL", // TODO
      info_material: "IM", // TODO
      fix_fee: "FF", // TODO
      pay_per_view: "PPV", // TODO
      no_contract: "NC", // TODO
      rejected_by_api: "RAPI", // TODO
      rejected_manually: "RM", // TODO
      approved_pay_per_view: "APPV", // TODO

      name: "User Name", // TODO
      phone: "Phone Number", // TODO
      street: "Street", // TODO
      zip: "Zip Code", // TODO
      city: "City", // TODO
      job_title: "Job Title", // TODO
      work_experience: "Work Experience", // TODO
      year_of_birth: "Year Of Birth", // TODO
      user_degree_types: "User Degree Types", // TODO

      lead_contact_option: "Main Lead", // TODO
      lead_contact_options: "Lead Options", // TODO
      model: "Model", // TODO
      criteria: "Criteria", // TODO

      to_email: "Email", // TODO
      cc_email: "CC", // TODO
      status: "Status", // TODO
      price: "Price", // TODO
      archived: "Archived", // TODO
      anonymous: "A", // TODO

      total_contacts: "Total Contacts", // TODO
      total_sent: "Total Sent", // TODO
      total_billable: "Total Billable", // TODO
      total_sent_anonymous: "Total Sent Anonymous", // TODO
      total_rejected: "Total Rejected", // TODO

      total: "T", // TODO
      rejected: "R", // TODO
      pending: "Pe", // TODO
      total_total: "Total", // TODO
      warnings: "Warnings", // TODO
      dangers: "Dangers", // TODO
      contacts_count: "Contacts Count", // TODO
      pendings_count: "Pendings Count", // TODO
      last_contact: "Last Contact", // TODO
      birthday_day: "Birthday Day", // TODO
      birthday_month: "Birthday Month", // TODO
      birthday_year: "Birthday Year", // TODO
      user_type: "User Type", // TODO
      user_status: "User Status", // TODO
      cc_emails: "CC email", // TODO
      details: "Details", // TODO
      freshData: "Fresh Data", // TODO
    },
  },
  wizards: {
    fields: {
      slug: "Slug", // TODO
      title: "Title", // TODO
      sub_title: "Subtitle", // TODO
      description: "Description", // TODO
      page: "Page", // TODO
      style: "Style", // TODO
      step: "Step", // TODO
      field: "Field", // TODO
      placeholder: "Placeholder", // TODO
      mandatory: "Mandatory", // TODO
      remove_zero_courses: "Remove options with zero courses", // TODO
      order_by: "Options Order", // TODO
      related_field: "Related Field", // TODO
      total_courses: "Show Total Courses", // TODO
      minimum: "Minimum Selection", // TODO
      maximum: "Maximum", // TODO
      columns: "Number of Columns", // TODO
      data_with_total_courses: "Show Total Courses per Option", // TODO
      data: "Options Data", // TODO
    },
    tabs: {
      summary: "General", // TODO
    },
  },
  register: {
    user: {
      email: {
        placeholder: "Please, insert your email address", // TODO
        label: "Email", // TODO
      },
      password: {
        placeholder: "Please, insert your password", // TODO
        label: "Password", // TODO
      },
      confirm_password: {
        placeholder: "Confirm your password", // TODO
        label: "Password", // TODO
      },
      first_name: {
        placeholder: "Please, insert your first name", // TODO
        label: "First Name", // TODO
      },
      last_name: {
        placeholder: "Please, insert your last name", // TODO
        label: "Last Name", // TODO
      },
      job_title: {
        placeholder: "Please, insert your job position", // TODO
        label: "Job Position", // TODO
      },
      phone: {
        placeholder: "Please, insert your phone number", // TODO
        label: "Phone", // TODO
      },
    },
    company: {},
  },
  kb_modules: {
    fields: {
      title: "Title", // TODO
      description: "Description", // TODO
    },
  },
  kb_sections: {
    fields: {
      title: "Title", // TODO
      description: "Description", // TODO
    },
  },
  kb_units: {
    fields: {
      title: "Title", // TODO
      description: "Description", // TODO
      video_url: "Video URL", // TODO
    },
  },
  todos: {
    fields: {
      user: "User",
      comment: "Comment",
      created_at: "Créé à",
      created_by: "Created by",
      priority: "Priority",
      due_date: "Due date",
      start_date: "Date de début",
      done: "Done",
      term: "Term",
      first_name: "Prénom",
      last_name: "Nom de famille",
      email: "e-mail",
    },
  },
};

export default customFrenchMessages;
