import * as React from "react";
import { usePermissions } from "react-admin";
import { AdministratorsList } from "../components";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";
import { OrganizationTitle } from "./OrganizationForm";

const OrganizationAdministrators = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <AdministratorsList
      target={"related_organization_id"}
      title={<OrganizationTitle />}
      resources={"organizations"}
      model_id={props.id}
      canAdd={permissions.includes(USER_ROLE_SUPER_ADMIN)}
      canDelete={permissions.includes(USER_ROLE_SUPER_ADMIN)}
    />
  );
};
export default OrganizationAdministrators;
