import * as React from "react";
import { AppBar, UserMenu, MenuItemLink, useTranslate } from "react-admin";
import { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { useMediaQuery, Theme } from "@material-ui/core";
import { DARK_BLUE, LIGHT_BLUE } from "../Constants";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  appBar: {
    backgroundImage: `-webkit-linear-gradient(${DARK_BLUE}, ${LIGHT_BLUE})`,
  },
}));

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={translate("pos.configuration")}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  );
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
);

const MyAppBar = (props: any) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <AppBar
      {...props}
      userMenu={isXSmall ? <></> : <CustomUserMenu />}
      className={classes.appBar}
    >
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  );
};

export default MyAppBar;
