import * as React from "react";
import SchoolGeneralFields from "./SchoolGeneralFields";
import { usePermissions } from "react-admin";
import SchoolLocationFields from "./SchoolLocationFields";
import SchoolContactFields from "./SchoolContactFields";
import SchoolSocialNetworkFields from "./SchoolSocialNetworkFields";
import SchoolContactPersonFields from "./SchoolContactPersonFields";
import SchoolInfoboxFields from "./SchoolInfoboxFields";
import SchoolInfoboxSoaFields from "./SchoolInfoboxSoaFields";
import SchoolRelationFields from "./SchoolRelationFields";
import SchoolGalleryFields from "./SchoolGalleryFields";
import SchoolUploadsFields from "./SchoolUploadsFields";
import { CreateUpdateInfo } from "../components";
import { USER_ROLE_SUPER_ADMIN, USER_ROLE_SOA_ADMIN } from "../Constants";
import SchoolReimbursementFields from "../components/SchoolReimbursementFields";
import SchoolLeadManagementFields from "../components/SchoolLeadManagementFields";

const SchoolTabSummary = ({
  action,
  disabled_level_1,
  disabled_level_2,
  disabled_level_3,
  disabled_level_4,
  specialSoaRules,
  disableModelLocal = false,
  ...props
}: any) => {
  const { permissions = [] } = usePermissions();
  if (action === undefined) {
    action = "show";
  }

  return (
    <>
      <CreateUpdateInfo {...props} />
      <SchoolGeneralFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        disabled_level_3={disabled_level_3}
        disabled_level_4={disabled_level_4}
        specialSoaRules={specialSoaRules}
        disableModelLocal={disableModelLocal}
        {...props}
      />
      {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
        <>
          <SchoolLocationFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            disableModelLocal={disableModelLocal}
            {...props}
          />
          <SchoolInfoboxFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            disableModelLocal={disableModelLocal}
            {...props}
          />
        </>
      )}
      {(permissions.includes(USER_ROLE_SOA_ADMIN) ||
        permissions.includes(USER_ROLE_SUPER_ADMIN)) && (
        <>
          <SchoolInfoboxSoaFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            disabled_level_4={disabled_level_4}
            disableModelLocal={disableModelLocal}
            {...props}
          />
        </>
      )}
      {(!permissions.includes(USER_ROLE_SOA_ADMIN) || specialSoaRules) && (
        <>
          <SchoolGalleryFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            disableModelLocal={disableModelLocal}
            {...props}
          />
          <SchoolUploadsFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            disableModelLocal={disableModelLocal}
            {...props}
          />
          <SchoolContactFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            disableModelLocal={disableModelLocal}
            {...props}
          />
          <SchoolContactPersonFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            disableModelLocal={disableModelLocal}
            {...props}
          />
          <SchoolSocialNetworkFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            disableModelLocal={disableModelLocal}
            {...props}
          />
        </>
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <SchoolReimbursementFields
          disabled_level_1={disabled_level_1}
          disabled_level_2={disabled_level_2}
          disabled_level_3={disabled_level_3}
          {...props}
        />
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <SchoolLeadManagementFields
          disabled_level_1={disabled_level_1}
          disabled_level_2={disabled_level_2}
          disabled_level_3={disabled_level_3}
          {...props}
        />
      )}
      {(!permissions.includes(USER_ROLE_SOA_ADMIN) || specialSoaRules) && (
        <SchoolRelationFields
          disabled_level_1={disabled_level_1}
          disabled_level_2={disabled_level_2}
          disabled_level_3={disabled_level_3}
          disableModelLocal={disableModelLocal}
          {...props}
        />
      )}
    </>
  );
};

export default SchoolTabSummary;
