import * as React from "react";
import { FC } from "react";
import { Edit, ShowProps } from "react-admin";
import UserForm from "./UserForm";

const UserShow: FC<ShowProps> = ({ ...props }) => {
  return (
    <Edit {...props} title={" "} actions={<></>}>
      <UserForm {...props} action="edit" />
    </Edit>
  );
};

export default UserShow;
