import * as React from "react";
import { usePermissions } from "react-admin";
import { CourseTitle } from "./CourseForm";
import { ExpertsList } from "../components";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";

const CourseExperts = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <ExpertsList
      target={"related_course_id"}
      title={<CourseTitle />}
      resources={"courses"}
      reference={"experts"}
      model_id={props.id}
      canAdd={permissions.includes(USER_ROLE_SUPER_ADMIN)}
      canDelete={permissions.includes(USER_ROLE_SUPER_ADMIN)}
    />
  );
};

export default CourseExperts;
