import * as React from "react";
import { FC } from "react";
import {
  ListProps,
  useTranslate,
  useGetList,
  Labeled,
  FormWithRedirect,
  SelectInput,
} from "react-admin";
import {
  Collapse,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  TableSortLabel,
} from "@material-ui/core";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { DateInputField, LinearLoading } from "../components";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const table_fields = [
  "title",
  "total_courses",
  "total_inquiries",
  "inquiry_unique_course_ids_count",
  "inquiry_unique_school_ids_count",
  "total_bulks",
  "bulk_same_course_ids_count",
  "bulk_same_school_ids_count",
];

const course_table_fields = ["id", "total_inquiries", "total_bulks", "total"];

export const TabbedDatagrid = ({ end_date, start_date, group_by }: any) => {
  const translate = useTranslate();

  const filters = {};
  if (end_date !== undefined) {
    filters["end_date"] = end_date;
  }
  if (start_date !== undefined) {
    filters["start_date"] = start_date;
  }
  if (group_by !== undefined) {
    filters["group_by"] = group_by;
  }
  const sort = { field: "title", order: "ASC" };
  const { /*total, error,*/ data, loading } = useGetList(
    "leads/group_by",
    { perPage: 999, page: 1 },
    sort,
    filters
  );

  if (loading) {
    return <LinearLoading />;
  }

  function Row({ row }: any) {
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <StyledTableRow>
          {group_by !== "courses" && (
            <StyledTableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
              </IconButton>
            </StyledTableCell>
          )}
          {group_by !== "courses"
            ? table_fields.map((table_field) => (
                <StyledTableCell>{row[table_field]}</StyledTableCell>
              ))
            : course_table_fields.map((table_field) => (
                <StyledTableCell>{row[table_field]}</StyledTableCell>
              ))}
        </StyledTableRow>
        {group_by !== "courses" && (
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Labeled
                    label={translate(`leads.fields.inquiry_course_ids_sum`)}
                  >
                    <ul>
                      {row["inquiry_course_ids_sum"]
                        .slice(0, 30)
                        .map((inquiry_course_ids) => (
                          <li>
                            {`Course Id: ${inquiry_course_ids["course_id"]} (${inquiry_course_ids["total"]})`}
                          </li>
                        ))}
                    </ul>
                  </Labeled>
                </Grid>
                <Grid item xs={2}>
                  <Labeled
                    label={translate(`leads.fields.inquiry_school_ids_sum`)}
                  >
                    <ul>
                      {row["inquiry_school_ids_sum"]
                        .slice(0, 30)
                        .map((inquiry_school_ids) => (
                          <li>
                            {`School Id: ${inquiry_school_ids["school_id"]} (${inquiry_school_ids["total"]})`}
                          </li>
                        ))}
                    </ul>
                  </Labeled>
                </Grid>
                <Grid item xs={2}>
                  <Labeled label={translate(`leads.fields.inquiry_main_order`)}>
                    <ul>
                      {row["inquiry_main_order"]
                        .slice(0, 30)
                        .map((inquiry_course_ids) => (
                          <li>
                            {`${inquiry_course_ids["main_order"]} (${inquiry_course_ids["total"]})`}
                          </li>
                        ))}
                    </ul>
                  </Labeled>
                </Grid>
                <Grid item xs={2}>
                  <Labeled
                    label={translate(`leads.fields.bulk_course_ids_sum`)}
                  >
                    <ul>
                      {row["bulk_course_ids_sum"]
                        .slice(0, 30)
                        .map((bulk_course_ids) => (
                          <li>
                            {`Course Id: ${bulk_course_ids["course_id"]} (${bulk_course_ids["total"]})`}
                          </li>
                        ))}
                    </ul>
                  </Labeled>
                </Grid>
                <Grid item xs={2}>
                  <Labeled
                    label={translate(`leads.fields.bulk_school_ids_sum`)}
                  >
                    <ul>
                      {row["bulk_school_ids_sum"]
                        .slice(0, 30)
                        .map((bulk_school_ids) => (
                          <li>
                            {`School Id: ${bulk_school_ids["school_id"]} (${bulk_school_ids["total"]})`}
                          </li>
                        ))}
                    </ul>
                  </Labeled>
                </Grid>
                <Grid item xs={2}>
                  <Labeled label={translate(`leads.fields.bulk_main_order`)}>
                    <ul>
                      {row["bulk_main_order"]
                        .slice(0, 30)
                        .map((bulk_course_ids) => (
                          <li>
                            {`${bulk_course_ids["main_order"]} (${bulk_course_ids["total"]})`}
                          </li>
                        ))}
                    </ul>
                  </Labeled>
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        )}
      </React.Fragment>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {group_by !== "courses" && <TableCell />}
            {group_by !== "courses"
              ? table_fields.map((table_field, index) => (
                  <TableCell key={index}>
                    {table_field === "created_at" ? (
                      <TableSortLabel active={true} direction={"desc"}>
                        {translate(`leads.fields.${table_field}`)}
                      </TableSortLabel>
                    ) : (
                      translate(`leads.fields.${table_field}`)
                    )}
                  </TableCell>
                ))
              : course_table_fields.map((course_table_field, index) => (
                  <TableCell
                    key={index}
                    sortDirection={
                      course_table_field === "total" ? "desc" : false
                    }
                  >
                    {course_table_field === "total" ? (
                      <TableSortLabel active={true} direction={"desc"}>
                        {translate(`leads.fields.${course_table_field}`)}
                      </TableSortLabel>
                    ) : (
                      <TableSortLabel active={false} direction={"desc"}>
                        {translate(`leads.fields.${course_table_field}`)}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data !== undefined &&
            Object.keys(data).map((key, index) => {
              return <Row row={data[key]} />;
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const LeadsTabs = ({
  school_id_input,
  course_id_input,
  ...props
}: any) => {
  const classes = useStyles();
  const [end_date, setEndDate] = React.useState(undefined);

  const today = new Date();
  const last_month = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate()
  );

  const [start_date, setStartDate] =
    React.useState<Date | undefined>(last_month);

  const [group_by, setGroupBy] = React.useState("study_field_categories");

  return (
    <div>
      <FormWithRedirect
        {...props}
        render={(formProps: any) => (
          <div className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <DateInputField
                  resource={"leads"}
                  source={"start_date"}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value === "") {
                      setStartDate(undefined);
                    } else {
                      setStartDate(value);
                    }
                  }}
                  allowEmpty={true}
                  defaultValue={start_date}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DateInputField
                  resource={"leads"}
                  source={"end_date"}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value === "") {
                      setEndDate(undefined);
                    } else {
                      setEndDate(value);
                    }
                  }}
                  allowEmpty={true}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectInput
                  fullWidth
                  source="sort_by"
                  choices={[
                    {
                      id: "study_field_categories",
                      name: "Study Field Categories",
                    },
                    { id: "study_fields", name: "Study Fields" },
                    { id: "regions", name: "Regions" },
                    { id: "courses", name: "Courses" },
                  ]}
                  onChange={(event) => {
                    const { value } = event.target;
                    setGroupBy(value);
                  }}
                  defaultValue={group_by}
                />
              </Grid>
            </Grid>
            <TabbedDatagrid
              end_date={end_date}
              start_date={start_date}
              group_by={group_by}
            />
          </div>
        )}
      />
    </div>
  );
};

const LeadsGroupBy: FC<ListProps> = (props) => <LeadsTabs />;

export default LeadsGroupBy;
