import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BoxTitle,
  TextInputEmailField,
  ShowImageUrl,
} from "../components";

const OrganizationContactPersonFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  const contact_person_image_url =
    props.record.contact_person_image !== null &&
    props.record.contact_person_image !== undefined
      ? props.record.contact_person_image.small_url
      : "";

  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.contact_person_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <ShowImageUrl
              title={translate(`organizations.fields.contact_person_image`)}
              image_src={contact_person_image_url}
              image_alt="Contact Person Image"
              disabled={disabled_level_2}
              resource={props.resource}
              id={props.id}
              collection="contact_person_image"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="contact_person_name"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="contact_person_position"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputEmailField
              source="contact_person_email"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputField
              source="contact_person_phone"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationContactPersonFields;
