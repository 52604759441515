import * as React from "react";
import { Grid } from "@material-ui/core";
import { DeleteButtonWithIcon } from ".";
import ImagesProvider from "../dataProvider/ImagesProvider";

const FileDownloadUrl = ({
  disabled,
  resource,
  identifier,
  collection,
  title = "",
  href = "",
  file_name = "",
}) => {
  const [removed, setRemoved] = React.useState(false);

  const handleDeleteClick = (resource, identifier, collection, name) => {
    if (name === "") {
      ImagesProvider.delete(resource, identifier, collection);
    } else {
      ImagesProvider.delete(resource, identifier, collection, name);
    }
    setRemoved(true);
  };

  if (removed) {
    return <></>;
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        <a href={href} download={file_name} target="_blanck">
          {title}
        </a>
      </Grid>
      {disabled === false && (
        <Grid item xs={2}>
          <DeleteButtonWithIcon
            handleClick={() =>
              handleDeleteClick(resource, identifier, collection, file_name)
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FileDownloadUrl;
