import { fetchUtils } from "react-admin";
import { apiUrl, httpClient } from "../dataProvider";
import MyLocalStorage from "../utils/MyLocalStorage";

const url = (resource, identifier, collection, many = false, name = "") => {
  if (many) {
    return `${apiUrl}/${resource}/${identifier}/images/many/${collection}`;
  }
  if (name !== "") {
    return `${apiUrl}/${resource}/${identifier}/images/${collection}/${name}`;
  }
  return `${apiUrl}/${resource}/${identifier}/images/${collection}`;
};

export const imageHttpClient = (url: any, options: any) => {
  if (options === undefined) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set(
    "Authorization",
    `Bearer ${MyLocalStorage.getCookie("access_token")}`
  );
  // Remove 'Content-Type' header to allow browser to add
  // along with the correct 'boundary'
  delete options.headers["Content-Type"];

  return fetchUtils.fetchJson(url, options);
};

const ImagesProvider = {
  upload: (
    resource: string,
    identifier: string,
    collection: any,
    file: any
  ) => {
    const formData = new FormData();

    for (let key in file) {
      formData.append(collection, file[key]);
    }

    const options = {
      method: "POST",
      body: formData,
    };
    return imageHttpClient(url(resource, identifier, collection), options).then(
      ({ headers, json }: any) => {
        return json;
      }
    );
  },
  uploadMany: (
    resource: string,
    identifier: string,
    collection: any,
    file: any
  ) => {
    const formData = new FormData();

    for (let key in file) {
      formData.append(collection + "[]", file[key]);
    }
    const options = {
      method: "POST",
      body: formData,
    };
    return imageHttpClient(
      url(resource, identifier, collection, true),
      options
    ).then(({ headers, json }: any) => {
      return json;
    });
  },
  delete: (
    resource: string,
    identifier: string,
    collection: any,
    name: string = ""
  ) => {
    return httpClient(url(resource, identifier, collection, false, name), {
      method: "DELETE",
    }).then(({ headers, json }: any) => ({
      data: json,
    }));
  },
};

export default ImagesProvider;
