import React from "react";
import { FC } from "react";
import {
  useTranslate,
  Labeled,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { Chip, Paper, Grid } from "@material-ui/core";
import { ChipStyle } from "../styles";
import { ResourceFieldProps } from "./types";
import { LinearLoading } from ".";

const ArrayOneFieldInputField: FC<ResourceFieldProps> = (props) => {
  const translate = useTranslate();
  const classes = ChipStyle();

  if (
    props.record[props.source] === null &&
    props.record[props.source] === undefined
  ) {
    return <LinearLoading />;
  }

  if (props.disabled) {
    return (
      <Labeled
        label={
          props.label ?? translate(`${props.resource}.fields.${props.source}`)
        }
      >
        <Paper component="ul" className={classes.root}>
          {props.record[props.source] !== null &&
            props.record[props.source] !== undefined &&
            props.record[props.source].map((data) => {
              return (
                <li>
                  <Chip
                    label={data}
                    onDelete={undefined}
                    className={classes.chip}
                  />
                </li>
              );
            })}
        </Paper>
      </Labeled>
    );
  }

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <ArrayInput source={props.source}>
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};

export default ArrayOneFieldInputField;
