import * as React from "react";
import { FC } from "react";
import { DateInput, useTranslate, required } from "react-admin";
import { ResourceFieldProps } from "./types";

const DateInputField: FC<ResourceFieldProps> = (props) => {
  const translate = useTranslate();
  return (
    <DateInput
      source={props.source}
      label={
        props.label ?? translate(`${props.resource}.fields.${props.source}`)
      }
      resource={props.resource}
      record={props.record}
      fullWidth
      disabled={props.disabled}
      validate={props.validate ? [required()] : []}
      onChange={props.onChange}
      allowEmpty={props.allowEmpty ?? false}
      defaultValue={props.defaultValue}
      helperText={props.helperText ?? <p></p>}
    />
  );
};

export default DateInputField;
