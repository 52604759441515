import * as React from "react";
import {
  ReferenceManyField,
  SimpleShowLayout,
  Datagrid,
  TextField,
  NumberField,
  ShowView,
  useRefresh,
  Labeled,
  useTranslate,
  BooleanField,
  ReferenceField,
  usePermissions,
} from "react-admin";
import { ListPagination, GoToPublicUrl } from "../components";
import { Grid, Switch } from "@material-ui/core";
import { apiUrl, httpClient } from "../dataProvider";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";

const ExpertsList = ({
  target, //Example: "related_school_id"
  reference,
  title,
  resources,
  model_id,
  canAdd,
  canDelete,
  isOrganization = false,
  ...props
}: any) => {
  const translate = useTranslate();
  const { permissions = [] } = usePermissions();

  const GoToPubUrl = (props) => {
    if (props.record.is_expert) {
      return <GoToPublicUrl record={props.record} source="public_url" />;
    }
    return <></>;
  };

  const ExpertExpand = ({
    reference,
    resources,
    model_id,
    canAdd,
    canDelete,
    ...props
  }: any) => {
    const refresh = useRefresh();
    const translate = useTranslate();
    const [verified, setVerified] = React.useState(
      props !== null &&
        props !== undefined &&
        props.record !== null &&
        props.record !== undefined &&
        props.record.verified === true
    );
    const [expert, setExpert] = React.useState(
      props !== null &&
        props !== undefined &&
        props.record !== null &&
        props.record !== undefined &&
        props.record.is_expert === true
    );

    const handleChangeVerified = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setVerified(event.target.checked);
      const url = `${apiUrl}/${resources}/${model_id}/experts/${props.record.user_id}/verified`;
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify({ verified: event.target.checked }),
      }).then(({ headers, json }: any) => {
        refresh();
      });
    };

    const handleChangeExpert = (event: React.ChangeEvent<HTMLInputElement>) => {
      setExpert(event.target.checked);
      const url = `${apiUrl}/${resources}/${model_id}/experts/${props.record.user_id}/expert`;
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify({ verified: event.target.checked }),
      }).then(({ headers, json }: any) => {
        refresh();
      });
    };

    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Labeled label={translate(`${reference}.fields.verified`)}>
            <Switch
              checked={verified}
              onChange={handleChangeVerified}
              color="primary"
              name="verified"
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={!canDelete}
            />
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <Labeled label={translate(`${reference}.fields.is_expert`)}>
            <Switch
              checked={expert}
              onChange={handleChangeExpert}
              color="primary"
              name="verified"
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={!canDelete}
            />
          </Labeled>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <ShowView {...props} component="div" title={title} actions={<></>}>
        <SimpleShowLayout>
          <ReferenceManyField
            label=""
            reference="alumni"
            target={target}
            pagination={<ListPagination />}
            sort={{ field: "first_name", order: "ASC" }}
          >
            <Datagrid
              rowClick={undefined}
              expand={
                <ExpertExpand
                  reference={reference}
                  resources={resources}
                  model_id={model_id}
                  canAdd={canAdd}
                  canDelete={canDelete}
                  {...props}
                />
              }
            >
              <ReferenceField
                source="user_id"
                reference="users"
                label={translate(`users.fields.first_name`)}
                link={false}
              >
                <TextField
                  source={
                    permissions.includes(USER_ROLE_SUPER_ADMIN)
                      ? "full_name_and_full_email"
                      : "full_name_and_email"
                  }
                />
              </ReferenceField>
              {isOrganization === false && (
                <ReferenceField
                  source="course_id"
                  reference="courses"
                  label={"Course Short Name"}
                  link="show"
                >
                  <TextField source="short_name" />
                </ReferenceField>
              )}
              <NumberField
                source="graduation_year"
                label={translate(`experts.fields.graduation_year`)}
              />
              <BooleanField source="is_expert" label={"Course Expert"} />
              <BooleanField source="verified" />
              <ReferenceField
                source="user_id"
                reference="users"
                label={translate(`users.fields.is_expert`)}
                link={false}
              >
                <BooleanField source="is_expert" />
              </ReferenceField>
              <ReferenceField
                source="user_id"
                reference="users"
                label={translate(`users.fields.public_url`)}
                link={false}
              >
                <GoToPubUrl {...props} />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      </ShowView>
    </div>
  );
};

export default ExpertsList;
