import { stringify } from "query-string";
import { apiUrl, httpClient } from "../dataProvider";

const ContactProvider = {
  perSchool: (params: any) => {
    const {
      month,
      start_date,
      end_date,
      school_id,
      organization_id,
      premium,
      pay_per_lead,
      infomaterial,
      details,
      freshData,
    } = params;
    const query = {
      month: month,
      start_date: start_date,
      end_date: end_date,
      school_id: school_id,
      organization_id: organization_id,
      premium: premium,
      pay_per_lead: pay_per_lead,
      infomaterial: infomaterial,
      details: details,
      freshData: freshData,
    };

    const url = `${apiUrl}/contacts/per_school?${stringify(query)}`;

    return httpClient(url, undefined).then(({ headers, json }: any) => ({
      data: json,
    }));
  },
  statistics: (params: any) => {
    const { start_date, end_date, school_id, organization_id } = params;
    const query = {
      start_date: start_date,
      end_date: end_date,
      school_id: school_id,
      organization_id: organization_id,
    };

    const url = `${apiUrl}/contacts/stats?${stringify(query)}`;

    return httpClient(url, undefined).then(({ headers, json }: any) => ({
      data: json,
    }));
  },
};

export default ContactProvider;
