import * as React from "react";
import {
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  Labeled,
} from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BoxTitle,
  RichTextInputField,
  SelectInputField,
} from "../components";

const OrganizationFaqs = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.faqs`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {(disabled_level_2 === false || disabled_level_2 === undefined) && (
              <ArrayInput source={"faqs"}>
                <SimpleFormIterator>
                  <TextInputField
                    source={"question"}
                    label={translate(`${props.resource}.fields.faqs.question`)}
                    resource={props.resource}
                    disabled={false}
                    validate={true}
                  />
                  <RichTextInputField
                    source={"answer"}
                    label={translate(`${props.resource}.fields.faqs.answer`)}
                    resource={props.resource}
                    disabled={false}
                    validate={true}
                  />
                  <SelectInputField
                    source="section"
                    resource={"section"}
                    choices={[
                      { id: "general", name: "General" },
                      { id: "knowledge-base", name: "Knowledge Base" },
                      { id: "consulting", name: "Consulting" },
                      { id: "course", name: "Course" },
                      { id: "network", name: "Network" },
                      { id: "application", name: "Application" },
                      { id: "profile", name: "Profile" },
                    ]}
                    disabled={false}
                    optionText="name"
                    optionValue="id"
                    validate={true}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            )}
            {disabled_level_2 === true &&
              props !== undefined &&
              props.record !== undefined &&
              props.record.faqs !== undefined &&
              Array.isArray(props.record.faqs) &&
              props.record.faqs.map((item, index) => (
                <div key={index}>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(`organizations.fields.faqs.question`)}
                    >
                      <>{item.question}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(`organizations.fields.faqs.answer`)}
                    >
                      <div
                        className="tag-temp-text"
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(`organizations.fields.faqs.section`)}
                    >
                      <>{item.section}</>
                    </Labeled>
                  </Grid>
                </div>
              ))}
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationFaqs;
