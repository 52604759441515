import {
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  Labeled,
  InputHelperText,
} from "react-admin";
import {
  ReferenceArrayAutocompleteInputField,
  TextInputField,
  NumberInputField,
  ReferenceStudyFields,
  ReferenceStudyFieldCategories,
} from "../components";
import { Grid } from "@material-ui/core";

const OrganizationFocusFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const translate = useTranslate();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <p>
          {`${translate("sentence.organization_focus_1")} `}
          <a href="https://eduwo.ch/de/suche/schools" target={"_blanck"}>
            https://eduwo.ch/de/suche/schools
          </a>
        </p>
        <p>
          {`${translate("sentence.organization_focus_2")} `}
          <a
            href="https://eduwo.ch/de/studiengaenge-weiterbildung/"
            target={"_blanck"}
          >
            https://eduwo.ch/de/studiengaenge-weiterbildung
          </a>
        </p>
      </Grid>
      <Grid item xs={12}>
        <ReferenceArrayAutocompleteInputField
          reference="schools"
          source="focus_school_ids"
          sort={{ field: "name", order: "ASC" }}
          optionText="name"
          optionValue="id"
          disabled={disabled_level_2}
          filter={{ type: "reference" }}
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceStudyFields disabled={disabled_level_2} />
      </Grid>
      <Grid item xs={12}>
        <ReferenceStudyFieldCategories disabled={disabled_level_2} />
      </Grid>
      <Grid item xs={12}>
        <Labeled
          resource={props.resource}
          label={translate(`organizations.fields.focus_topics.name`)}
        >
          <>
            <Grid item xs={12}>
              <InputHelperText
                touched
                helperText={translate("sentence.focus_topics")}
              />
            </Grid>
            <Grid item xs={12}>
              {disabled_level_2 === false && (
                <ArrayInput source="focus_topics" label={null}>
                  <SimpleFormIterator>
                    <TextInputField
                      source="topic"
                      label={translate(
                        `organizations.fields.focus_topics.topic`
                      )}
                      resource={props.resource}
                      record={props.record}
                      disabled={disabled_level_2}
                    />
                    <NumberInputField
                      source="percentage"
                      label={translate(
                        `organizations.fields.focus_topics.percentage`
                      )}
                      resource={props.resource}
                      record={props.record}
                      disabled={disabled_level_2}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              )}
              {disabled_level_2 === true &&
                props !== undefined &&
                props.record !== undefined &&
                props.record.focus_topics !== undefined &&
                Array.isArray(props.record.focus_topics) &&
                props.record.focus_topics.map((item, index) => (
                  <div key={index}>
                    <Grid item xs={3}>
                      <Labeled
                        resource={props.resource}
                        label={translate(
                          `organizations.fields.focus_topics.topic`
                        )}
                      >
                        <>{item.topic}</>
                      </Labeled>
                    </Grid>
                    <Grid item xs={9}>
                      <Labeled
                        resource={props.resource}
                        label={translate(
                          `organizations.fields.focus_topics.percentage`
                        )}
                      >
                        <>{item.percentage}</>
                      </Labeled>
                    </Grid>
                  </div>
                ))}
            </Grid>
          </>
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default OrganizationFocusFields;
