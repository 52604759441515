import * as React from "react";
import { useTranslate } from "react-admin";
import { ReferenceArrayAutocompleteInputField, BoxTitle } from "../components";
import { Grid, Collapse } from "@material-ui/core";

const OrganizationDashboardRecommendedCourses = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.dashbard_recommended_courses`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference="courses"
              source="dashboard_recommended_course_ids"
              sort={{ field: "short_name", order: "ASC" }}
              optionText="name_with_school"
              optionValue="id"
              disabled={disabled_level_1}
              renderSugestions={3}
              filter={{
                show_only: ["id", "name_with_school"],
              }}
              dynamic={true}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationDashboardRecommendedCourses;
