import { makeStyles, Theme } from "@material-ui/core/styles";

export const RelatedToolbarStyle = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
  toolbar: {
    padding: 0,
    backgroundColor: "transparent",
  },
}));
