import * as React from "react";
import { usePermissions } from "react-admin";
import { EventsList } from "../components";
import { SchoolTitle } from "./SchoolForm";
import { USER_ROLE_SCHOOL_ADMIN, USER_ROLE_SUPER_ADMIN } from "../Constants";

const SchoolEvents = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <EventsList
      target={"related_school_id"}
      reference={"school_events"}
      title={<SchoolTitle />}
      resources={"schools"}
      model_id={props.id}
      canAdd={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN)
      }
      canDelete={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN)
      }
    />
  );
};

export default SchoolEvents;
