import * as React from "react";
import { FC, cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  FilterProps,
  Filter,
  SearchInput,
  BooleanInput,
  useListContext,
  sanitizeListRestProps,
  TopToolbar,
  CreateButton,
  ExportButton,
  BooleanField,
  ShowButton,
  useTranslate,
  usePermissions,
  Responsive,
  DateField,
} from "react-admin";
import { CheckRow, ListBulkActions, ListPagination } from "../components";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";

export const UsersDatagrid: any = ({ deletedMode, ...props }) => {
  const translate = useTranslate();
  const { permissions = [] } = usePermissions();
  return (
    <Datagrid rowClick={undefined}>
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && <CheckRow {...props} />}
      <TextField source="title" label={translate(`users.fields.title`)} />
      <TextField
        source="first_name"
        label={translate(`users.fields.first_name`)}
      />
      <TextField
        source="last_name"
        label={translate(`users.fields.last_name`)}
      />
      <TextField source="email" label={translate(`users.fields.email`)} />
      <BooleanField
        source="is_expert"
        label={translate(`users.fields.is_expert`)}
      />
      <BooleanField
        source="premium"
        label={translate(`users.fields.premium`)}
      />
      {!deletedMode && (
        <DateField
          source="updated_at"
          label={translate(`schools.fields.updated_at`)}
        />
      )}
      {deletedMode && (
        <DateField
          source="deleted_at"
          label={translate(`schools.fields.deleted_at`)}
        />
      )}
      {!deletedMode && permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <ShowButton />
      )}
    </Datagrid>
  );
};

const useDeleteMode = () => {
  const [deletedMode, setDeletedMode] = React.useState(false);
  return {
    deletedMode,
    setDeletedMode,
  };
};

const UserFilter: FC<Omit<FilterProps, "children">> = (props) => {
  const { setDeletedMode } = useDeleteMode();
  const { permissions = [] } = usePermissions();

  return (
    <Filter {...props}>
      <SearchInput source="term" alwaysOn />
      <BooleanInput source="premium" />
      <BooleanInput source="is_expert" />
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <BooleanInput
          source="deleted"
          onChange={(value) => {
            setDeletedMode(value);
          }}
        />
      )}
    </Filter>
  );
};

const UserList: FC<ListProps> = (props) => {
  const { deletedMode, setDeletedMode } = useDeleteMode();

  const UserActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      // hasCreate,
      basePath,
      // selectedIds,
      showFilter,
      total,
    } = useListContext();
    setDeletedMode(filterValues.deleted ?? false);
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton basePath={basePath} />
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        />
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={
        <ListBulkActions deletedMode={deletedMode} {...props} />
      }
      actions={<UserActions />}
      filters={<UserFilter />}
      pagination={<ListPagination />}
      // Sort by translated fields decrease performance a lot
      // sort={{ field: "name", order: "ASC" }}
    >
      <Responsive
        medium={<UsersDatagrid deletedMode={deletedMode} {...props} />}
      />
    </List>
  );
};

export default UserList;
