import * as React from "react";
import { FC } from "react";
import { Edit, ShowProps } from "react-admin";
import OrganizationForm from "./OrganizationForm";

const OrganizationEdit: FC<ShowProps> = ({ ...props }) => {
  return (
    <Edit {...props} actions={<></>} title={" "}>
      <OrganizationForm {...props} action="edit" />
    </Edit>
  );
};

export default OrganizationEdit;
