import React from "react";
import { FC } from "react";
import { useTranslate } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { ButtonAddInProps } from "./types";
import { ButtonStyle } from "../styles";

const AddRelationButtonIn: FC<ButtonAddInProps> = (props) => {
  const translate = useTranslate();
  const classes = ButtonStyle();
  return (
    <Button
      onClick={() => props.handleClickOpen()}
      className={classes.addRelationButton}
    >
      <AddIcon /> {translate(`resources.${props.resource}.add`)}
    </Button>
  );
};

export default AddRelationButtonIn;
