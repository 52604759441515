import * as React from "react";
import { Route } from "react-router-dom";
import Configuration from "./configuration/Configuration";
import ReferenceDataForm from "./referenceData/ReferenceDataForm";
import ITForm from "./it/ITForm";
import CompanyRegister from "./companyRegister/CompanyRegister";

const Routes = [
  <Route exact path="/configuration" render={() => <Configuration />} />,
  <Route exact path="/reference_data" render={() => <ReferenceDataForm />} />,
  <Route exact path="/it" render={() => <ITForm />} />,
  <Route exact path="/company-register" render={() => <CompanyRegister />} />,
];

export default Routes;
