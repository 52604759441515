import React from "react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Button, useTranslate } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { ButtonAddToProps } from "./types";

const AddRelationButtonTo: FC<ButtonAddToProps> = (props) => {
  const translate = useTranslate();
  return (
    <Button
      component={Link}
      to={props.to}
      label={translate(`resources.${props.resource}.add`)}
      title={translate(`resources.${props.resource}.add`)}
    >
      <AddIcon />
    </Button>
  );
};

export default AddRelationButtonTo;
