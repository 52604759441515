import * as React from "react";
import {
  FormWithRedirect,
  useTranslate,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import {
  StyledTableCell,
  StyledTableRow,
  StyledTableCellBorder,
} from "../styles";
import {
  Collapse,
  Grid,
  Box,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons/";
import PropTypes from "prop-types";
import {
  LinearLoading,
  HorizontalLine,
  DateInputField,
  NullableBooleanInputField,
  BoxTitle,
  BooleanInputField,
} from "../components";
import ContactProvider from "./ContactProvider";
import { apiUrl } from "../dataProvider";
import { stringify } from "query-string";
import MyLocalStorage from "../utils/MyLocalStorage";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Row = ({ schoolName, row, data, filters }: any) => {
  const [open, setOpen] = React.useState(false);
  let totalCells = 11;
  if (data !== undefined) {
    totalCells =
      totalCells + Object.keys(data.summary["total_leads"]).length * 6;
  }
  const cellBorder = StyledTableCellBorder();

  const handleExportDetails = () => {
    const query = {
      type: "school_details",
      school_id: row["school_id"],
    };
    const url = `${apiUrl}/contacts/export?${stringify(filters)}&${stringify(
      query
    )}`;

    const headers = new Headers({ Accept: "application/json" });
    headers.set(
      "Authorization",
      `Bearer ${MyLocalStorage.getCookie("access_token")}`
    );
    headers.set("Content-Type", `application/json`);

    let options = { headers: headers };

    return fetch(url, options)
      .then(function (response) {
        if (response !== null) {
          return response.blob();
        }
      })
      .then(function (blob) {
        var file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      });
  };

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{schoolName}</StyledTableCell>
        {data !== undefined &&
          Object.keys(data["summary"]["total_leads"]).map((key) => {
            if (row["total_leads"][key] === undefined) {
              return (
                <>
                  <TableCell className={cellBorder.left}>0</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell className={cellBorder.right}>0</TableCell>
                </>
              );
            }
            return (
              <>
                <TableCell
                  className={cellBorder.left}
                >{`${row["total_leads"][key]["total"]}`}</TableCell>
                <TableCell>{`${row["total_leads"][key]["anonymous"]}`}</TableCell>
                <TableCell>{row["total_leads"][key]["fix_fee"]}</TableCell>
                <TableCell>{`${row["total_leads"][key]["pay_per_lead"]}`}</TableCell>
                <TableCell>{`${row["total_leads"][key]["pay_per_view"]}`}</TableCell>
                <TableCell>{`${row["total_leads"][key]["no_contract"]}`}</TableCell>
                <TableCell>
                  {row["total_leads"][key]["rejected_by_api"]}
                </TableCell>
                <TableCell>
                  {row["total_leads"][key]["rejected_manually"]}
                </TableCell>
                <TableCell>
                  {row["total_leads"][key]["approved_pay_per_view"]}
                </TableCell>
                <TableCell
                  className={cellBorder.right}
                >{`${row["total_leads"][key]["pending"]}`}</TableCell>
              </>
            );
          })}
      </StyledTableRow>
      <TableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={totalCells}
      >
        <Collapse in={open}>
          <Grid container spacing={1}>
            <Grid xs={12}>
              <p>
                <strong>Sources</strong>
              </p>
            </Grid>
            {Object.keys(row["sources"]).map((key) => {
              return (
                <>
                  <Grid xs={12}>
                    {key}: {row["sources"][key]}
                  </Grid>
                </>
              );
            })}
            <Grid xs={12}>
              <p>
                <strong>Courses</strong>
              </p>
            </Grid>
            {Object.keys(row["courses_count"]).map((key) => {
              return (
                <>
                  <Grid xs={12}>
                    {key}: {row["courses_count"][key]}
                  </Grid>
                </>
              );
            })}
            <Grid xs={12}>
              <p>
                <strong>Details</strong>
              </p>
            </Grid>
            <Grid xs={12}>
              <Button variant="outlined" onClick={() => handleExportDetails()}>
                {"EXPORT"}
              </Button>
            </Grid>
            <Grid xs={2}>
              <strong>User</strong>
            </Grid>
            <Grid xs={1}>
              <strong>Status</strong>
            </Grid>
            <Grid xs={1}>
              <strong>Sent Date</strong>
            </Grid>
            <Grid xs={1}>
              <strong>Anonymous</strong>
            </Grid>
            <Grid xs={2}>
              <strong>To Email</strong>
            </Grid>
            <Grid xs={5}>
              <strong>Courses</strong>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
            {row["details"].map((detail) => {
              return (
                <>
                  <Grid xs={2}>
                    {`${detail["email"]} <${detail["first_name"]} ${detail["last_name"]}>`}
                  </Grid>
                  <Grid xs={1}>
                    {detail["status"].map((status) => {
                      return <li>{status}</li>;
                    })}
                  </Grid>
                  <Grid xs={1}>
                    {detail["sent_date"].map((sent_date) => {
                      return <li>{sent_date}</li>;
                    })}
                  </Grid>
                  <Grid xs={1}>
                    {detail["anonymous"].map((status) => {
                      return <li>{status === true ? "true" : "false"}</li>;
                    })}
                  </Grid>
                  <Grid xs={2}>
                    {detail["to_email"].map((status) => {
                      return <li>{status}</li>;
                    })}
                  </Grid>
                  <Grid xs={5}>
                    {detail["course_short_names"].map((course) => {
                      return <li>{course}</li>;
                    })}
                  </Grid>
                  <Grid xs={12}>
                    <hr />
                  </Grid>
                </>
              );
            })}
          </Grid>
          <Grid xs={12}>
            <p></p>
          </Grid>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
};

const SchoolsList = ({
  start_date,
  end_date,
  school_id,
  organization_id,
  premium,
  contractTypeId,
  infomaterial,
  details,
  freshData,
  loading,
  data,
  error,
}: any) => {
  const translate = useTranslate();
  const [collapseTopSchools, setCollapseTopSchools] = React.useState(false);
  const [collapseTopCourses, setCollapseTopCourses] = React.useState(false);
  const [collapseTopOrganizations, setCollapseTopOrganizations] =
    React.useState(false);
  const [collapseTopSources, setCollapseTopSources] = React.useState(false);

  const filters = {
    start_date: start_date,
    end_date: end_date,
    school_id: school_id,
    organization_id: organization_id,
    premium: premium,
    contractTypeId: contractTypeId,
    infomaterial: infomaterial,
    details: details,
    freshData: freshData,
  };

  const cellBorder = StyledTableCellBorder();

  const handleExport = () => {
    const url = `${apiUrl}/contacts/export?${stringify(filters)}`;

    const headers = new Headers({ Accept: "application/json" });
    headers.set(
      "Authorization",
      `Bearer ${MyLocalStorage.getCookie("access_token")}`
    );
    headers.set("Content-Type", `application/json`);

    let options = { headers: headers };

    return fetch(url, options)
      .then(function (response) {
        if (response !== null) {
          return response.blob();
        }
      })
      .then(function (blob) {
        var file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      });
  };

  if (loading) {
    return <LinearLoading />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!data) {
    return <></>;
  }

  return (
    <>
      <div>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            Total contacts between the dates: {data.summary.total}
          </Grid>
          {/* <Grid item xs={6}>
            Total Schools: {data.summary.total_schools}
          </Grid> */}
          <Grid item xs={4}>
            Total unique schools: {data.summary.total_unique_schools}
          </Grid>
          {/* <Grid item xs={6}>
            Total courses: {data.total_courses}
          </Grid> */}
          <Grid item xs={4}>
            Total unique courses: {data.summary.total_unique_courses}
          </Grid>
          {data !== undefined &&
            Object.keys(data.summary["total_leads"]).map((key) => {
              return (
                <>
                  <Grid item xs={3}>
                    {key}
                  </Grid>
                  <Grid item xs={9}>
                    Total: {data.summary["total_leads"][key]["total"]} |{" "}
                    Anonymous: {data.summary["total_leads"][key]["anonymous"]} |{" "}
                    Fix Fee: {data.summary["total_leads"][key]["fix_fee"]} | Pay
                    Per Lead: {data.summary["total_leads"][key]["pay_per_lead"]}{" "}
                    | Pay Per View:{" "}
                    {data.summary["total_leads"][key]["pay_per_view"]} | No
                    Contract: {data.summary["total_leads"][key]["no_contract"]}{" "}
                    | Rejected By Api:{" "}
                    {data.summary["total_leads"][key]["rejected_by_api"]} |{" "}
                    Rejected Manually:{" "}
                    {data.summary["total_leads"][key]["rejected_manually"]} |{" "}
                    Approved Pay Per View:{" "}
                    {data.summary["total_leads"][key]["approved_pay_per_view"]}{" "}
                    | Pending: {data.summary["total_leads"][key]["pending"]}
                  </Grid>
                </>
              );
            })}
          <Grid item xs={12}>
            <BoxTitle
              title="Top schools"
              collapse={collapseTopSchools}
              setCollapse={setCollapseTopSchools}
            />
            <Collapse in={collapseTopSchools}>
              {data !== undefined &&
                Object.keys(data.summary.school_contacts_count).map((key) => {
                  return (
                    <p>
                      {key}: {data.summary["school_contacts_count"][key]}
                    </p>
                  );
                })}
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <BoxTitle
              title="Top courses"
              collapse={collapseTopCourses}
              setCollapse={setCollapseTopCourses}
            />
            <Collapse in={collapseTopCourses}>
              {data !== undefined &&
                Object.keys(data.summary.course_contacts_count).map((key) => {
                  return (
                    <p>
                      {key}: {data.summary["course_contacts_count"][key]}
                    </p>
                  );
                })}
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <BoxTitle
              title="Top organizations"
              collapse={collapseTopOrganizations}
              setCollapse={setCollapseTopOrganizations}
            />
            <Collapse in={collapseTopOrganizations}>
              {data !== undefined &&
                Object.keys(data.summary.organization_contacts_count).map(
                  (key) => {
                    return (
                      <p>
                        {key}:{" "}
                        {data.summary["organization_contacts_count"][key]}
                      </p>
                    );
                  }
                )}
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <BoxTitle
              title="Top sources"
              collapse={collapseTopSources}
              setCollapse={setCollapseTopSources}
            />
            <Collapse in={collapseTopSources}>
              {data !== undefined &&
                Object.keys(data.summary.source_contacts_count).map((key) => {
                  return (
                    <p>
                      {key}: {data.summary["source_contacts_count"][key]}
                    </p>
                  );
                })}
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <HorizontalLine />
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" onClick={() => handleExport()}>
              {"EXPORT"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <p>
              T = Total | P = Premium | PPL = Pay Per Lead | IM = Info Material
              | A = Anonymous | R = Rejected | APPV = Approvd Pay Per View | Pe
              = Pending
            </p>
          </Grid>
        </Grid>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{translate(`contacts.fields.school_name`)}</TableCell>
              {data !== undefined &&
                Object.keys(data.summary["total_leads"]).map((key) => {
                  return (
                    <TableCell
                      align="center"
                      colSpan={10}
                      className={`${cellBorder.left} ${cellBorder.right}`}
                    >
                      Total {key}
                    </TableCell>
                  );
                })}
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell />
              {data !== undefined &&
                Object.keys(data.summary["total_leads"]).map((key) => {
                  return (
                    <>
                      <TableCell className={cellBorder.left}>
                        {translate(`contacts.fields.total`)}
                      </TableCell>
                      <TableCell>
                        {translate(`contacts.fields.anonymous`)}
                      </TableCell>
                      <TableCell>
                        {translate(`contacts.fields.fix_fee`)}
                      </TableCell>
                      <TableCell>
                        {translate(`contacts.fields.pay_per_lead`)}
                      </TableCell>
                      <TableCell>
                        {translate(`contacts.fields.pay_per_view`)}
                      </TableCell>
                      <TableCell>
                        {translate(`contacts.fields.no_contract`)}
                      </TableCell>
                      <TableCell>
                        {translate(`contacts.fields.rejected_by_api`)}
                      </TableCell>
                      <TableCell>
                        {translate(`contacts.fields.rejected_manually`)}
                      </TableCell>
                      <TableCell>
                        {translate(`contacts.fields.approved_pay_per_view`)}
                      </TableCell>
                      <TableCell className={cellBorder.right}>
                        {translate(`contacts.fields.pending`)}
                      </TableCell>
                    </>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data !== undefined &&
              Object.keys(data.details["school_contacts_desc"]).map((key) => {
                return (
                  <Row
                    schoolName={key}
                    row={data.details["school_contacts_desc"][key]}
                    data={data}
                    filters={filters}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const ContactSchools = ({ ...props }: any) => {
  const classes = useStyles();
  const [end_date, setEndDate] = React.useState(undefined);
  const today = new Date();
  const last_month = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate()
  );
  const [start_date, setStartDate] = React.useState<Date | undefined>(
    last_month
  );
  const [school_id, setSchoolId] = React.useState(undefined);
  const [organization_id, setOrganizationId] = React.useState(undefined);
  const [premium, setPremium] = React.useState(undefined);
  const [contractTypeId, setContractTypeId] = React.useState(undefined);
  const [infomaterial, setInfomaterial] = React.useState(undefined);
  const [details, setDetails] = React.useState(false);
  const [freshData, setFreshData] = React.useState(false);
  const [data, setData] = React.useState<any>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(undefined);

  const filters = {
    start_date: start_date,
    end_date: end_date,
    school_id: school_id,
    organization_id: organization_id,
    premium: premium,
    contractTypeId: contractTypeId,
    infomaterial: infomaterial,
    details: details,
    freshData: freshData,
  };

  const handleShowtDetails = () => {
    setLoading(true);
    const filters = {
      start_date: start_date,
      end_date: end_date,
      school_id: school_id,
      organization_id: organization_id,
      premium: premium,
      contractTypeId: contractTypeId,
      infomaterial: infomaterial,
      details: details,
      freshData: freshData,
    };
    ContactProvider.perSchool(filters)
      .then((json: any) => {
        setData(json.data);
        setLoading(false);
      })
      .catch((reason: any) => {
        setLoading(false);
        setError(reason);
      });
  };

  const handleExportDetails = () => {
    const query = {
      type: "school_details",
      school_id,
    };
    const url = `${apiUrl}/contacts/export?${stringify(filters)}&${stringify(
      query
    )}`;

    const headers = new Headers({ Accept: "application/json" });
    headers.set(
      "Authorization",
      `Bearer ${MyLocalStorage.getCookie("access_token")}`
    );
    headers.set("Content-Type", `application/json`);

    let options = { headers: headers };

    return fetch(url, options)
      .then(function (response) {
        if (response !== null) {
          return response.blob();
        }
      })
      .then(function (blob) {
        var file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      });
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <ReferenceInput
                perPage={1000}
                source="school_id"
                reference="schools"
                sort={{ field: "name", order: "ASC" }}
                filter={{
                  show_only: ["id", "name_and_abbreviation"],
                }}
                inputProps={{
                  onChange: (e) => {
                    if (e.target.value === "") {
                      setSchoolId(undefined);
                    } else {
                      setSchoolId(e.target.value);
                    }
                  },
                }}
                allowEmpty={true}
                fullWidth
              >
                <SelectInput
                  optionText="name_and_abbreviation"
                  optionValue="id"
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ReferenceInput
                perPage={1000}
                source="organization_id"
                reference="organizations"
                sort={{ field: "name", order: "ASC" }}
                filter={{
                  show_only: ["id", "name"],
                }}
                inputProps={{
                  onChange: (e) => {
                    if (e.target.value === "") {
                      setOrganizationId(undefined);
                    } else {
                      setOrganizationId(e.target.value);
                    }
                  },
                }}
                allowEmpty={true}
                fullWidth
              >
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateInputField
                resource={"leads"}
                source={"start_date"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setStartDate(undefined);
                  } else {
                    setStartDate(value);
                  }
                }}
                allowEmpty={true}
                defaultValue={start_date}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateInputField
                resource={"leads"}
                source={"end_date"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setEndDate(undefined);
                  } else {
                    setEndDate(value);
                  }
                }}
                allowEmpty={true}
              />
            </Grid>
            <Grid item xs={4}>
              <NullableBooleanInputField
                resource={"leads"}
                source={"premium"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setPremium(undefined);
                  } else {
                    setPremium(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                source="contract_type_id"
                reference="contract_types"
              >
                <SelectInput optionText="title" optionValue="id" fullWidth />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <NullableBooleanInputField
                resource={"leads"}
                source={"infomaterial"}
                onChange={(event) => {
                  const { value } = event.target;
                  console.log(value);
                  if (value === "") {
                    setInfomaterial(undefined);
                  } else {
                    setInfomaterial(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <BooleanInputField
                resource={"contacts"}
                source={"details"}
                onChange={(event) => {
                  console.log(event);
                  setDetails(event);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <BooleanInputField
                resource={"contacts"}
                source={"freshData"}
                onChange={(event) => {
                  console.log(event);
                  setFreshData(event);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant="outlined" onClick={() => handleShowtDetails()}>
                {"SHOW"}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                disabled={school_id === undefined}
                onClick={() => handleExportDetails()}
              >
                {"EXPORT"}
              </Button>
            </Grid>
          </Grid>
          <SchoolsList
            end_date={end_date}
            start_date={start_date}
            school_id={school_id}
            organization_id={organization_id}
            premium={premium}
            contract_type_id={contractTypeId}
            infomaterial={infomaterial}
            details={details}
            freshData={freshData}
            loading={loading}
            data={data}
            error={error}
          />
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default ContactSchools;
