import * as React from "react";
import { FC } from "react";
import { SelectInput, useTranslate, required } from "react-admin";
import { ResourceSelectProps } from "./types";

const SelectInputField: FC<ResourceSelectProps> = (props) => {
  const translate = useTranslate();
  return (
    <SelectInput
      source={props.source}
      label={
        props.label ?? translate(`${props.resource}.fields.${props.source}`)
      }
      choices={props.choices}
      fullWidth
      disabled={props.disabled}
      optionText={props.optionText}
      optionValue={props.optionValue}
      validate={props.validate ? [required()] : []}
      helperText={props.helperText ?? <p></p>}
    />
  );
};

export default SelectInputField;
