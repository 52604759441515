import * as React from "react";
import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { HorizontalLineProps } from "./types";

const useStyles = makeStyles(() => ({
  root: {
    height: "5px",
    backgroundColor: "#0a50aa",
  },
  small: {
    height: "1px",
    backgroundColor: "#0a50aa",
  },
}));

const HorizontalLine: FC<HorizontalLineProps> = (props) => {
  const classes = useStyles();
  return (
    <Divider
      className={props.size === "small" ? classes.small : classes.root}
    />
  );
};

export default HorizontalLine;
