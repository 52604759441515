import * as React from "react";
import { useTranslate } from "react-admin";
import { Collapse } from "@material-ui/core";
import { BoxTitle, Infobox } from "../components";

const CourseInfoboxFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`courses.boxes.infobox`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Infobox
          disabled={disabled_level_2}
          infobox_array={
            props !== undefined &&
            props.record !== undefined &&
            props.record.infobox !== undefined &&
            props.record.infobox !== null &&
            Array.isArray(props.record.infobox)
              ? props.record.infobox
              : undefined
          }
          {...props}
        />
      </Collapse>
    </>
  );
};

export default CourseInfoboxFields;
