import { useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";
import {
  TextInputField,
  NumberInputField,
  ReferenceArrayAutocompleteInputField,
} from "../components";

import ReferenceDataTable from "./ReferenceDataTable";

const UserDegreeTypeEditCreateForm = () => {
  const translate = useTranslate();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={"user_degree_types"}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <NumberInputField
          source="order"
          resource={"user_degree_types"}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceArrayAutocompleteInputField
          label={translate(`user_degree_types.fields.degree_type`)}
          reference={"degree_types"}
          source={"degree_type_ids"}
          sort={{ field: "title", order: "ASC" }}
          optionText={"title"}
          optionValue={"id"}
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceArrayAutocompleteInputField
          label={translate(`user_degree_types.fields.exclude_degree_type`)}
          reference={"degree_types"}
          source={"exclude_degree_type_ids"}
          sort={{ field: "title", order: "ASC" }}
          optionText={"title"}
          optionValue={"id"}
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceArrayAutocompleteInputField
          reference={"school_types"}
          source={"school_type_ids"}
          sort={{ field: "title", order: "ASC" }}
          optionText={"title"}
          optionValue={"id"}
        />
      </Grid>
    </Grid>
  );
};
const UserDegreeTypeForm = ({ props }: any) => {
  const fields = ["title"];
  const reference_data_fields = [
    "title",
    "order",
    "degree_type_ids",
    "exclude_degree_type_ids",
    "school_type_ids",
  ];

  return (
    <ReferenceDataTable
      resource={"user_degree_types"}
      table_fields={fields}
      reference_data_fields={reference_data_fields}
      {...props}
      editForm={<UserDegreeTypeEditCreateForm />}
      createForm={<UserDegreeTypeEditCreateForm />}
    />
  );
};

export default UserDegreeTypeForm;
