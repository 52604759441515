import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BooleanInputField,
  BoxTitle,
  RichTextInputField,
  NumberInputField,
  ReferenceSelectInputField,
  ReferenceArrayAutocompleteInputField,
  GoToShowUrl,
  LogoBackgroundImages,
  CardWithIcon,
} from "../components";
import WarningIcon from "@material-ui/icons/Warning";

const CourseDescriptionFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`courses.boxes.description`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid>
          <Grid item xs={12}>
            <RichTextInputField
              source="description"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="cost_description"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="duration_description"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="study_begins_at"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="registration_fees"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="registration_deadline"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference="course_languages"
              source="language_ids"
              sort={{ field: "title", order: "ASC" }}
              optionText="title"
              optionValue="id"
              disabled={disabled_level_2}
              validate={true}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default CourseDescriptionFields;
