import * as React from "react";
import { useTranslate } from "react-admin";
import { Theme } from "@material-ui/core";
import { useListContext } from "react-admin";
import { Toolbar, Grid, Button } from "@material-ui/core";
import { ModelLocaleSelect } from "../components";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 115,
    },
  })
);

const ListPagination = (props) => {
  const { page, perPage, total, setPage, setPerPage } = useListContext();
  const classes3 = useStyles();
  const translate = useTranslate();
  const nbPages = Math.ceil(total / perPage) || 1;
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPerPage(event.target.value as number);
  };

  return (
    <Grid
      container
      direction={!isXSmall ? "row" : "column-reverse"}
      justify={"space-between"}
      alignItems="center"
    >
      <Grid item>
        <ModelLocaleSelect label={translate(`sentence.show_in`)} />
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {!isXSmall && (
            <Grid item>
              <FormControl className={classes3.formControl}>
                <InputLabel id="demo-simple-select-label">
                  {translate(`ra.navigation.page_rows_per_page`)}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={perPage}
                  onChange={handleChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item>
            {nbPages > 1 && (
              <Toolbar>
                {isXSmall && page > 1 && (
                  <Button
                    color="primary"
                    key="prev"
                    onClick={() => setPage(page - 1)}
                  >
                    <ChevronLeft />
                    {translate(`ra.navigation.prev`)}
                  </Button>
                )}
                {!isXSmall && page !== 1 && (
                  <Button
                    color="primary"
                    key="firstPage"
                    onClick={() => setPage(1)}
                  >
                    1...
                  </Button>
                )}
                <p color="primary"> {page} </p>
                {!isXSmall && page !== nbPages && (
                  <Button
                    color="primary"
                    key="lastPage"
                    onClick={() => setPage(nbPages)}
                  >
                    ...{nbPages}
                  </Button>
                )}
                {page !== nbPages && (
                  <Button
                    color="primary"
                    key="next"
                    onClick={() => setPage(page + 1)}
                  >
                    {translate(`ra.navigation.next`)}
                    <ChevronRight />
                  </Button>
                )}
              </Toolbar>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListPagination;
