import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";
import { useLocation, useHistory } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import { Notification, useTranslate, useLogin, useNotify } from "react-admin";
import { apiUrl, httpClient } from "../dataProvider";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#C0C0C0",
    // background: 'url(https://source.unsplash.com/random/1600x900)',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    maxWidth: 300,
    marginTop: "6em",
  },
  warning: {
    minWidth: 300,
    maxWidth: 600,
    marginTop: "1em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  warning_title: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  username?: string;
  password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [verify, setVerify] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation<{ nextPathname: string } | null>();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const emailParams = params.get("email");
  const organization_name = params.get("organization_name");

  if (verify === false) {
    if (token !== null && emailParams !== null && organization_name !== null) {
      setLoading(true);
      setVerify(true);
      const url = `${apiUrl}/users/verify_organization_admin_email`;
      console.log(url);

      httpClient(url, {
        method: "POST",
        body: JSON.stringify({ token, email: emailParams, organization_name }),
      }).then((json: any) => {
        setLoading(false);
        setVerify(false);
        // Redirect the page
        history.push("/login");
        history.go(0);
      });
    }
  }

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : "/").catch(
      (error: Error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      }
    );
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  if (verify) {
    return (
      <div className={classes.main}>
        <p>Please wait, we are verifing your email</p>
        <CircularProgress size={25} thickness={2} />
      </div>
    );
  }
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <Card className={classes.card}>
                <div className={classes.avatar}>
                  <Avatar className={classes.icon}>
                    <LockIcon />
                  </Avatar>
                </div>
                <div className={classes.hint}>Eduwo Admin Console</div>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="username"
                      // @ts-ignore
                      component={renderInput}
                      label={translate("pos.email")}
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="password"
                      // @ts-ignore
                      component={renderInput}
                      label={translate("ra.auth.password")}
                      type="password"
                      disabled={loading}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    {translate("ra.auth.sign_in")}
                  </Button>
                </CardActions>
              </Card>
              <Notification />
              <Card className={classes.warning}>
                <div className={classes.warning_title}>
                  <b>{translate("sentence.login_info_title")}</b>
                </div>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <p>
                      {translate("sentence.login_info_body_part1")}
                      <a href="https://eduwo.ch/de/register">
                        eduwo.ch/de/register
                      </a>
                      {translate("sentence.login_info_body_part2")}
                      <a
                        href={`mailto:raphael@eduwo.ch?subject=${translate(
                          "sentence.login_email_subject"
                        )}&body=${translate("sentence.login_email_body")}`}
                      >
                        Raphael Tobler
                      </a>{" "}
                      {translate("sentence.login_info_body_part3")}
                      <br></br>
                      <br></br>
                      {translate("sentence.login_info_body_part4")}
                      <a href="https://eduwo.ch/de/recover">
                        eduwo.ch/de/recover
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          </form>
        )}
      />
    </>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => <Login {...props} />;

export default LoginWithTheme;
