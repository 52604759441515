import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BoxTitle,
  ReferenceSelectInputField,
} from "../components";

const UserContactFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`users.boxes.contact_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextInputField
              source="phone"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="street"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputField
              source="zip"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ReferenceSelectInputField
              source="city_id"
              reference="cities"
              optionText="title"
              optionValue="id"
              disabled={disabled_level_1}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default UserContactFields;
