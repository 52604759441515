import * as React from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  TextInputField,
  RichTextInputField,
  NumberInputField,
} from "../components";
import { useTranslate, ArrayInput, SimpleFormIterator } from "react-admin";
import { apiUrl, httpClient } from "../dataProvider";
import { ConfirmationModal } from "../components";
import ReferenceDataTable from "./ReferenceDataTable";
import { makeStyles } from "@material-ui/core/styles";

const resource = "degree_types";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: "800px",
    maxWidth: "1200px",
  },
}));

const DegreeTypeEditCreateForm = () => {
  const translate = useTranslate();
  const [type, setType] = React.useState<string>("accept");
  const [open, setOpen] = React.useState(false);
  const [addedAcceptRules, setAddedAcceptRules] = React.useState<any>([]);
  const [addedRejectRules, setAddedRejectRules] = React.useState<any>([]);

  const handleAgreeFunction = (ruleDisplay) => {
    if (type === "accept") {
      addedAcceptRules.push(ruleDisplay);
      setAddedAcceptRules(addedAcceptRules);
    }
    if (type === "reject") {
      addedRejectRules.push(ruleDisplay);
      setAddedRejectRules(addedRejectRules);
    }
  };

  const AddComplexRule = () => {
    const classes = useStyles();
    const [options, setOptions] = React.useState([]);
    const [operations, setOperations] = React.useState([]);
    const [openBrackets, setOpenBrackets] = React.useState(false);
    const [finish, setFinish] = React.useState(false);
    const [rule, setRule] = React.useState<any>([]);
    const [ruleDisplay, setRuleDisplay] = React.useState<any>([]);
    const [ruleLine, setRuleLine] = React.useState({
      filter: undefined,
      operation: undefined,
      option: undefined,
      operator: undefined,
    });

    const availableFilters = {
      user_degree_types: {
        id: "user_degree_types",
        title: "User Degree Type",
        operations: ["==="],
      },
      age: {
        id: "age",
        title: "Age",
        operations: ["===", ">", "<"],
      },
      work_experiences: {
        id: "work_experiences",
        title: "Work Experience",
        operations: [">=", "<"],
      },
    };

    const setRuleType = (event) => {
      setType(event.target.value);
    };

    const getOptions = (event) => {
      const filter = event.target.value;
      if (filter === undefined || filter === "") {
        setRuleLine({
          filter: undefined,
          operation: undefined,
          option: undefined,
          operator: undefined,
        });
        return;
      }
      setRuleLine({
        filter: filter,
        operation: undefined,
        option: undefined,
        operator: undefined,
      });
      setOperations(availableFilters[filter].operations);
      if (filter === "age") {
        setOptions([]);
        return null;
      }
      const url = `${apiUrl}/${filter}?lolcale=de&show_only[]=id&show_only[]=title`;
      return httpClient(url, {
        method: "GET",
      }).then(({ headers, json }: any) => {
        setOptions(json);
      });
    };

    const setOperationRuleLine = (event) => {
      setRuleLine({ ...ruleLine, operation: event.target.value });
    };

    const setOptionRuleLine = (event) => {
      setRuleLine({ ...ruleLine, option: event.target.value });
    };

    const setConfirmRuleLine = (event) => {
      const ruleLineToAdd = { ...ruleLine, operator: event.target.value };
      if (event.target.value.includes("(")) {
        setOpenBrackets(true);
      }
      if (event.target.value.includes(")")) {
        setOpenBrackets(false);
      }
      if (event.target.value.includes(";")) {
        setFinish(true);
      }
      const ruleToAdd = rule;
      ruleToAdd.push(ruleLineToAdd);

      const ruleToAddDisplay = ruleDisplay;
      ruleToAddDisplay.push({
        filter: ruleLineToAdd.filter,
        operation: ruleLineToAdd.operation,
        option:
          options.length === 0
            ? ruleLineToAdd.option
            : `"${
                options.filter(function (option) {
                  return option["id"] === ruleLineToAdd.option;
                })[0]["title"]
              }"`,
        operator: ruleLineToAdd.operator,
      });
      setRule(ruleToAdd);
      setRuleDisplay(ruleToAddDisplay);
      setRuleLine({
        filter: undefined,
        operation: undefined,
        option: undefined,
        operator: undefined,
      });
    };

    return (
      <>
        <Button
          variant="outlined"
          style={{ color: "blue" }}
          onClick={() => setOpen(true)}
        >
          {"Add Complex Rule"}
        </Button>

        <ConfirmationModal
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          handleAgree={() => {
            handleAgreeFunction(ruleDisplay);
            setRuleDisplay([]);
            setRule([]);
            setFinish(false);
            setOpen(false);
          }}
          handleDisagree={() => {
            setRuleLine({
              filter: undefined,
              operation: undefined,
              option: undefined,
              operator: undefined,
            });
            setRule([]);
            setOpenBrackets(false);
            setFinish(false);
            setOpen(false);
          }}
          disableAgree={!finish}
          message={
            <Grid container spacing={1} className={classes.root}>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel id="type-label">Type</InputLabel>
                  <Select
                    labelId={"type-label"}
                    id="filter"
                    onChange={setRuleType}
                    value={type}
                  >
                    <MenuItem value={"accept"}>{"ACCEPT"}</MenuItem>
                    <MenuItem value={"reject"}>{"REJECT"}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {ruleDisplay.map(function (r) {
                  return (
                    <p>{`${r.filter} ${r.operation} ${r.option} ${r.operator}`}</p>
                  );
                })}
              </Grid>
              {!finish && (
                <>
                  <Grid item xs={5}>
                    <FormControl>
                      <InputLabel id="filter-label">Filter</InputLabel>
                      <Select
                        labelId={"filter-label"}
                        id="filter"
                        onChange={getOptions}
                        value={ruleLine.filter ?? ""}
                      >
                        <MenuItem value={""}>{""}</MenuItem>
                        {Object.keys(availableFilters).map(function (filter) {
                          return (
                            <MenuItem value={availableFilters[filter]["id"]}>
                              {availableFilters[filter]["title"]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl>
                      <InputLabel id="operation-label">Operation</InputLabel>
                      <Select
                        labelId={"operation-label"}
                        id="operation"
                        onChange={setOperationRuleLine}
                        disabled={operations.length < 1}
                        value={ruleLine.operation ?? ""}
                      >
                        <MenuItem value={""}>{""}</MenuItem>
                        {operations.map(function (operation) {
                          return (
                            <MenuItem value={operation}>{operation}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    {options.length > 0 && (
                      <FormControl>
                        <InputLabel id="options-label">Options</InputLabel>
                        <Select
                          labelId={"options-label"}
                          id="options"
                          onChange={setOptionRuleLine}
                          disabled={ruleLine.operation === undefined}
                          value={ruleLine.option ?? ""}
                        >
                          <MenuItem value={""}>{""}</MenuItem>
                          {options.map(function (option) {
                            return (
                              <MenuItem value={option["id"]}>
                                {option["title"]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                    {options.length === 0 && (
                      <TextField
                        id="standard-basic"
                        label="Options"
                        variant="standard"
                        onChange={setOptionRuleLine}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel id="operator-label"></InputLabel>
                      <Select
                        labelId={"operator-label"}
                        id="operator"
                        onChange={setConfirmRuleLine}
                        disabled={
                          ruleLine.filter === undefined ||
                          ruleLine.operation === undefined ||
                          ruleLine.option === undefined
                        }
                        value={ruleLine.operator ?? ""}
                      >
                        <MenuItem value={""}>{""}</MenuItem>
                        <MenuItem value={"AND"}>AND</MenuItem>
                        <MenuItem value={"OR"}>OR</MenuItem>
                        {openBrackets ? (
                          <MenuItem value={") AND"}>{`) AND`}</MenuItem>
                        ) : (
                          <MenuItem value={"AND ("}>{`AND (`}</MenuItem>
                        )}
                        {openBrackets ? (
                          <MenuItem value={") OR"}>{`) OR`}</MenuItem>
                        ) : (
                          <MenuItem value={"OR ("}>{`OR (`}</MenuItem>
                        )}
                        {openBrackets && (
                          <MenuItem value={");"}>{`) and Finish`}</MenuItem>
                        )}
                        {!openBrackets && (
                          <MenuItem value={";"}>Finish</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          }
          title={`Contact`}
        />
      </>
    );
  };

  console.log(addedAcceptRules);
  const addedAcceptComplexRule = addedAcceptRules.map(function (complexRule) {
    console.log(complexRule);
    let line = complexRule.map(function (r) {
      return r.filter + " " + r.operation + " " + r.option + " " + r.operator;
    });
    return line.join(" ");
  });
  console.log(addedAcceptComplexRule);

  console.log(addedRejectRules);
  const addedRejectComplexRule = addedRejectRules.map(function (complexRule) {
    console.log(complexRule);
    let line = complexRule.map(function (r) {
      return r.filter + " " + r.operation + " " + r.option + " " + r.operator;
    });
    return line.join(" ");
  });
  console.log(addedRejectComplexRule);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInputField
          source="validation_month"
          resource={resource}
          disabled={false}
          validate={true}
          helperText={"0 = infinite"}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInputField
          source="order"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextInputField
          source="description"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <ArrayInput source={"faqs"}>
          <SimpleFormIterator>
            <TextInputField
              source={"question"}
              label={translate(`${resource}.fields.faqs.question`)}
              resource={resource}
              disabled={false}
              validate={true}
            />
            <RichTextInputField
              source={"answer"}
              label={translate(`${resource}.fields.faqs.answer`)}
              resource={resource}
              disabled={false}
              validate={true}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      <Grid item xs={12}>
        <ArrayInput source={"accept_complex_rules"}>
          <SimpleFormIterator disableAdd>
            <TextInputField
              source={"rule"}
              label={translate(`${resource}.fields.complex_rules.rule`)}
              resource={resource}
              disabled={true}
              validate={true}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      <Grid item xs={12}>
        <ArrayInput source={"reject_complex_rules"}>
          <SimpleFormIterator disableAdd>
            <TextInputField
              source={"rule"}
              label={translate(`${resource}.fields.complex_rules.rule`)}
              resource={resource}
              disabled={true}
              validate={true}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      {addedAcceptComplexRule.map(function (complexRule, index) {
        return (
          <Grid item xs={12}>
            <TextInputField
              source={`add_accept_complex_rules_${index}`}
              label={translate(`${resource}.fields.accept_complex_rules`)}
              resource={resource}
              disabled={true}
              validate={true}
              defaultValue={`${complexRule}`}
            />
          </Grid>
        );
      })}
      {addedRejectComplexRule.map(function (complexRule, index) {
        return (
          <Grid item xs={12}>
            <TextInputField
              source={`add_reject_complex_rules_${index}`}
              label={translate(`${resource}.fields.reject_complex_rules`)}
              resource={resource}
              disabled={true}
              validate={true}
              defaultValue={`${complexRule}`}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <AddComplexRule />
      </Grid>
    </Grid>
  );
};
const DegreeTypeForm = ({ props }: any) => {
  const fields = ["title"];
  const reference_data_fields = [
    "title",
    "validation_month",
    "order",
    "description",
    "faqs",
    "accept_complex_rules",
    "reject_complex_rules",
  ];

  return (
    <ReferenceDataTable
      resource={resource}
      table_fields={fields}
      reference_data_fields={reference_data_fields}
      reference_data_images={["background_image"]}
      {...props}
      editForm={<DegreeTypeEditCreateForm />}
      createForm={<DegreeTypeEditCreateForm />}
    />
  );
};

export default DegreeTypeForm;
