import * as React from "react";
import { FC, cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  FilterProps,
  Filter,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  useListContext,
  sanitizeListRestProps,
  TopToolbar,
  CreateButton,
  ExportButton,
  ReferenceField,
  BooleanField,
  ShowButton,
  useTranslate,
  usePermissions,
  DateField,
  Responsive,
  SearchInput,
} from "react-admin";
import {
  CheckRow,
  GoToPublicUrl,
  ListBulkActions,
  ListPagination,
} from "../components";
import {
  USER_ROLE_COURSE_ADMIN,
  USER_ROLE_SCHOOL_ADMIN,
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_SOA_ADMIN,
} from "../Constants";
import { useMediaQuery, Theme } from "@material-ui/core";

export const SchoolDatagrid: any = ({ deletedMode, ...props }) => {
  const { permissions = [] } = usePermissions();
  const translate = useTranslate();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Datagrid>
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && <CheckRow {...props} />}
      <TextField source="name" label={translate(`schools.fields.name`)} />
      {!isSmall && (
        <TextField
          source="abbreviation"
          label={translate(`schools.fields.abbreviation`)}
        />
      )}
      <ReferenceField
        source="school_type_id"
        reference="school_types"
        label={translate(`resources.school_types.name`)}
        link={false}
      >
        <TextField source="title" />
      </ReferenceField>
      {!isXSmall && !permissions.includes(USER_ROLE_SOA_ADMIN) && (
        <BooleanField
          source="premium"
          label={translate(`schools.fields.premium`)}
        />
      )}
      {!deletedMode && !permissions.includes(USER_ROLE_SOA_ADMIN) && (
        <DateField
          source="updated_at"
          label={translate(`schools.fields.updated_at`)}
        />
      )}
      {deletedMode && (
        <DateField
          source="deleted_at"
          label={translate(`schools.fields.deleted_at`)}
        />
      )}
      {!isSmall && !deletedMode && (
        <GoToPublicUrl record={props.record} source="public_url" />
      )}
      {!deletedMode &&
        (permissions.includes(USER_ROLE_SUPER_ADMIN) ||
          permissions.includes(USER_ROLE_SCHOOL_ADMIN) ||
          permissions.includes(USER_ROLE_COURSE_ADMIN) ||
          permissions.includes(USER_ROLE_SOA_ADMIN)) && <ShowButton />}
    </Datagrid>
  );
};

const useDeleteMode = () => {
  const [deletedMode, setDeletedMode] = React.useState(false);
  return {
    deletedMode,
    setDeletedMode,
  };
};

const SchoolFilter: FC<Omit<FilterProps, "children">> = (props) => {
  const { permissions = [] } = usePermissions();
  const { setDeletedMode } = useDeleteMode();
  return (
    <Filter {...props}>
      <SearchInput source="term" alwaysOn />
      <ReferenceInput source="school_type_id" reference="school_types">
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
      <BooleanInput source="premium" />
      <BooleanInput source="pay_per_lead" />
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <BooleanInput
          source="deleted"
          onChange={(value) => {
            setDeletedMode(value);
          }}
        />
      )}
    </Filter>
  );
};

const SchoolList: FC<ListProps> = (props) => {
  const { deletedMode, setDeletedMode } = useDeleteMode();
  const { permissions = [] } = usePermissions();

  const SchoolActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      // hasCreate,
      basePath,
      // selectedIds,
      showFilter,
      total,
    } = useListContext();
    setDeletedMode(filterValues.deleted ?? false);

    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
          <CreateButton basePath={basePath} />
        )}
        {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={1000000}
          />
        )}
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={
        <ListBulkActions deletedMode={deletedMode} {...props} />
      }
      actions={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ? (
          <SchoolActions {...props} />
        ) : (
          <></>
        )
      }
      filters={<SchoolFilter />}
      pagination={<ListPagination />}
      // Sort by translated fields decrease performance a lot
      // sort={{ field: "name", order: "ASC" }}
    >
      <Responsive
        medium={<SchoolDatagrid deletedMode={deletedMode} {...props} />}
      />
    </List>
  );
};

export default SchoolList;
