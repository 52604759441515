import * as React from "react";
import { useTranslate } from "react-admin";
import { Collapse } from "@material-ui/core";
import { BoxTitle, Infobox } from "../components";

const CourseInfoboxSoaFields = ({
  disabled_level_1,
  disabled_level_2,
  disabled_level_3,
  disabled_level_4,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`courses.boxes.infobox_soa`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Infobox
          source={"infobox_soa"}
          disabled={disabled_level_4}
          infobox_array={
            props !== undefined &&
            props.record !== undefined &&
            props.record.infobox_soa !== undefined &&
            props.record.infobox_soa !== null &&
            Array.isArray(props.record.infobox_soa)
              ? props.record.infobox_soa
              : undefined
          }
          translation={true}
          {...props}
        />
      </Collapse>
    </>
  );
};

export default CourseInfoboxSoaFields;
