import React from "react";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { Button, useTranslate } from "react-admin";
import {
  BoxTitle,
  DateInputField,
  Loading,
  LinearLoading,
} from "../components";
import statisticsProvider from "./statisticsProvider";
import EduwoStatistics from "./EduwoStatistics";
import SchoolCoursesStatistics from "./SchoolCoursesStatistics";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  button: {
    marginTop: "1em",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  alignCenter: { textAlign: "center" },
}));

// TODO: Eduwo details

export default function Statistics({ ...props }) {
  const [group_by, setGroupBy] = React.useState("day");
  const [collapse, setCollapse] = React.useState(true);
  const [eduwo_stats, setEduwoStats] = React.useState(null);
  const [eduwo_stats_details, setEduwoStatsDetails] = React.useState(null);
  const [google_stats, setGoogleStats] = React.useState(null);
  const [check, setCheck] = React.useState({
    eduwoTotal: true,
    eduwoDetail: false,
    googleTotal: true,
    coursesDetails: false,
  });
  const [eduwoTotalLoading, setEduwoTotalLoading] = React.useState(true);
  const [eduwoDetailLoading, setEduwoDetailLoading] = React.useState(false);
  const [courseDetailsLoading, setCourseDetailsLoading] = React.useState<
    boolean | undefined
  >(undefined);
  const [eduwo_line_chart_data, setEduwoLineChartData] = React.useState<any[]>(
    []
  );
  const [google_line_chart_data, setGoogleLineChartData] = React.useState<
    any[]
  >([]);
  const [
    course_detail_line_chart_data,
    setCourseDetailChartData,
  ] = React.useState<any[]>([]);
  const [line_chart_data, setLineChartData] = React.useState<any[]>([]);
  const [line_chart_labels, setLineChartsLabels] = React.useState([]);
  const translate = useTranslate();

  let today = new Date();
  const yesterday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  );
  const lastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate() - 1
  );
  const [end_date, setEndDate] = React.useState<string | undefined>(
    yesterday.getFullYear() +
      "-" +
      (yesterday.getMonth() + 1 > 10
        ? yesterday.getMonth() + 1
        : "0" + (yesterday.getMonth() + 1)) +
      "-" +
      (yesterday.getDate() > 10
        ? yesterday.getDate()
        : "0" + yesterday.getDate())
  );
  const [start_date, setStartDate] = React.useState<string | undefined>(
    lastMonth.getFullYear() +
      "-" +
      (lastMonth.getMonth() + 1 > 10
        ? lastMonth.getMonth() + 1
        : "0" + (lastMonth.getMonth() + 1)) +
      "-" +
      (lastMonth.getDate() > 10
        ? lastMonth.getDate()
        : "0" + lastMonth.getDate())
  );
  const [more_options, setMoreOptions] = React.useState<boolean>(false);

  const handleChangeGroupBy = (event) => {
    setGroupBy(event.target.value);
  };

  const handleRefreshClick = () => {
    if (check.eduwoTotal) {
      setEduwoTotalLoading(true);
      statisticsProvider
        .getEduwoTotal("schools", props.id, {
          start_date: start_date,
          end_date: end_date,
          group_by: group_by,
        })
        .then((json: any) => {
          setEduwoStats(json.data.eduwo);
          setEduwoTotalLoading(false);
        });
    }

    if (check.googleTotal) {
      setCourseDetailsLoading(true);
      statisticsProvider
        .getGoogleTotal("schools", props.id, {
          start_date: start_date,
          end_date: end_date,
          group_by: group_by,
        })
        .then((json: any) => {
          setGoogleStats(json.data.google);

          let googleLineChartD = [
            {
              label: "Google Visitors",
              data: json.data.google.details.map((entry) => {
                return Object.values<any>(entry)[0].views;
              }),
            },
            {
              label: "Google Unique Visitors",
              data: json.data.google.details.map((entry) => {
                return Object.values<any>(entry)[0].total_unique_users;
              }),
            },
          ];

          setGoogleLineChartData(googleLineChartD);
          if (check.eduwoDetail) {
            setLineChartData([...eduwo_line_chart_data, ...googleLineChartD]);
          } else {
            setLineChartData(googleLineChartD);
          }

          setCourseDetailChartData(json.data);

          setLineChartsLabels(
            json.data.google.details_de.map((entry) => {
              return Object.keys(entry)[0];
            })
          );
          setCourseDetailsLoading(false);
        });
    }
    if (check.eduwoDetail) {
      setEduwoDetailLoading(true);
      statisticsProvider
        .getEduwoDetail("schools", props.id, {
          start_date: start_date,
          end_date: end_date,
          group_by: group_by,
        })
        .then((json: any) => {
          setEduwoStatsDetails(json.data.eduwo);

          let eduwoLineChartD = [
            {
              label: "Visitors",
              data: json.data.eduwo.details.map((entry) => {
                return Object.values<any>(entry)[0].views;
              }),
            },
            {
              label: "Unique Visitors",
              data: json.data.eduwo.details.map((entry) => {
                return Object.values<any>(entry)[0].total_unique_users;
              }),
            },
            {
              label: "DE Visitors",
              data: json.data.eduwo.details_de.map((entry) => {
                return Object.values<any>(entry)[0].views;
              }),
            },
            {
              label: "DE Unique Visitors",
              data: json.data.eduwo.details_de.map((entry) => {
                return Object.values<any>(entry)[0].total_unique_users;
              }),
            },
            {
              label: "FR Visitors",
              data: json.data.eduwo.details_fr.map((entry) => {
                return Object.values<any>(entry)[0].views;
              }),
            },
            {
              label: "FR Unique Visitors",
              data: json.data.eduwo.details_fr.map((entry) => {
                return Object.values<any>(entry)[0].total_unique_users;
              }),
            },
          ];

          setLineChartsLabels(
            json.data.eduwo.details_de.map((entry) => {
              return Object.keys(entry)[0];
            })
          );

          setEduwoLineChartData(eduwoLineChartD);
          setLineChartData([...eduwoLineChartD, ...google_line_chart_data]);

          setEduwoDetailLoading(false);
        });
    }

    if (check.coursesDetails) {
      setCourseDetailsLoading(true);
      statisticsProvider
        .getCourseDetail("schools", props.id, {
          start_date: start_date,
          end_date: end_date,
          group_by: group_by,
        })
        .then((json: any) => {
          setCourseDetailChartData(json.data);
          setCourseDetailsLoading(false);
        });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };

  const classes = useStyles();

  useEffect(handleRefreshClick, []);

  return (
    <div className={classes.root}>
      <BoxTitle
        title={translate(`statistics.parameters`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item sm={6} md={3}>
            <DateInputField
              resource={"statistics"}
              source={"start_date"}
              defaultValue={start_date}
              onChange={(event) => {
                const { value } = event.target;
                if (value === "") {
                  setStartDate(undefined);
                } else {
                  setStartDate(value);
                }
              }}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DateInputField
              resource={"statistics"}
              source={"end_date"}
              defaultValue={end_date}
              onChange={(event) => {
                const { value } = event.target;
                if (value === "") {
                  setEndDate(undefined);
                } else {
                  setEndDate(value);
                }
              }}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                Group By
              </InputLabel>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={group_by}
                onChange={handleChangeGroupBy}
                className={classes.selectEmpty}
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6} md={3}>
            <Button
              label={translate(`button.loadSelected`)}
              onClick={() => handleRefreshClick()}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          {/* <Grid item xs={6} sm={3}>
            <Button
              label={more_options === false ? "More Options" : "Less Options"}
              onClick={() => {
                setMoreOptions(!more_options);
              }}
            />
          </Grid> */}

          {more_options && (
            <>
              <Grid item xs={6} sm={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check.eduwoDetail}
                      onChange={handleChange}
                      name="eduwoDetail"
                      style={{ color: "red" }}
                    />
                  }
                  label={
                    <Typography style={{ color: "red" }}>
                      Eduwo Details
                    </Typography>
                  }
                />
                {eduwoDetailLoading && <Loading />}
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check.coursesDetails}
                      onChange={handleChange}
                      name="coursesDetails"
                      style={{ color: "red" }}
                    />
                  }
                  label={
                    <Typography style={{ color: "red" }}>
                      Course Details
                    </Typography>
                  }
                />
                {courseDetailsLoading && <Loading />}
              </Grid>{" "}
            </>
          )}
        </Grid>
      </Collapse>
      {!eduwoTotalLoading ? (
        <>
          <EduwoStatistics
            eduwo_stats={eduwo_stats}
            eduwo_stats_details={eduwo_stats_details}
            google_stats={google_stats}
            loading={eduwo_stats === null}
            line_chart_data={line_chart_data}
            line_chart_labels={line_chart_labels}
          />
          <SchoolCoursesStatistics
            stats={course_detail_line_chart_data}
            loading={course_detail_line_chart_data.length === 0}
            line_chart_labels={line_chart_labels}
          />
        </>
      ) : (
        <LinearLoading />
      )}
    </div>
  );
}
