import * as React from "react";
import { FC, useState } from "react";
import { useTranslate, useRefresh } from "react-admin";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import MyLocalStorage from "../utils/MyLocalStorage";
import { SelectStyle } from "../styles";

const ModelLocaleSelect: FC<Omit<any, "children">> = ({
  basePath,
  data,
  label = undefined,
  disableModelLocal = false,
  forceModelLocale = undefined,
  ...props
}) => {
  const [locale, setLocale] = useState(
    MyLocalStorage.getCookie("model_locale") ?? "de"
  );
  const translate = useTranslate();
  const refresh = useRefresh();
  const classes = SelectStyle();

  const handleChangeModelLocal = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setLocale(event.target.value as string);
    MyLocalStorage.setCookie("model_locale", event.target.value as string);
    refresh();
  };

  if (forceModelLocale !== undefined && forceModelLocale !== locale) {
    setLocale(forceModelLocale);
    MyLocalStorage.setCookie("model_locale", forceModelLocale);
    refresh();
  }

  if (disableModelLocal) {
    return <></>;
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">
        {label ? label : translate("sentence.edit_in")}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={locale}
        onChange={handleChangeModelLocal}
      >
        <MenuItem value={`de`}>{translate("languages.de")}</MenuItem>
        <MenuItem value={`fr`}>{translate("languages.fr")}</MenuItem>
        <MenuItem value={`en`}>{translate("languages.en")}</MenuItem>
        <MenuItem value={`it`}>{translate("languages.it")}</MenuItem>
      </Select>
    </FormControl>
  );
};
export default ModelLocaleSelect;
