import * as React from "react";
import { useTranslate, FormWithRedirect } from "react-admin";
import {
  EmojiEvents,
  MenuBook,
  AttachMoney,
  Timer,
  Translate,
  LocationCity,
  LibraryBooks,
  School,
  Work,
  Business,
  Sports,
  Toys,
  Public,
} from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Tabs, AppBar, Tab, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import AwardForm from "./AwardsForm";
import PublictiesForm from "./PublictiesForm";
import DegreeTypeForm from "./DegreeTypeForm";
import StudyFieldCategoryForm from "./StudyFieldCategoryForm";
import StudyFieldForm from "./StudyFieldForm";
import SubStudyFieldForm from "./SubStudyFieldForm";
import ReferenceDataTable from "./ReferenceDataTable";
import SchoolTypeForm from "./SchoolTypeForm";
import UserDegreeTypeForm from "./UserDegreeTypeForm";
import CitiesForm from "./CitiesForm";
import DistrictsForm from "./DistrictsForm";
import RegionsForm from "./RegionsForm";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ReferenceDataForm = ({ ...props }: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function a11yProps(index: any) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const tabs = {
    0: {
      tab: "awards",
      icon: <EmojiEvents />,
      tab_panel: <AwardForm {...props} />,
    },
    1: {
      tab: "course_costs",
      icon: <AttachMoney />,
      tab_panel: <ReferenceDataTable resource={"course_costs"} sort={{}} />,
    },
    2: {
      tab: "course_durations",
      icon: <Timer />,
      tab_panel: <ReferenceDataTable resource={"course_durations"} sort={{}} />,
    },
    3: {
      tab: "course_languages",
      icon: <Translate />,
      tab_panel: <ReferenceDataTable resource={"course_languages"} />,
    },
    4: {
      tab: "cities",
      icon: <LocationCity />,
      tab_panel: <CitiesForm {...props} />,
    },
    5: {
      tab: "districts",
      icon: <LocationCity />,
      tab_panel: <DistrictsForm {...props} />,
    },
    6: {
      tab: "regions",
      icon: <LocationCity />,
      tab_panel: <RegionsForm {...props} />,
    },
    7: {
      tab: "countries",
      icon: <Public />,
      tab_panel: <ReferenceDataTable resource={"countries"} />,
    },
    8: {
      tab: "study_field_categories",
      icon: <LibraryBooks />,
      tab_panel: <StudyFieldCategoryForm {...props} />,
    },
    9: {
      tab: "study_fields",
      icon: <MenuBook />,
      tab_panel: <StudyFieldForm {...props} />,
    },
    10: {
      tab: "sub_study_fields",
      icon: <MenuBook />,
      tab_panel: <SubStudyFieldForm {...props} />,
    },
    11: {
      tab: "school_types",
      icon: <School />,
      tab_panel: <SchoolTypeForm {...props} />,
    },
    12: {
    tab: "study_types",
      icon: <MenuBook />,
      tab_panel: <ReferenceDataTable resource={"study_types"} />,
    },
    13: {
      tab: "branches",
      icon: <Business />,
      tab_panel: <ReferenceDataTable resource={"branches"} />,
    },
    14: {
      tab: "job_types",
      icon: <Work />,
      tab_panel: <ReferenceDataTable resource={"job_types"} />,
    },
    15: {
      tab: "organization_types",
      icon: <Business />,
      tab_panel: <ReferenceDataTable resource={"organization_types"} />,
    },
    16: {
      tab: "degree_types",
      icon: <School />,
      tab_panel: <DegreeTypeForm {...props} />,
    },
    17: {
      tab: "soa_school_types",
      icon: <Sports />,
      tab_panel: <ReferenceDataTable resource={"soa_school_types"} />,
    },
    18: {
      tab: "publicities",
      icon: <Toys />,
      tab_panel: <PublictiesForm {...props} />,
    },
    19: {
      tab: "user_degree_types",
      icon: <School />,
      tab_panel: <UserDegreeTypeForm {...props} />,
    },
    20: {
      tab: "work_experiences",
      icon: <Work />,
      tab_panel: <ReferenceDataTable resource={"work_experiences"} />,
    },
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
            >
              {Object.keys(tabs).map((key) => {
                return (
                  <Tab
                    tabIndex={Number(key)}
                    label={translate(`resources.${tabs[key].tab}.name`)}
                    {...a11yProps(Number(key))}
                    icon={tabs[key].icon}
                  />
                );
              })}
            </Tabs>

            {Object.keys(tabs).map((key) => {
              return (
                <TabPanel value={value} index={Number(key)}>
                  {tabs[key].tab_panel}
                </TabPanel>
              );
            })}
          </AppBar>
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default ReferenceDataForm;
