import React from "react";
import { Pie } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  alignCenter: { textAlign: "center" },
}));

const PieChart = ({ title, data, labels }: any) => {
  const colors = [
    "rgba(0,0,0,1)",
    "rgba(0,0,128,1)",
    "rgba(0,0,255,1)",
    "rgba(0,128,0,1)",
    "rgba(0,128,128,1)",
    "rgba(0,255,0,1)",
    "rgba(0,255,255,1)",
    "rgba(128,0,0,1)",
    "rgba(128,0,128,1)",
    "rgba(128,128,0,1)",
    "rgba(128,128,128,1)",
    "rgba(192,192,192,1)",
    "rgba(255,0,0,1)",
    "rgba(255,0,255,1)",
    "rgba(255,255,0,1)",
    "rgba(255,255,255,1)",
  ];

  const backgroundColor = data.map(function (d, index) {
    return colors[index];
  });

  const hoverBackgroundColor = data.map(function (d, index) {
    return colors[colors.length - 1 - index];
  });

  const datasets = [
    {
      data: data,
      backgroundColor: backgroundColor,
      hoverBackgroundColor: hoverBackgroundColor,
    },
  ];

  const classes = useStyles();

  return (
    <>
      <h2 className={classes.alignCenter}>{title}</h2>
      <Pie
        data={{
          labels: labels,
          datasets: datasets,
        }}
      />
    </>
  );
};

export default PieChart;
