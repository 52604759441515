import * as React from "react";
import { FC, cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  FilterProps,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  useListContext,
  sanitizeListRestProps,
  TopToolbar,
  ExportButton,
  ReferenceField,
  BooleanField,
  ShowButton,
  useTranslate,
  usePermissions,
  useRefresh,
  DateField,
  Responsive,
  SearchInput,
} from "react-admin";
import {
  CheckRow,
  ListPagination,
  ListBulkActions,
  DeleteButtonList,
} from "../components";
import {
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_SCHOOL_ADMIN,
  USER_ROLE_COURSE_ADMIN,
  USER_ROLE_SOA_ADMIN,
} from "../Constants";
import { apiUrl, httpClient } from "../dataProvider";
import { useMediaQuery, Theme } from "@material-ui/core";

export const CoursesDatagrid: any = ({
  canDelete,
  deletedMode,
  fromSchool = false,
  ...props
}) => {
  const { permissions = [] } = usePermissions();
  const refresh = useRefresh();
  const translate = useTranslate();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );

  const handleDelete = async (id) => {
    const url = `${apiUrl}/${props.resource}/${id}`;
    return httpClient(url, {
      method: "DELETE",
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const MyDeleteButton = (props) => {
    return (
      <DeleteButtonList handleClick={() => handleDelete(props.record.id)} />
    );
  };

  return (
    <Datagrid
      rowClick={undefined}
      style={{ tableLayout: fromSchool && isXSmall ? "fixed" : "initial" }}
    >
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && <CheckRow {...props} />}
      <TextField
        source="short_name"
        label={translate(`courses.fields.short_name`)}
      />
      {(!isMedium || !fromSchool) && (
        <ReferenceField
          source="school_id"
          reference="schools"
          label={translate(`resources.schools.name`)}
        >
          <TextField source="abbreviation" />
        </ReferenceField>
      )}
      {!isSmall && (
        <ReferenceField
          source="study_type_id"
          reference="study_types"
          label={translate(`resources.study_types.name`)}
          link={false}
        >
          <TextField source="title" />
        </ReferenceField>
      )}
      {!isXSmall && (
        <ReferenceField
          source="degree_type_id"
          reference="degree_types"
          label={translate(`resources.degree_types.name`)}
          link={false}
        >
          <TextField source="title" />
        </ReferenceField>
      )}
      {!isMedium && !permissions.includes(USER_ROLE_SOA_ADMIN) && (
        <BooleanField
          source="premium"
          label={translate(`courses.fields.premium`)}
        />
      )}
      {!deletedMode && !permissions.includes(USER_ROLE_SOA_ADMIN) && (
        <DateField
          source="updated_at"
          label={translate(`schools.fields.updated_at`)}
        />
      )}
      {deletedMode && (
        <DateField
          source="deleted_at"
          label={translate(`schools.fields.deleted_at`)}
        />
      )}
      {!deletedMode &&
        (permissions.includes(USER_ROLE_SUPER_ADMIN) ||
          permissions.includes(USER_ROLE_SCHOOL_ADMIN) ||
          permissions.includes(USER_ROLE_COURSE_ADMIN) ||
          permissions.includes(USER_ROLE_SOA_ADMIN)) && <ShowButton />}
      {canDelete && <MyDeleteButton {...props} />}
    </Datagrid>
  );
};

const useDeleteMode = () => {
  const [deletedMode, setDeletedMode] = React.useState(false);
  return {
    deletedMode,
    setDeletedMode,
  };
};

const CourseFilter: FC<Omit<FilterProps, "children">> = (props) => {
  const { permissions = [] } = usePermissions();
  const { setDeletedMode } = useDeleteMode();

  return (
    <Filter {...props}>
      <SearchInput source="term" alwaysOn />
      <ReferenceInput perPage={1000} source="school_id" reference="schools">
        <SelectInput optionText="abbreviation" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput source="study_type_id" reference="study_types">
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput source="degree_type_id" reference="degree_types">
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
      <BooleanInput source="premium" />
      <BooleanInput source="pay_per_lead" />
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <BooleanInput
          source="deleted"
          onChange={(value) => {
            setDeletedMode(value);
          }}
        />
      )}
    </Filter>
  );
};

const CourseList: FC<ListProps> = (props) => {
  const { deletedMode, setDeletedMode } = useDeleteMode();
  const { permissions = [] } = usePermissions();

  const CourseActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      // hasCreate,
      // basePath,
      // selectedIds,
      showFilter,
      total,
    } = useListContext();
    const { permissions = [] } = usePermissions();
    setDeletedMode(filterValues.deleted ?? false);

    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={maxResults}
          />
        )}
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={
        <ListBulkActions deletedMode={deletedMode} {...props} />
      }
      actions={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ? <CourseActions /> : <></>
      }
      filters={<CourseFilter />}
      pagination={<ListPagination />}
      // Sort by translated fields decrease performance a lot
      // sort={{ field: "name", order: "ASC" }}
    >
      <Responsive
        medium={<CoursesDatagrid deletedMode={deletedMode} {...props} />}
      />
    </List>
  );
};

export default CourseList;
