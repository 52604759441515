import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BooleanInputField,
  BoxTitle,
  SelectInputField,
  DateInputField,
  ReferenceArrayAutocompleteInputField,
  LogoBackgroundImages,
} from "../components";

const UserGeneralFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`users.boxes.general`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <LogoBackgroundImages
          resource={props.resource}
          disabled={disabled_level_2}
          item_logo={props.record.photo}
          collection_logo="photo"
          item_background={props.record.background_picture}
          collection_background="background_picture"
          {...props}
        />
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <BooleanInputField
              source="verified"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <BooleanInputField
              source="premium"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <BooleanInputField
              source="is_interested_in_ubs_promo"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item xs={12} md={2} lg={1}>
            <TextInputField
              source="title"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <TextInputField
              source="first_name"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              validate={true}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={6}>
            <TextInputField
              source="last_name"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              validate={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="email"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              validate={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="slug"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              validate={true}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectInputField
              source="gender"
              resource={props.resource}
              choices={[
                { id: 1, name: "m" },
                { id: 2, name: "f" },
                { id: 2, name: "d" },
              ]}
              disabled={disabled_level_1}
              optionText="name"
              optionValue="name"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectInputField
              source="language"
              resource={props.resource}
              choices={[
                { id: 1, name: "DE" },
                { id: 2, name: "FR" },
              ]}
              disabled={disabled_level_1}
              optionText="name"
              optionValue="name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInputField
              source={"birthday"}
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference={"languages"}
              source={"language_ids"}
              sort={{ field: "title", order: "ASC" }}
              optionText={"title"}
              optionValue={"id"}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="sport_type"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInputField
              source={"type"}
              resource={props.resource}
              choices={[
                { id: "Not set", name: "Not set" },
                { id: "A", name: "A" },
                { id: "B", name: "B" },
                { id: "C", name: "C" },
                { id: "D", name: "D" },
              ]}
              disabled={disabled_level_1}
              optionText="name"
              optionValue="name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInputField
              source={"lead_status"}
              resource={props.resource}
              choices={[
                { id: "New", name: "New" },
                { id: "Updated", name: "Updated" },
                { id: "Not reached", name: "Not reached" },
                { id: "Call not wished", name: "Call not wished" },
                { id: "Not called", name: "Not called" },
                { id: "Contacted", name: "Contacted" },
                { id: "Stopped searching", name: "Stopped searching" },
                { id: "Want to book", name: "Want to book" },
                { id: "Booked without us", name: "Booked without us" },
                { id: "Booked with us", name: "Booked with us" },
              ]}
              optionText="name"
              optionValue="name"
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default UserGeneralFields;
