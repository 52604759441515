import * as React from "react";
import { FC } from "react";
import { useTranslate, Labeled, usePermissions } from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { ResourceFieldProps } from "./types";
import { LinearLoading, TranslationButton } from ".";
import { Grid } from "@material-ui/core";
import { USER_ROLE_SUPER_ADMIN, USER_ROLE_SOA_ADMIN } from "../Constants";

const RichTextInputField: FC<ResourceFieldProps> = (props) => {
  const translate = useTranslate();
  const { permissions = [] } = usePermissions();
  if (permissions.length === 0) {
    return <></>;
  }

  // URLs and emails are only allowed for super users.
  // The validation is made in backend
  const helperText: string =
    props.helperText ??
    (permissions.includes(USER_ROLE_SUPER_ADMIN) ||
      permissions.includes(USER_ROLE_SOA_ADMIN))
      ? ""
      : "URLs and emails are not allowed.";

  const toolbar = permissions.includes(USER_ROLE_SUPER_ADMIN)
    ? [
        [{ header: [3, false] }],
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
      ]
    : [
        [{ header: [3, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ];

  if (props.disabled === false) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={props.translation ? 11 : 12}>
          <RichTextInput
            source={props.source}
            fullWidth
            label={`${
              props.label ??
              translate(`${props.resource}.fields.${props.source}`)
            }${props.validate ? "*" : ""}`}
            helperText={helperText ?? <p></p>}
            toolbar={toolbar}
          />
        </Grid>
      </Grid>
    );
  }

  if (!props.record) {
    return <LinearLoading />;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={props.translation ? 11 : 12}>
        <Labeled
          source={props.source}
          resource={props.resource}
          label={
            props.label ?? translate(`${props.resource}.fields.${props.source}`)
          }
        >
          <div
            className="tag-temp-text"
            dangerouslySetInnerHTML={{ __html: props.record[props.source] }}
          />
        </Labeled>
      </Grid>
      {props.translation && (
        <Grid item xs={1}>
          <TranslationButton {...props} />
        </Grid>
      )}
    </Grid>
  );
};

export default RichTextInputField;
