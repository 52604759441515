import * as React from "react";

import { Grid } from "@material-ui/core";
import { TextInputField, ReferenceSelectInputField } from "../components";

import ReferenceDataTable from "./ReferenceDataTable";

const PublicityEditCreateForm = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="link"
          resource={"publicities"}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceSelectInputField
          source="advertisement_category_id"
          reference="advertisement_categories"
          optionText="title"
          optionValue="id"
          allowEmpty={true}
        />
      </Grid>
    </Grid>
  );
};
const PublicitiesForm = ({ props }: any) => {
  const fields = ["link"];
  const reference_data_fields = ["link", "advertisement_category_id"];

  return (
    <ReferenceDataTable
      resource={"publicities"}
      table_fields={fields}
      reference_data_fields={reference_data_fields}
      reference_data_images={["image_leaderboard", "image_rectangle"]}
      {...props}
      editForm={<PublicityEditCreateForm />}
      createForm={<PublicityEditCreateForm />}
      sort={{}}
    />
  );
};

export default PublicitiesForm;
