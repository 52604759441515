import { makeStyles } from "@material-ui/core/styles";

export const DownToolbarStyle = makeStyles({
  toolbar: {
    marginTop: 0,
  },
  pagination: {
    marginRight: "60px",
  },
  paginationMedium: {
    marginRight: "140px",
  },
});
