import * as React from "react";
import { usePermissions } from "react-admin";
import { ExpertsList } from "../components";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";
import { SchoolTitle } from "./SchoolForm";

const SchoolExperts = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <ExpertsList
      target={"related_school_id"}
      title={<SchoolTitle />}
      resources={"schools"}
      reference={"experts"}
      model_id={props.id}
      canAdd={false}
      canDelete={permissions.includes(USER_ROLE_SUPER_ADMIN)}
    />
  );
};

export default SchoolExperts;
