import * as React from "react";
import {
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@material-ui/core";
import githubProvider from "../githubProvider";
import { ConfirmationModalChecks, ConfirmationModal } from "../components";

const DeployToolsForm = ({ ...props }: any) => {
  const [check, setCheck] = React.useState({
    snapshot: false,
    merge: false,
    read: false,
  });

  const Snapshot = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheck({ ...check, snapshot: event.target.checked });
  };
  const Merge = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheck({ ...check, merge: event.target.checked });
  };
  const Read = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheck({ ...check, read: event.target.checked });
  };

  const MasterDeployCheckboxes = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControlLabel
            value="read"
            control={
              <Checkbox color="primary" onChange={Read} checked={check.read} />
            }
            label={
              <div>
                <p>
                  You have read the deployment{` `}
                  <a
                    href={`https://github.com/eduwo/gc-cd/wiki/Deploy`}
                    target={`_blank`}
                  >
                    tutorial
                  </a>
                </p>
              </div>
            }
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value="merge"
            control={
              <Checkbox
                color="primary"
                onChange={Merge}
                checked={check.merge}
              />
            }
            label="You merged the code"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value="snapshot"
            control={
              <Checkbox
                color="primary"
                onChange={Snapshot}
                checked={check.snapshot}
              />
            }
            label={
              <div>
                <p>
                  You created the snashop in{` `}
                  <a
                    href={`https://console.cloud.google.com/compute/snapshots?project=eduwo-263314`}
                    target={`_blank`}
                  >
                    google cloud platform
                  </a>
                </p>
              </div>
            }
            labelPlacement="end"
          />
        </Grid>
      </Grid>
    );
  };

  const [branch, setBranch] = React.useState("development");
  const [openBackendConfirmationModal, setOpenBackendConfirmationModal] =
    React.useState(false);
  const [openFrontendConfirmationModal, setOpenFrontendConfirmationModal] =
    React.useState(false);
  const [openAdminUIConfirmationModal, setOpenAdminUIConfirmationModal] =
    React.useState(false);
  const [
    openMasterBackendConfirmationModal,
    setMasterOpenBackendConfirmationModal,
  ] = React.useState(false);
  const [
    openMasterFrontendConfirmationModal,
    setMasterOpenFrontendConfirmationModal,
  ] = React.useState(false);
  const [
    openMasterAdminUIConfirmationModal,
    setMasterOpenAdminUIConfirmationModal,
  ] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBranch(event.target.value as string);
  };

  const deployBackend = () => {
    if (branch === "development") {
      githubProvider.deployDevelopment();
    } else if (branch === "staging") {
      githubProvider.deployStaging();
    } else if (branch === "master") {
      githubProvider.deployMaster();
    }
    setOpenBackendConfirmationModal(false);
    setOpenFrontendConfirmationModal(false);
    setOpenAdminUIConfirmationModal(false);
    setMasterOpenBackendConfirmationModal(false);
    setMasterOpenFrontendConfirmationModal(false);
    setMasterOpenAdminUIConfirmationModal(false);
  };
  const deployFrontend = () => {
    if (branch === "development") {
      githubProvider.deployFrontendDevelopment();
    } else if (branch === "staging") {
      githubProvider.deployFrontendStaging();
    } else if (branch === "master") {
      githubProvider.deployFrontendMaster();
    }
    setOpenBackendConfirmationModal(false);
    setOpenFrontendConfirmationModal(false);
    setOpenAdminUIConfirmationModal(false);
    setMasterOpenBackendConfirmationModal(false);
    setMasterOpenFrontendConfirmationModal(false);
    setMasterOpenAdminUIConfirmationModal(false);
  };
  const deployAdminUI = () => {
    if (branch === "development") {
      githubProvider.deployAdminUIDevelopment();
    } else if (branch === "staging") {
      githubProvider.deployAdminUIStaging();
    } else if (branch === "master") {
      githubProvider.deployAdminUIMaster();
    }
    setOpenBackendConfirmationModal(false);
    setOpenFrontendConfirmationModal(false);
    setOpenAdminUIConfirmationModal(false);
    setMasterOpenBackendConfirmationModal(false);
    setMasterOpenFrontendConfirmationModal(false);
    setMasterOpenAdminUIConfirmationModal(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={branch}
          onChange={handleChange}
        >
          <MenuItem value={`development`}>DEVELOPMENT</MenuItem>
          <MenuItem value={`staging`}>STAGING</MenuItem>
          <MenuItem value={`master`}>MASTER</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          onClick={() => {
            if (branch === "master") {
              setMasterOpenBackendConfirmationModal(true);
            } else {
              setOpenBackendConfirmationModal(true);
            }
          }}
        >
          {"DEPLOY BACKEND"}
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          onClick={() => {
            if (branch === "master") {
              setMasterOpenFrontendConfirmationModal(true);
            } else {
              setOpenFrontendConfirmationModal(true);
            }
          }}
        >
          {"DEPLOY FRONTEND"}
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          onClick={() => {
            if (branch === "master") {
              setMasterOpenAdminUIConfirmationModal(true);
            } else {
              setOpenAdminUIConfirmationModal(true);
            }
          }}
        >
          {"DEPLOY ADMIN UI"}
        </Button>
      </Grid>
      <ConfirmationModal
        open={openBackendConfirmationModal}
        handleClose={() => {
          setOpenBackendConfirmationModal(false);
        }}
        handleAgree={() => deployBackend()}
        handleDisagree={() => {
          setOpenBackendConfirmationModal(false);
        }}
        message={`Are you sure do you want deploy backend in ${branch}?`}
        title={`Deploy in backend ${branch}`}
      />
      <ConfirmationModal
        open={openFrontendConfirmationModal}
        handleClose={() => {
          setOpenFrontendConfirmationModal(false);
        }}
        handleAgree={() => deployFrontend()}
        handleDisagree={() => {
          setOpenFrontendConfirmationModal(false);
        }}
        message={`Are you sure do you want deploy Frontend in ${branch}?`}
        title={`Deploy in Frontend ${branch}`}
      />
      <ConfirmationModal
        open={openAdminUIConfirmationModal}
        handleClose={() => {
          setOpenAdminUIConfirmationModal(false);
        }}
        handleAgree={() => deployAdminUI()}
        handleDisagree={() => {
          setOpenAdminUIConfirmationModal(false);
        }}
        message={`Are you sure do you want deploy Admin UI in ${branch}?`}
        title={`Deploy in Admin UI ${branch}`}
      />

      <ConfirmationModalChecks
        open={openMasterBackendConfirmationModal}
        handleClose={() => {
          setMasterOpenBackendConfirmationModal(false);
        }}
        handleAgree={() => deployBackend()}
        handleDisagree={() => {
          setMasterOpenBackendConfirmationModal(false);
        }}
        message={`ATTENDATION DEPLOY IN LIVE. Please confirm if:`}
        title={`Deploy Backend in Live`}
        checkboxes={<MasterDeployCheckboxes />}
        disabled={
          !(
            check.read === true &&
            check.merge === true &&
            check.snapshot === true
          )
        }
      />

      <ConfirmationModalChecks
        open={openMasterFrontendConfirmationModal}
        handleClose={() => {
          setMasterOpenFrontendConfirmationModal(false);
        }}
        handleAgree={() => deployFrontend()}
        handleDisagree={() => {
          setMasterOpenFrontendConfirmationModal(false);
        }}
        message={`ATTENDATION DEPLOY IN LIVE. Please confirm if:`}
        title={`Deploy Frontend in Live`}
        checkboxes={<MasterDeployCheckboxes />}
        disabled={
          !(
            check.read === true &&
            check.merge === true &&
            check.snapshot === true
          )
        }
      />

      <ConfirmationModalChecks
        open={openMasterAdminUIConfirmationModal}
        handleClose={() => {
          setMasterOpenAdminUIConfirmationModal(false);
        }}
        handleAgree={() => deployAdminUI()}
        handleDisagree={() => {
          setMasterOpenAdminUIConfirmationModal(false);
        }}
        message={`ATTENDATION DEPLOY IN LIVE. Please confirm if:`}
        title={`Deploy Admin UI in Live`}
        checkboxes={<MasterDeployCheckboxes />}
        disabled={
          !(
            check.read === true &&
            check.merge === true &&
            check.snapshot === true
          )
        }
      />
    </Grid>
  );
};

export default DeployToolsForm;
