import React from "react";
import { FC } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslate } from "react-admin";
import { ConfirmationModalProps } from "./types";

const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
  const translate = useTranslate();
  return (
    <div>
      {/* Button to trigger the opening of the dialog */}
      {/* <Button onClick={this.handleClickOpen}>Open alert dialog</Button> */}
      {/* Dialog that is displayed if the state open is true */}
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleDisagree} color="primary">
            {translate(`common.disagree`)}
          </Button>
          <Button onClick={props.handleAgree} color="primary" autoFocus disabled={props.disableAgree ?? false}>
            {translate(`common.agree`)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationModal;
