import * as React from "react";
import { FC } from "react";
import { TextInput, useTranslate, required } from "react-admin";
import { TextProps } from "./types";
import { Grid } from "@material-ui/core";
import { TranslationButton } from ".";
import InputAdornment from "@material-ui/core/InputAdornment";

const TextInputField: FC<TextProps> = (props) => {
  const translate = useTranslate();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInput
          source={props.source}
          label={
            props.label ?? translate(`${props.resource}.fields.${props.source}`)
          }
          resource={props.resource}
          record={props.record}
          fullWidth
          disabled={props.disabled}
          multiline={props.multiline}
          validate={props.validate ? [required()] : []}
          onChange={props.onChange}
          InputProps={
            props.translation
              ? {
                  endAdornment: (
                    <InputAdornment position="start">
                      <TranslationButton {...props} />
                    </InputAdornment>
                  ),
                }
              : {}
          }
          helperText={props.helperText ?? <p></p>}
          defaultValue={props.defaultValue}
        />
      </Grid>
    </Grid>
  );
};

export default TextInputField;
