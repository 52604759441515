import * as React from "react";
import { FC } from "react";
import {
  useTranslate,
  FormWithRedirect,
  SimpleShowLayout,
  Show,
  Edit,
  SimpleForm,
  usePermissions,
} from "react-admin";
import { OrganizationStatistics } from "../statistics";
import OrganizationExperts from "./OrganizationExperts";
import OrganizationEvents from "./OrganizationEvents";
import OrganizationSuccessStories from "./OrganizationSuccessStories";
import {
  Storage,
  Event,
  SupervisorAccount,
  BarChart,
  SentimentSatisfiedAlt,
  DeviceHub,
  Work,
  Dashboard,
} from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Tabs, AppBar, Tab, Box, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import OrganizationTabSummary from "./OrganizationTabSummary";
import OrganizationAdministrators from "./OrganizationAdministrators";
import {
  USER_ROLE_ORGANIZATION_ADMIN,
  USER_ROLE_SUPER_ADMIN,
} from "../Constants";
import { MyActionShowBar, MyActionEditBar } from "../components";
import OrganizationMatching from "./OrganizationMatching";
import OrganizationDashboard from "./OrganizationDashboard";

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const OrganizationTitle: FC<Omit<any, "children">> = (props: any) => {
  const translate = useTranslate();
  return (
    <span>
      {translate(`resources.organizations.name_singular`)}{" "}
      {props.record ? props.record.name : ""}
    </span>
  );
};

const OrganizationForm = ({ action, ...props }: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { permissions = [] } = usePermissions();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const disabled_level_1 = !permissions.includes(USER_ROLE_SUPER_ADMIN);
  const disabled_level_2 = !(
    permissions.includes(USER_ROLE_SUPER_ADMIN) ||
    permissions.includes(USER_ROLE_ORGANIZATION_ADMIN)
  );

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                tabIndex={0}
                label={translate(`organizations.tabs.summary`)}
                {...a11yProps(0)}
                icon={<Storage />}
              />
              <Tab
                tabIndex={1}
                label={translate(`organizations.tabs.dashboard`)}
                {...a11yProps(1)}
                icon={<Dashboard />}
              />
              <Tab
                tabIndex={2}
                label={translate(`organizations.tabs.matching`)}
                {...a11yProps(2)}
                icon={<DeviceHub />}
              />
              <Tab
                value={3}
                label={translate(`organizations.tabs.experts`)}
                {...a11yProps(3)}
                icon={<Work />}
                disabled={action !== "show"}
              />
              <Tab
                value={4}
                label={translate(`organizations.tabs.events`)}
                {...a11yProps(4)}
                icon={<Event />}
                disabled={action !== "show"}
              />
              <Tab
                value={5}
                label={translate(`organizations.tabs.success_stories`)}
                {...a11yProps(5)}
                icon={<SentimentSatisfiedAlt />}
                disabled={action !== "show"}
              />
              <Tab
                value={6}
                label={translate(`organizations.tabs.administrators`)}
                {...a11yProps(6)}
                icon={<SupervisorAccount />}
                disabled={action !== "show"}
              />
              {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
                <Tab
                  value={7}
                  label={translate(`organizations.tabs.statistics`)}
                  {...a11yProps(7)}
                  icon={<BarChart />}
                  disabled={action !== "show"}
                />
              )}
            </Tabs>
            <TabPanel value={value} index={0}>
              {action === "show" && (
                <Show
                  {...props}
                  title={<OrganizationTitle />}
                  actions={
                    isXSmall ? (
                      <></>
                    ) : (
                      <MyActionShowBar disabledEdit={disabled_level_2} />
                    )
                  }
                  component="div"
                >
                  <SimpleShowLayout>
                    <OrganizationTabSummary
                      {...props}
                      disabled_level_1={
                        action === "show" ? true : disabled_level_1
                      }
                      disabled_level_2={
                        action === "show" ? true : disabled_level_2
                      }
                      action={action}
                    />
                  </SimpleShowLayout>
                </Show>
              )}
              {action === "edit" && (
                <Edit
                  {...props}
                  title={<OrganizationTitle />}
                  actions={<></>}
                  component="div"
                >
                  <SimpleForm
                    toolbar={<MyActionEditBar {...props} />}
                    submitOnEnter={false}
                    redirect="show"
                  >
                    <OrganizationTabSummary
                      disabled_level_1={
                        action === "show" ? true : disabled_level_1
                      }
                      disabled_level_2={
                        action === "show" ? true : disabled_level_2
                      }
                      action={action}
                      {...props}
                    />
                  </SimpleForm>
                </Edit>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <OrganizationDashboard {...props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <OrganizationMatching
                disabled_level_1={disabled_level_1}
                disabled_level_2={disabled_level_2}
                {...props}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <OrganizationExperts {...props} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <OrganizationEvents {...props} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <OrganizationSuccessStories {...props} />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <OrganizationAdministrators {...props} />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <OrganizationStatistics {...props} />
            </TabPanel>
          </AppBar>
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default OrganizationForm;
