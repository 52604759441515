import * as React from "react";
import { cloneElement, FC } from "react";
import {
  List,
  Datagrid,
  TextField,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  ShowButton,
  SearchInput,
  ReferenceInput,
  SelectInput,
  Filter,
  BooleanField,
  ReferenceField,
  BooleanInput,
  FilterProps,
  useTranslate,
  ListProps,
  usePermissions,
  DateField,
  Responsive,
} from "react-admin";
import {
  CheckRow,
  GoToPublicUrl,
  ListBulkActions,
  ListPagination,
} from "../components";
import {
  USER_ROLE_ORGANIZATION_ADMIN,
  USER_ROLE_SUPER_ADMIN,
} from "../Constants";
import { useMediaQuery, Theme } from "@material-ui/core";

export const OrganizationDatagrid: any = ({ deletedMode, ...props }) => {
  const { permissions = [] } = usePermissions();
  const translate = useTranslate();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <Datagrid rowClick={undefined}>
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && <CheckRow {...props} />}
      <TextField source="name" label={translate(`organizations.fields.name`)} />
      {!isSmall && (
        <ReferenceField
          source="organization_type_id"
          reference="organization_types"
          label={translate(`resources.organization_types.name`)}
          link={false}
        >
          <TextField source="title" />
        </ReferenceField>
      )}
      <BooleanField source="premium" />
      {!deletedMode && (
        <DateField
          source="updated_at"
          label={translate(`schools.fields.updated_at`)}
        />
      )}
      {deletedMode && (
        <DateField
          source="deleted_at"
          label={translate(`schools.fields.deleted_at`)}
        />
      )}
      {!deletedMode && (
        <GoToPublicUrl record={props.record} source="public_url" />
      )}
      {!deletedMode &&
        (permissions.includes(USER_ROLE_SUPER_ADMIN) ||
          permissions.includes(USER_ROLE_ORGANIZATION_ADMIN)) && <ShowButton />}
    </Datagrid>
  );
};

const useDeleteMode = () => {
  const [deletedMode, setDeletedMode] = React.useState(false);
  return {
    deletedMode,
    setDeletedMode,
  };
};

const OrganizationFilter: FC<Omit<FilterProps, "children">> = (props) => {
  const { permissions = [] } = usePermissions();
  const { setDeletedMode } = useDeleteMode();

  return (
    <Filter {...props}>
      <SearchInput source="term" alwaysOn />
      <ReferenceInput
        source="organization_type_id"
        reference="organization_types"
      >
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
      <BooleanInput source="premium" />
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <BooleanInput
          source="deleted"
          onChange={(value) => {
            setDeletedMode(value);
          }}
        />
      )}
    </Filter>
  );
};

const OrganizationList: FC<ListProps> = (props) => {
  const { deletedMode, setDeletedMode } = useDeleteMode();
  const { permissions = [] } = usePermissions();

  const OrganizationActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      // hasCreate,
      basePath,
      // selectedIds,
      showFilter,
      total,
    } = useListContext();
    setDeletedMode(filterValues.deleted ?? false);

    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton basePath={basePath} />
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        />
      </TopToolbar>
    );
  };
  return (
    <List
      {...props}
      bulkActionButtons={
        <ListBulkActions deletedMode={deletedMode} {...props} />
      }
      actions={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ? (
          <OrganizationActions />
        ) : (
          <></>
        )
      }
      filters={<OrganizationFilter />}
      pagination={<ListPagination />}
      // Sort by translated fields decrease performance a lot
      // sort={{ field: "name", order: "ASC" }}
    >
      <Responsive
        medium={<OrganizationDatagrid deletedMode={deletedMode} {...props} />}
      />
    </List>
  );
};

export default OrganizationList;
