import { AuthProvider } from "react-admin";
import MyLocalStorage from "./utils/MyLocalStorage";

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    const request = new Request(
      process.env.REACT_APP_API_SERVER_ADMIN_V2 + "/auth/login",
      {
        method: "POST",
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(
        ({
          access_token,
          id,
          slug,
          first_name,
          last_name,
          roles,
          photo,
          course_ids,
          school_ids,
          organization_ids,
          soa_admin,
        }) => {
          MyLocalStorage.setCookie("id", id);
          MyLocalStorage.setCookie("slug", slug);
          MyLocalStorage.setCookie("access_token", access_token);
          MyLocalStorage.setCookie("roles", JSON.stringify(roles));
          MyLocalStorage.setCookie("username", first_name + " " + last_name);
          MyLocalStorage.setCookie("first_name", first_name);
          MyLocalStorage.setCookie("last_name", last_name);
          MyLocalStorage.setCookie("photo", photo);
          MyLocalStorage.setCookie("course_ids", JSON.stringify(course_ids));
          MyLocalStorage.setCookie("school_ids", JSON.stringify(school_ids));
          MyLocalStorage.setCookie(
            "organization_ids",
            JSON.stringify(organization_ids)
          );
          MyLocalStorage.setCookie("soa_admin", JSON.stringify(soa_admin));
        }
      );
  },
  logout: () => {
    var headers = new Headers({ "Content-Type": "application/json" });
    headers.set(
      "Authorization",
      `Bearer ${MyLocalStorage.getCookie("access_token")}`
    );
    const request = new Request(
      process.env.REACT_APP_API_SERVER_ADMIN_V2 + "/auth/logout",
      {
        method: "GET",
        headers: headers,
      }
    );
    fetch(request).then(() => {});

    MyLocalStorage.deleteCookie("id");
    MyLocalStorage.deleteCookie("slug");
    MyLocalStorage.deleteCookie("access_token");
    MyLocalStorage.deleteCookie("roles");
    MyLocalStorage.deleteCookie("username");
    MyLocalStorage.deleteCookie("first_name");
    MyLocalStorage.deleteCookie("last_name");
    MyLocalStorage.deleteCookie("photo");
    MyLocalStorage.deleteCookie("course_ids");
    MyLocalStorage.deleteCookie("school_ids");
    MyLocalStorage.deleteCookie("organization_ids");
    MyLocalStorage.deleteCookie("soa_admin");

    return Promise.resolve();
  },
  checkError: () => {
    return Promise.resolve();
  },
  checkAuth: () => {
    if (
      MyLocalStorage.getCookie("roles") === undefined ||
      MyLocalStorage.getCookie("roles") === null
    ) {
      MyLocalStorage.deleteCookie("id");
      MyLocalStorage.deleteCookie("slug");
      MyLocalStorage.deleteCookie("access_token");
      MyLocalStorage.deleteCookie("roles");
      MyLocalStorage.deleteCookie("username");
      MyLocalStorage.deleteCookie("first_name");
      MyLocalStorage.deleteCookie("last_name");
      MyLocalStorage.deleteCookie("photo");
      MyLocalStorage.deleteCookie("course_ids");
      MyLocalStorage.deleteCookie("school_ids");
      MyLocalStorage.deleteCookie("organization_ids");
      MyLocalStorage.deleteCookie("soa_admin");
      return Promise.reject({ message: "login.required" });
    }

    return MyLocalStorage.getCookie("access_token") !== null
      ? Promise.resolve()
      : Promise.reject({ message: "login.required" });
  },
  getPermissions: () => {
    const path = window.location.href;
    if (path.endsWith("company-register")) {
      return Promise.resolve(["guest"]);
    }

    return MyLocalStorage.getCookie("roles")
      ? Promise.resolve(JSON.parse(MyLocalStorage.getCookie("roles") ?? "[]"))
      : Promise.reject({ message: "login.required" });
  },
  getIdentity: () => {
    if (
      MyLocalStorage.getCookie("id") === null ||
      MyLocalStorage.getCookie("username") === null
    ) {
      Promise.reject({ message: "login.required" });
    }
    return Promise.resolve({
      id: MyLocalStorage.getCookie("id") ?? 0,
      fullName: MyLocalStorage.getCookie("username") ?? "guest",
      avatar: MyLocalStorage.getCookie("photo") ?? "",
    });
  },
};

export default authProvider;
