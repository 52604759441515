import * as React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";

import "./App.css";

import englishMessages from "./i18n/en";
import frenchMessages from "./i18n/fr";
import germanMessages from "./i18n/de";
import italianMessages from "./i18n/it";

import authProvider from "./authProvider";
import dataProvider from "./dataProvider";

import { Layout, Login } from "./layout";
import routes from "./routes";

import schools from "./schools";
import courses from "./courses";
import organizations from "./organizations";
import leads from "./leads";
import contacts from "./contacts";
import users from "./users";
import Dashboard from "./dashboard/Dashboard2";
import MyLocalStorage from "./utils/MyLocalStorage";
import reference_data from "./referenceData";
import wizards from "./wizards";
import knowledge_base from "./knowledgeBase";

const i18nProvider = polyglotI18nProvider((locale) => {
  const locale_selected = MyLocalStorage.getCookie("locale") ?? locale;
  if (locale_selected === "fr") {
    return frenchMessages;
  }
  if (locale_selected === "en") {
    return englishMessages;
  }
  if (locale_selected === "it") {
    return italianMessages;
  }

  // Always fallback on german
  return germanMessages;
}, "de");

const App = () => {
  if (!dataProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <Admin
      title="Admin UI - Eduwo"
      dashboard={Dashboard}
      authProvider={authProvider}
      loginPage={Login}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      layout={Layout}
      customRoutes={routes}
    >
      <Resource name="schools" {...schools} />
      <Resource name="courses" {...courses} />
      <Resource name="organizations" {...organizations} />
      <Resource name="users" {...users} />
      <Resource name="leads" {...leads} />
      <Resource name="contacts" {...contacts} />
      <Resource name="referenceData" {...reference_data} />
      <Resource name="wizards" {...wizards} />
      <Resource name="knowledge_base" {...knowledge_base} />

      <Resource name="school_events" />
      <Resource name="course_events" />
      <Resource name="organization_events" />

      <Resource name="school_success_stories" />
      <Resource name="course_success_stories" />
      <Resource name="organization_success_stories" />

      <Resource name="school_types" />
      <Resource name="soa_school_types" />
      <Resource name="soa_school_levels" />

      <Resource name="study_types" />
      <Resource name="degree_types" />
      <Resource name="user_degree_types" />
      <Resource name="course_languages" />
      <Resource name="course_costs" />
      <Resource name="course_durations" />
      <Resource name="sport_facilities" />
      <Resource name="food_facilities" />
      <Resource name="initial_languages" />
      <Resource name="work_experiences" />

      <Resource name="cities" />
      <Resource name="districts" />
      <Resource name="regions" />
      <Resource name="countries" />

      <Resource name="awards" />

      <Resource name="experts" />
      <Resource name="all_experts" />
      <Resource name="alumni" />
      <Resource name="employees" />
      <Resource name="organization_employees" />

      <Resource name="organization_types" />
      <Resource name="legal_forms" />
      <Resource name="branches" />

      <Resource name="study_fields" />
      <Resource name="study_field_categories" />
      <Resource name="sub_study_fields" />
      <Resource name="tags" />

      <Resource name="job_types" />

      <Resource name="lead_options" />

      <Resource name="user_looking_fors" />
      <Resource name="sport_types" />
      <Resource name="languages" />

      <Resource name="reviews" />
      <Resource name="all_reviews" />
      <Resource name="all_expert_reviews" />

      <Resource name="leads/group_by" />

      <Resource name="publicities" />
      <Resource name="advertisement_categories" />

      <Resource name="contacts" />
      <Resource name="contact_emails" />
      <Resource name="users/contacts" />
      <Resource name="todos" />

      <Resource name="kb_modules" />
      <Resource name="kb_sections" />
      <Resource name="kb_units" />

      <Resource name="contract_types" />
    </Admin>
  );
};

export default App;
