import * as React from "react";
import { FC } from "react";
import {
  ReferenceManyField,
  SimpleShowLayout,
  TopToolbar,
  Datagrid,
  TextField,
  ShowView,
  FormWithRedirect,
  useCreate,
  useNotify,
  useRefresh,
  useTranslate,
  usePermissions,
} from "react-admin";
import {
  ReferenceArrayAutocompleteInputField,
  ListPagination,
  DeleteButtonList,
  MySaveButton,
} from "../components";
import { Grid } from "@material-ui/core";
import { apiUrl, httpClient } from "../dataProvider";
import AddIcon from "@material-ui/icons/Add";
import { useMediaQuery, Theme } from "@material-ui/core";
import { RelatedToolbarStyle } from "../styles";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";

const AdministrationActions: FC<Omit<any, "children">> = ({
  canAdd,
  model_id,
  ...props
}) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const classes = RelatedToolbarStyle();
  const [create, { loading }] = useCreate(
    `${props.resource}/${model_id}/administrators`
  );
  const notify = useNotify();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const { permissions = [] } = usePermissions();

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <TopToolbar className={classes.toolbar}>
      {canAdd && (
        <>
          <FormWithRedirect
            resource={props.resource}
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={isXSmall ? 11 : 10}>
                    <ReferenceArrayAutocompleteInputField
                      reference="users"
                      source="user_ids"
                      sort={{ field: "first_name", order: "ASC" }}
                      optionText={
                        permissions.includes(USER_ROLE_SUPER_ADMIN)
                          ? "full_name_and_full_email"
                          : "full_name_and_email"
                      }
                      optionValue="id"
                      disabled={false}
                      renderSugestions={3}
                      filter={{
                        show_only: [
                          "id",
                          "full_name_and_email",
                          "full_name_and_full_email",
                          "first_name",
                          "last_name",
                        ],
                      }}
                      dynamic={true}
                      validate={true}
                    />
                  </Grid>
                  <Grid item xs={isXSmall ? 1 : 2}>
                    <MySaveButton
                      label={isXSmall ? "" : translate(`ra.action.add`)}
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      saving={saving}
                      disabled={loading}
                      variant="text"
                      icon={<AddIcon />}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          />
        </>
      )}
    </TopToolbar>
  );
};

const AdministratorsList = ({
  target, //Example: "related_school_id"
  title,
  resources,
  model_id,
  canAdd,
  canDelete,
  ...props
}: any) => {
  const refresh = useRefresh();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const { permissions = [] } = usePermissions();

  const handleDelete = async (admin_id) => {
    const url = `${apiUrl}/${resources}/${model_id}/administrators/${admin_id}`;
    return httpClient(url, {
      method: "DELETE",
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const MyDeleteButton = (props) => {
    return (
      <DeleteButtonList handleClick={() => handleDelete(props.record.id)} />
    );
  };

  return (
    <div>
      <ShowView
        {...props}
        component="div"
        title={title}
        actions={
          <AdministrationActions
            canAdd={canAdd}
            model_id={model_id}
            {...props}
          />
        }
      >
        <SimpleShowLayout>
          <ReferenceManyField
            label=""
            reference="users"
            target={target}
            pagination={<ListPagination />}
            sort={{ field: "first_name", order: "ASC" }}
          >
            <Datagrid rowClick={undefined}>
              <TextField source="first_name" />
              {!isXSmall && <TextField source="last_name" />}
              {!isXSmall && (
                <TextField
                  source={
                    permissions.includes(USER_ROLE_SUPER_ADMIN)
                      ? "original_email"
                      : "original_hidden_email"
                  }
                />
              )}
              {canDelete && <MyDeleteButton {...props} />}
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      </ShowView>
    </div>
  );
};

export default AdministratorsList;
