import * as React from "react";
import { usePermissions } from "react-admin";
import { SuccessStoriesList } from "../components";
import { SchoolTitle } from "./SchoolForm";
import { USER_ROLE_SCHOOL_ADMIN, USER_ROLE_SUPER_ADMIN } from "../Constants";

const SchoolSuccessStories = ({ ...props }: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <SuccessStoriesList
      target={"related_school_id"}
      reference={"school_success_stories"}
      title={<SchoolTitle />}
      resources={"schools"}
      school_id={props.id}
      canAdd={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN)
      }
      canDelete={
        permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN)
      }
    />
  );
};

export default SchoolSuccessStories;
