import * as React from "react";

import { Grid } from "@material-ui/core";
import {
  TextInputField,
  RichTextInputField,
  ReferenceStudyFields,
  BooleanInputField,
  ReferenceTags,
} from "../components";
import { useTranslate, ArrayInput, SimpleFormIterator } from "react-admin";

import ReferenceDataTable from "./ReferenceDataTable";
const resource = "sub_study_fields";

const SubStudyFieldEditCreateForm = ({ type }) => {
  const translate = useTranslate();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <ReferenceStudyFields disabled={false} />
      </Grid>
      <Grid item xs={12}>
        <RichTextInputField
          source="description"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      {type !== "create" && (
        <Grid item xs={12}>
          <TextInputField
            source="slug"
            resource={resource}
            disabled={false}
            validate={true}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextInputField
          source="icon"
          resource={resource}
          disabled={false}
          validate={false}
        />
      </Grid>
      <Grid item xs={6}>
        <BooleanInputField
          source="popular"
          resource={resource}
          disabled={false}
          validate={false}
        />
      </Grid>
      <Grid item xs={12}>
        <ArrayInput source={"faqs"}>
          <SimpleFormIterator>
            <TextInputField
              source={"question"}
              label={translate(`${resource}.fields.faqs.question`)}
              resource={resource}
              disabled={false}
              validate={true}
            />
            <RichTextInputField
              source={"answer"}
              label={translate(`${resource}.fields.faqs.answer`)}
              resource={resource}
              disabled={false}
              validate={true}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      <Grid item xs={12}>
        <ReferenceTags disabled={false} />
      </Grid>
    </Grid>
  );
};
const SubStudyFieldForm = ({ props }: any) => {
  const fields = ["title", "slug"];
  const reference_data_fields = [
    "title",
    "study_field_ids",
    "description",
    "slug",
    "icon",
    "popular",
    "faqs",
    "tag_titles",
  ];

  return (
    <ReferenceDataTable
      resource={resource}
      table_fields={fields}
      reference_data_fields={reference_data_fields}
      reference_data_images={["background_image"]}
      {...props}
      editForm={<SubStudyFieldEditCreateForm type={"edit"} />}
      createForm={<SubStudyFieldEditCreateForm type={"create"} />}
    />
  );
};

export default SubStudyFieldForm;
