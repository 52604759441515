import * as React from "react";
import { Checkbox } from "@material-ui/core";

const CheckRow = (props) => {
  if (props.record === undefined) {
    return <></>;
  }

  return (
    <Checkbox
      checked={props.selectedIds.includes(props.record.id)}
      onClick={() => props.onToggleItem(props.record.id)}
    />
  );
};

export default CheckRow;
