import { FC } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery, Theme, Box } from "@material-ui/core";
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useTranslate,
  usePermissions,
} from "react-admin";
import schools from "../schools";
import courses from "../courses";
import organizations from "../organizations";
import users from "../users";
import leads from "../leads";
import contacts from "../contacts";
import knowledgeBase from "../knowledgeBase";
import referenceData from "../referenceData";
import wizards from "../wizards";
import { AppState } from "../types";
import {
  USER_ROLE_COURSE_ADMIN,
  USER_ROLE_ORGANIZATION_ADMIN,
  USER_ROLE_SCHOOL_ADMIN,
  USER_ROLE_SUPER_ADMIN,
  USER_ROLE_SOA_ADMIN,
} from "../Constants";
import { Security, Settings } from "@material-ui/icons";

const Menu: FC<MenuProps> = ({ onMenuClick, logout, dense = false }) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  const { permissions = [] } = usePermissions();

  return (
    <Box mt={1}>
      {" "}
      {!permissions.includes("guest") && (
        <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      )}
      {(permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN) ||
        permissions.includes(USER_ROLE_COURSE_ADMIN) ||
        permissions.includes(USER_ROLE_SOA_ADMIN)) && (
        <MenuItemLink
          to={`/schools`}
          primaryText={translate(`resources.schools.name`, {
            smart_count: 2,
          })}
          leftIcon={<schools.icon />}
          sidebarIsOpen={open}
          dense={dense}
          onClick={onMenuClick}
        />
      )}
      {(permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_SCHOOL_ADMIN) ||
        permissions.includes(USER_ROLE_COURSE_ADMIN) ||
        permissions.includes(USER_ROLE_SOA_ADMIN)) && (
        <MenuItemLink
          to={`/courses`}
          primaryText={translate(`resources.courses.name`, {
            smart_count: 2,
          })}
          leftIcon={<courses.icon />}
          sidebarIsOpen={open}
          dense={dense}
          onClick={onMenuClick}
        />
      )}
      {(permissions.includes(USER_ROLE_SUPER_ADMIN) ||
        permissions.includes(USER_ROLE_ORGANIZATION_ADMIN)) && (
        <MenuItemLink
          to={`/organizations`}
          primaryText={translate(`resources.organizations.name`, {
            smart_count: 2,
          })}
          leftIcon={<organizations.icon />}
          sidebarIsOpen={open}
          dense={dense}
          onClick={onMenuClick}
        />
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <MenuItemLink
          to={`/users`}
          primaryText={translate(`resources.users.name`, {
            smart_count: 2,
          })}
          leftIcon={<users.icon />}
          sidebarIsOpen={open}
          dense={dense}
          onClick={onMenuClick}
        />
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <MenuItemLink
          to={`/reference_data`}
          primaryText={translate(`resources.reference_data.name`, {
            smart_count: 2,
          })}
          leftIcon={<referenceData.icon />}
          sidebarIsOpen={open}
          dense={dense}
          onClick={onMenuClick}
        />
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <MenuItemLink
          to={`/wizards`}
          primaryText={translate(`resources.wizards.name`)}
          leftIcon={<wizards.icon />}
          sidebarIsOpen={open}
          dense={dense}
          onClick={onMenuClick}
        />
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <MenuItemLink
          to="/leads"
          primaryText={translate("resources.leads.name")}
          leftIcon={<leads.icon />}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <MenuItemLink
          to="/contacts"
          primaryText={translate("resources.contacts.name")}
          leftIcon={<contacts.icon />}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <MenuItemLink
          to="/knowledge_base"
          primaryText={translate("resources.knowledge_base.name")}
          leftIcon={<knowledgeBase.icon />}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <MenuItemLink
          to="/it"
          primaryText={`IT`}
          leftIcon={<Security />}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate("pos.configuration")}
          leftIcon={<Settings />}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
      {permissions.includes("guest") && (
        <MenuItemLink
          to="/company-register"
          primaryText={`Company Register`}
          leftIcon={<organizations.icon />}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
    </Box>
  );
};

export default Menu;
