import * as React from "react";
import { FC } from "react";
import { Edit, ShowProps } from "react-admin";
import SchoolForm from "./SchoolForm";

const SchoolEdit: FC<ShowProps> = ({ ...props }) => {
  return (
    <Edit {...props} actions={<></>} title={" "}>
      <SchoolForm {...props} action="edit" />
    </Edit>
  );
};

export default SchoolEdit;
