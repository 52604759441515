import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  BoxTitle,
  ReferenceArrayAutocompleteInputField,
  ReferenceStudyFieldCategories,
} from "../components";

const UserRelationFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`users.boxes.relation_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference={"user_looking_fors"}
              source={"user_looking_for_ids"}
              sort={{ field: "title", order: "ASC" }}
              optionText={"title"}
              optionValue={"id"}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference={"school_types"}
              source={"school_type_ids_looking"}
              sort={{ field: "title", order: "ASC" }}
              optionText={"title"}
              optionValue={"id"}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference={"degree_types"}
              source={"degree_type_ids_looking"}
              sort={{ field: "title", order: "ASC" }}
              optionText={"title"}
              optionValue={"id"}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceStudyFieldCategories
              disabled={disabled_level_1}
              source={"study_field_category_ids_looking"}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default UserRelationFields;
