import { TranslationMessages } from "react-admin";
import germanMessages from "ra-language-german";

const customGermanMessages: TranslationMessages = {
  ...germanMessages,
  pos: {
    search: "Suche",
    configuration: "Konfiguration",
    language: "Sprache",
    model_locale: "Modelleinrichtung",
    email: "Email-Adresse",
  },
  button: {
    refreshSelected: "Ausgewählte neu laden",
    loadSelected: "Ausgewählte laden",
    reply: "Antwort aktualisieren",
    restore: "Wiederherstellen",
    delete: "Löschen",
    validate: "Validate", // TODO
    submit: "Submit", // TODO
    reset: "Reset", // TODO
    regist: "Regist", // TODO
    reset_email: "Reset", // TODO
    login: "Login", // TODO
  },
  title: {
    create_tag: "Tag erstellen",
    create_study_field: "Studienfeld erstellen",
  },
  common: {
    fields: {
      created_at: "Erstellt",
      last_update: "Letztes Update",
    },
    not_available: "Nicht verfügbar",
    agree: "Annehmen",
    disagree: "Ablehnen",
    accept: "Annehmen",
    cancel: "Canceln",
    submit: "Absenden",
    restore: "Wiederherstellen",
  },
  sentence: {
    images_premium_courses:
      "Das Logo und Hintergrundbild sind nur für Premium Studiengänge verfügbar.",
    delete_with_name: "Lösche %{name}",
    confirm_all: "Bitte bestätige, wenn:",
    show_in: "Sprachversion:",
    edit_in: "Bearbeiten in:",
    more_filters: "Mehr Filters",
    less_filters: "Weniger Filters",
    reply: "Antworten aktualisieren",
    reply_placeholder: "Fügen Sie eine Antwort ein",
    create_success_story_warning:
      "Beim Erfassen einer neuen Erfolgsgeschichte muss diese erfasst und einmal gespeichert werden. Erst danach kann das entsprechende Foto hinzugefügt werden. Vielen Dank.",
    login_info_title: "Noch kein Backend-Login?",
    login_info_body_part1: "Registrieren Sie sich bitte zuerst unter ",
    login_info_body_part2:
      " als normaler User und melden Sie sich danach gerne bei ",
    login_info_body_part3:
      " damit wir Ihnen die entsprechenden Berechtigungen zuweisen können.",
    login_info_body_part4:
      "Wenn Sie bereits einen Zugang haben, aber das Passwort vergessen haben, dann können Sie es hier zurücksetzen: ",
    login_email_subject: "Registration Admin-Bereich",
    login_email_body:
      "Guten Tag eduwo-Team,%0D%0A%0D%0AIch habe ein normales User-Profil auf eduwo erstellt und möchte nun bitte Zugang zum Admin-Backend.%0D%0A%0D%0AVielen Dank und herzliche Grüsse",
    search_related_user_begin:
      "Um einen User als Experten hinzufügen zu können, muss er/sie bereits ein User-Profil besitzen. Der User kann sich dazu unter",
    search_related_user_end: "registrieren.",
    school_cities_warning:
      "Dieses Feld kann auch leer gelassen werden.\nWenn dieses Feld leer ist, dann werden auf dem Schulprofil alle Städte angezeigt, die bei den Lehrgängen als Orte hinterlegt sind.",
    restore_content: "Möchten Sie dieses Element wirklich wiederherstellen?",
    organization_infobox:
      "Sie können hier mehrere Infoboxen mit jeweils einem Titel und einer Beschreibung auf Ihrem Profil platzieren.",
    organization_teaser:
      "Sie haben die Möglichkeit einen Teaser mit einem Titel, einer Beschreibung und einem Call-to-Action Link zu platzieren.",
    organization_focus_1: "Eine Übersicht aller Schulen finden Sie hier:",
    organization_focus_2:
      "Und eine Übersicht aller Studienbereich (High level) und Studienrichtungen (Deep dive) finden Sie hier:",
    focus_topics:
      "Hier können Sie hinterlegen, auf welche Themen Sie als Unternehmen fokussiert sind bzw. in welchen Themen Sie Auskunft bieten können.",
    only_premium: "Diese Funktion steht nur Premiumkunden zur Verfügung.",
    infobox_warning:
      "Falls Ihre Schule spitzensportfreundliche Angebote hat, sind in den untenstehenden Infoboxen entsprechende Informationen in Zusammenarbeit mit Swiss Olympic aufgeführt worden um potentiellen Studierende darüber zu informieren. Wir bitten Sie, diese Informationen nicht anzupassen oder zu entfernen. Bei Fragen oder Anpassungswünschen können Sie sich sehr gerne direkt an uns wenden.",
    course_locations: "Es können pro Lehrgang mehrere Orte hinterlegt werden.",
    admmission_description:
      "Dies ist ein Textfeld und an keine Bedingungen geknüpft. Was eingegeben wird, ist auf dem Profil ersichtlich.",
    admmission_conditions_description:
      "An diese Felder sind Bedingungen für die Filterung geknüpft. Diese sind wichtig für die Leadgenerierung. Je weniger Berufserfahrung/tiefer das Alter, desto mehr Personen können gematcht werden.",
    school_is_updated:
      "When empty, the school is considered not to be updated after 6 months without any update.", // TODO
    course_is_updated:
      "When empty, the course is considered not to be updated after expiration of the degree type validations.", // TODO
    employee_copy_link:
      "Bitte kopieren Sie den folgenden Link und geben Sie ihn an Ihre Mitarbeiter weiter, um sich als verifizierter Mitarbeiter zu registrieren.",
    employee_official_email:
      "All users with this official email address will be considered automatically verified", // TODO
  },
  dashboard: {
    title: "Herzlich Willkommen im Admin-Bereich von eduwo",
    user_name: "Ihr Name:",
    role: "Ihre Rolle:",
    admin_schools: "Ihnen zugewiesen Organisation(en)",
    explanation: {
      title: "Erklärung",
      body: "In diesem Backend können Sie als Administrator*In diverse Änderungen an den Ihnen zugewiesenen Profilen vornehmen. Falls Sie Unterstützung benötigen, erreichen Sie uns unter 079 278 32 94 oder per Mail an raphael@eduwo.ch.\nGrau unterlegte Bereich können Sie nicht anpassen. Diese können nur von eduwo angepasst werden. Wenn Sie der Meinung sind, dass in diesem Bereich etwas angepasst werden müssen, senden Sie uns bitte eine kurze E-Mail an raphael@eduwo.ch.",
    },
    premium_profile: {
      title: "Premiumprofil",
      body: "Wenn Sie Ihr Profil nicht selber verwalten möchten, dürfen Sie uns jederzeit eine Nachricht mit den Änderungswünschen an patrick@eduwo.ch senden und wir nehmen die Anpassungen sehr gerne für Sie vor.",
    },
    non_premium_profile: {
      title: "Noch kein Premiumprofil?",
      body: "Sofern Ihre Schule bei eduwo noch kein Premiumprofil hat, dann werden auf dem öffentlichen Profil nicht alle hier im Admin-Bereich sichtbaren Bereiche angezeigt. Dies ist Premiumkunden vorenthalten. Falls Sie sich Gedanken zu einem Premiumprofil machen, stehen wir Ihnen gerne unter 079 278 32 94 oder raphael@eduwo.ch zur Verfügung.",
    },
  },
  languages: {
    de: "Deutsch",
    fr: "Französisch",
    en: "Englisch",
    it: "Italian",
  },
  resources: {
    schools: {
      name: "Schulen",
      name_singular: "Schule",
      create: "Schule erstellen",
      add: "Schule hinzufügen",
    },
    courses: {
      name: "Studiengänge",
      name_singular: "Studiengang",
      create: "Studiengang erstellen",
      add: "Studiengang hinzufügen",
    },
    organizations: {
      name: "Organisationen",
      name_singular: "Organisation",
      create: "Organisation erstellen",
      add: "Organisation hinzufügen",
    },
    users: {
      name: "Nutzer",
      name_singular: "Nutzer",
      create: "Nutzer erstellen",
      add: "Nutzer hinzufügen",
    },
    experts: {
      name: "Experten",
      name_singular: "Experte",
      create: "Experte erstellen",
      add: "Experte hinzufügen",
    },
    administrators: {
      name: "Administratoren",
      name_singular: "Administrator",
      create: "Administrator erstellen",
      add: "Administrator hinzufügen",
    },
    reference_data: {
      name: "Referenzdaten",
      name_singular: "Referenzdaten",
      create: "Referenzdaten erstellen",
      add: "Referenzdaten hinzufügen",
    },
    school_types: {
      name: "Schularten",
      name_singular: "Schulart",
      create: "Schulart erstellen",
      add: "Schulart hinzufügen",
    },
    soa_school_types: {
      name: "SOA Schultyp",
      name_singular: "SOA Schultyp",
      create: "SOA Schultyp erstellen",
      add: "SOA Schultyp hinzufügen",
    },
    soa_school_levels: {
      name: "SOA Schullevel",
      name_singular: "SOA Schullevel",
      create: "SOA Schullevel erstellen",
      add: "SOA Schullevel hinzufügen",
    },
    cities: {
      name: "Städte",
      name_singular: "Stadt",
      create: "Stadt erstellen",
      add: "Stadt hinzufügen",
    },
    districts: {
      name: "Bezirke",
      name_singular: "Bezirk",
      create: "Bezirk erstellen",
      add: "Bezirk hinzufügen",
    },
    regions: {
      name: "Region",
      name_singular: "Region",
      create: "Region erstellen",
      add: "Region hinzufügen",
    },
    countries: {
      name: "Countries",
      name_singular: "Country",
      create: "Create Country",
      add: "Add Country",
    },
    study_types: {
      name: "Studientypen",
      name_singular: "Studientyp",
      create: "Studientyp erstellen",
      add: "Studientyp hinzufügen",
    },
    degree_types: {
      name: "Abschlusstypen",
      name_singular: "Abschlusstyp",
      create: "Abschlusstyp erstellen",
      add: "Abschlusstyp hinzufügen",
    },
    school_events: {
      name: "Schulevents",
      name_singular: "Schulevent",
      create: "Schulevent erstellen",
      add: "Schulevent hinzufügen",
    },
    course_events: {
      name: "Studiengangevents",
      name_singular: "Studiengangevent",
      create: "Studiengangevent erstellen",
      add: "Schulevent hinzufügen",
    },
    organization_events: {
      name: "Veranstaltungen der Organisation",
      name_singular: "Veranstaltung der Organisation",
      create: "Erstelle Organisationsveranstaltung",
      add: "Füge Organisationsveranstaltung hinzu",
    },
    events: {
      name: "Veranstaltungen",
      name_singular: "Veranstaltung",
      create: "Erstelle Veranstaltung",
      add: "Füge Veranstaltung hinzu",
    },
    organization_types: {
      name: "Organisationsarten",
      name_singular: "Organisationsart",
      create: "Organisationsarten erstellen",
      add: "Organisationsarten hinzufügen",
    },
    leads: {
      name: "Leads",
      name_singular: "Lead",
      create: "Lead erstellen",
      add: "Lead hinzufügen",
    },
    course_languages: {
      name: "Studiengangssprachen",
      name_singular: "Studiengangssprache",
      create: "Studiengangssprache erstellen",
      add: "Studiengangssprache hinzufügen",
    },
    course_costs: {
      name: "Kosten der Studiengänge",
      name_singular: "Kosten des Studiengangs",
      create: "Kosten des Studiengangs erstellen",
      add: "Kosten des Studiengangs hinzufügen",
    },
    course_durations: {
      name: "Dauer der Studinengänge",
      name_singular: "Dauer des Studinengangs",
      create: "Dauer des Studinengangs erstellen",
      add: "Dauer des Studinengangs hinzufügen",
    },
    study_fields: {
      name: "Studienbereiche",
      name_singular: "Studienbereich",
      create: "Studienbereich erstellen",
      add: "Studienbereich hinzufügen",
    },
    sub_study_fields: {
      name: "Sub Studienbereiche",
      name_singular: "Sub Studienbereich",
      create: "Sub Studienbereich erstellen",
      add: "Sub Studienbereich hinzufügen",
    },
    study_field_categories: {
      name: "Studienrichtungen",
      name_singular: "Studienrichtungen",
      create: "Erstelle Studienrichtungen",
      add: "Füge Studienrichtungen hinzu",
    },
    tags: {
      name: "Tags",
      name_singular: "Tag",
      create: "Erstelle Tag",
      add: "Füge Tag",
    },
    legal_forms: {
      name: "Rechtliche Formulare",
      name_singular: "Rechtliches Formular",
      create: "Erstelle Rechtliche Formulare",
      add: "Füge Rechtliche Formulare",
    },
    branches: {
      name: "Abteilungen",
      name_singular: "Abteilung",
      create: "Erstelle Abteilungen",
      add: "Füge Abteilungen",
    },
    awards: {
      name: "Auszeichnungen",
      name_singular: "Auszeichnung",
      create: "Erstelle Auszeichnung",
      add: "Füge Auszeichnung hinzu",
    },
    job_types: {
      name: "Jobtypen",
      name_singular: "Jobtyp",
      create: "Erstelle Jobtyp",
      add: "Füge Jobtyp",
    },
    success_stories: {
      name: "Erfolgsgeschichten",
      name_singular: "Erfolgsgeschichte",
      create: "Erstelle Erfolgsgeschichte",
      add: "Füge Erfolgsgeschichte hinzu",
    },
    languages: {
      name: "Sprachen",
      name_singular: "Sprache",
      create: "Kreiere Sprachen",
      add: "Füge Sprachen hinzu",
    },
    user_looking_fors: {
      name: "Benutzer suchen nach",
      name_singular: "Benutzer sucht nach",
      create: "Kreiere Benutzer sucht nach",
      add: "Füge Benutzer sucht nach hinzu",
    },
    sport_types: {
      name: "Sportarten",
      name_singular: "Sportart",
      create: "Kreiere Sportart",
      add: "Füge Sportart hinzu",
    },
    initial_languages: {
      name: "Ausgangsspraches",
      name_singular: "Ausgangssprache",
      create: "Kreiere Ausgangssprache",
      add: "Füge Ausgangssprache",
    },
    publicities: {
      name: "Anzeige",
      name_singular: "Anzeige",
      create: "Kreiere Anzeige",
      add: "Füge Anzeige",
    },
    advertisement_categories: {
      name: "Anzeigenkategorien",
      name_singular: "Anzeigenkategorie",
      create: "Kreiere Anzeigenkategorie",
      add: "Füge Anzeigenkategorie",
    },
    user_degree_types: {
      name: "Arten von Benutzerabschlüssen",
      name_singular: "Art des Benutzerabschlusses",
      create: "Kreiere Art des Benutzerabschlusses",
      add: "Füge Art des Benutzerabschlusses",
    },
    work_experiences: {
      name: "Work Experiences", // TODO
      name_singular: "Work Experience", // TODO
      create: "Create Work Experience", // TODO
      add: "Add Work Experience", // TODO
    },
    contacts: {
      name: "Contacts", // TODO
      name_singular: "Contact", // TODO
      create: "Create Contact", // TODO
      add: "Add Contact", // TODO
    },
    wizards: {
      name: "Wizards", // TODO
      name_singular: "Wizard", // TODO
      create: "Create Wizard", // TODO
      add: "Add Wizard", // TODO
    },
    knowledge_base: {
      name: "Knowledge Bases", // TODO
      name_singular: "Knowledge Base", // TODO
      create: "Create Knowledge Base", // TODO
      add: "Add Knowledge Base", // TODO
    },
    kb_modules: {
      name: "Modules", // TODO
      name_singular: "Module", // TODO
      create: "Create Module", // TODO
      add: "Add Module", // TODO
    },
    kb_sections: {
      name: "Sections", // TODO
      name_singular: "Section", // TODO
      create: "Create Section", // TODO
      add: "Add Section", // TODO
    },
    kb_units: {
      name: "Units", // TODO
      name_singular: "Unit", // TODO
      create: "Create Unit", // TODO
      add: "Add Unit", // TODO
    },
  },
  schools: {
    fields: {
      show_link: "Zeige Link",
      has_phone_number_mandatory: "Telefonnummer als Pflichtfeld",
      has_events_open_spots: "Anmeldung für Events",
      has_course_reservations: "Platz reservieren",
      has_courses_open_spots: "Anmeldung",
      has_counseling: "Beratung",
      info_material: "Infomaterial",
      amount_eduwo_reimbursements: "Höhe Rückvergütung Eduwo",
      has_eduwo_reimbursements: "Verfügbarkeit Rückvergütungen eduwo",
      amount_of_subsidies: "Höhe Bundesbeitrag",
      has_government_subsidies: "Möglichkeit der Rückvergütung durch Bund",
      name: "Name",
      subtitle: "Untertitel",
      abbreviation: "Abkürzung",
      slug: "Slug",
      description: "Beschreibung",
      founding_year: "Gründungsjahr",
      students_count: "Anzahl Studierende",
      logo_image: "Logo",
      profile_image: "Profilbild",
      premium: "Premium",
      soa_elite_sport: "Spitzensport",
      reviews_section: "Erfahrungsberichte",
      only_verified: "Nur verifizierte",
      soa_financing_type: "SOA Finanzierungsart",
      soa_students_count: "SOA Studierendenanzahl",
      soa_certification_year: "SOA Zertifizierungsjahr",
      phone: "Telefon",
      fax: "Fax",
      public_email: "Öffentliche Email",
      qa_email: "Email für Fragen",
      reviews_email: "Email für Erfahrungsberichte",
      requests_email: "Email für Anfragen",
      street: "Strasse",
      postcode: "Postleitzahl",
      cities: "Städte",
      city: "Städte",
      site_url: "URL der Webseite",
      youtube_url: "Youtube URL",
      facebook_url: "Facebook URL",
      twitter_url: "Twitter URL",
      instagram_url: "Instagram URL",
      rss: "RSS",
      youtube_video: "Youtube Video",
      facebook_embed_code: "Facebook - Code",
      twitter_embed_code: "Twitter - Code",
      contact_person_name: "Name der Kontaktperson",
      contact_person_email: "Email der Kontaktperson",
      contact_person_phone: "Telefonnummer der Kontaktperson",
      contact_person_position: "Stellenbeschreibung der Kontaktperson",
      contact_person_image: "Bild der Kontaktperson",
      infobox: { title: "Titel", content: "Inhalt" },
      infobox_soa: { title: "Titel", content: "Inhalt" },
      online_courses: "Onlinekurse",
      updated_at: "Aktualisiert am",
      pay_per_lead: "Zahle per Lead",
      deleted_at: "Gelöscht am",
      sport_facility: "Sporteinrichtungen",
      food_facility: "Verpflegungseinrichtungen",
      sport_facility_description: "Sporteinrichtungen Beschreibung",
      // A comment just to stay with the same line number
      food_facility_description: "Verpflegungseinrichtungen Beschreibung",
      email: "email",
      has_course_starting_date: "Has Starting Data Mandatory",
      is_updated: "Is Updated", // TODO
      cc_email: "CC Email", // TODO
      has_address_mandatory: "Address als Pflichtfeld", //TODO
      has_gender_mandatory: "Gender als Pflichtfeld", //TODO
      sales_force_email: "Sales Force Email", //TODO
    },
    boxes: {
      reimbursement_fields: "Rückvergütung",
      lead_management_fields: "Lead Management",
      general: "Allgemein",
      soa: "SOA",
      contact_fields: "Kontakte",
      social_network_fields: "Soziale Netzwerke",
      contact_person_fields: "Kontaktperson",
      infobox: "Infofeld",
      relation_fields: "Beziehung",
      gallery: "Bildgalerie",
      uploads: "PDF Upload",
      locations: "Standorte",
      infobox_soa: "Infofeld SOA",
    },
    tabs: {
      summary: "Zusammenfassung",
      images: "Bilder",
      courses: "Studiengänge",
      success_stories: "Erfolgsstories",
      administrators: "Administratoren",
      events: "Events",
      statistics: "Statistik",
      experts: "Alumni / Experten",
      employees: "Employees / Experten",
      leads: "Leads",
      reviews: "Erfahrungsberichte",
    },
  },
  courses: {
    fields: {
      show_link: "Zeige Link",
      short_name: "Name",
      full_name: "Offizieller Name",
      slug: "Slug",
      subtitle: "Untertitel",
      premium: "Premium",
      only_verified: "Nur Verifizierte",
      description: "Beschreibung",
      admission: "Zulassungsbedingungen",
      duration_description: "Dauer Beschreibung",
      cost_description: "Kosten Beschreibung",
      study_begins_at: "Studienbeginn",
      registration_fees: "Einschreibegebühr",
      registration_deadline: "Letztes Anmeldedatum",
      ects_credits: "ECTS",
      info_material: "Infomaterial",
      question_block: "Fragenblock",
      logo_image: "Logo",
      background_image: "Hintergrundbild",
      public_email: "Öffentliche Email",
      qa_email: "Email für Fragen",
      reviews_email: "Email für Erfahrungsberichte",
      requests_email: "Email für Anfragen",
      street: "Strasse",
      postcode: "Postleitzahl",
      city: "Stadt",
      director_name: "Name des Kursleiters",
      director_email: "Email des Kursleiters",
      director_phone: "Telefonnummer des des Kursleiters",
      director_image: "Bild des Kursleiters",
      director_position: "Position des Kursleiters",
      site_url: "URL",
      youtube_video: "Youtube Video",
      facebook_embed: "Facebook - Code",
      twitter_embed: "Twitter - Code",
      has_government_subsidies: "Möglichkeit der Rückvergütung durch Bund",
      amount_of_subsidies: "Höhe Bundesbeitrag",
      has_eduwo_reimbursements: "Verfügbarkeit Rückvergütungen eduwo",
      amount_eduwo_reimbursements: "Höhe Rückvergütung Eduwo",
      has_counseling: "Beratung",
      has_courses_open_spots: "Anmeldung",
      has_course_reservations: "Platz reservieren",
      has_events_open_spots: "Anmeldung für Events",
      infobox: { title: "Titel", content: "Inhalt" },
      infobox_soa: { title: "Titel", content: "Inhalt" },
      start_dates: {
        start_date: "Startdatum",
        taking_place_definitely: "Definitive Durchführung",
        has_open_spots: "Verfügbarkeit",
      },
      onsite_course: "Kurse vor Ort",
      online_course: "Onlinekurse",
      updated_at: "Aktualisiert am",
      pay_per_lead: "Zahle per Lead",
      deleted_at: "Gelöscht am",
      hidde_updated: "Ausgeblendet Aktualisiert",
      email: "Email",
      cities: "Städte",
      has_phone_number_mandatory: "Telefonnummer als Pflichtfeld",
      has_course_starting_date: "Has Starting Data Mandatory",
      minimum_age: "Mindestalter für Zulassung",
      is_updated: "Is Updated", // TODO
      has_address_mandatory: "Address als Pflichtfeld", //TODO
      has_gender_mandatory: "Gender als Pflichtfeld", //TODO
      sales_force_code: "Sales Force Code", // TODO
      sales_force_email: "Sales Force Email", // TODO
      is_public: "Is Public", // TODO
      inhouse_course: "Inhouse Course", // TODO
    },
    boxes: {
      lead_management_fields: "Lead Management",
      general: "Allgemein",
      contact_fields: "Kontakte",
      director_fields: "Studienleiter",
      social_network_fields: "Soziale Netzwerke",
      reimbursement_fields: "Rückvergütung",
      relation_fields: "Beziehung",
      infobox: "Infofeld",
      gallery: "Bildgalerie",
      uploads: "PDF Upload",
      start_dates: "Startdaten",
      locations: "Kurs-Orte",
      description: "Beschreibung",
      admission: "Zulassung",
      adminission_conditions: "Zulassungskriterien für das Datenbank-Matching",
      infobox_soa: "Infofeld SOA",
    },
    tabs: {
      summary: "Zusammenfassung",
      images: "Bilder",
      success_stories: "Erfolgsgeschichten",
      administrators: "Administratoren",
      events: "Events",
      statistics: "Statistik",
      experts: "Alumni / Experten",
      leads: "Leads",
      relations: "Beziehungen",
      reviews: "Erfahrungsberichte",
    },
  },
  school_events: {
    fields: {
      starts_on: "Startdatum",
      ends_on: "Enddatum",
      published: "Veröffentlicht",
      title: "Titel",
      description: "Beschreibung",
      link: "Link",
      slug: "Slug",
      location: "Standort",
    },
  },
  course_events: {
    fields: {
      starts_on: "Startdatum",
      ends_on: "Enddatum",
      published: "Veröffentlicht",
      title: "Titel",
      description: "Beschreibung",
      link: "Link",
      slug: "Slug",
      location: "Standort",
    },
  },
  organization_events: {
    fields: {
      starts_on: "Startdatum",
      ends_on: "Enddatum",
      published: "Veröffentlicht",
      title: "Titel",
      description: "Beschreibung",
      link: "Link",
      slug: "Slug",
      location: "Standort",
    },
  },
  users: {
    fields: {
      id: "ID",
      title: "Titel",
      first_name: "Vorname",
      last_name: "Nachname",
      email: "Email",
      verified: "Verifiziert",
      is_interested_in_ubs_promo: "An ubs Promo interessiert",
      password: "Passwort",
      remember_token: "remember_token",
      created_at: "Erstellt am",
      updated_at: "Aktualisiert am",
      role: "Rolle",
      deleted_at: "Gelöscht am",
      gender: "Geschlecht",
      birthday: "Geburtstag",
      uid: "UID",
      phone: "Telefonnummer",
      street: "Strasse",
      zip: "Postleitzahl",
      known: "bekannt",
      provider: "Provider",
      social_id: "Social ID",
      city_id: "Stadt ID",
      language: "Sprache",
      is_expert: "Experten",
      expert_biography: "Biographie",
      expert_motivation: "Motivation",
      expert_linkedin_url: "Linkedin URL",
      expert_facebook_url: "Facebook URL",
      expert_twitter_url: "Twitter URL",
      slug: "Slug",
      premium: "Premium",
      price: "Preis",
      professional: "Professionell",
      pay_fee: "Kostensatz",
      user_looking_for_id: "user_looking_for_id",
      views: "Views",
      form_contacts: "Kontakte",
      compare_count: "Anzahl im Vergleich",
      login_count: "Anzahl Logins",
      order_infomaterial: "Infomaterialbestellung",
      user_degree_type_id: "User Degree Type ID",
      job_title: "Jobtitel",
      organization_id: "Organization ID",
      public_url: "Öffentliche URL",
      sport_type: "Sportart",
      photo: "Foto",
      background_picture: "Hintergrundbild",
      user_type: "Type",
      user_status: "Status",
    },
    boxes: {
      general: "Generell",
      contact_fields: "Kontakte",
      social_login: "Social Login",
      expert_fields: "Fachgebiete",
      relation_fields: "Beziehungsfelder",
    },
    tabs: {
      summary: "Zusammenfassung",
      reviews: "Erfahrungsberichte",
    },
  },
  statistics: {
    parameters: "Parameter",
    eduwo: { title: "Eduwo Statistik" },
    schools: { courses: { title: "Statistik von Schulen und Studiengängen" } },
  },
  organizations: {
    boxes: {
      general: "Allgemein",
      contact_fields: "Kontakte",
      social_network_fields: "Soziale Netzwerke",
      contact_person_fields: "Kontaktperson",
      infobox: "Infofeld",
      focus: "Fokus",
      follow: "Folgen",
      teaser_study_field: "Teaser Studienfeld",
      teaser: "Teaser",
      gallery: "Bildgalerie",
      uploads: "PDF Upload",
      locations: "Orte",
      relation_fields: "Beziehungsfelder",
      dashboard_fields: "Dashboard", // TODO
      dashboard_questions: "Dashboard Questions", // TODO
      faqs: "Faqs", // TODO
      dashboard_approval_process: "Approval Process", // TODO
      dashboard_job_finder: "Job Fields", // TODO
      dashbard_recommended_courses: "Favourite Courses",
    },
    fields: {
      name: "Name",
      short_name: "Abkürzung",
      subtitle: "Untertitel",
      slug: "Slug",
      premium: "Premium",
      only_verified: "Nur Verifizierte",
      public: "Öffentlich",
      description: "Beschreibung",
      number_of_employees: "Anzahl Mitarbeiter",
      foundation_year: "Gründungsjahr",
      turnover: "Umsatz",
      site_url: "URL",
      public_email: "Öffentliche Email",
      hr_email: "HR Email",
      youtube_account_url: "Youtube Account URL",
      facebook_account_url: "Facebook Account URL",
      twitter_account_url: "Twitter Account URL",
      instagram_account_url: "Instagram Account URL",
      linkedin_account_url: "Linkedin Account URL",
      video_url: "Video URL",
      contact_person_image: "Bild der Kontaktperson",
      contact_person_name: "Name der Kontaktperson",
      contact_person_email: "Email der Kontaktperson",
      contact_person_phone: "Telefonnummer der Kontaktperson",
      contact_person_position: "Position der Kontaktperson",
      follow_expert_tab: "Expertentab Anzeige von Tabs",
      follow_school_tab: "Schultab Anzeige von Tabs",
      follow_organization_tab: "Organisationstab Anzeige von Tabs",
      follow_course_tab: "Studiengangstab Anzeige von Tabs",
      follow_schools_description: "Folge Schulen Beschreibung",
      follow_courses_description: "Folge Kursen Beschreibung",
      // A comment just to stay with the same line number
      follow_organizations_description: "Folge Organisationsbeschreibung",
      background_image: "Hintergrundbild",
      logo_image: "Logo",
      info_box: { title: "Titel", content: "Inhalt" },
      teaser: { title: "Title", link: "Link", description: "Beschreibung" },
      teaser_study_field: { title: "Titel", lead: "Lead" },
      locations: {
        street: "Strasse",
        city: "Stadt",
        public_email: "Öffentliche Email",
        website: "Webseite",
      },
      focus_topics: {
        name: "Fokus-Themen",
        topic: "Thema",
        percentage: "Prozentzahl",
      },
      updated_at: "Aktualisiert am",
      deleted_at: "Gelöscht am",
      allow_external_experts: "Allow external experts", // TODO
      allow_coach_experts: "Allow coaches", // TODO
      show_dashboard_dashboard: "Show Dashboard", // TODO
      show_dashboard_knowledge_base: "Show Knowledge Base", // TODO
      show_dashboard_search: "Show Search", // TODO
      show_dashboard_network: "Show Network", // TODO
      show_dashboard_applications: "Show Applications", // TODO
      questions: {
        question_type: "Question type", // TODO
        style: "Question style", // TODO
        question: "Question", // TODO
        description: "Question description", // TODO
        placeholder: "Placeholder", // TODO
        data: "Question data", // TODO
        mandatory: "Mandatory", // TODO
        dataFields: { title: "Option" },
      },
      search_only_premium: "Search Only Premium", // TODO
      booking_only_premium: "Booking Only Premium", // TODO
      faqs: {
        question: "Question", // TODO
        answer: "Answer", // TODODO
      },
      employee_email: "Official email", // TODO
      meeting_link: "Meetlink Link", // TODO
      booking_stages: {
        order: "Order", // TODO
        type: "Type", // TODO
        approver: "Approver", // TODO
      },
      job_fields: {
        title: "Title",
      },
    },
    tabs: {
      summary: "Zusammenfassung",
      success_stories: "Erfolgsggeschichten",
      administrators: "Administratoren",
      events: "Events",
      statistics: "Statistik",
      experts: "Employees / Experten",
      matching: "Matching",
      dashboard: "Dashboard",
    },
  },
  leads: {
    fields: {
      message: "Nachricht",
      title: "Titel",
      request_order: "Bestellung nachfragen",
      created_at: "Erstellungsdatum",
      receiver: "Empfänger",
      sender: "Absender",
      date: "Datum",
      bulk: "Infomaterialbestellformular",
      main_order: "Hauptbestellung",
      course_name: "Name des Studiengangs",
      school_name: "Name der Schule",
      schools_and_courses: "Schule und Studiengänge",
      total_receiver: "Gesamtempfänger",
      start_date: "Anfangsdatum",
      end_date: "Enddatum",
      iban: "IBAN",
      term: "Begriff",
      total_inquiries: "Direct contacts", // TODO
      total_bulks: "Contacts through wizard", // TODO
      inquiry_course_ids_count: "Inquiry Course Ids Count", // TODO
      inquiry_unique_course_ids_count:
        "Total unique courses contacted directly", // TODO
      bulk_course_ids_count: "Bulk Course Ids Count", // TODO
      bulk_unique_course_ids_count:
        "Total unique courses contacted through wizard", // TODO
      total_courses: "Total courses in this category", // TODO
      inquiry_course_ids_sum: "Top 30 courses direct contacted", // TODO
      bulk_course_ids_sum: "Top 30 courses contacted through wizard", // TODO
      bulk_same_course_ids_count:
        "Total unique courses contacted through wizard", // TODO
      bulk_same_school_ids: "Total unique schools contacted through wizard", // TODO
      inquiry_unique_school_ids_count:
        "Total unique schools contacted directly", // TODO
      bulk_same_school_ids_count:
        "Total unique schools contacted through wizard", // TODO
      inquiry_school_ids_sum: "Top 30 schools direct contacted", // TODO
      bulk_school_ids_sum: "Top 30 schools contacted through wizard", // TODO
      inquiry_main_order: "Top direct contacted orders", // TODO
      bulk_main_order: "Top direct contacted through wizard", // TODO

      premium: "Premium", // TODO
      pay_per_lead: "Pay Per Lead", // TODO
      infomaterial: "Infomaterial", // TODO
      only_pending: "Only Pending", // TODO
      hide_archived: "Hide Archived", // TODO
    },
    summary: {
      title: "Zusammenfassung",
      total: "Total",
      total_schools: "Total Schulen",
      total_courses: "Total Studiengänge",
      total_anmeldung: "Total Anmeldungen",
      total_beratungsgespräch: "Total Beratungsgespräche",
      total_infotag: "Total Infotage",
      total_infomaterial: "Total Infomaterialbestellungen",
      total_unknown: "Total Unbekannt",
      // Tab
      name: "Summary",
    },
    list: {
      title: "Leads",
    },
    inquiries: {
      name: "Einzelleads",
    },
    bulk: {
      name: "Sammelleads",
    },
    reimbursement: {
      name: "Rückerstattung",
    },
  },
  course_success_stories: {
    fields: {
      title: "Titel",
      first_name: "Vorname",
      last_name: "Nachname",
      description: "Beschreibung",
      slug: "URL-Slug",
      photo: "Photo", // TODO
    },
  },
  school_success_stories: {
    fields: {
      title: "Titel",
      first_name: "Vorname",
      last_name: "Nachname",
      description: "Beschreibung",
      slug: "URL-Slug",
      photo: "Photo", // TODO
    },
  },
  organization_success_stories: {
    fields: {
      title: "Titel",
      first_name: "Vorname",
      last_name: "Nachname",
      description: "Beschreibung",
      slug: "URL-Slug",
      photo: "Photo", // TODO
    },
  },
  experts: {
    fields: {
      verified: "Verifiziert",
      is_expert: "Expert", // TODO
      graduation_year: "Abschlussjahr",
      job_title: "Berufsbezeichnung",
    },
  },
  reference_data: {
    tabs: {
      awards: "Auszeichnungen",
    },
    fields: {
      title: "Title", // TODO
    },
  },
  awards: {
    fields: {
      private_title: "Privater Titel",
      title: "Titel",
      type: "Type",
      description: "Beschreibung",
      icon: "Symbol",
      url: "URL",
      can_change_by_admin: "Can change by admin", // TODO
    },
  },
  study_field_categories: {
    fields: {
      title: "Titel",
      slug: "Slug",
      description: "Beschreibung",
      popular: "beliebt",
      order: "Bestellen",
      faqs: {
        question: "Question", // TODO
        answer: "Answer", // TODO
      },
    },
  },
  study_fields: {
    fields: {
      title: "Titel",
      slug: "Slug",
      description: "Beschreibung",
      popular: "beliebt",
      icon: "Symbol",
      faqs: {
        question: "Question", // TODO
        answer: "Answer", // TODO
      },
    },
  },
  sub_study_fields: {
    fields: {
      title: "Titel",
      slug: "Slug",
      description: "Beschreibung",
      popular: "beliebt",
      icon: "Symbol",
      faqs: {
        question: "Question", // TODO
        answer: "Answer", // TODO
      },
    },
  },
  school_types: {
    fields: {
      title: "Titel",
      slug: "Slug",
      description: "Beschreibung",
    },
  },
  reviews: {
    fields: {
      year: "Jahr",
      approved: "Genehmigt",
      created_at: "Erstellt am",
      rating_avg: "Durchschnittsbewertung",
      rating_content: "Bewertungsinhalt",
      rating_environment: "Bewertungsumfeld",
      rating_equipment: "Rating Ausrüstung",
      rating_organisation: "Organisationsbewertung",
      rating_teachers: "Bewertung Dozierende",
      recommend: "Empfehlen",
      title: "Titel",
      message: "Nachricht",
      pros: "Pro",
      cons: "Contra",
      reply: "Antworten",
      rating_communication: "Kommunikationsbewertung",
      rating_competence: "Kompetenzenbewertung",
      rating_performance: "Performancebewertung",
    },
  },
  publicities: {
    fields: {
      link: "link", // TODO
    },
  },
  degree_types: {
    fields: {
      title: "Title", // TODO
      validation_month: "Validation Month", // TODO
      order: "Order", // TODO
    },
  },
  user_degree_types: {
    fields: {
      title: "Title", // TODO
      order: "Order", // TODO
      degree_type: "Diese Abschlusstypen werden empfohlen", // TODO
      exclude_degree_type:
        "Diese Abschlusstypen machen wenig Sinn (ausschliessen/exclude)", // TODO
    },
  },
  course_costs: {
    fields: {
      title: "Title", // TODO
    },
  },
  course_durations: {
    fields: {
      title: "Title", // TODO
    },
  },
  course_languages: {
    fields: {
      title: "Title", // TODO
    },
  },
  cities: {
    fields: {
      title: "Title", // TODO
    },
  },
  districts: {
    fields: {
      title: "Title", // TODO
    },
  },
  regions: {
    fields: {
      title: "Title", // TODO
    },
  },
  countries: {
    fields: {
      title: "Title", // TODO
    },
  },
  study_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  branches: {
    fields: {
      title: "Title", // TODO
    },
  },
  job_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  organization_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  soa_school_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  work_experiences: {
    fields: {
      title: "Title", // TODO
    },
  },
  contacts: {
    fields: {
      first_name: "First Name", // TODO
      last_name: "Last Name", // TODO
      email: "Email", // TODO
      source: "Source", // TODO
      total_price: "Total Price", // TODO
      total_schools: "Total Schools", // TODO
      total_courses: "Total Courses", // TODO
      created_at: "Created At", // TODO
      school_name: "School Name", // TODO
      premium: "P", // TODO
      pay_per_lead: "PPL", // TODO
      info_material: "IM", // TODO
      fix_fee: "FF", // TODO
      pay_per_view: "PPV", // TODO
      no_contract: "NC", // TODO
      rejected_by_api: "RAPI", // TODO
      rejected_manually: "RM", // TODO
      approved_pay_per_view: "APPV", // TODO

      name: "User Name", // TODO
      phone: "Phone Number", // TODO
      street: "Street", // TODO
      zip: "Zip Code", // TODO
      city: "City", // TODO
      job_title: "Job Title", // TODO
      work_experience: "Work Experience", // TODO
      year_of_birth: "Year Of Birth", // TODO
      user_degree_types: "User Degree Types", // TODO

      lead_contact_option: "Main Lead", // TODO
      lead_contact_options: "Lead Options", // TODO
      model: "Model", // TODO
      criteria: "Criteria", // TODO

      to_email: "Email", // TODO
      cc_email: "CC", // TODO
      status: "Status", // TODO
      price: "Price", // TODO
      archived: "Archived", // TODO
      anonymous: "A", // TODO

      total_contacts: "Total Contacts", // TODO
      total_sent: "Total Sent", // TODO
      total_billable: "Total Billable", // TODO
      total_sent_anonymous: "Total Sent Anonymous", // TODO
      total_rejected: "Total Rejected", // TODO

      total: "T", // TODO
      rejected: "R", // TODO
      pending: "Pe", // TODO
      total_total: "Total", // TODO
      warnings: "Warnings",
      dangers: "Dangers",
      contacts_count: "Contacts Count", // TODO
      pendings_count: "Pendings Count", // TODO
      last_contact: "Last Contact", // TODO
      birthday_day: "Birthday Day", // TODO
      birthday_month: "Birthday Month", // TODO
      birthday_year: "Birthday Year", // TODO
      user_type: "User Type", // TODO
      user_status: "User Status", // TODO
      cc_emails: "CC email", // TODO
      details: "Details", // TODO
      freshData: "Fresh Data", // TODO
    },
  },
  wizards: {
    fields: {
      slug: "Slug", // TODO
      title: "Title", // TODO
      sub_title: "Subtitle", // TODO
      description: "Description", // TODO
      page: "Page", // TODO
      style: "Style", // TODO
      step: "Step", // TODO
      field: "Field", // TODO
      placeholder: "Placeholder", // TODO
      mandatory: "Mandatory", // TODO
      remove_zero_courses: "Remove options with zero courses", // TODO
      order_by: "Options Order", // TODO
      related_field: "Related Field", // TODO
      total_courses: "Show Total Courses", // TODO
      minimum: "Minimum Selection", // TODO
      maximum: "Maximum", // TODO
      columns: "Number of Columns", // TODO
      data_with_total_courses: "Show Total Courses per Option", // TODO
      data: "Options Data", // TODO
    },
    tabs: {
      summary: "General", // TODO
    },
  },
  register: {
    user: {
      email: {
        placeholder: "Please, insert your email address", // TODO
        label: "Email", // TODO
      },
      password: {
        placeholder: "Please, insert your password", // TODO
        label: "Password", // TODO
      },
      confirm_password: {
        placeholder: "Confirm your password", // TODO
        label: "Password", // TODO
      },
      first_name: {
        placeholder: "Please, insert your first name", // TODO
        label: "First Name", // TODO
      },
      last_name: {
        placeholder: "Please, insert your last name", // TODO
        label: "Last Name", // TODO
      },
      job_title: {
        placeholder: "Please, insert your job position", // TODO
        label: "Job Position", // TODO
      },
      phone: {
        placeholder: "Please, insert your phone number", // TODO
        label: "Phone", // TODO
      },
    },
    company: {},
  },
  kb_modules: {
    fields: {
      title: "Title", // TODO
      description: "Description", // TODO
    },
  },
  kb_sections: {
    fields: {
      title: "Title", // TODO
      description: "Description", // TODO
    },
  },
  kb_units: {
    fields: {
      title: "Title", // TODO
      description: "Description", // TODO
      video_url: "Video URL", // TODO
    },
  },
  todos: {
    fields: {
      user: "User",
      comment: "Comment",
      created_at: "Erstellt",
      created_by: "Created by",
      priority: "Priority",
      due_date: "Due date",
      start_date: "Startdatum",
      done: "Done",
      term: "Term",
      first_name: "Vorname",
      last_name: "Nachname",
      email: "Email",
    },
  },
};

export default customGermanMessages;
