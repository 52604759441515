import * as React from "react";
import { useTranslate, usePermissions } from "react-admin";
import {
  BoxTitle,
  ReferenceTags,
  ReferenceAwards,
  ReferenceAwardsSchoolAdmin,
} from "../components";
import { Grid, Collapse } from "@material-ui/core";
import { USER_ROLE_SUPER_ADMIN, USER_ROLE_SCHOOL_ADMIN } from "../Constants";

const SchoolRelationFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  const { permissions = [] } = usePermissions();

  return (
    <>
      <BoxTitle
        title={translate(`schools.boxes.relation_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ReferenceTags disabled={disabled_level_2} />
          </Grid>
          {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <ReferenceAwards disabled={disabled_level_1} />
              </Grid>
            </Grid>
          )}
          {permissions.includes(USER_ROLE_SCHOOL_ADMIN) && (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <ReferenceAwardsSchoolAdmin disabled={disabled_level_2} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </>
  );
};

export default SchoolRelationFields;
