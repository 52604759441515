import * as React from "react";
import { Labeled, useTranslate } from "react-admin";
import Button from "@material-ui/core/Button";
import { DeleteForever } from "@material-ui/icons";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { ConfirmationModal } from "../components";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#b71c1c",
    },
  },
});

const DeleteButtonWitIcon = ({ handleClick }) => {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    handleClick();
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Labeled label={" "}>
        <Button
          variant="contained"
          onClick={() => handleClickOpen()}
          color="secondary"
        >
          <DeleteForever />
        </Button>
      </Labeled>
      <ConfirmationModal
        open={open}
        handleClose={() => handleClose()}
        handleAgree={() => handleAgree()}
        handleDisagree={() => handleClose()}
        message={translate(`ra.message.delete_content`)}
        title={translate(`ra.action.delete`)}
      />
    </ThemeProvider>
  );
};

export default DeleteButtonWitIcon;
