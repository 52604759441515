import * as React from "react";
import { FormWithRedirect, ReferenceInput, SelectInput } from "react-admin";
import { Grid, Box } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { DateInputField, BarChart } from "../components";
import ContactProvider from "./ContactProvider";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ContactGraph = ({ start_date, end_date, school_id }: any) => {
  const [data, setData] = React.useState<any>(undefined);
  const [doRequest, setDoRequest] = React.useState<boolean>(true);
  const [currentFilters, setCurrentFilter] = React.useState<{
    start_date: string | undefined;
    end_date: string | undefined;
    school_id: string | undefined;
  }>({
    start_date: undefined,
    end_date: undefined,
    school_id: undefined,
  });
  const filters = {
    start_date: start_date,
    end_date: end_date,
    school_id: school_id,
  };

  if (
    currentFilters.start_date !== filters.start_date ||
    currentFilters.end_date !== filters.end_date ||
    currentFilters.school_id !== filters.school_id
  ) {
    setDoRequest(true);
  }

  if (doRequest) {
    setDoRequest(false);
    setCurrentFilter(filters);
    ContactProvider.statistics(filters).then((json: any) => {
      setData(json.data);
    });
  }

  var dataContact = {};
  var dataContactSource = {};
  var dataContactType = {};
  var labels = {};
  var contactSourceTypes = {};
  var contactLeadTypes = {};
  if (data) {
    labels = Object.keys(data).map((e, i) => {
      return e;
    });
    dataContact = [
      {
        label: "Total",
        data: Object.keys(data).map((e, i) => {
          return data[e]["total"];
        }),
      },
      {
        label: "Billable",
        data: Object.keys(data).map((e, i) => {
          return data[e]["billable"];
        }),
      },
      {
        label: "Premium",
        data: Object.keys(data).map((e, i) => {
          return data[e]["premium"];
        }),
      },
      {
        label: "Pending",
        data: Object.keys(data).map((e, i) => {
          return data[e]["pending"];
        }),
      },
      {
        label: "Rejected",
        data: Object.keys(data).map((e, i) => {
          return data[e]["rejected"];
        }),
      },
      {
        label: "Rejected",
        data: Object.keys(data).map((e, i) => {
          return data[e]["anonymous"];
        }),
      },
    ];

    contactSourceTypes = Array.from(
      new Set(
        Object.keys(data)
          .map((e, i) => {
            return Object.keys(data[e]["total_contacts"]).map((e, i) => {
              return e;
            });
          })
          .flat()
      )
    );

    contactLeadTypes = Array.from(
      new Set(
        Object.keys(data)
          .map((e, i) => {
            return Object.keys(data[e]["total_leads"]).map((e, i) => {
              return e;
            });
          })
          .flat()
      )
    );

    // @ts-ignore
    dataContactSource = contactSourceTypes.map((e, i) => {
      const sourceData = Object.keys(data).map((e2, i) => {
        return data[e2]["total_contacts"][e]
          ? data[e2]["total_contacts"][e]["total"]
          : 0;
      });
      return { label: e, data: sourceData };
    });
    // @ts-ignore
    dataContactType = contactLeadTypes.map((e, i) => {
      const sourceData = Object.keys(data).map((e2, i) => {
        return data[e2]["total_leads"][e]
          ? data[e2]["total_leads"][e]["total"]
          : 0;
      });
      return { label: e, data: sourceData };
    });
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {data && (
            <BarChart title="Contacts" data={dataContact} labels={labels} />
          )}
          {data && (
            <BarChart
              title="Contacts Source"
              data={dataContactSource}
              labels={labels}
            />
          )}
          {data && (
            <BarChart
              title="Contacts Type"
              data={dataContactType}
              labels={labels}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

const ContactStatistics = ({ ...props }: any) => {
  const classes = useStyles();
  const [end_date, setEndDate] = React.useState(undefined);
  const today = new Date();
  const last_month = new Date(
    today.getFullYear(),
    today.getMonth() - 6,
    today.getDate()
  );
  const [start_date, setStartDate] = React.useState<Date | undefined>(
    last_month
  );
  const [school_id, setSchoolId] = React.useState(undefined);

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ReferenceInput
                perPage={1000}
                source="school_id"
                reference="schools"
                sort={{ field: "name", order: "ASC" }}
                filter={{
                  show_only: ["id", "name_and_abbreviation"],
                }}
                inputProps={{
                  onChange: (e) => {
                    if (e.target.value === "") {
                      setSchoolId(undefined);
                    } else {
                      setSchoolId(e.target.value);
                    }
                  },
                }}
                allowEmpty={true}
                fullWidth
              >
                <SelectInput
                  optionText="name_and_abbreviation"
                  optionValue="id"
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <DateInputField
                resource={"leads"}
                source={"start_date"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setStartDate(undefined);
                  } else {
                    setStartDate(value);
                  }
                }}
                allowEmpty={true}
                defaultValue={start_date}
              />
            </Grid>
            <Grid item xs={6}>
              <DateInputField
                resource={"leads"}
                source={"end_date"}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value === "") {
                    setEndDate(undefined);
                  } else {
                    setEndDate(value);
                  }
                }}
                allowEmpty={true}
              />
            </Grid>
          </Grid>
          <ContactGraph
            end_date={end_date}
            start_date={start_date}
            school_id={school_id}
          />
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default ContactStatistics;
