import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BooleanInputField,
  BoxTitle,
  RichTextInputField,
  NumberInputField,
  ReferenceSelectInputField,
  ReferenceArrayAutocompleteInputField,
  LogoBackgroundImages,
} from "../components";

const OrganizationGeneralFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.general`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <LogoBackgroundImages
          resource={props.resource}
          disabled={disabled_level_2}
          item_logo={props.record.logo_image}
          item_background={props.record.background_image}
          {...props}
        />
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item xs={6} sm={4}>
            <BooleanInputField
              source="premium"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <BooleanInputField
              source="only_verified"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <BooleanInputField
              source="public"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <BooleanInputField
              source="allow_external_experts"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <BooleanInputField
              source="allow_coach_experts"
              resource={props.resource}
              disabled={disabled_level_2}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <TextInputField
              source="name"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
              validate={true}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputField
              source="short_name"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputField
              source="subtitle"
              resource={props.resource}
              record={props.record}
              translation={true}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputField
              source="slug"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
              validate={true}
              translation={true}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextInputField
              source="description"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ReferenceSelectInputField
              source="organization_type_id"
              reference="organization_types"
              optionText="title"
              optionValue="id"
              disabled={disabled_level_1}
              inputProps={null}
              validate={true}
              allowEmpty={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ReferenceSelectInputField
              source="branch_id"
              reference="branches"
              optionText="title"
              optionValue="id"
              disabled={disabled_level_2}
              inputProps={null}
              allowEmpty={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ReferenceSelectInputField
              source="city_id"
              reference="cities"
              optionText="title"
              optionValue="id"
              disabled={disabled_level_2}
              inputProps={null}
              allowEmpty={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ReferenceSelectInputField
              source="legal_form_id"
              reference="legal_forms"
              optionText="title"
              optionValue="id"
              disabled={disabled_level_2}
              inputProps={null}
              allowEmpty={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <NumberInputField
              source="number_of_employees"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <NumberInputField
              source="foundation_year"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TextInputField
              source="turnover"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference="job_types"
              source="job_type_ids"
              sort={{ field: "title", order: "ASC" }}
              optionText="title"
              optionValue="id"
              disabled={disabled_level_2}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationGeneralFields;
