import * as React from "react";
import { ShowView } from "react-admin";
import { CourseTitle } from "./CourseForm";
import { LeadsTabs } from "../leads/Leads";

const CourseLeads = ({ disabled_level_1, disabled_level_2, ...props }: any) => {
  return (
        <ShowView
          {...props}
          component="div"
          title={<CourseTitle />}
          actions={null}
        >
          <LeadsTabs course_id_input={props.id} {...props} />
        </ShowView>
  );
};

export default CourseLeads;
