import * as React from "react";
import { useRef } from "react";
import {
  ReferenceManyField,
  SimpleShowLayout,
  Datagrid,
  TextField,
  NumberField,
  ShowView,
  useRefresh,
  Labeled,
  useTranslate,
  BooleanField,
  ReferenceField,
  DateField,
} from "react-admin";
import { ListPagination } from "../components";
import {
  Grid,
  Switch,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as TextFieldMaterial,
} from "@material-ui/core";
import { apiUrl, httpClient } from "../dataProvider";
import { ButtonStyle } from "../styles/ButtonStyle";
import { Check, Clear } from "@material-ui/icons/";

const ReviewsList = ({
  target, //Example: "related_school_id"
  reference,
  title,
  resources,
  model_id,
  canApprove,
  canReply,
  isExpert = false,
  ...props
}: any) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const classes = ButtonStyle();
  const [openReply, setOpenReply] = React.useState(false);

  const replyRef = useRef("");

  const handleClickOpen = () => {
    setOpenReply(true);
  };

  const handleClickClose = () => {
    setOpenReply(false);
  };

  const ReviewExpand = (props) => {
    const [approved, setApproved] = React.useState(
      props !== null &&
        props !== undefined &&
        props.record !== null &&
        props.record !== undefined &&
        props.record.approved === true
    );

    const handleSubmitReply = async () => {
      const url = `${apiUrl}/${resources}/${model_id}/reviews/${props.record.id}/reply_review`;
      return httpClient(url, {
        method: "POST",
        // @ts-ignore
        body: JSON.stringify({ reply: replyRef.current.value }),
      }).then(({ headers, json }: any) => {
        setOpenReply(false);
        refresh();
      });
    };

    const handleChangeApproved = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setApproved(event.target.checked);
      const url = `${apiUrl}/${resources}/${model_id}/reviews/${props.record.id}/approve_review`;
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify({ approved: event.target.checked }),
      }).then(({ headers, json }: any) => {
        refresh();
      });
    };

    return (
      <Grid container spacing={1}>
        {isExpert === false && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Labeled label={translate(`${reference}.fields.rating_content`)}>
                <Typography>
                  {props !== null &&
                  props !== undefined &&
                  props.record !== null &&
                  props.record !== undefined
                    ? props.record.rating_content
                    : ``}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Labeled
                label={translate(`${reference}.fields.rating_environment`)}
              >
                <Typography>
                  {props !== null &&
                  props !== undefined &&
                  props.record !== null &&
                  props.record !== undefined
                    ? props.record.rating_environment
                    : ``}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Labeled
                label={translate(`${reference}.fields.rating_equipment`)}
              >
                <Typography>
                  {props !== null &&
                  props !== undefined &&
                  props.record !== null &&
                  props.record !== undefined
                    ? props.record.rating_equipment
                    : ``}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Labeled
                label={translate(`${reference}.fields.rating_organisation`)}
              >
                <Typography>
                  {props !== null &&
                  props !== undefined &&
                  props.record !== null &&
                  props.record !== undefined
                    ? props.record.rating_organisation
                    : ``}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Labeled label={translate(`${reference}.fields.rating_teachers`)}>
                <Typography>
                  {props !== null &&
                  props !== undefined &&
                  props.record !== null &&
                  props.record !== undefined
                    ? props.record.rating_teachers
                    : ``}
                </Typography>
              </Labeled>
            </Grid>
          </>
        )}
        {isExpert === true && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Labeled
                label={translate(`${reference}.fields.rating_communication`)}
              >
                <Typography>
                  {props !== null &&
                  props !== undefined &&
                  props.record !== null &&
                  props.record !== undefined
                    ? props.record.rating_communication
                    : ``}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Labeled
                label={translate(`${reference}.fields.rating_competence`)}
              >
                <Typography>
                  {props !== null &&
                  props !== undefined &&
                  props.record !== null &&
                  props.record !== undefined
                    ? props.record.rating_competence
                    : ``}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Labeled
                label={translate(`${reference}.fields.rating_performance`)}
              >
                <Typography>
                  {props !== null &&
                  props !== undefined &&
                  props.record !== null &&
                  props.record !== undefined
                    ? props.record.rating_performance
                    : ``}
                </Typography>
              </Labeled>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Labeled label={translate(`${reference}.fields.recommend`)}>
            <Typography>
              {props !== null &&
              props !== undefined &&
              props.record !== null &&
              props.record !== undefined ? (
                props.record.recommend ? (
                  <Check />
                ) : (
                  <Clear />
                )
              ) : (
                ``
              )}
            </Typography>
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={translate(`${reference}.fields.title`)}>
            <Typography>
              {props !== null &&
              props !== undefined &&
              props.record !== null &&
              props.record !== undefined
                ? props.record.title
                : ``}
            </Typography>
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled label={translate(`${reference}.fields.message`)}>
            <Typography>
              {props !== null &&
              props !== undefined &&
              props.record !== null &&
              props.record !== undefined
                ? props.record.message
                : ``}
            </Typography>
          </Labeled>
        </Grid>
        {isExpert === false && (
          <>
            <Grid item xs={12}>
              <Labeled label={translate(`${reference}.fields.pros`)}>
                <Typography>
                  {props !== null &&
                  props !== undefined &&
                  props.record !== null &&
                  props.record !== undefined
                    ? props.record.pros
                    : ``}
                </Typography>
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <Labeled label={translate(`${reference}.fields.cons`)}>
                <Typography>
                  {props !== null &&
                  props !== undefined &&
                  props.record !== null &&
                  props.record !== undefined
                    ? props.record.cons
                    : ``}
                </Typography>
              </Labeled>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Labeled label={translate(`${reference}.fields.reply`)}>
            <Typography>
              {props !== null &&
              props !== undefined &&
              props.record !== null &&
              props.record !== undefined
                ? props.record.reply
                : ``}
            </Typography>
          </Labeled>
        </Grid>
        {canReply === true && (
          <Grid item xs={6}>
            <Button
              onClick={() => handleClickOpen()}
              className={classes.containedButton}
            >
              {translate(`button.reply`)}
            </Button>
          </Grid>
        )}
        {canApprove === true && (
          <Grid item xs={6}>
            <Labeled label={translate(`${reference}.fields.approved`)}>
              <Switch
                checked={approved}
                onChange={handleChangeApproved}
                color="primary"
                name="approved"
                inputProps={{ "aria-label": "primary checkbox" }}
                disabled={!canApprove}
              />
            </Labeled>
          </Grid>
        )}
        <Dialog
          open={openReply}
          onClose={() => handleClickClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            {translate(`sentence.reply`)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextFieldMaterial
                    inputRef={replyRef}
                    fullWidth={true}
                    multiline={true}
                    defaultValue={
                      props !== null &&
                      props !== undefined &&
                      props.record !== null &&
                      props.record !== undefined
                        ? props.record.reply
                        : ``
                    }
                    placeholder={translate(`sentence.reply_placeholder`)}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmitReply}
              color="primary"
              autoFocus
              variant={"contained"}
            >
              {translate(`ra.action.save`)}
            </Button>
            <Button
              onClick={() => handleClickClose()}
              color="primary"
              autoFocus
            >
              {translate(`ra.action.close`)}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  };

  return (
    <div>
      <ShowView {...props} component="div" title={title} actions={<></>}>
        <SimpleShowLayout>
          <ReferenceManyField
            label=""
            reference={isExpert ? "all_expert_reviews" : "all_reviews"}
            target={target}
            pagination={<ListPagination />}
            sort={{ field: "created_at", order: "DESC" }}
          >
            <Datagrid rowClick={undefined} expand={<ReviewExpand {...props} />}>
              <ReferenceField
                source="user_id"
                reference="users"
                label={translate(`users.fields.first_name`)}
              >
                <TextField source="first_name" />
              </ReferenceField>
              {isExpert === false && (
                <ReferenceField
                  source="course_id"
                  reference="courses"
                  label={translate(`courses.fields.short_name`)}
                  link="show"
                >
                  <TextField source="short_name" />
                </ReferenceField>
              )}
              {canReply === true && (
                <NumberField
                  source="year"
                  label={translate(`reviews.fields.year`)}
                />
              )}
              <NumberField
                source="rating_avg"
                label={translate(`reviews.fields.rating_avg`)}
              />
              <BooleanField
                source="approved"
                label={translate(`reviews.fields.approved`)}
              />
              <DateField
                source="created_at"
                label={translate(`reviews.fields.created_at`)}
              />
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      </ShowView>
    </div>
  );
};

export default ReviewsList;
