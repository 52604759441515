import * as React from "react";
import { FC } from "react";
// eslint-disable-next-line
import { TextInput, useTranslate, regex, required } from "react-admin";
import { TextProps } from "./types";
import { Grid } from "@material-ui/core";
import { TranslationButton } from ".";
import InputAdornment from "@material-ui/core/InputAdornment";

const TextInputSiteField: FC<TextProps> = (props) => {
  const translate = useTranslate();
  const regex_site = regex(
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
    "Must be a valid URL"
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInput
          source={props.source}
          label={
            props.label ?? translate(`${props.resource}.fields.${props.source}`)
          }
          resource={props.resource}
          record={props.record}
          fullWidth
          disabled={props.disabled}
          multiline={props.multiline}
          validate={props.validate ? [required(), regex_site] : regex_site}
          InputProps={
            props.translation
              ? {
                  endAdornment: (
                    <InputAdornment position="start">
                      <TranslationButton {...props} />
                    </InputAdornment>
                  ),
                }
              : {}
          }
          helperText={props.helperText ?? <p></p>}
        />
      </Grid>
    </Grid>
  );
};

export default TextInputSiteField;
