import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import { BoxTitle, TextInputSiteField } from "../components";

const OrganizationSocialNetworkFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.social_network_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <TextInputSiteField
              source="site_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputSiteField
              source="youtube_account_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputSiteField
              source="facebook_account_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputSiteField
              source="twitter_account_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputSiteField
              source="instagram_account_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputSiteField
              source="linkedin_account_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
              translation={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInputSiteField
              source="video_url"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
              translation={true}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationSocialNetworkFields;
