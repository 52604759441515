import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    model_locale: "Model Locale",
    email: "Email Address",
  },
  button: {
    refreshSelected: "Refresh Selected",
    loadSelected: "Load Selected",
    reply: "Update reply",
    restore: "Restore",
    delete: "Delete",
    validate: "Validate", // TODO
    submit: "Submit", // TODO
    reset: "Reset", // TODO
    regist: "Regist", // TODO
    reset_email: "Reset", // TODO
    login: "Login", // TODO
  },
  title: {
    create_tag: "Create Tag",
    create_study_field: "Create Study Field",
  },
  common: {
    fields: {
      created_at: "Created",
      last_update: "Last Update",
    },
    not_available: "Not Available",
    agree: "Agree",
    disagree: "Disagree",
    accept: "Accept",
    cancel: "Cancel",
    submit: "Submit",
    restore: "Restore",
  },
  sentence: {
    images_premium_courses:
      "The logo and background images are visible only for premium courses.",
    delete_with_name: "Delete %{name}",
    confirm_all: "Please confirm if:",
    show_in: "Show in:",
    edit_in: "Edit in:",
    more_filters: "More Filters",
    less_filters: "Less Filters",
    reply: "Reply",
    reply_placeholder: "Please insert your reply",
    create_success_story_warning:
      "When writing a new success story, it must be created and saved once. Only then can the corresponding photo be added. Thank you.",
    login_info_title: "No backend login yet?",
    login_info_body_part1: "Please register first at ",
    login_info_body_part2:
      " as a normal user and feel free to log in afterwards ",
    login_info_body_part3:
      " so that we can assign you the appropriate authorizations.",
    login_info_body_part4:
      "Wenn Sie bereits einen Zugang haben, aber das Passwort vergessen haben, dann können Sie es hier zurücksetzen: ",
    login_email_subject: "Registration Admin-Bereich", // TODO
    login_email_body:
      "Guten Tag eduwo-Team,%0D%0A%0D%0AIch habe ein normales User-Profil auf eduwo erstellt und möchte nun bitte Zugang zum Admin-Backend.%0D%0A%0D%0AVielen Dank und herzliche Grüsse",
    search_related_user_begin:
      "To add a user as an expert, he/she must already have a user profile. The user can register at",
    search_related_user_end: ".",
    school_cities_warning:
      "This field can also be left blank.\nIf this field is empty, all cities that are stored as locations in the courses are displayed on the school profile.",
    restore_content: "Are you sure you want to restore this item?",
    organization_infobox:
      "Here you can place multiple infoboxes on your profile, each with a title and description.",
    organization_teaser:
      "You have the option of placing a teaser with a title, description, and a call-to-action link.",
    organization_focus_1: "Eine Übersicht aller Schulen finden Sie hier:", // TODO
    organization_focus_2:
      "Und eine Übersicht aller Studienbereich (High level) und Studienrichtungen (Deep dive) finden Sie hier:", // TODO
    focus_topics:
      "Here you can save topics that you focus on as a business or topics that you can offer information on.",
    only_premium: "This function is only available to premium customers.",
    infobox_warning:
      "If your school has top sport-friendly offers, the corresponding information has been listed in the info boxes below in cooperation with Swiss Olympic in order to inform potential students about it. We ask you not to adapt or remove this information. Please feel free to contact us directly if you have any questions or would like to make any changes.",
    course_locations: "Several locations can be entered for each course.",
    admmission_description:
      "This is a text field and is not subject to any conditions. What is entered can be seen on the profile.",
    admmission_conditions_description:
      "Conditions for filtering are attached to these fields. These are important for lead generation. The less work experience/lower the age, the more people can be matched.",
    school_is_updated:
      "When empty, the school is considered not to be updated after 6 months without any update.",
    course_is_updated:
      "When empty, the course is considered not to be updated after expiration of the degree type validations.",
    valid_email: "Please insert a valid email.", // TODO
    employee_copy_link:
      "Please copy the following link and share it with your employees to be registered as a verified employee.",
    employee_official_email:
      "All users with this official email address will be considered automatically verified", // TODO
  },
  dashboard: {
    title: "Welcome to the eduwo admin area",
    user_name: "Your name:",
    role: "Your role:",
    admin_schools: "Organization(s) assigned to you",
    explanation: {
      title: "Statement",
      body: "In this backend, as an administrator, you can make various changes to the profiles assigned to you. If you need support, you can reach us at 079 278 32 94 or by email to raphael@eduwo.ch.  NThe area highlighted in gray cannot be changed . These can only be adjusted by eduwo. If you are of the opinion that something needs to be adjusted in this area, please send us a short e-mail to raphael@eduwo.ch. ",
    },
    premium_profile: {
      title: "Premium Profile",
      body: "If you do not want to manage your profile yourself, you can send us a message at any time with the change requests to patrick@eduwo.ch and we will be happy to make the changes for you.",
    },
    non_premium_profile: {
      title: "No premium profile yet?",
      body: "If your school does not yet have a premium profile on eduwo, then not all areas visible here in the admin area will be displayed on the public profile. This is reserved for premium customers. If you are thinking about a premium profile, please do not hesitate to contact us at 079 278 32 94 or raphael@eduwo.ch.",
    },
  },
  languages: {
    de: "German",
    fr: "French",
    en: "English",
    it: "Italian",
  },
  resources: {
    schools: {
      name: "Schools",
      name_singular: "School",
      create: "Create School",
      add: "Add School",
    },
    courses: {
      name: "Courses",
      name_singular: "Course",
      create: "Create Course",
      add: "Add Course",
    },
    organizations: {
      name: "Organizations",
      name_singular: "Organization",
      create: "Create Organization",
      add: "Add Organization",
    },
    users: {
      name: "Users",
      name_singular: "User",
      create: "Create User",
      add: "Add User",
    },
    experts: {
      name: "Experts",
      name_singular: "Expert",
      create: "Create Expert",
      add: "Add Expert",
    },
    administrators: {
      name: "Administrators",
      name_singular: "Administrator",
      create: "Create Administrator",
      add: "Add Administrator",
    },
    reference_data: {
      name: "Reference Data",
      name_singular: "Reference Data",
      create: "Create Reference Data",
      add: "Add Reference Data",
    },
    school_types: {
      name: "School Types",
      name_singular: "School Type",
      create: "Create School Type",
      add: "Add School Type",
    },
    soa_school_types: {
      name: "Soa School Types",
      name_singular: "Soa School Type",
      create: "Create Soa School Type",
      add: "Add Soa School Type",
    },
    soa_school_levels: {
      name: "Soa School Levels",
      name_singular: "Soa School Level",
      create: "Create Soa School Level",
      add: "Add Soa School Level",
    },
    cities: {
      name: "Cities",
      name_singular: "City",
      create: "Create City",
      add: "Add City",
    },
    districts: {
      name: "Districts",
      name_singular: "District",
      create: "Create District",
      add: "Add District",
    },
    regions: {
      name: "Regions",
      name_singular: "Region",
      create: "Create Region",
      add: "Add Region",
    },
    countries: {
      name: "Countries", // TODO
      name_singular: "Country", // TODO
      create: "Create Country", // TODO
      add: "Add Country", // TODO
    },
    study_types: {
      name: "Study Types",
      name_singular: "Study Type",
      create: "Create Study Type",
      add: "Add Study Type",
    },
    degree_types: {
      name: "Degree Types",
      name_singular: "Degree Type",
      create: "Create Degree Type",
      add: "Add Degree Type",
    },
    school_events: {
      name: "School Events",
      name_singular: "School Event",
      create: "Create School Event",
      add: "Add Schoool Event",
    },
    course_events: {
      name: "Course Events",
      name_singular: "Course Event",
      create: "Create Course Event",
      add: "Add Course Event",
    },
    organization_events: {
      name: "Organization Events",
      name_singular: "Organization Event",
      create: "Create Organization Event",
      add: "Add Organization Event",
    },
    events: {
      name: "Events",
      name_singular: "Event",
      create: "Create Event",
      add: "Add Event",
    },
    organization_types: {
      name: "Organization Types",
      name_singular: "Organization Type",
      create: "Create Organization Type",
      add: "Add Organization Type",
    },
    leads: {
      name: "Leads",
      name_singular: "Lead",
      create: "Create Lead",
      add: "Add Lead",
    },
    course_languages: {
      name: "Course Languages",
      name_singular: "Course Language",
      create: "Create Course Language",
      add: "Add Course Language",
    },
    course_costs: {
      name: "Course Costs",
      name_singular: "Course Cost",
      create: "Create Course Cost",
      add: "Add Course Cost",
    },
    course_durations: {
      name: "Course Durations",
      name_singular: "Course Duration",
      create: "Create Course Duration",
      add: "Add Course Duration",
    },
    study_fields: {
      name: "Study Fields",
      name_singular: "Study Field",
      create: "Create Study Field",
      add: "Add Study Field",
    },
    sub_study_fields: {
      name: "Sub Study Fields",
      name_singular: "Sub Study Field",
      create: "Create Sub Study Field",
      add: "Add Sub Study Field",
    },
    study_field_categories: {
      name: "Study Field Categories",
      name_singular: "Study Field Category",
      create: "Create Study Field Category",
      add: "Add Study Field Category",
    },
    tags: {
      name: "Tags",
      name_singular: "Tag",
      create: "Create Tag",
      add: "Add Tag",
    },
    legal_forms: {
      name: "Legal Forms",
      name_singular: "Legal Form",
      create: "Create Legal Form",
      add: "Add Legal Form",
    },
    branches: {
      name: "Branches",
      name_singular: "Branch",
      create: "Create Branch",
      add: "Add Branch",
    },
    awards: {
      name: "Awards",
      name_singular: "Award",
      create: "Create Award",
      add: "Add Award",
    },
    job_types: {
      name: "Job Types",
      name_singular: "Job Type",
      create: "Create Job Type",
      add: "Add Job Type",
    },
    success_stories: {
      name: "Success Stories",
      name_singular: "Success Story",
      create: "Create Success Story",
      add: "Add Success Story",
    },
    languages: {
      name: "Languages",
      name_singular: "Language",
      create: "Create Language",
      add: "Add Languages",
    },
    user_looking_fors: {
      name: "User Looking For",
      name_singular: "User Looking For",
      create: "Create User Looking For",
      add: "Add User Looking For",
    },
    sport_types: {
      name: "Sport Types",
      name_singular: "Sport Type",
      create: "Create Sport Type",
      add: "Add Sport Type",
    },
    initial_languages: {
      name: "Initial Languages",
      name_singular: "Initial Language",
      create: "Create Initial Language",
      add: "Add Initial Language",
    },
    publicities: {
      name: "Advertisements", // TODO
      name_singular: "Advertisement", // TODO
      create: "Create Advertisement", // TODO
      add: "Add Advertisement", // TODO
    },
    advertisement_categories: {
      name: "Advertisement Categories", // TODO
      name_singular: "Advertisement Category", // TODO
      create: "Create Advertisement Category", // TODO
      add: "Add Advertisement Category", // TODO
    },
    user_degree_types: {
      name: "User Degree Types",
      name_singular: "User Degree Type",
      create: "Create User Degree Type",
      add: "Add User Degree Type",
    },
    work_experiences: {
      name: "Work Experiences",
      name_singular: "Work Experience",
      create: "Create Work Experience",
      add: "Add Work Experience",
    },
    contacts: {
      name: "Contacts",
      name_singular: "Contact",
      create: "Create Contact",
      add: "Add Contact",
    },
    wizards: {
      name: "Wizards", // TODO
      name_singular: "Wizard", // TODO
      create: "Create Wizard", // TODO
      add: "Add Wizard", // TODO
    },
    knowledge_base: {
      name: "Knowledge Bases", // TODO
      name_singular: "Knowledge Base", // TODO
      create: "Create Knowledge Base", // TODO
      add: "Add Knowledge Base", // TODO
    },
    kb_modules: {
      name: "Modules", // TODO
      name_singular: "Module", // TODO
      create: "Create Module", // TODO
      add: "Add Module", // TODO
    },
    kb_sections: {
      name: "Sections", // TODO
      name_singular: "Section", // TODO
      create: "Create Section", // TODO
      add: "Add Section", // TODO
    },
    kb_units: {
      name: "Units", // TODO
      name_singular: "Unit", // TODO
      create: "Create Unit", // TODO
      add: "Add Unit", // TODO
    },
  },
  schools: {
    fields: {
      show_link: "Show button",
      has_phone_number_mandatory: "Has Phone Number Mandatory",
      has_events_open_spots: "Has Events Open Spots",
      has_course_reservations: "Has Course Reservations",
      has_courses_open_spots: "Has Courses Open Spots",
      has_counseling: "Has Counseling",
      info_material: "Info Material",
      amount_eduwo_reimbursements: "Amount Eduwo Reimbursements",
      has_eduwo_reimbursements: "Has Eduwo Reimbursements",
      amount_of_subsidies: "Amount Of Subsidies",
      has_government_subsidies: "Has Government Subsidies",
      name: "Name",
      subtitle: "Subtitle",
      abbreviation: "Abbreviation",
      slug: "Slug",
      description: "Description",
      founding_year: "Founding Year",
      students_count: "Students Count",
      logo_image: "Logo Image",
      profile_image: "Profile Image",
      premium: "Premium",
      soa_elite_sport: "Soa Elite Sport",
      reviews_section: "Reviews Section",
      only_verified: "Only Verified",
      soa_financing_type: "Soa Financing Type",
      soa_students_count: "Soa Students Count",
      soa_certification_year: "Soa Certification Year",
      phone: "Phone",
      fax: "Fax",
      public_email: "Public Email",
      qa_email: "QA Email",
      reviews_email: "Reviews Email",
      requests_email: "Requests Email",
      street: "Street",
      postcode: "ZIP",
      cities: "Cities",
      city: "City",
      site_url: "Site Url",
      youtube_url: "Youtube Url",
      facebook_url: "Facebook Url",
      twitter_url: "Twitter Url",
      instagram_url: "Instagram Url",
      rss: "RSS",
      youtube_video: "Youtube Video",
      facebook_embed_code: "Facebook Embed Code",
      twitter_embed_code: "Twitter Embed Code",
      contact_person_name: "Contact Person Name",
      contact_person_email: "Contact Person Email",
      contact_person_phone: "Contact Person Phone",
      contact_person_position: "Contact Person Position",
      contact_person_image: "Contact Person Image",
      infobox: { title: "Title", content: "Content" },
      infobox_soa: { title: "Title", content: "Content" },
      online_courses: "Online Courses",
      updated_at: "Updated At",
      pay_per_lead: "Pay per lead",
      deleted_at: "Deleted at",
      sport_facility: "Sport Facilities",
      food_facility: "Food Facilities",
      sport_facility_description: "Sport Facilities Description",
      // A comment just to stay with the same line number
      food_facility_description: "Food Facilities Description",
      email: "email",
      has_course_starting_date: "Has Starting Data Mandatory",
      is_updated: "Is Updated",
      cc_email: "CC Email",
      has_address_mandatory: "Has Address Mandatory",
      has_gender_mandatory: "Has Gender Mandatory",
      sales_force_email: "Sales Force Email",
    },
    boxes: {
      lead_management_fields: "Lead Management",
      reimbursement_fields: "Reimbursement",
      general: "General",
      soa: "Soa",
      contact_fields: "Contacts",
      social_network_fields: "Social Network",
      contact_person_fields: "Contact Person",
      infobox: "Infobox",
      relation_fields: "Relation",
      gallery: "Gallery",
      uploads: "PDF Upload",
      locations: "Locations",
      infobox_soa: "Infobox SOA",
    },
    tabs: {
      summary: "Summary",
      images: "Images",
      courses: "Courses",
      success_stories: "Success Stories",
      administrators: "Administrators",
      events: "Events",
      statistics: "Statistics",
      experts: "Alumni / Experts",
      employees: "Employees / Experts",
      leads: "Leads",
      reviews: "Reviews",
    },
  },
  courses: {
    fields: {
      show_link: "Show button",
      short_name: "Short Name",
      full_name: "Full Name",
      slug: "Slug",
      subtitle: "Subtitle",
      premium: "Premium",
      only_verified: "Only Verified",
      description: "Description",
      admission: "Adminssion",
      duration_description: "Duration Description",
      cost_description: "Cost Description",
      study_begins_at: "Study Begins At",
      registration_fees: "Registration Fees",
      registration_deadline: "Registration Deadline",
      ects_credits: "Ects Credits",
      info_material: "Info Material",
      question_block: "Question Block",
      logo_image: "Logo Image",
      background_image: "Background Image",
      public_email: "Public Email",
      qa_email: "QA Email",
      reviews_email: "Reviews Email",
      requests_email: "Requests Email",
      street: "Street",
      postcode: "ZIP",
      city: "City",
      director_name: "Director Name",
      director_email: "Director Email",
      director_phone: "Director Phone",
      director_image: "Director Image",
      director_position: "Director Position",
      site_url: "Site Url",
      youtube_video: "Youtube Video",
      facebook_embed: "Facebook Embed Code",
      twitter_embed: "Twitter Embed Code",
      has_government_subsidies: "Has Government Subsidies",
      amount_of_subsidies: "Amount Of Subsidies",
      has_eduwo_reimbursements: "Has Eduwo Reimbursements",
      amount_eduwo_reimbursements: "Amount Eduwo Reimbursements",
      has_counseling: "Has Counseling",
      has_courses_open_spots: "Has Courses Open Spots",
      has_course_reservations: "Has Course Reservations",
      has_events_open_spots: "Has Events Open Spots",
      infobox: { title: "Title", content: "Content" },
      infobox_soa: { title: "Title", content: "Content" },
      start_dates: {
        start_date: "Start Date",
        taking_place_definitely: "Taking Place Definitely",
        has_open_spots: "Has Open Spots",
      },
      onsite_course: "Onsite Course",
      online_course: "Online Course",
      updated_at: "Updated At",
      pay_per_lead: "Pay per lead",
      deleted_at: "Deleted at",
      hidde_updated: "Hidde Update",
      email: "Email",
      cities: "Cities",
      has_phone_number_mandatory: "Has Phone Number Mandatory",
      has_course_starting_date: "Has Starting Data Mandatory",
      minimum_age: "Minimum age for admission",
      is_updated: "Is Updated",
      has_address_mandatory: "Has Address Mandatory",
      has_gender_mandatory: "Has Gender Mandatory",
      sales_force_code: "Sales Force Code", // TODO
      sales_force_email: "Sales Force Email", // TODO
      is_public: "Is Public", // TODO
      inhouse_course: "Inhouse Course", // TODO
    },
    boxes: {
      lead_management_fields: "Lead Management",
      general: "General",
      contact_fields: "Contacts",
      director_fields: "Director",
      social_network_fields: "Social Network",
      reimbursement_fields: "Reimbursement",
      relation_fields: "Relation",
      infobox: "Infobox",
      gallery: "Gallery",
      uploads: "PDF Upload",
      start_dates: "Start Dates",
      locations: "Course places",
      description: "Description",
      admission: "Admission",
      adminission_conditions: "Admission criteria for database matching",
      infobox_soa: "Infobox SOA",
    },
    tabs: {
      summary: "Summary",
      images: "Images",
      success_stories: "Success Stories",
      administrators: "Administrators",
      events: "Events",
      statistics: "Statistics",
      experts: "Alumni / Experts",
      leads: "Leads",
      relations: "Relations",
      reviews: "Reviews",
    },
  },
  school_events: {
    fields: {
      starts_on: "Starts On",
      ends_on: "Ends On",
      published: "Published",
      title: "Title",
      description: "Description",
      link: "Link",
      slug: "Slug",
      location: "Location",
    },
  },
  course_events: {
    fields: {
      starts_on: "Starts On",
      ends_on: "Ends On",
      published: "Published",
      title: "Title",
      description: "Description",
      link: "Link",
      slug: "Slug",
      location: "Location",
    },
  },
  organization_events: {
    fields: {
      starts_on: "Starts On",
      ends_on: "Ends On",
      published: "Published",
      title: "Title",
      description: "Description",
      link: "Link",
      slug: "Slug",
      location: "Location",
    },
  },
  users: {
    fields: {
      id: "Id",
      title: "Title",
      first_name: "First Name",
      last_name: "Last Name",
      email: "email",
      verified: "Verified",
      is_interested_in_ubs_promo: "is_interested_in_ubs_promo", // TODO
      password: "Password",
      remember_token: "Remember Token",
      created_at: "Created At",
      updated_at: "Updated At",
      role: "Role",
      deleted_at: "Deleted at",
      gender: "Gender",
      birthday: "Birthday",
      uid: "uid",
      phone: "Phone",
      street: "Street",
      zip: "Zip",
      known: "Known",
      provider: "Provider",
      social_id: "Social Id",
      city_id: "City Id",
      language: "Language",
      is_expert: "Expert",
      expert_biography: "Expert Biography",
      expert_motivation: "Expert Motivation",
      expert_linkedin_url: "Expert Linkedin Url",
      expert_facebook_url: "Expert Facebook Url",
      expert_twitter_url: "Expert Twitter Url",
      slug: "slug",
      premium: "Premium",
      price: "Price",
      professional: "Professional",
      pay_fee: "Pay Fee",
      user_looking_for_id: "User Looking For",
      views: "Views",
      form_contacts: "Form Contacts",
      compare_count: "Compare Count",
      login_count: "Login Count",
      order_infomaterial: "Order Infomaterial",
      user_degree_type_id: "User Degree Type",
      job_title: "Job Title",
      organization_id: "Organization Id",
      public_url: "Public Url",
      sport_type: "Sport Type",
      photo: "Photo",
      background_picture: "Background Picture",
      user_type: "Type",
      user_status: "Status",
    },
    boxes: {
      general: "General",
      contact_fields: "Contacts",
      social_login: "Social Login",
      expert_fields: "Expert Fields",
      relation_fields: "Relation Fields",
    },
    tabs: {
      summary: "Summary",
      reviews: "Reviews",
    },
  },
  statistics: {
    parameters: "Parameters",
    eduwo: { title: "Eduwo Statistics" },
    schools: { courses: { title: "School Courses Statistics" } },
  },
  organizations: {
    boxes: {
      general: "General",
      contact_fields: "Contacts",
      social_network_fields: "Social Network",
      contact_person_fields: "Contact Person",
      infobox: "Infobox",
      focus: "Focus",
      follow: "Follow",
      teaser_study_field: "Teaser Study Field",
      teaser: "Teaser",
      gallery: "Gallery",
      uploads: "PDF Upload",
      locations: "Locations",
      relation_fields: "Relation Fields",
      dashboard_fields: "Dashboard", // TODO
      dashboard_questions: "Dashboard Questions", // TODO
      faqs: "Faqs", // TODO
      dashboard_approval_process: "Approval Process", // TODO
      dashboard_job_finder: "Job Fields", // TODO
      dashbard_recommended_courses: "Favourite Courses",
    },
    fields: {
      name: "Name",
      short_name: "Short Name",
      subtitle: "Subtitle",
      slug: "Slug",
      premium: "Premium",
      only_verified: "Only Verified",
      public: "Public",
      description: "Description",
      number_of_employees: "Number Of Employees",
      foundation_year: "Foundation Year",
      turnover: "Turnover",
      site_url: "Site Url",
      public_email: "Public Email",
      hr_email: "HR Email",
      youtube_account_url: "Youtube Account Url",
      facebook_account_url: "Facebook Account Url",
      twitter_account_url: "Twitter Account Url",
      instagram_account_url: "Instagram Account Url",
      linkedin_account_url: "Linkedin Account Url",
      video_url: "Video Url",
      contact_person_image: "Contact Person Image",
      contact_person_name: "Contact Person Name",
      contact_person_email: "Contact Person Email",
      contact_person_phone: "Contact Person Phone",
      contact_person_position: "Contact Person Position",
      follow_expert_tab: "Follow Expert Tab",
      follow_school_tab: "Follow School Tab",
      follow_organization_tab: "Follow Organization Tab",
      follow_course_tab: "Follow Course Tab",
      follow_schools_description: "Follow Schools Description",
      follow_courses_description: "Follow Courses Description",
      // A comment just to stay with the same line number
      follow_organizations_description: "Follow Organizations Description",
      background_image: "Background Image",
      logo_image: "Logo Image",
      info_box: { title: "Title", content: "Content" },
      teaser: { title: "Title", link: "Link", description: "Description" },
      teaser_study_field: { title: "Title", lead: "Lead" },
      locations: {
        street: "Street",
        city: "City",
        public_email: "Public Email",
        website: "Website",
      },
      focus_topics: {
        name: "Focus Topics",
        topic: "Topic",
        percentage: "Percentage",
      },
      updated_at: "Updated At",
      deleted_at: "Deleted at",
      allow_external_experts: "Allow external experts", // TODO
      allow_coach_experts: "Allow coaches", // TODO
      show_dashboard_dashboard: "Show Dashboard", // TODO
      show_dashboard_knowledge_base: "Show Knowledge Base", // TODO
      show_dashboard_search: "Show Search", // TODO
      show_dashboard_network: "Show Network", // TODO
      show_dashboard_applications: "Show Applications", // TODO
      questions: {
        question_type: "Question type", // TODO
        style: "Question style", // TODO
        question: "Question", // TODO
        description: "Question description", // TODO
        placeholder: "Placeholder", // TODO
        data: "Question data", // TODO
        mandatory: "Mandatory", // TODO
        dataFields: { title: "Option" },
      },
      search_only_premium: "Search Only Premium", // TODO
      booking_only_premium: "Booking Only Premium", // TODO
      faqs: {
        question: "Question", // TODO
        answer: "Answer", // TODODO
      },
      employee_email: "Official email", // TODO
      meeting_link: "Meetlink Link", // TODO
      booking_stages: {
        order: "Order", // TODO
        type: "Type", // TODO
        approver: "Approver", // TODO
      },
      job_fields: {
        title: "Title",
      },
    },
    tabs: {
      summary: "Summary",
      success_stories: "Success Stories",
      administrators: "Administrators",
      events: "Events",
      statistics: "Statistics",
      experts: "Employees / Experts",
      matching: "Matching",
      dashboard: "Dashboard",
    },
  },
  leads: {
    fields: {
      message: "Message",
      title: "Title",
      request_order: "Request Orders",
      created_at: "Created At",
      receiver: "Receiver",
      sender: "Sender",
      date: "Date",
      bulk: "Bulk",
      main_order: "Main Order",
      course_name: "Course Name",
      school_name: "School Name",
      schools_and_courses: "Schools and Courses",
      total_receiver: "Total Receiver",
      start_date: "Start Date",
      end_date: "End Date",
      iban: "iban",
      term: "Term",
      total_inquiries: "Direct contacts",
      total_bulks: "Contacts through wizard",
      inquiry_course_ids_count: "Inquiry Course Ids Count",
      inquiry_unique_course_ids_count:
        "Total unique courses contacted directly",
      bulk_course_ids_count: "Bulk Course Ids Count",
      bulk_unique_course_ids_count:
        "Total unique courses contacted through wizard",
      total_courses: "Total courses in this category",
      inquiry_course_ids_sum: "Top 30 courses direct contacted",
      bulk_course_ids_sum: "Top 30 courses contacted through wizard",
      bulk_same_course_ids_count:
        "Total unique courses contacted through wizard",
      bulk_same_school_ids: "Total unique schools contacted through wizard",
      inquiry_unique_school_ids_count:
        "Total unique schools contacted directly",
      bulk_same_school_ids_count:
        "Total unique schools contacted through wizard",
      inquiry_school_ids_sum: "Top 30 schools direct contacted",
      bulk_school_ids_sum: "Top 30 schools contacted through wizard",
      inquiry_main_order: "Top direct contacted orders",
      bulk_main_order: "Top direct contacted through wizard",

      premium: "Premium", // TODO
      pay_per_lead: "Pay Per Lead", // TODO
      infomaterial: "Infomaterial", // TODO
      only_pending: "Only Pending", // TODO
      hide_archived: "Hide Archived", // TODO
    },
    summary: {
      title: "Summary",
      total: "Total",
      total_schools: "Total Schools",
      total_courses: "Total Courses",
      total_anmeldung: "Total Anmeldung",
      total_beratungsgespräch: "Total Beratungsgespräch",
      total_infotag: "Total Infotag",
      total_infomaterial: "Total Infomaterial",
      total_unknown: "Total Unknown",
      // Tab
      name: "Summary",
    },
    list: {
      title: "Leads",
    },
    inquiries: {
      name: "Inquiries",
    },
    bulk: {
      name: "Bulk",
    },
    reimbursement: {
      name: "Reimbursement",
    },
  },
  course_success_stories: {
    fields: {
      title: "Title",
      first_name: "First Name",
      last_name: "Last Name",
      description: "Description",
      slug: "Slug",
      photo: "Photo",
    },
  },
  school_success_stories: {
    fields: {
      title: "Title",
      first_name: "First Name",
      last_name: "Last Name",
      description: "Description",
      slug: "Slug",
      photo: "Photo",
    },
  },
  organization_success_stories: {
    fields: {
      title: "Title",
      first_name: "First Name",
      last_name: "Last Name",
      description: "Description",
      slug: "Slug",
      photo: "Photo",
    },
  },
  experts: {
    fields: {
      verified: "Verified",
      is_expert: "Expert", // TODO
      graduation_year: "Graduation Year",
      job_title: "Job Title",
    },
  },
  reference_data: {
    tabs: {
      awards: "Awards",
    },
    fields: {
      title: "Title", // TODO
    },
  },
  awards: {
    fields: {
      private_title: "Private Title",
      title: "Title",
      type: "Type",
      description: "Description",
      icon: "Icon",
      url: "Url",
      can_change_by_admin: "Can change by admin",
    },
  },
  study_field_categories: {
    fields: {
      title: "Title",
      slug: "Slug",
      description: "Description",
      popular: "Popular",
      order: "Order",
      faqs: {
        question: "Question",
        answer: "Answer",
      },
    },
  },
  study_fields: {
    fields: {
      title: "Title",
      slug: "Slug",
      description: "Description",
      popular: "Popular",
      icon: "Icon",
      faqs: {
        question: "Question",
        answer: "Answer",
      },
    },
  },
  sub_study_fields: {
    fields: {
      title: "Title",
      slug: "Slug",
      description: "Description",
      popular: "Popular",
      icon: "Icon",
      faqs: {
        question: "Question",
        answer: "Answer",
      },
    },
  },
  school_types: {
    fields: {
      title: "Title",
      slug: "Slug",
      description: "Description",
    },
  },
  reviews: {
    fields: {
      year: "Year",
      approved: "Approved",
      created_at: "Created At",
      rating_avg: "Rating Average",
      rating_content: "Rating Content",
      rating_environment: "Rating Environment",
      rating_equipment: "Rating Equipment",
      rating_organisation: "Rating Organisation",
      rating_teachers: "Rating Teachers",
      recommend: "Recommend",
      title: "Title",
      message: "Message",
      pros: "Pros",
      cons: "Cons",
      reply: "Reply",
      rating_communication: "Rating Communication",
      rating_competence: "Rating Competence",
      rating_performance: "Rating Performance",
    },
  },
  publicities: {
    fields: {
      link: "link", // TODO
    },
  },
  degree_types: {
    fields: {
      title: "Title", // TODO
      validation_month: "Validation Month", // TODO
      order: "Order", // TODO
    },
  },
  user_degree_types: {
    fields: {
      title: "Title", // TODO
      order: "Order", // TODO
      degree_type: "Diese Abschlusstypen werden empfohlen", // TODO
      exclude_degree_type:
        "Diese Abschlusstypen machen wenig Sinn (ausschliessen/exclude)", // TODO
    },
  },
  course_costs: {
    fields: {
      title: "Title", // TODO
    },
  },
  course_durations: {
    fields: {
      title: "Title", // TODO
    },
  },
  course_languages: {
    fields: {
      title: "Title", // TODO
    },
  },
  cities: {
    fields: {
      title: "Title", // TODO
    },
  },
  districts: {
    fields: {
      title: "Title", // TODO
    },
  },
  regions: {
    fields: {
      title: "Title", // TODO
    },
  },
  countries: {
    fields: {
      title: "Title", // TODO
    },
  },
  study_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  branches: {
    fields: {
      title: "Title", // TODO
    },
  },
  job_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  organization_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  soa_school_types: {
    fields: {
      title: "Title", // TODO
    },
  },
  work_experiences: {
    fields: {
      title: "Title", // TODO
    },
  },
  contacts: {
    fields: {
      first_name: "First Name", // TODO
      last_name: "Last Name", // TODO
      email: "Email", // TODO
      source: "Source", // TODO
      total_price: "Total Price", // TODO
      total_schools: "Total Schools", // TODO
      total_courses: "Total Courses", // TODO
      created_at: "Created At", // TODO
      school_name: "School Name", // TODO
      premium: "P", // TODO
      pay_per_lead: "PPL", // TODO
      info_material: "IM", // TODO
      fix_fee: "FF", // TODO
      pay_per_view: "PPV", // TODO
      no_contract: "NC", // TODO
      rejected_by_api: "RAPI", // TODO
      rejected_manually: "RM", // TODO
      approved_pay_per_view: "APPV", // TODO

      name: "User Name", // TODO
      phone: "Phone Number", // TODO
      street: "Street", // TODO
      zip: "Zip Code", // TODO
      city: "City", // TODO
      job_title: "Job Title", // TODO
      work_experience: "Work Experience", // TODO
      year_of_birth: "Year Of Birth", // TODO
      user_degree_types: "User Degree Types", // TODO

      lead_contact_option: "Main Lead", // TODO
      lead_contact_options: "Lead Options", // TODO
      model: "Model", // TODO
      criteria: "Criteria", // TODO

      to_email: "Email", // TODO
      cc_email: "CC", // TODO
      status: "Status", // TODO
      price: "Price", // TODO
      archived: "Archived", // TODO
      anonymous: "A", // TODO

      total_contacts: "Total Contacts", // TODO
      total_sent: "Total Sent", // TODO
      total_billable: "Total Billable", // TODO
      total_sent_anonymous: "Total Sent Anonymous", // TODO
      total_rejected: "Total Rejected", // TODO

      total: "T", // TODO
      rejected: "R", // TODO
      pending: "Pe", // TODO
      total_total: "Total", // TODO
      warnings: "Warnings", // TODO
      dangers: "Dangers", // TODO
      contacts_count: "Contacts Count", // TODO
      pendings_count: "Pendings Count", // TODO
      last_contact: "Last Contact", // TODO
      birthday_day: "Birthday Day", // TODO
      birthday_month: "Birthday Month", // TODO
      birthday_year: "Birthday Year", // TODO
      user_type: "User Type", // TODO
      user_status: "User Status", // TODO
      cc_emails: "CC email", // TODO
      details: "Details", // TODO
      freshData: "Fresh Data", // TODO
    },
  },
  wizards: {
    fields: {
      slug: "Slug", // TODO
      title: "Title", // TODO
      sub_title: "Subtitle", // TODO
      description: "Description", // TODO
      page: "Page", // TODO
      style: "Style", // TODO
      step: "Step", // TODO
      field: "Field", // TODO
      placeholder: "Placeholder", // TODO
      mandatory: "Mandatory", // TODO
      remove_zero_courses: "Remove options with zero courses", // TODO
      order_by: "Options Order", // TODO
      related_field: "Related Field", // TODO
      total_courses: "Show Total Courses", // TODO
      minimum: "Minimum Selection", // TODO
      maximum: "Maximum", // TODO
      columns: "Number of Columns", // TODO
      data_with_total_courses: "Show Total Courses per Option", // TODO
      data: "Options Data", // TODO
    },
    tabs: {
      summary: "General", // TODO
    },
  },
  register: {
    user: {
      email: {
        placeholder: "Please, insert your email address", // TODO
        label: "Email", // TODO
      },
      password: {
        placeholder: "Please, insert your password", // TODO
        label: "Password", // TODO
      },
      confirm_password: {
        placeholder: "Confirm your password", // TODO
        label: "Password", // TODO
      },
      first_name: {
        placeholder: "Please, insert your first name", // TODO
        label: "First Name", // TODO
      },
      last_name: {
        placeholder: "Please, insert your last name", // TODO
        label: "Last Name", // TODO
      },
      job_title: {
        placeholder: "Please, insert your job position", // TODO
        label: "Job Position", // TODO
      },
      phone: {
        placeholder: "Please, insert your phone number", // TODO
        label: "Phone", // TODO
      },
    },
    company: {},
  },
  kb_modules: {
    fields: {
      title: "Title", // TODO
      description: "Description", // TODO
    },
  },
  kb_sections: {
    fields: {
      title: "Title", // TODO
      description: "Description", // TODO
    },
  },
  kb_units: {
    fields: {
      title: "Title", // TODO
      description: "Description", // TODO
      video_url: "Video URL", // TODO
    },
  },
  todos: {
    fields: {
      user: "User",
      comment: "Comment",
      created_at: "Created",
      created_by: "Created by",
      priority: "Priority",
      due_date: "Due date",
      start_date: "Start date",
      done: "Done",
      term: "Term",
      first_name: "First Name", // TODO
      last_name: "Last Name", // TODO
      email: "Email", // TODO
    },
  },
};

export default customEnglishMessages;
