import * as React from "react";
import { useTranslate } from "react-admin";
import { Collapse } from "@material-ui/core";
import { BoxTitle, Gallery } from "../components";

const CourseGalleryFields = ({
  disabled_level_1,
  disabled_level_2,
  disabled_level_3,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`courses.boxes.gallery`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Gallery
          disabled={disabled_level_3}
          gallery_array={
            props !== undefined &&
            props.record !== undefined &&
            props.record.gallery !== undefined &&
            props.record.gallery !== null &&
            Array.isArray(props.record.gallery)
              ? props.record.gallery
              : undefined
          }
          {...props}
        />
      </Collapse>
    </>
  );
};

export default CourseGalleryFields;
