import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import { BoxTitle, BooleanInputField, TextInputField } from ".";

const SchoolLeadManagementFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`schools.boxes.lead_management_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <BooleanInputField
              source="info_material"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <BooleanInputField
              source="has_counseling"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <BooleanInputField
              source="has_courses_open_spots"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <BooleanInputField
              source="has_course_reservations"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <BooleanInputField
              source="has_events_open_spots"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <BooleanInputField
              source="show_link"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <BooleanInputField
              source="has_phone_number_mandatory"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <BooleanInputField
              source="has_course_starting_date"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <BooleanInputField
              source="has_address_mandatory"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <BooleanInputField
              source="has_gender_mandatory"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="sales_force_email"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_1}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default SchoolLeadManagementFields;
