import * as React from "react";
import {
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  Labeled,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import {
  BoxTitle,
  TextInputField,
  TextInputEmailField,
  TextInputSiteField,
} from "../components";
import { Grid, Collapse } from "@material-ui/core";

const OrganizationLocationFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.locations`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {disabled_level_2 === false && (
              <ArrayInput source="locations">
                <SimpleFormIterator>
                  <TextInputField
                    source="street"
                    label={translate(`organizations.fields.locations.street`)}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                  <ReferenceInput
                    source={"city_id"}
                    reference={"cities"}
                    label={translate(`resources.cities.name_singular`)}
                    perPage={1000}
                  >
                    <SelectInput
                      fullWidth
                      optionText={"title"}
                      optionValue={"id"}
                    />
                  </ReferenceInput>
                  <TextInputEmailField
                    source="public_email"
                    label={translate(
                      `organizations.fields.locations.public_email`
                    )}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                  <TextInputSiteField
                    source="website"
                    label={translate(`organizations.fields.locations.website`)}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            )}
            {disabled_level_2 === true &&
              props !== undefined &&
              props.record !== undefined &&
              props.record.locations !== undefined &&
              Array.isArray(props.record.locations) &&
              props.record.locations.map((item, index) => (
                <div key={index}>
                  <Grid item xs={12}>
                    <Labeled
                      resource={props.resource}
                      label={translate(`organizations.fields.locations.street`)}
                    >
                      <>{item.street}</>
                    </Labeled>
                  </Grid>
                  {/* TODO CITIES */}
                  <Grid item xs={12}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.locations.public_email`
                      )}
                    >
                      <>{item.public_email}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.locations.website`
                      )}
                    >
                      <>{item.website}</>
                    </Labeled>
                  </Grid>
                </div>
              ))}
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationLocationFields;
