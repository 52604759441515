import * as React from "react";

import { Grid } from "@material-ui/core";
import {
  TextInputField,
  RichTextInputField,
  ReferenceSelectInputField,
} from "../components";
import ReferenceDataTable from "../referenceData/ReferenceDataTable";
import { ArrayInput, SimpleFormIterator } from "react-admin";
const resource = "kb_modules";

const KbModulesFormEditCreateForm = ({ type }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextInputField
          source="description"
          resource={resource}
          disabled={false}
          validate={true}
        />
      </Grid>
      <Grid item xs={12}>
        <ArrayInput source={"kb_section_ids"}>
          <SimpleFormIterator>
            <ReferenceSelectInputField
              source="id"
              reference="kb_sections"
              optionText="title"
              optionValue="id"
              allowEmpty={false}
              validate={true}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};
const KbModulesForm = ({ props }: any) => {
  const fields = ["title"];
  const reference_data_fields = ["title", "description", "kb_section_ids"];

  return (
    <ReferenceDataTable
      resource={resource}
      table_fields={fields}
      reference_data_fields={reference_data_fields}
      reference_data_images={["background_image"]}
      {...props}
      editForm={<KbModulesFormEditCreateForm type={"edit"} />}
      createForm={<KbModulesFormEditCreateForm type={"create"} />}
    />
  );
};

export default KbModulesForm;
