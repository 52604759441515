import React from "react";
import { ShowImageUrl, LinearLoading } from "../components";
import { useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";

export default function LogoBackgroundImages({
  disabled,
  item_logo,
  item_background,
  collection_logo = "logo_image",
  collection_background = "background_image",
  image_alt_logo = "Logo Image",
  image_alt_background = "Background Image",
  ...props
}) {
  const translate = useTranslate();
  const background_image_url =
    item_background !== null && item_background !== undefined
      ? item_background.small_url
      : "";

  const logo_image_url =
    item_logo !== null && item_logo !== undefined ? item_logo.small_url : "";

  return (
    <Grid
      container
      spacing={1}
      justify="space-between"
      direction="row"
    >
      {(props === undefined || props.record === undefined) && (
        <Grid item xs={12}>
          <LinearLoading />
        </Grid>
      )}
      {props !== undefined && props.record !== undefined && (
        <>
          {item_logo !== undefined && (<Grid item xs={12} sm={6}>
            <ShowImageUrl
              title={translate(`${props.resource}.fields.${collection_logo}`)}
              image_src={logo_image_url}
              image_alt={image_alt_logo}
              disabled={disabled}
              resource={props.resource}
              id={props.id}
              collection={collection_logo}
            />
          </Grid>)}
          <Grid item xs={12} sm={6} justify="flex-end">
            <ShowImageUrl
              title={translate(
                `${props.resource}.fields.${collection_background}`
              )}
              image_src={background_image_url}
              image_alt={image_alt_background}
              disabled={disabled}
              resource={props.resource}
              id={props.id}
              collection={collection_background}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
