import * as React from "react";
import {
  SimpleForm,
  Create,
  ReferenceInput,
  required,
  SelectInput,
  useTranslate,
  usePermissions,
} from "react-admin";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ReferenceArrayAutocompleteInputField,
  ReferenceSelectInputField,
  TextInputField,
} from "../components";
import { parse } from "query-string";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";
import { CourseStudyFields } from "./CourseRelationFields";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  warningSpacing: {
    marginTop: "-20px",
  },
}));

const Elements = ({ ...props }: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { permissions = [] } = usePermissions();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ReferenceInput
            source="school_id"
            reference="schools"
            validate={required()}
          >
            <SelectInput optionText="name" fullWidth disabled />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <TextInputField
            source="full_name"
            resource={props.resource}
            record={props.record}
            validate={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputField
            source="short_name"
            resource={props.resource}
            record={props.record}
            validate={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReferenceSelectInputField
            source="study_type_id"
            reference="study_types"
            optionText="title"
            optionValue="id"
            validate={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReferenceSelectInputField
            source="degree_type_id"
            reference="degree_types"
            optionText="title"
            optionValue="id"
            validate={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReferenceSelectInputField
            source="duration_id"
            reference="course_durations"
            optionText="title"
            optionValue="id"
            validate={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReferenceSelectInputField
            source="cost_id"
            reference="course_costs"
            optionText="title"
            optionValue="id"
            validate={true}
          />
        </Grid>
        <Grid item xs={12}>
          <ReferenceArrayAutocompleteInputField
            reference="course_languages"
            source="language_ids"
            sort={{ field: "title", order: "ASC" }}
            optionText="title"
            optionValue="id"
            validate={true}
          />
        </Grid>
        <Grid item xs={12}>
          <ReferenceArrayAutocompleteInputField
            reference="cities"
            source="city_ids"
            sort={{ field: "title", order: "ASC" }}
            optionText="title"
            optionValue="id"
            validate={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CourseStudyFields />
        </Grid>
      </Grid>
    </div>
  );
};

const CourseCreate = (props) => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { school_id: school_id_string } = parse(props.location.search);

  // ra-data-fakerest uses integers as identifiers, we need to parse the querystring
  // We also must ensure we can still create a new comment without having a post_id
  // from the url by returning an empty string if post_id isn't specified
  // @ts-ignore
  const school_id = school_id_string ? parseInt(school_id_string, 10) : "";
  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ school_id }}>
        <Elements />
      </SimpleForm>
    </Create>
  );
};

export default CourseCreate;
