import * as React from "react";
import { FC } from "react";
import { Show, ShowProps } from "react-admin";
import CourseForm from "./CourseForm";
import { makeStyles, Theme } from "@material-ui/core/styles";

const CourseShow: FC<ShowProps> = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Show {...props} title={" "} actions={<></>} className={classes.root}>
      <CourseForm {...props} action="show" />
    </Show>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default CourseShow;
