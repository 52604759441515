import * as React from "react";
import {
  useTranslate,
  useGetList,
  useRefresh,
  useNotify,
  useUpdate,
  useCreate,
  useDelete,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  TablePagination,
  TableSortLabel,
  TableFooter,
} from "@material-ui/core";
import {
  DeleteButtonList,
  LinearLoading,
  ModelLocaleSelect,
  MySaveButton,
  Gallery,
} from "../components";
import EditIcon from "@material-ui/icons/Edit";
import { Grid, Button } from "@material-ui/core";
import { TextInputField, ShowImageUrl } from "../components";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface ReferenceDataProps {
  resource: string;
}
interface ReferenceDataCreateProps extends ReferenceDataProps {
  editForm: any;
  createForm: any;
  setCreateMode: any;
  setUpdateRow: any;
  setListMode: any;
}
interface ReferenceDataUpdateProps extends ReferenceDataCreateProps {
  row: any;
  reference_data_fields: Array<string>;
  reference_data_images: Array<string>;
  reference_data_files: string;
}

const CommonForm = (props: ReferenceDataProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextInputField
          source="title"
          resource={props.resource}
          disabled={false}
          validate={true}
        />
      </Grid>
    </Grid>
  );
};

const ReferenceDataCreate = ({
  resource,
  editForm,
  createForm,
  setCreateMode,
  setUpdateRow,
  setListMode,
}: ReferenceDataCreateProps) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [create] = useCreate(resource);
  const handleCreate = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setUpdateRow(undefined);
          setCreateMode(false);
          refresh();
        },
        onFailure: ({ error }) => {
          setUpdateRow(undefined);
          setCreateMode(false);
          notify(error.message, "error");
          refresh();
        },
      }
    );
  };

  return (
    <div>
      <FormWithRedirect
        resource={resource}
        save={handleCreate}
        render={({ handleSubmitWithRedirect, pristine, saving }) => (
          <>
            {createForm === undefined ? (
              <CommonForm resource={resource} />
            ) : (
              createForm
            )}
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <MySaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                />
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" onClick={() => setListMode()}>
                  {translate(`common.cancel`)}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      />
    </div>
  );
};

const ReferenceDataUpdate = ({
  resource,
  row,
  reference_data_fields,
  editForm,
  createForm,
  setUpdateRow,
  setCreateMode,
  setListMode,
  reference_data_images,
  reference_data_files,
}: ReferenceDataUpdateProps) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [update] = useUpdate(resource, row.id);
  const handleUpdate = async (values) => {
    update(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setUpdateRow(undefined);
          setCreateMode(false);
          refresh();
        },
        onFailure: ({ error }) => {
          setUpdateRow(undefined);
          setCreateMode(false);
          notify(error.message, "error");
          refresh();
        },
      }
    );
  };

  var defaultValkue = {};

  for (var i = 0; i < reference_data_fields.length; i++) {
    defaultValkue[reference_data_fields[i]] = row[reference_data_fields[i]];
  }

  var images_url = {};
  for (var j = 0; j < reference_data_images.length; j++) {
    images_url[reference_data_images[j]] =
      row[reference_data_images[j]] !== null &&
      row[reference_data_images[j]] !== undefined
        ? row[reference_data_images[j]].small_url
        : "";
  }

  return (
    <FormWithRedirect
      resource={resource}
      save={handleUpdate}
      defaultValue={defaultValkue}
      render={({ handleSubmitWithRedirect, pristine, saving }) => (
        <>
          <Grid container spacing={1}>
            {reference_data_images.map((reference_data_image) => (
              <Grid item xs={6}>
                <ShowImageUrl
                  image_src={images_url[reference_data_image]}
                  image_alt={reference_data_image}
                  disabled={false}
                  resource={resource}
                  id={row.id}
                  collection={reference_data_image}
                  title={reference_data_image}
                />
              </Grid>
            ))}
            {reference_data_files && (
              <Grid item xs={6}>
                <Gallery
                  disabled={false}
                  type={"file"}
                  gallery_array={row[reference_data_files]}
                  accepted_files={["image/*"]}
                  collection="uploads"
                  fullUrl={true}
                  resource={resource}
                  id={row.id}
                />
              </Grid>
            )}
          </Grid>
          {editForm === undefined ? (
            <CommonForm resource={resource} />
          ) : (
            editForm
          )}
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <MySaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                saving={saving}
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" onClick={() => setListMode()}>
                {translate(`common.cancel`)}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    />
  );
};

const MyDeleteButton = ({ resource, row }: any) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [deleteOne] = useDelete(resource, row.id);
  const handleDelete = async (values) => {
    deleteOne(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
          refresh();
        },
      }
    );
  };
  return <DeleteButtonList handleClick={handleDelete} />;
};

const ReferenceDataTableComponent = ({
  table_fields,
  resource,
  dataList,
  listTotal,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  setCreateMode,
  setUpdateRow,
}: any) => {
  const translate = useTranslate();
  function Row({ row }: any) {
    const setEditMode = (id) => {
      setUpdateRow(id);
      setCreateMode(false);
    };

    return (
      <React.Fragment>
        <StyledTableRow>
          {table_fields.map((table_field) => (
            <StyledTableCell>{row[table_field]}</StyledTableCell>
          ))}
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setEditMode(row)}
            >
              <EditIcon />
            </IconButton>
          </StyledTableCell>
          <StyledTableCell>
            <MyDeleteButton resource={resource} row={row} />
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {table_fields.map((table_field) => (
                <TableCell>
                  {table_field === "title" ? (
                    <TableSortLabel active={true} direction={"asc"}>
                      {translate(`${resource}.fields.${table_field}`)}
                    </TableSortLabel>
                  ) : (
                    translate(`${resource}.fields.${table_field}`)
                  )}
                </TableCell>
              ))}
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList !== undefined &&
              Object.keys(dataList).map((key) => {
                return <Row row={dataList[key]} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={1}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listTotal ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell colSpan={1}>
            <ModelLocaleSelect label={translate(`sentence.show_in`)} />
          </TableCell>
        </TableRow>
      </TableFooter>
    </>
  );
};

const ReferenceDataTable = ({
  resource,
  pagination = { perPage: 10, page: 1 },
  sort = { field: "title", order: "ASC" },
  filters = {},
  table_fields = ["title"],
  reference_data_fields = ["title"],
  editForm = undefined,
  createForm = undefined,
  reference_data_images = [],
  reference_data_files = [],
  props,
}: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const [update_row, setUpdateRow] = React.useState(undefined);
  const [create_mode, setCreateMode] = React.useState(false);
  const [page, setPage] = React.useState(pagination.page - 1);
  const [rowsPerPage, setRowsPerPage] = React.useState(pagination.perPage);
  const [term, setTerm] = React.useState(undefined);
  const [study_field_category_id, setStudyFieldCategoryId] =
    React.useState(undefined);

  if (term !== undefined) {
    filters["term"] = term;
  }
  if (study_field_category_id !== undefined) {
    filters["study_field_category_id"] = study_field_category_id;
  }

  const { total, /*error,*/ data, loading } = useGetList(
    resource,
    { perPage: rowsPerPage, page: page + 1 },
    sort,
    filters
  );

  const setListMode = () => {
    setUpdateRow(undefined);
    setCreateMode(false);
    refresh();
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Grid container spacing={1}>
        {update_row === undefined && create_mode === false && (
          <Grid item xs={12}>
            <TextInputField
              resource={resource}
              source={"term"}
              label={translate("pos.search")}
              onChange={(event) => {
                const { value } = event.target;
                if (value.length < 3) {
                  setTerm(undefined);
                } else {
                  setTerm(value);
                }
              }}
            />
          </Grid>
        )}
        {/* Specific filter for study fields.
        If other specific filters are requested please do something generic. */}
        {update_row === undefined &&
          create_mode === false &&
          resource === "study_fields" && (
            <Grid item xs={12}>
              <ReferenceInput
                perPage={1000}
                source="study_field_category_id"
                reference="study_field_categories"
                sort={{ field: "title", order: "ASC" }}
                filter={{
                  show_only: ["id", "title"],
                }}
                inputProps={{
                  onChange: (e) => {
                    if (e.target.value === "") {
                      setStudyFieldCategoryId(undefined);
                    } else {
                      setStudyFieldCategoryId(e.target.value);
                    }
                  },
                }}
                allowEmpty={true}
                fullWidth
              >
                <SelectInput optionText="title" optionValue="id" />
              </ReferenceInput>
            </Grid>
          )}
        {update_row === undefined && create_mode === false && (
          <Grid item xs={12}>
            <Button variant="contained" onClick={() => setCreateMode(true)}>
              {translate(`resources.${resource}.create`)}
            </Button>
          </Grid>
        )}
      </Grid>
      {loading ? (
        <LinearLoading />
      ) : (
        <>
          {update_row === undefined && create_mode === false && (
            <ReferenceDataTableComponent
              table_fields={table_fields}
              resource={resource}
              dataList={data}
              listTotal={total}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              setCreateMode={setCreateMode}
              setUpdateRow={setUpdateRow}
            />
          )}
          {update_row !== undefined && create_mode === false && (
            <ReferenceDataUpdate
              resource={resource}
              row={update_row}
              reference_data_fields={reference_data_fields}
              editForm={editForm}
              createForm={createForm}
              setCreateMode={setCreateMode}
              setUpdateRow={setUpdateRow}
              setListMode={setListMode}
              reference_data_images={reference_data_images}
              reference_data_files={reference_data_files}
            />
          )}
          {create_mode === true && (
            <ReferenceDataCreate
              resource={resource}
              editForm={editForm}
              createForm={createForm}
              setCreateMode={setCreateMode}
              setUpdateRow={setUpdateRow}
              setListMode={setListMode}
            />
          )}
        </>
      )}
    </>
  );
};

export default ReferenceDataTable;
