import * as React from "react";
import { FC } from "react";
import {
  useTranslate,
  useGetList,
  required,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { LinearLoading } from "../components";
import MyLocalStoraqge from "../utils/MyLocalStorage";
import { makeStyles } from "@material-ui/core/styles";
import { ReferenceArrayProps } from "./types";

const useStyles = makeStyles(() => ({
  root: {
    pointerEvents: "none",
  },
}));

export interface ReferenceArrayAutocompleteGetListProps
  extends ReferenceArrayProps {
  choices: object;
}

const ReferenceInputAutocompleteInputFieldGetList: FC<ReferenceArrayAutocompleteGetListProps> =
  (props) => {
    const translate = useTranslate();

    // Avoid calling always this method using global variables
    const { data, ids, loading, error } = useGetList(
      props.reference,
      props.pagination ?? { page: 1, perPage: 1000 },
      props.sort ?? { field: "id", order: "ASC" },
      props.filter ?? {}
    );
    if (loading) {
      return <LinearLoading />;
    }
    if (error) {
      return <p>ERROR</p>;
    }
    // @ts-ignore: Object is possibly 'undefined'.
    var choices = ids.map((id) => ({
      id: id,
      // @ts-ignore: Object is possibly 'undefined'.
      name: data[id][optionText],
      // props.disabled,
    }));
    MyLocalStoraqge.setWithExpiry(props.reference, choices, props.filter);

    return (
      <AutocompleteInput
        label={translate(`resources.${props.reference}.name`)}
        autoComplete="off"
        fullWidth
        optionText="name"
        optionValue={props.optionValue}
        disabled={props.disabled}
        choices={choices}
        validate={props.validate ? [required()] : []}
        shouldRenderSuggestions={(val) => {
          if (props.disabled) {
            return val.trim().length > 10;
          }
          return val.trim().length >= (props.renderSugestions ?? 0);
        }}
      />
    );
  };

const ReferenceInputAutocompleteInputField: React.FC<ReferenceArrayProps> = (
  props
) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (!props.dynamic) {
    var choices = MyLocalStoraqge.getWithExpiry(props.reference, props.filter);
    if (!props.forceRefresh && choices !== null) {
      return (
        <div className={props.disabled ? classes.root : ""}>
          <AutocompleteInput
            label={translate(`resources.${props.reference}.name`)}
            fullWidth
            optionText="name"
            optionValue={props.optionValue}
            disabled={props.disabled}
            validate={props.validate ? [required()] : []}
            shouldRenderSuggestions={(val) => {
              if (val === undefined) {
                return false;
              }
              if (props.disabled) {
                return false;
              }
              return val.trim().length >= (props.renderSugestions ?? 0);
            }}
            choices={choices}
          />
        </div>
      );
    }
    return (
      <div className={props.disabled ? classes.root : ""}>
        <ReferenceInputAutocompleteInputFieldGetList
          choices={choices}
          {...props}
        />
      </div>
    );
  }
  return (
    <div className={props.disabled ? classes.root : ""}>
      <ReferenceInput
        label={translate(`resources.${props.reference}.name`)}
        reference={props.reference}
        source={props.source}
        filterToQuery={(searchText) => ({ term: searchText })}
        filter={props.filter}
      >
        <AutocompleteInput
          fullWidth
          optionText={props.optionText}
          optionValue={props.optionValue}
          disabled={props.disabled}
          validate={props.validate ? [required()] : []}
          shouldRenderSuggestions={(val) => {
            if (props.disabled) {
              return val.trim().length > 10;
            }
            return val.trim().length >= (props.renderSugestions ?? 0);
          }}
        />
      </ReferenceInput>
    </div>
  );
};

export default ReferenceInputAutocompleteInputField;
