const githubUrl = `https://api.github.com`;
const githubActions = `${githubUrl}/repos/eduwo/gc-cd/actions/workflows`;

const options = (opt: any, body: any, method = "GET") => {
  const headers = new Headers({
    Accept: "application/vnd.github.everest-preview+json",
  });
  headers.set("Content-Type", `application/json`);
  // TODO: With no-cache a CORS problem is returned.
  // headers.set("cache-control", `no-cache`);
  headers.set(
    "Authorization",
    `Bearer 1f0f806cfad4559f067db15f196c570b34aee6c2`
  );

  const options = {
    method: method,
    body: body,
    options: opt,
    headers: headers,
  };
  return options;
};

const githubProvider = {
  deployDevelopment: () => {
    const workflow = `development-deploy.yml`;

    const url = `${githubActions}/${workflow}/dispatches`;

    return fetch(
      url,
      options(undefined, JSON.stringify({ ref: "development" }), "POST")
    );
  },
  deployFrontendDevelopment: () => {
    const workflow = `frontend-development-deploy.yml`;

    const url = `${githubActions}/${workflow}/dispatches`;

    return fetch(
      url,
      options(undefined, JSON.stringify({ ref: "development" }), "POST")
    );
  },
  deployAdminUIDevelopment: () => {
    const workflow = `admin-ui-development-deploy.yml`;

    const url = `${githubActions}/${workflow}/dispatches`;

    return fetch(
      url,
      options(undefined, JSON.stringify({ ref: "development" }), "POST")
    );
  },
  runsDevelopment: () => {
    const workflow_id = `652934`;
    const branch = `development`;

    const url = `${githubActions}/${workflow_id}/runs?branch=${branch}`;

    return fetch(url, options(undefined, undefined, "GET")).then((response) => {
      return response.json();
    });
  },
  runsFrontendDevelopment: () => {
    const workflow_id = `14881795`;
    const branch = `development`;

    const url = `${githubActions}/${workflow_id}/runs?branch=${branch}`;

    return fetch(url, options(undefined, undefined, "GET")).then((response) => {
      return response.json();
    });
  },
  runsAdminUIDevelopment: () => {
    const workflow_id = `4252974`;
    const branch = `development`;

    const url = `${githubActions}/${workflow_id}/runs?branch=${branch}`;

    return fetch(url, options(undefined, undefined, "GET")).then((response) => {
      return response.json();
    });
  },
  deployStaging: () => {
    const workflow = `staging-deploy.yml`;

    const url = `${githubActions}/${workflow}/dispatches`;

    return fetch(
      url,
      options(undefined, JSON.stringify({ ref: "staging" }), "POST")
    );
  },
  deployFrontendStaging: () => {
    const workflow = `frontend-staging-deploy.yml`;

    const url = `${githubActions}/${workflow}/dispatches`;

    return fetch(
      url,
      options(undefined, JSON.stringify({ ref: "staging" }), "POST")
    );
  },
  deployAdminUIStaging: () => {
    const workflow = `admin-ui-staging-deploy.yml`;

    const url = `${githubActions}/${workflow}/dispatches`;

    return fetch(
      url,
      options(undefined, JSON.stringify({ ref: "staging" }), "POST")
    );
  },
  runsStaging: () => {
    const workflow_id = `653661`;
    const branch = `staging`;

    const url = `${githubActions}/${workflow_id}/runs?branch=${branch}`;

    return fetch(url, options(undefined, undefined, "GET")).then((response) => {
      return response.json();
    });
  },
  runsFrontendStaging: () => {
    const workflow_id = `15201419`;
    const branch = `staging`;

    const url = `${githubActions}/${workflow_id}/runs?branch=${branch}`;

    return fetch(url, options(undefined, undefined, "GET")).then((response) => {
      return response.json();
    });
  },
  runsAdminUIStaging: () => {
    const workflow_id = `5466684`;
    const branch = `staging`;

    const url = `${githubActions}/${workflow_id}/runs?branch=${branch}`;

    return fetch(url, options(undefined, undefined, "GET")).then((response) => {
      return response.json();
    });
  },
  deployMaster: () => {
    const workflow = `master-deploy.yml`;

    const url = `${githubActions}/${workflow}/dispatches`;

    return fetch(
      url,
      options(undefined, JSON.stringify({ ref: "master" }), "POST")
    );
  },
  deployFrontendMaster: () => {
    const workflow = `frontend-master-deploy.yml`;

    const url = `${githubActions}/${workflow}/dispatches`;

    return fetch(
      url,
      options(undefined, JSON.stringify({ ref: "master" }), "POST")
    );
  },
  deployAdminUIMaster: () => {
    const workflow = `admin-ui-master-deploy.yml`;

    const url = `${githubActions}/${workflow}/dispatches`;

    return fetch(
      url,
      options(undefined, JSON.stringify({ ref: "master" }), "POST")
    );
  },
  runsMaster: () => {
    const workflow_id = `5850255`;
    const branch = `master`;

    const url = `${githubActions}/${workflow_id}/runs?branch=${branch}`;

    return fetch(url, options(undefined, undefined, "GET")).then((response) => {
      return response.json();
    });
  },
  runsFrontendMaster: () => {
    const workflow_id = `15205876`;
    const branch = `master`;

    const url = `${githubActions}/${workflow_id}/runs?branch=${branch}`;

    return fetch(url, options(undefined, undefined, "GET")).then((response) => {
      return response.json();
    });
  },
  runsAdminUIMaster: () => {
    const workflow_id = `5500385`;
    const branch = `master`;

    const url = `${githubActions}/${workflow_id}/runs?branch=${branch}`;

    return fetch(url, options(undefined, undefined, "GET")).then((response) => {
      return response.json();
    });
  },
};

export default githubProvider;
