import * as React from "react";
import {
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import {
  TextInputField,
  BoxTitle,
  TextInputEmailField,
  ArrayOneFieldInputField,
} from "../components";

const SchoolContactFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`schools.boxes.contact_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextInputField
              source="phone"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputField
              source="fax"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputEmailField
              source="public_email"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputEmailField
              source="qa_email"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputEmailField
              source="reviews_email"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputEmailField
              source="requests_email"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12}>
            <ArrayOneFieldInputField
              source="cc_email"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputField
              source="street"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputField
              source="postcode"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputField
              source="city"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default SchoolContactFields;
