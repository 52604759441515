import React from "react";
import { LinearLoading } from "../components";
import { useTranslate, Labeled } from "react-admin";
import { Grid } from "@material-ui/core";
import { useMediaQuery, Theme } from "@material-ui/core";

export default function CreateUpdateInfo({ ...props }) {
  const translate = useTranslate();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      {(props === undefined ||
        props.record === undefined ||
        props.record.created_at === undefined) && (
        <Grid item xs={12}>
          <LinearLoading />
        </Grid>
      )}
      {!isXSmall &&
        props !== undefined &&
        props.record !== undefined &&
        props.record.created_at !== undefined && (
          <Grid item>
            <Labeled
              resource={props.resource}
              label={translate(`common.fields.created_at`)}
            >
              <>{props.record.created_at}</>
            </Labeled>
          </Grid>
        )}
      {props !== undefined &&
        props.record !== undefined &&
        props.record.updated_at !== undefined && (
          <Grid item>
            <Labeled
              resource={props.resource}
              label={translate(`common.fields.last_update`)}
            >
              <>{props.record.updated_at}</>
            </Labeled>
          </Grid>
        )}
    </Grid>
  );
}
