import * as React from "react";
import { SaveButton } from "react-admin";
import { ButtonStyle } from "../styles/ButtonStyle";
import { useMediaQuery, Theme } from "@material-ui/core";

const MySaveButton = (props) => {
  const classes = ButtonStyle();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  return (
    <SaveButton
      className={
        props.variant === "text"
          ? classes.saveTextButton
          : classes.containedButton
      }
      label={isXSmall ? "" : undefined}
      {...props}
    />
  );
};

export default MySaveButton;
