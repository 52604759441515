import * as React from "react";
import { FormWithRedirect } from "react-admin";
import { Box, Tabs, AppBar, Tab } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ContactsEmails from "./ContactEmails";
import ContactSchools from "./ContactSchools";
import ContactUsers from "./ContactUsers";
import Contacts from "./Contacts";
import ContactStatistics from "./ContactStatistics";
import ContactTodo from "./ContactTodo";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ContactsList = ({ ...props }: any) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function a11yProps(index: any) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const tabs = {
    0: {
      tab: "Generated Leads",
      tab_panel: <ContactsEmails />,
    },
    1: {
      tab: "Leads Source",
      tab_panel: <Contacts />,
    },
    2: {
      tab: "Leads School",
      tab_panel: <ContactSchools />,
    },
    3: {
      tab: "Leads User",
      tab_panel: <ContactUsers />,
    },
    4: {
      tab: "Statistics",
      tab_panel: <ContactStatistics />,
    },
    5: {
      tab: "TODO",
      tab_panel: <ContactTodo />,
    },
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
            >
              {Object.keys(tabs).map((key) => {
                return (
                  <Tab
                    tabIndex={Number(key)}
                    label={`${tabs[key].tab}`}
                    {...a11yProps(Number(key))}
                  />
                );
              })}
            </Tabs>

            {Object.keys(tabs).map((key) => {
              return (
                <TabPanel value={value} index={Number(key)}>
                  {tabs[key].tab_panel}
                </TabPanel>
              );
            })}
          </AppBar>
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default ContactsList;
