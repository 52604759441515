import * as React from "react";
import { SimpleForm, Create } from "react-admin";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReferenceSelectInputField, TextInputField } from "../components";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const Elements = ({ ...props }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextInputField
            source="name"
            resource={props.resource}
            record={props.record}
            validate={true}
          />
        </Grid>
        <Grid item xs={12}>
          <ReferenceSelectInputField
            source="organization_type_id"
            reference="organization_types"
            optionText="title"
            optionValue="id"
            validate={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const OrganizationCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <Elements />
      </SimpleForm>
    </Create>
  );
};

export default OrganizationCreate;
