import * as React from "react";
import { usePermissions } from "react-admin";
import OrganizationGeneralFields from "./OrganizationGeneralFields";
import OrganizationContactFields from "./OrganizationContactFields";
import OrganizationSocialNetworkFields from "./OrganizationSocialNetworkFields";
import OrganizationContactPersonFields from "./OrganizationContactPersonFields";
import OrganizationFollowFields from "./OrganizationFollowFields";
import OrganizationInfoboxFields from "./OrganizationInfoboxFields";
import OrganizationTeaserFields from "./OrganizationTeaserFields";
import OrganizationStudyFieldTeaserFields from "./OrganizationStudyFieldTeaserFields";
import OrganizationGalleryFields from "./OrganizationGalleryFields";
import OrganizationUploadsFields from "./OrganizationUploadsFields";
import OrganizationRelationFields from "./OrganizationRelationFields";
import OrganizationLocationFields from "./OrganizationLocationFields";
import { CreateUpdateInfo } from "../components";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";
import OrganizationDashboardFields from "./OrganizationDashboardFields";
import OrganizationQuestions from "./OrganizationQuestions";
import OrganizationFaqs from "./OrganizationFaqs";

const OrganizationTabSummary = ({
  action,
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const { permissions = [] } = usePermissions();
  return (
    <>
      <CreateUpdateInfo {...props} />
      <OrganizationGeneralFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      <OrganizationInfoboxFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      <OrganizationTeaserFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <OrganizationStudyFieldTeaserFields
          disabled_level_1={disabled_level_1}
          disabled_level_2={disabled_level_2}
          {...props}
        />
      )}
      <OrganizationGalleryFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      <OrganizationUploadsFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      <OrganizationContactFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      <OrganizationLocationFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      <OrganizationContactPersonFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      <OrganizationSocialNetworkFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        {...props}
      />
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <OrganizationFollowFields
          disabled_level_1={disabled_level_1}
          disabled_level_2={disabled_level_2}
          {...props}
        />
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <OrganizationRelationFields
          disabled_level_1={disabled_level_1}
          disabled_level_2={disabled_level_2}
          {...props}
        />
      )}
    </>
  );
};

export default OrganizationTabSummary;
