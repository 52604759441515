import { stringify } from "query-string";
import { apiUrl, httpClient } from "../dataProvider";

const statisticsProvider = {
  getAll: (resource: string, identifier: string, params: any) => {
    const { start_date, end_date, group_by } = params;
    const query = {
      start_date: start_date,
      end_date: end_date,
      group_by: group_by,
    };

    const url = `${apiUrl}/${resource}/${identifier}/analytics/all?${stringify(
      query
    )}`;

    return httpClient(url, undefined).then(({ headers, json }: any) => ({
      data: json,
    }));
  },
  getEduwoTotal: (resource: string, identifier: string, params: any) => {
    const { start_date, end_date, group_by } = params;
    const query = {
      start_date: start_date,
      end_date: end_date,
      group_by: group_by,
    };

    const url = `${apiUrl}/${resource}/${identifier}/analytics/eduwo/totals?${stringify(
      query
    )}`;

    return httpClient(url, undefined).then(({ headers, json }: any) => ({
      data: json,
    }));
  },
  getGoogleTotal: (resource: string, identifier: string, params: any) => {
    const { start_date, end_date, group_by } = params;
    const query = {
      start_date: start_date,
      end_date: end_date,
      group_by: group_by,
    };

    const url = `${apiUrl}/${resource}/${identifier}/analytics/google/totals?${stringify(
      query
    )}`;

    return httpClient(url, undefined).then(({ headers, json }: any) => ({
      data: json,
    }));
  },
  getEduwoDetail: (resource: string, identifier: string, params: any) => {
    const { start_date, end_date, group_by } = params;
    const query = {
      start_date: start_date,
      end_date: end_date,
      group_by: group_by,
    };

    const url = `${apiUrl}/${resource}/${identifier}/analytics/eduwo/details?${stringify(
      query
    )}`;

    return httpClient(url, undefined).then(({ headers, json }: any) => ({
      data: json,
    }));
  },
  getCourseDetail: (resource: string, identifier: string, params: any) => {
    const { start_date, end_date, group_by } = params;
    const query = {
      start_date: start_date,
      end_date: end_date,
      group_by: group_by,
    };

    const url = `${apiUrl}/${resource}/${identifier}/analytics/courses?${stringify(
      query
    )}`;

    return httpClient(url, undefined).then(({ headers, json }: any) => ({
      data: json,
    }));
  },
};

export default statisticsProvider;
