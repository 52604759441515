import * as React from "react";
import { FC } from "react";
import { Labeled, useTranslate } from "react-admin";
import Button from "@material-ui/core/Button";
import { Publish } from "@material-ui/icons";
import { DropzoneDialog } from "material-ui-dropzone";
import { UploadButtonWithIconProps } from "./types";
import { DARK_BLUE } from "../Constants";

const UploadButtonWithIcon: FC<UploadButtonWithIconProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const translate = useTranslate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Labeled label={" "}>
        <Button
          variant="contained"
          onClick={() => handleClickOpen()}
          style={{ backgroundColor: DARK_BLUE, color: "white" }}
        >
          <Publish />
        </Button>
      </Labeled>

      <DropzoneDialog
        acceptedFiles={props.accepted_files ?? ["image/*"]}
        cancelButtonText={translate(`common.cancel`)}
        submitButtonText={translate(`common.submit`)}
        maxFileSize={10000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          props.handleClick(files);
          setOpen(false);
        }}
        showPreviews={false}
        showFileNamesInPreview={true}
        showPreviewsInDropzone={true}
        filesLimit={props.files_limit ?? 1}
      />
    </>
  );
};

export default UploadButtonWithIcon;
