import * as React from "react";
import {
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  BooleanInput,
  required,
  Labeled,
} from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import { TextInputField, BoxTitle } from "../components";
import { Check, Clear } from "@material-ui/icons/";

const OrganizationQuestions = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.dashboard_questions`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {(disabled_level_2 === false  || disabled_level_2 === undefined) && (
              <ArrayInput source="questions">
                <SimpleFormIterator>
                  <SelectInput
                    fullWidth
                    source={`question_type`}
                    label={translate(
                      `organizations.fields.questions.question_type`
                    )}
                    placeholder={"question_type"}
                    resource={props.resource}
                    record={props.record}
                    validate={required()}
                    onChange={props.onChange}
                    choices={[{ id: "booking", name: "Booking" }]}
                    disabled={disabled_level_2}
                  />
                  <SelectInput
                    fullWidth
                    source={`style`}
                    label={translate(`organizations.fields.questions.style`)}
                    resource={props.resource}
                    record={props.record}
                    validate={required()}
                    choices={[
                      { id: "free_text", name: "Free Text" },
                      { id: "free_text_area", name: "Free Text Area" },
                      { id: "dropdown", name: "Dropdown" },
                    ]}
                    disabled={disabled_level_2}
                  />
                  <TextInputField
                    source="question"
                    label={translate(`organizations.fields.questions.question`)}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                    validate={true}
                  />
                  <TextInputField
                    source="description"
                    label={translate(
                      `organizations.fields.questions.description`
                    )}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                  <TextInputField
                    source="default_answer"
                    label={translate(
                      `organizations.fields.questions.default_answer`
                    )}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                  <TextInputField
                    source="placeholder"
                    label={translate(
                      `organizations.fields.questions.placeholder`
                    )}
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                  <ArrayInput
                    source="data"
                    label={translate(`organizations.fields.questions.data`)}
                  >
                    <SimpleFormIterator>
                      <TextInputField
                        source="title"
                        label={translate(
                          `organizations.fields.questions.dataFields.title`
                        )}
                        resource={props.resource}
                        record={props.record}
                        disabled={disabled_level_2}
                        validate={true}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                  <BooleanInput
                    source="mandatory"
                    label={translate(
                      `organizations.fields.questions.mandatory`
                    )}
                    disabled={disabled_level_2}
                    resource={props.resource}
                    record={props.record}
                    defaultValue={false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            )}
            {disabled_level_2 === true &&
              props !== undefined &&
              props.record !== undefined &&
              props.record.questions !== undefined &&
              Array.isArray(props.record.questions) &&
              props.record.questions.map((item, index) => (
                <div key={index}>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.questions.question_type`
                      )}
                    >
                      <>{item.question_type}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(`organizations.fields.questions.style`)}
                    >
                      <>{item.style}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.questions.question`
                      )}
                    >
                      <>{item.question}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.questions.description`
                      )}
                    >
                      <>{item.description}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.questions.description`
                      )}
                    >
                      <>{item.description}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.questions.default_answer`
                      )}
                    >
                      <>{item.default_answer}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.questions.placeholder`
                      )}
                    >
                      <>{item.placeholder}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(`organizations.fields.questions.data`)}
                    >
                      <>
                        {item.data !== undefined &&
                          Array.isArray(item.data) &&
                          item.data.map((d, index) => (
                            // <p>COISAS</p>

                            <Grid item xs={6}>
                              <Labeled
                                resource={props.resource}
                                label={translate(
                                  `organizations.fields.questions.dataFields.title`
                                )}
                              >
                                <>{d.title}</>
                              </Labeled>
                            </Grid>
                          ))}
                      </>
                    </Labeled>
                  </Grid>
                  <Grid item xs={6}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.questions.mandatory`
                      )}
                    >
                      <>{item.mandatory ? <Check /> : <Clear />}</>
                    </Labeled>
                  </Grid>
                </div>
              ))}
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationQuestions;
