import * as React from "react";
import { FC } from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DeleteButtonWithIcon, UploadButtonWithIcon } from ".";
import { useTranslate } from "react-admin";
import ImagesProvider from "../dataProvider/ImagesProvider";
import { Loading } from "../components";
import { ShowImageProps } from "./types";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "300px",
    maxHeight: "200px",
  },
}));

const ShowImageUrl: FC<ShowImageProps> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [removed, setRemoved] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  const handleDeleteClick = (resource, identifier, collection, name) => {
    if (name === "") {
      ImagesProvider.delete(resource, identifier, collection);
    } else {
      ImagesProvider.delete(resource, identifier, collection, name);
    }
    setRemoved(true);
  };
  const handleUploadClick = (resource, identifier, collection, file) => {
    setLoading(true);
    setRemoved(false);
    ImagesProvider.upload(resource, identifier, collection, file).then(
      (json: any) => {
        setRemoved(false);
        setUploaded(json);
        setLoading(false);
      }
    );
  };

  if ((uploaded !== undefined || loading === true) && removed === false) {
    return (
      <Grid container spacing={1} justify="flex-end">
        <Grid item xs={12}>
          <Typography color="textSecondary" gutterBottom>
            {props.title}
          </Typography>
          {loading === false && (
            <img
              src={uploaded}
              alt={props.image_alt}
              className={classes.root}
            />
          )}
        </Grid>
        {loading === true && <Loading />}
        {loading === false && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {!props.only_delete && (
                  <UploadButtonWithIcon
                    handleClick={(file) =>
                      handleUploadClick(
                        props.resource,
                        props.id,
                        props.collection,
                        file
                      )
                    }
                    files_limit={props.files_limit ?? 1}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <DeleteButtonWithIcon
                  handleClick={() =>
                    handleDeleteClick(
                      props.resource,
                      props.id,
                      props.collection,
                      props.name
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  if (removed && props.only_delete) {
    return <></>;
  }

  if (props.image_src === "" || removed) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color="textSecondary" gutterBottom>
            {props.title}
          </Typography>
          {translate(`common.not_available`)}
        </Grid>
        {!props.disabled && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {!props.only_delete && (
                  <UploadButtonWithIcon
                    handleClick={(file) =>
                      handleUploadClick(
                        props.resource,
                        props.id,
                        props.collection,
                        file
                      )
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography color="textSecondary" gutterBottom>
          {props.title}
        </Typography>
        <img
          src={uploaded === undefined ? props.image_src : uploaded}
          alt={props.image_alt}
          className={classes.root}
        />
      </Grid>
      {!props.disabled && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {!props.only_delete && (
                <UploadButtonWithIcon
                  handleClick={(file) =>
                    handleUploadClick(
                      props.resource,
                      props.id,
                      props.collection,
                      file
                    )
                  }
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <DeleteButtonWithIcon
                handleClick={() =>
                  handleDeleteClick(
                    props.resource,
                    props.id,
                    props.collection,
                    props.name
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ShowImageUrl;
