import * as React from "react";
import {
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  Labeled,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import { TextInputField, BoxTitle, RichTextInputField } from "../components";

const OrganizationStudyFieldTeaserFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.teaser_study_field`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {disabled_level_2 === false && (
              <ArrayInput source="teaser_study_field">
                <SimpleFormIterator>
                  <ReferenceInput
                    source={"study_field_id"}
                    reference={"study_fields"}
                    label={translate(`resources.study_fields.name_singular`)}
                    perPage={1000}
                  >
                    <SelectInput
                      fullWidth
                      optionText={"title"}
                      optionValue={"id"}
                    />
                  </ReferenceInput>
                  <TextInputField
                    source="title"
                    label="Title"
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                  <RichTextInputField
                    source="lead"
                    label="Lead"
                    resource={props.resource}
                    record={props.record}
                    disabled={disabled_level_2}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            )}
            {disabled_level_2 === true &&
              props !== undefined &&
              props.record !== undefined &&
              props.record.teaser_study_field !== undefined &&
              Array.isArray(props.record.teaser_study_field) &&
              props.record.teaser_study_field.map((item, index) => (
                <div key={index}>
                  <Grid item xs={12}>
                    <Labeled
                      resource={props.resource}
                      label={translate(`resources.study_fields.name_singular`)}
                    >
                      <>{item.study_field_title}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.teaser_study_field.title`
                      )}
                    >
                      <>{item.title}</>
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled
                      resource={props.resource}
                      label={translate(
                        `organizations.fields.teaser_study_field.lead`
                      )}
                    >
                      <div
                        className="tag-temp-text"
                        dangerouslySetInnerHTML={{ __html: item.lead }}
                      />
                    </Labeled>
                  </Grid>
                </div>
              ))}
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationStudyFieldTeaserFields;
