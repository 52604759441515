import * as React from "react";
import { useTranslate } from "react-admin";
import { Collapse } from "@material-ui/core";
import { BoxTitle, Gallery } from "../components";

const OrganizationUploadsFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.uploads`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Gallery
          disabled={disabled_level_2}
          type={"file"}
          gallery_array={
            props !== undefined &&
            props.record !== undefined &&
            props.record.uploads !== undefined &&
            props.record.uploads !== null &&
            Array.isArray(props.record.uploads)
              ? props.record.uploads
              : undefined
          }
          accepted_files={["application/pdf"]}
          collection="uploads"
          fullUrl={true}
          {...props}
        />
      </Collapse>
    </>
  );
};

export default OrganizationUploadsFields;
