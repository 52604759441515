import React from "react";
import { Line } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  alignCenter: { textAlign: "center" },
}));

const LineChart = ({ title, data, labels, legend }: any) => {
  const colors = [
    "rgba(0,0,0,1)",
    "rgba(0,0,128,1)",
    "rgba(0,0,255,1)",
    "rgba(0,128,0,1)",
    "rgba(0,128,128,1)",
    "rgba(0,255,0,1)",
    "rgba(0,255,255,1)",
    "rgba(128,0,0,1)",
    "rgba(128,0,128,1)",
    "rgba(128,128,0,1)",
    "rgba(128,128,128,1)",
    "rgba(192,192,192,1)",
    "rgba(255,0,0,1)",
    "rgba(255,0,255,1)",
    "rgba(255,255,0,1)",
    "rgba(255,255,255,1)",
  ];

  const datasets = data.map(function (d, index) {
    d.lineTension = 0.5;
    d.borderWidth = 2;
    d.fill = false;
    d.borderColor = colors[index];
    d.backgroundColor = 0.5;
    return d;
  });

  const classes = useStyles();

  return (
    <>
      {title !== undefined && <h2 className={classes.alignCenter}>{title}</h2>}
      <Line
        data={{
          labels: labels,
          datasets: datasets,
        }}
        options={{
          title: {
            display: false,
            text: title,
            fontSize: 20,
          },
          legend: {
            display: legend,
            position: "right",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
              },
            ],
          },
        }}
      />
    </>
  );
};

export default LineChart;
