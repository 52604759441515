import * as React from "react";
import {
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useTranslate } from "react-admin";
import { apiUrl, httpClient } from "../dataProvider";
import { Loading } from "../components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {},
  img: {
    maxWidth: "100%",
  },
}));

const CompanyRegister = () => {
  const translate = useTranslate();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [user, setUser] = React.useState<{
    id: null | BigInteger;
    first_name: null | string;
    last_name: null | string;
    job_title: null | string;
    phone: null | string;
  }>({
    id: null,
    first_name: "",
    last_name: "",
    job_title: "",
    phone: "",
  });

  const [organization, setOrganization] = React.useState<{
    name: null | string;
    make_expert: boolean;
  }>({ name: "", make_expert: true });
  const classes = useStyles();

  const [message, setMessage] = React.useState("");

  const [validation, setValidation] = React.useState<{
    validEmail: null | boolean;
    existsEmail: null | boolean;
    validPasswordSize: null | boolean;
    validPassword: null | boolean;
    userData: null | boolean;
    validUserData: null | boolean;
    validOrganizationName: null | boolean;
  }>({
    validEmail: null,
    existsEmail: null,
    validPasswordSize: false,
    validPassword: null,
    userData: null,
    validUserData: false,
    validOrganizationName: null,
  });
  const [confirmPasswordValidation, setConfirmPasswordValidation] =
    React.useState(false);

  const handleInputValue: any = (fieldValues) => {
    switch (fieldValues.target.id) {
      case "email":
        setEmail(fieldValues.target.value);
        break;
      case "password":
        setPassword(fieldValues.target.value);
        setValidation({
          ...validation,
          validPasswordSize: fieldValues.target.value.length >= 8,
        });
        break;
      case "confirm_password":
        setConfirmPassword(fieldValues.target.value);
        if (password !== fieldValues.target.value) {
          setConfirmPasswordValidation(false);
        } else {
          setConfirmPasswordValidation(true);
        }
        break;
      case "first_name":
        setUser({ ...user, first_name: fieldValues.target.value });
        break;
      case "last_name":
        setUser({ ...user, last_name: fieldValues.target.value });
        break;
      case "job_title":
        setUser({ ...user, job_title: fieldValues.target.value });
        break;
      case "phone":
        setUser({ ...user, phone: fieldValues.target.value ?? null });
        break;
      case "make_expert":
        setOrganization({
          ...organization,
          make_expert: fieldValues.target.checked,
        });
        break;
      case "organization_name":
        setOrganization({ ...organization, name: fieldValues.target.value });
        break;
      case "message":
        setMessage(fieldValues.target.value);
        break;
    }
  };

  const handleKeyDown: any = (e) => {
    console.log(e);
    if (e.key === "Enter") {
      switch (e.target.id) {
        case "email":
          validateEmail();
          break;
        case "password":
          if (validation.validPasswordSize) {
            validateEmail();
          }
          break;
      }
    }
  };

  const reset: any = () => {
    setValidation({
      validEmail: null,
      existsEmail: null,
      validPasswordSize: false,
      validPassword: null,
      userData: null,
      validUserData: false,
      validOrganizationName: null,
    });
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setUser({
      id: null,
      first_name: null,
      last_name: null,
      job_title: null,
      phone: null,
    });
    setOrganization({
      name: "",
      make_expert: true,
    });
  };

  const validateEmail: any = () => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const validEmail = regexp.test(email);
    if (validEmail) {
      setLoading(true);
      const body = password
        ? JSON.stringify({ email, password })
        : JSON.stringify({ email });

      const url = `${apiUrl}/users/validate_email`;
      httpClient(url, {
        method: "POST",
        body: body,
      }).then((json: any) => {
        if (password) {
          if (JSON.parse(json.body) === false) {
            setValidation({
              ...validation,
              validPassword: false,
            });
          } else {
            setValidation({
              ...validation,
              validPassword: true,
              userData: true,
            });
            setUser(JSON.parse(json.body));
          }
        } else {
          setValidation({
            ...validation,
            validEmail: true,
            existsEmail: JSON.parse(json.body),
            userData: JSON.parse(json.body) === false,
          });
        }
        setLoading(false);
      });
    } else {
      setValidation({ ...validation, validEmail: false });
    }
  };

  const regist: any = () => {
    setLoading(true);
    const url = `${apiUrl}/users/store_from_register`;
    httpClient(url, {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
        first_name: user.first_name,
        last_name: user.last_name,
        job_title: user.job_title,
        phone: user.phone,
      }),
    }).then((json: any) => {
      setValidation({
        ...validation,
        validUserData: true,
      });
      setUser({ ...user, id: JSON.parse(json.body).id });
      setLoading(false);
    });
  };

  const submit: any = () => {
    setLoading(true);
    const url = `${apiUrl}/users/${user.id}/update_from_register`;
    httpClient(url, {
      method: "PUT",
      body: JSON.stringify({
        first_name: user.first_name,
        last_name: user.last_name,
        job_title: user.job_title,
        phone: user.phone,
      }),
    }).then((json: any) => {
      console.log(json);
      setValidation({
        ...validation,
        validUserData: true,
      });
      setLoading(false);
    });
  };

  const validateOrganizationName: any = () => {
    setLoading(true);
    const url = `${apiUrl}/organizations/validate_name?name=${organization.name}`;
    httpClient(url, {
      method: "POST",
      body: JSON.stringify({
        name: organization.name,
        make_expert: organization.make_expert,
        user_id: user.id,
      }),
    }).then((json: any) => {
      console.log(json);
      setValidation({
        ...validation,
        validOrganizationName: JSON.parse(json.body),
      });
      setLoading(false);
    });
  };

  const sendRequest: any = () => {
    setLoading(true);
    const url = `${apiUrl}/organizations/request_to_be_admin`;
    httpClient(url, {
      method: "POST",
      body: JSON.stringify({
        email,
        user_id: user.id,
        organization_name: organization.name,
        make_expert: organization.make_expert,
        message,
      }),
    }).then((json: any) => {
      console.log(json);
      setValidation({
        ...validation,
        validUserData: true,
      });
      setLoading(false);
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <h1>Herzlich Willkommen bei eduwo</h1>
        <h2>
          Wir helfen Ihnen bei den Themen:
          <ul>
            <li>Employer Branding</li>
            <li>Personalentwicklung</li>
          </ul>
        </h2>
        <p>
          Falls Sie weitere Informationen zu diesen beiden Themen wünschen,
          finden Sie unter dem folgenden Link eine einfache und schnelle
          Übersicht.
        </p>
        <a href="https://unternehmen.eduwo.ch/" target={"_blank"}>
          https://unternehmen.eduwo.ch/
        </a>
        <p>
          Mit der nachfolgenden Registration erhalten Sie kostenlos und
          unverbindlich Zugriff auf das Backend von eduwo. Sie können dort für
          Ihr Unternehmen ein kostenloses Profil erstellen und selber
          entscheiden, wann Sie das Profil öffentlich anzeigen lassen wollen.
        </p>

        <h1>Registration für Administratoren</h1>
        <p>
          Sie sind in Ihrem Unternehmen für das HR, Employer Branding und/oder
          die Personalentwicklung zuständig? Dann sind sie hier richtig.
        </p>
        <h3>Prozess</h3>
        <p>
          Erfassen Sie hier unten Ihre geschäftliche Email-Adresse. Nach der
          Verifikation erhalten Sie in wenigen Schritten Zugriff auf das
          Unternehmens-Profil zugreifen des Unternehmens. Sie entscheiden dann
          selber, zu welchem Zeitpunkt Sie das Profil auf eduwo veröffentlichen
          wollen.
        </p>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <TextField
              id="email"
              type="email"
              placeholder={`Ihre Email`}
              required
              onChange={handleInputValue}
              fullWidth
              variant="outlined"
              label={translate(`register.user.email.label`)}
              helperText={
                validation.validEmail === false
                  ? translate(`sentence.valid_email`)
                  : ""
              }
              disabled={validation.validEmail === true}
              onKeyDown={handleKeyDown}
              error={validation.validEmail === false ? true : false}
              value={email}
            />
          </Grid>
          <Grid item xs={2}>
            {loading ? (
              <Loading />
            ) : (
              <Button
                variant="contained"
                onClick={validation.validEmail ? reset : validateEmail}
              >
                {validation.validEmail
                  ? translate(`button.reset_email`)
                  : translate(`button.validate`)}
              </Button>
            )}
          </Grid>
          {validation.validEmail &&
            validation.existsEmail === true &&
            validation.userData !== true && (
              <>
                <Grid item xs={10}>
                  <TextField
                    id="password"
                    type="password"
                    placeholder={translate(
                      `register.user.password.placeholder`
                    )}
                    required
                    onChange={handleInputValue}
                    fullWidth
                    variant="outlined"
                    label={translate(`register.user.password.label`)}
                    helperText={`Forgot the password?${
                      validation.validPassword === false
                        ? ` Wrong Password`
                        : ``
                    }`}
                    onKeyDown={handleKeyDown}
                    error={validation.validPassword === false ? true : false}
                    value={password}
                  />
                </Grid>
                <Grid item xs={2}>
                  {loading ? (
                    <Loading />
                  ) : (
                    <Button
                      variant="contained"
                      onClick={validateEmail}
                      disabled={validation.validPasswordSize !== true}
                    >
                      {translate(`button.login`)}
                    </Button>
                  )}
                </Grid>
              </>
            )}
        </Grid>
        <p>
          Ihre Email-Adresse wird nicht an Dritte weitergegeben oder für andere
          Zwecke verwendet.
        </p>
      </Grid>
      <Grid item xs={12} md={6}>
        <h1>Fakten zu eduwo</h1>
        <p>
          Ein seit 2016 inhabergeführtes Unternehmen im Bereich Ausbildung und
          Weiterbildung mit Fokus auf die Schweiz.
        </p>
        <ul>
          <li>380'000 Unique User</li>
          <li> 270 Schulen und 5'500 Lehrgänge</li>{" "}
          <li> 7'000 Kurs-Reviews 60</li>
          <li>Unternehmen Verbände und BIZ</li>
        </ul>
        <p>
          eduwo bringt Bildung und Karriere näher zusammen, sodass Personen
          einen passenden Lehrgang finden, der auf dem Arbeitsmarkt idealerweise
          auch maximal nachgefragt ist. Dabei steht der User immer im Fokus und
          die Benutzung der Plattform durch den User ist kostenlos.
        </p>
        <h2>Ihr persönlicher Kontakt</h2>{" "}
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <img
                  src={"https://dev.eduwo.ch/assets/images/Raphael-Tobler.jpg"}
                  className={classes.img}
                  alt="Raphael Tobler"
                />
              </Grid>
              <Grid item>
                <p>
                  Raphael Tobler<br></br>CEO eduwo.ch
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <p>
              079 278 32 94<br></br>raphael@eduwo.ch
            </p>
            <br></br>
            <p>
              Falls Sie eine Besprechung mit mir wünschen, erhalten Sie unter
              dem folgenden Link Zugriff auf meine Agenda:
            </p>
            <p>
              <a href=" https://calendly.com/tobler/30min" target={"_blank"}>
                https://calendly.com/tobler/30min
              </a>
            </p>
          </Grid>
        </Grid>
      </Grid>

      {validation.validEmail && (
        <>
          {validation.userData === true && (
            <>
              <Grid item xs={6}>
                <TextField
                  id="first_name"
                  type="text"
                  placeholder={translate(
                    `register.user.first_name.placeholder`
                  )}
                  required
                  onChange={handleInputValue}
                  fullWidth
                  variant="outlined"
                  label={translate(`register.user.first_name.label`)}
                  value={user.first_name}
                  disabled={validation.validUserData === true}
                  error={validation.validPassword === false ? true : false}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="last_name"
                  type="text"
                  placeholder={translate(`register.user.last_name.placeholder`)}
                  required
                  onChange={handleInputValue}
                  fullWidth
                  variant="outlined"
                  label={translate(`register.user.last_name.label`)}
                  value={user.last_name}
                  disabled={validation.validUserData === true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="job_title"
                  type="text"
                  placeholder={translate(`register.user.job_title.placeholder`)}
                  required
                  onChange={handleInputValue}
                  fullWidth
                  variant="outlined"
                  label={translate(`register.user.job_title.label`)}
                  value={user.job_title}
                  disabled={validation.validUserData === true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="phone"
                  type="text"
                  placeholder={translate(`register.user.phone.placeholder`)}
                  required
                  onChange={handleInputValue}
                  fullWidth
                  variant="outlined"
                  label={translate(`register.user.phone.label`)}
                  value={user.phone}
                  disabled={validation.validUserData === true}
                />
              </Grid>

              {validation.existsEmail === false && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      id="password"
                      type="password"
                      placeholder={translate(
                        `register.user.password.placeholder`
                      )}
                      required
                      onChange={handleInputValue}
                      fullWidth
                      variant="outlined"
                      label={translate(`register.user.password.label`)}
                      helperText={"Minimum 8 characters"}
                      disabled={validation.validUserData === true}
                      value={password}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="confirm_password"
                      type="password"
                      placeholder={translate(
                        `register.user.confirm_password.placeholder`
                      )}
                      required
                      onChange={handleInputValue}
                      fullWidth
                      variant="outlined"
                      label={translate(`register.user.confirm_password.label`)}
                      helperText={
                        confirmPasswordValidation ? "" : "Passwords don't match"
                      }
                      disabled={validation.validUserData === true}
                      value={confirmPassword}
                    />
                  </Grid>
                </>
              )}
              {validation.validUserData === false && (
                <Grid item xs={12}>
                  {loading ? (
                    <Loading />
                  ) : (
                    <Button
                      variant="contained"
                      onClick={
                        validation.existsEmail === false ? regist : submit
                      }
                      disabled={
                        (validation.existsEmail === false &&
                          (!(
                            user.first_name !== "" &&
                            user.last_name !== "" &&
                            user.job_title !== "" &&
                            user.phone !== ""
                          ) ||
                            confirmPasswordValidation === false ||
                            validation.validPasswordSize === false)) ||
                        (validation.existsEmail === true &&
                          !(
                            user.first_name !== "" &&
                            user.last_name !== "" &&
                            user.job_title !== "" &&
                            user.phone !== ""
                          ))
                      }
                    >
                      {validation.existsEmail === false
                        ? translate(`button.regist`)
                        : translate(`button.submit`)}
                    </Button>
                  )}
                </Grid>
              )}
            </>
          )}
          {validation.validUserData === true && (
            <>
              <Grid item xs={12}>
                <TextField
                  id="organization_name"
                  type="text"
                  placeholder="Insert the company name"
                  required
                  onChange={handleInputValue}
                  fullWidth
                  variant="outlined"
                  label="Company Name"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  value="make_expert"
                  control={
                    <Checkbox
                      id="make_expert"
                      color="primary"
                      onChange={handleInputValue}
                      checked={organization.make_expert}
                    />
                  }
                  label={<p>Make me expert of the company</p>}
                  labelPlacement="end"
                />
              </Grid>
              {validation.validOrganizationName === null && (
                <Grid item xs={12}>
                  {loading ? (
                    <Loading />
                  ) : (
                    <Button
                      variant="contained"
                      onClick={validateOrganizationName}
                    >
                      {translate(`button.submit`)}
                    </Button>
                  )}
                </Grid>
              )}
            </>
          )}
          {validation.validOrganizationName === false && (
            <>
              <Grid item xs={12}>
                <p>
                  Eduwo needs to approve, please justify why you are the
                  administrator of this company.
                </p>
                <Grid item xs={12}></Grid>
                <TextField
                  id="message"
                  type="text"
                  required
                  onChange={handleInputValue}
                  fullWidth
                  variant="outlined"
                  label="Small Justification"
                  rows={5}
                  maxRows={10}
                  multiline
                />
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" onClick={sendRequest}>
                  Send
                </Button>
              </Grid>
            </>
          )}
          {validation.validOrganizationName === true && (
            <Grid item xs={12}>
              <p>Please, check your email and follow the link.</p>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default CompanyRegister;
