import React from "react";
import { ReferenceArrayAutocompleteInputField } from "../components";
import { Grid } from "@material-ui/core";

export default function ReferenceStudyFields({
  disabled,
  reference = "study_fields",
  resource = "",
  source = "study_field_ids",
  sort = { field: "title", order: "ASC" },
  perPage = 1000,
  optionText = "title",
  optionValue = "id",
}) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ReferenceArrayAutocompleteInputField
          reference={reference}
          source={source}
          sort={sort}
          optionText={optionText}
          optionValue={optionValue}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}
