import { usePermissions } from "react-admin";
import CourseGeneralFields from "./CourseGeneralFields";
import CourseAdmissionFields from "./CourseAdmissionFields";
import CourseDescriptionFields from "./CourseDescriptionFields";
import CourseLocaltionFields from "./CourseLocationFields";
import CourseContactFields from "./CourseContactFields";
import CourseSocialNetworkFields from "./CourseSocialNetworkFields";
import CourseContactDirectorFields from "./CourseContactDirectorFields";
import CourseReimbursementFields from "./CourseReimbursementFields";
import CourseInfoboxFields from "./CourseInfoboxFields";
import CourseStartDateFields from "./CourseStartDateFields";
import CourseRelationFields from "./CourseRelationFields";
import CourseGalleryFields from "./CourseGalleryFields";
import CourseUploadsFields from "./CourseUploadsFields";
import CourseInfoboxSoaFields from "./CourseInfoboxSoaFields";
import { CreateUpdateInfo } from "../components";
import { USER_ROLE_SUPER_ADMIN, USER_ROLE_SOA_ADMIN } from "../Constants";
import CourseLeadManagementFields from "./CourseLeadManagementFields";

const CourseTabSummary = ({
  action,
  disabled_level_1,
  disabled_level_2,
  disabled_level_3,
  disabled_level_4,
  ...props
}: any) => {
  const { permissions = [] } = usePermissions();

  if (action === undefined) {
    action = "show";
  }

  return (
    <>
      <CreateUpdateInfo {...props} />
      <CourseGeneralFields
        disabled_level_1={disabled_level_1}
        disabled_level_2={disabled_level_2}
        disabled_level_3={disabled_level_3}
        disabled_level_4={disabled_level_4}
        {...props}
      />
      {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
        <>
          <CourseDescriptionFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            {...props}
          />
          <CourseAdmissionFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            {...props}
          />
          <CourseLocaltionFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            {...props}
          />
          <CourseInfoboxFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            {...props}
          />
        </>
      )}
      {(permissions.includes(USER_ROLE_SOA_ADMIN) ||
        permissions.includes(USER_ROLE_SUPER_ADMIN)) && (
        <>
          <CourseInfoboxSoaFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            disabled_level_4={disabled_level_4}
            {...props}
          />
        </>
      )}
      {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
        <>
          <CourseStartDateFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            {...props}
          />
          <CourseGalleryFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            {...props}
          />
          <CourseUploadsFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            {...props}
          />
          <CourseContactFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            {...props}
          />
          <CourseContactDirectorFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            {...props}
          />
          <CourseSocialNetworkFields
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            disabled_level_3={disabled_level_3}
            {...props}
          />
        </>
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <CourseReimbursementFields
          disabled_level_1={disabled_level_1}
          disabled_level_2={disabled_level_2}
          disabled_level_3={disabled_level_3}
          {...props}
        />
      )}
      {permissions.includes(USER_ROLE_SUPER_ADMIN) && (
        <CourseLeadManagementFields
          disabled_level_1={disabled_level_1}
          disabled_level_2={disabled_level_2}
          disabled_level_3={disabled_level_3}
          {...props}
        />
      )}
      {!permissions.includes(USER_ROLE_SOA_ADMIN) && (
        <CourseRelationFields
          disabled_level_1={disabled_level_1}
          disabled_level_2={disabled_level_2}
          disabled_level_3={disabled_level_3}
          {...props}
        />
      )}
    </>
  );
};

export default CourseTabSummary;
