import * as React from "react";
import { FC } from "react";
import {
  ReferenceInput,
  SelectInput,
  useTranslate,
  required,
} from "react-admin";
import { ReferenceSelectProps } from "./types";

const ReferenceSelectInputField: FC<ReferenceSelectProps> = (props) => {
  const translate = useTranslate();

  return (
    <ReferenceInput
      source={props.source}
      reference={props.reference}
      label={translate(`resources.${props.reference}.name_singular`)}
      perPage={props.pagination ? props.pagination.perPage : 1000}
      filter={props.filter ?? undefined}
      allowEmpty={props.allowEmpty}
    >
      <SelectInput
        fullWidth
        disabled={props.disabled}
        optionText={props.optionText}
        optionValue={props.optionValue}
        inputProps={props.inputProps}
        validate={props.validate ? [required()] : []}
        helperText={props.helperText ?? <p></p>}
      />
    </ReferenceInput>
  );
};

export default ReferenceSelectInputField;
