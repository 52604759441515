import * as React from "react";
import { useTranslate } from "react-admin";
import {
  ReferenceArrayAutocompleteInputField,
  BoxTitle,
  BooleanInputField,
  RichTextInputField,
} from "../components";
import { Grid, Collapse } from "@material-ui/core";

const OrganizationFollowFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`organizations.boxes.follow`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <BooleanInputField
              source="follow_expert_tab"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BooleanInputField
              source="follow_school_tab"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BooleanInputField
              source="follow_course_tab"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BooleanInputField
              source="follow_organization_tab"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextInputField
              source="follow_schools_description"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
              translation={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference="schools"
              source="follow_school_ids"
              sort={{ field: "name", order: "ASC" }}
              optionText="name"
              optionValue="id"
              disabled={disabled_level_1}
              renderSugestions={1}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextInputField
              source="follow_courses_description"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
              translation={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference="courses"
              source="follow_course_ids"
              sort={{ field: "short_name", order: "ASC" }}
              optionText="name_with_school"
              optionValue="id"
              disabled={disabled_level_1}
              renderSugestions={3}
              filter={{
                show_only: ["id", "name_with_school"],
              }}
              dynamic={true}
            />
          </Grid>
          <Grid item xs={12}>
            <RichTextInputField
              source="follow_organizations_description"
              disabled={disabled_level_2}
              resource={props.resource}
              record={props.record}
              translation={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayAutocompleteInputField
              reference="organizations"
              source="follow_organization_ids"
              sort={{ field: "name", order: "ASC" }}
              optionText="name"
              optionValue="id"
              disabled={disabled_level_1}
              renderSugestions={1}
              filter={{ show_only: ["id", "name"], except_ids: [props.id] }}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default OrganizationFollowFields;
