import React from "react";
import { Grid, Collapse } from "@material-ui/core";
import { BoxTitle, CardWithIcon, LineChart } from "../components";
import { LocalLibrary } from "@material-ui/icons";
import { useTranslate } from "react-admin";

const SchoolCoursesStatistics = ({ loading, stats, line_chart_labels }) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();
  return (
    <div>
      <BoxTitle
        title={translate(`statistics.schools.courses.title`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        {loading !== false && <p>Not Loaded</p>}
        {loading === false &&
          stats !== null &&
          stats !== undefined &&
          stats.length !== 0 && (
            <>
              <Grid container spacing={1}>
                {stats.eduwo !== undefined && (
                  <>
                    <Grid item xs={4}>
                      <CardWithIcon
                        title="TOTAL VISITORS"
                        value={parseInt(stats.eduwo.courses_total.views)}
                        icon={<LocalLibrary />}
                        note={`Value in google: ${stats.google.courses_total.views}`}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CardWithIcon
                        title="TOTAL UNIQUE VISITORS"
                        value={parseInt(
                          stats.eduwo.courses_total.total_unique_users
                        )}
                        icon={<LocalLibrary />}
                        note={`Value in google: ${stats.google.courses_total.total_unique_users}`}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CardWithIcon
                        title="AVERAGE TIME (GOOGLE)"
                        value={stats.google.courses_total.avg_time}
                        icon={<LocalLibrary />}
                        note={`Value from google`}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <LineChart
                    data={[
                      // {
                      //   label: "Visitors",
                      //   data: stats.eduwo.courses_details.map((entry) => {
                      //     return Object.values<any>(entry)[0].views;
                      //   }),
                      // },
                      // {
                      //   label: "Unique Visitors",
                      //   data: stats.eduwo.courses_details.map((entry) => {
                      //     return Object.values<any>(entry)[0]
                      //       .total_unique_users;
                      //   }),
                      // },
                      {
                        label: "Google Visitors",
                        data: stats.google_courses.courses_details.map(
                          (entry) => {
                            return Object.values<any>(entry)[0].views;
                          }
                        ),
                      },
                      {
                        label: "Google Unique Visitors",
                        data: stats.google_courses.courses_details.map(
                          (entry) => {
                            return Object.values<any>(entry)[0]
                              .total_unique_users;
                          }
                        ),
                      },
                      // {
                      //   label: "DE Visitors",
                      //   data: stats.eduwo.courses_details_de.map((entry) => {
                      //     return Object.values<any>(entry)[0].views;
                      //   }),
                      // },
                      // {
                      //   label: "DE Unique Visitors",
                      //   data: stats.eduwo.courses_details_de.map((entry) => {
                      //     return Object.values<any>(entry)[0]
                      //       .total_unique_users;
                      //   }),
                      // },
                      // {
                      //   label: "FR Visitors",
                      //   data: stats.eduwo.courses_details_fr.map((entry) => {
                      //     return Object.values<any>(entry)[0].views;
                      //   }),
                      // },
                      // {
                      //   label: "FR Unique Visitors",
                      //   data: stats.eduwo.courses_details_fr.map((entry) => {
                      //     return Object.values<any>(entry)[0]
                      //       .total_unique_users;
                      //   }),
                      // },
                    ]}
                    labels={line_chart_labels}
                    legend={true}
                  />
                </Grid>
                {/* <Grid item md={12}>
                  <LineChart
                    data={stats.eduwo.courses_courses_details.map((entry) => {
                      return {
                        label: Object.keys(entry)[0],
                        data: Object.values<any>(entry)[0].map((values) => {
                          return Object.values<any>(values)[0].views;
                        }),
                      };
                    })}
                    labels={line_chart_labels}
                    legend={false}
                  />
                </Grid> */}
              </Grid>
            </>
          )}
      </Collapse>
    </div>
  );
};

export default SchoolCoursesStatistics;
