import * as React from "react";
import { FC } from "react";
import { useTranslate } from "react-admin";
import { TextProps } from "./types";
import {
  Grid,
  Card,
  Typography,
  DialogTitle,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import TranslateIcon from "@material-ui/icons/Translate";
import { HorizontalLine } from ".";

const TranslationButton: FC<TextProps> = (props) => {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div onClick={() => handleClickOpen()} style={{ cursor: "pointer" }}>
          <TranslateIcon />
        </div>
        <Dialog
          open={open}
          scroll={"body"}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Card>
            <DialogTitle id="alert-dialog-title">
              {props.label ??
                translate(`${props.resource}.fields.${props.source}`)}
              <HorizontalLine />
            </DialogTitle>
            <DialogContent>
              {props.record !== undefined &&
              props.record.translations !== undefined ? (
                props.record.translations.map((translation) => (
                  <>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {translate(`languages.${translation.locale}`)}
                    </Typography>
                    <p>{translation[props.source]}</p>
                  </>
                ))
              ) : (
                <p></p>
              )}
            </DialogContent>
          </Card>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default TranslationButton;
