import * as React from "react";
import { Grid } from "@material-ui/core";
import MyLocalStorage from "../utils/MyLocalStorage";
import { useTranslate } from "react-admin";

const Dashboard = () => {
  const translate = useTranslate();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h1>{translate(`dashboard.title`)}</h1>
      </Grid>
      <Grid item xs={12} sm={6}>
        <p>
          {translate(`dashboard.user_name`)}{" "}
          {MyLocalStorage.getCookie("username")}
        </p>
      </Grid>
      <Grid item xs={12} sm={6}>
        <p>
          {translate(`dashboard.role`)} {MyLocalStorage.getCookie("role")}
        </p>
      </Grid>
      <Grid item xs={12}>
        <p>{translate(`dashboard.admin_schools`)}</p>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <h2>{translate(`dashboard.explanation.title`)} </h2>
          </Grid>
          <Grid item xs={12}>
            <p>{translate(`dashboard.explanation.body`)} </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <h2>{translate(`dashboard.premium_profile.title`)} </h2>
          </Grid>
          <Grid item xs={12}>
            <p>{translate(`dashboard.premium_profile.body`)} </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <h2>{translate(`dashboard.non_premium_profile.title`)} </h2>
          </Grid>
          <Grid item xs={12}>
            <p>{translate(`dashboard.non_premium_profile.body`)} </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
