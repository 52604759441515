import * as React from "react";
import { useTranslate } from "react-admin";
import { Grid, Collapse } from "@material-ui/core";
import { BoxTitle, BooleanInputField, NumberInputField } from "../components";

const CourseReimbursementFields = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const [collapse, setCollapse] = React.useState(true);
  const translate = useTranslate();

  return (
    <>
      <BoxTitle
        title={translate(`courses.boxes.reimbursement_fields`)}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={collapse}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <BooleanInputField
              source="has_government_subsidies"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInputField
              source="amount_of_subsidies"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BooleanInputField
              source="has_eduwo_reimbursements"
              resource={props.resource}
              disabled={disabled_level_1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInputField
              source="amount_eduwo_reimbursements"
              resource={props.resource}
              record={props.record}
              disabled={disabled_level_2}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default CourseReimbursementFields;
