import * as React from "react";
import {
  FormWithRedirect,
  useTranslate,
  useGetList,
  useRefresh,
} from "react-admin";
import { StyledTableCell, StyledTableRow } from "../styles";
import {
  Collapse,
  Grid,
  Box,
  Tabs,
  AppBar,
  Tab,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons/";
import PropTypes from "prop-types";
import { apiUrl, httpClient } from "../dataProvider";
import { HorizontalLine, LinearLoading, Loading } from "../components";
import { Receivers, Sender, Lead, Criteria, AddReceiver } from "./Contacts";
import { ConfirmationModalChecks } from "../components";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const table_fields = [
  "to_email",
  "school_name",
  "total_courses",
  "price",
  "created_at",
];

const Row = ({ status, row }: any) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </StyledTableCell>
        {table_fields.map((table_field) => (
          <StyledTableCell>{row[table_field]}</StyledTableCell>
        ))}
      </StyledTableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open}>
          <Grid container spacing={1}>
            <Sender contact={row["contact"]} />
            <Lead contact={row["contact"]} />
            {row["contact"]["source"].indexOf("wizard") >= 0 && (
              <>
                <AddReceiver contact={row["contact"]} />
                <Criteria contact={row["contact"]} />
              </>
            )}
            <Receivers contactEmails={[row]} status={status} />
            <Grid item xs={12}>
              <HorizontalLine size={"small"} />
            </Grid>
            <LeadButtons row={row} status={status} />
          </Grid>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
};

export const LeadButtons = ({ status, row }: any) => {
  const [sendModel, setSendModel] = React.useState(false);
  const [newContactModels, setNewContactModels] = React.useState<any>([]);
  let courses2 = {};
  row["contact_model"].forEach((model) => {
    courses2 = Object.assign(courses2, { [model["id"]]: true });
  });
  const [sendChecks, setSendChecks] = React.useState({
    anonymous: row["anonymous"],
    archive: true,
    courses: courses2,
    send_phone: true,
  });
  const refresh = useRefresh();

  const resource = "contact_emails";

  const Anonymous = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendChecks({ ...sendChecks, anonymous: event.target.checked });
  };

  const SendCourses = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendChecks({
      ...sendChecks,
      courses: {
        ...sendChecks.courses,
        [event.target.value]: event.target.checked,
      },
    });
  };

  const Archive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendChecks({ ...sendChecks, archive: event.target.checked });
  };

  const SendPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendChecks({ ...sendChecks, send_phone: event.target.checked });
  };

  const handleSend = (id) => {
    const url = `${apiUrl}/${resource}/${id}/send`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(sendChecks),
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const handleSendAndArchice = (id) => {
    const url = `${apiUrl}/${resource}/${id}/send_archive`;
    return httpClient(url, {
      method: "PUT",
      body: {},
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const handleReject = (id) => {
    const url = `${apiUrl}/${resource}/${id}/reject`;
    return httpClient(url, {
      method: "PUT",
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const handleArchive = (id) => {
    const url = `${apiUrl}/${resource}/${id}/archive`;
    return httpClient(url, {
      method: "PUT",
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const handleUnarchive = (id) => {
    const url = `${apiUrl}/${resource}/${id}/unarchive`;
    return httpClient(url, {
      method: "PUT",
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const handlePending = (id) => {
    const url = `${apiUrl}/${resource}/${id}/pending`;
    return httpClient(url, {
      method: "PUT",
    }).then(({ headers, json }: any) => {
      refresh();
    });
  };

  const SendCheckboxes = (row: any) => {
    const [newCourses, setNewCourses] = React.useState(undefined);
    const [loadingCourses, setLoadingCourses] = React.useState(false);
    const [newCourse, setNewCourse] = React.useState(undefined);

    const getCourses = () => {
      setLoadingCourses(true);
      const url = `${apiUrl}/contact_emails/${row["row"]["id"]}/by_email?email=${row["row"]["to_email"]}`;
      return httpClient(url, {
        method: "GET",
      }).then(({ headers, json }: any) => {
        setLoadingCourses(false);
        setNewCourses(json);
      });
    };

    const addCourseEmail = () => {
      setLoadingCourses(true);
      const url = `${apiUrl}/contact_emails/${row["row"]["id"]}/add_course`;
      return httpClient(url, {
        method: "PUT",
        body: JSON.stringify({ course_id: newCourse }),
      })
        .then(({ headers, json }: any) => {
          console.log(json);
          const aux = JSON.parse(JSON.stringify(newContactModels));
          aux.push(json);
          console.log(newContactModels);
          setNewContactModels(aux);
          setLoadingCourses(false);
        })
        .catch((error: Error) => {
          setLoadingCourses(false);
        });
    };

    const setNewSelectedCourse = (event) => {
      setNewCourse(event.target.value);
    };

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControlLabel
            value="anonymous"
            control={
              <Checkbox
                color="primary"
                onChange={Anonymous}
                checked={sendChecks.anonymous}
              />
            }
            label={"Anonymous email"}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value="sendPhone"
            control={
              <Checkbox
                color="primary"
                onChange={SendPhone}
                checked={sendChecks.send_phone}
              />
            }
            label={"Send phone number"}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value="archive"
            control={
              <Checkbox
                color="primary"
                onChange={Archive}
                checked={sendChecks.archive}
              />
            }
            label={"Archive contact after send"}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        {row["row"]["contact_model"].map((model) => {
          return (
            <Grid item xs={12}>
              <FormControlLabel
                value={model.id}
                control={
                  <Checkbox
                    color="primary"
                    onChange={SendCourses}
                    checked={sendChecks.courses[model.id]}
                  />
                }
                label={model.course_short_name}
                labelPlacement="end"
              />
            </Grid>
          );
        })}
        {newContactModels.map((model) => {
          return (
            <Grid item xs={12}>
              <FormControlLabel
                // @ts-ignore
                value={model.id}
                control={
                  <Checkbox
                    color="primary"
                    onChange={SendCourses}
                    // @ts-ignore
                    checked={sendChecks.courses[model.id]}
                  />
                }
                // @ts-ignore
                label={model.course_short_name}
                labelPlacement="end"
              />
            </Grid>
          );
        })}
        {loadingCourses ? (
          <Loading />
        ) : (
          <>
            {!newCourses && (
              <Button
                variant="outlined"
                style={{ color: "blue" }}
                onClick={() => getCourses()}
              >
                {"Add Courses with this email addres"}
              </Button>
            )}
            {newCourses && (
              <Grid container spacing={1}>
                <Grid item xs={10}>
                  <FormControl>
                    <InputLabel id="user-type-label">{"Courses"}</InputLabel>
                    <Select
                      labelId={"user-type-label"}
                      id="priority"
                      onChange={setNewSelectedCourse}
                    >
                      {/* @ts-ignore */}
                      {newCourses.map((course) => {
                        return (
                          <MenuItem value={course["id"]}>
                            {course["short_name"]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    style={{ color: "blue" }}
                    onClick={() => addCourseEmail()}
                  >
                    {"Add"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={2}
        >
          {status === "pending" && (
            <>
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ color: "red" }}
                  onClick={() => handleReject(row["id"])}
                >
                  {"REJECT"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ color: "green" }}
                  // onClick={() => handleSend(row["id"])}
                  onClick={() => {
                    setSendModel(true);
                  }}
                >
                  {"SEND"}
                </Button>
              </Grid>
            </>
          )}
          {status === "rejected" && (
            <>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => handlePending(row["id"])}
                >
                  {"Move To Pending"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ color: "green" }}
                  onClick={() => handleArchive(row["id"])}
                >
                  {"ARCHIVE"}
                </Button>
              </Grid>
            </>
          )}
          {status === "sent" && (
            <Grid item>
              <Button
                variant="outlined"
                style={{ color: "green" }}
                onClick={() => handleArchive(row["id"])}
              >
                {"ARCHIVE"}
              </Button>
            </Grid>
          )}
          {status === "archived" && (
            <Grid item>
              <Button
                variant="outlined"
                style={{ color: "green" }}
                onClick={() => handleUnarchive(row["id"])}
              >
                {"UNARCHIVE"}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <ConfirmationModalChecks
        open={sendModel}
        handleClose={() => {
          setSendModel(false);
        }}
        handleAgree={() => handleSend(row["id"])}
        handleDisagree={() => {
          setSendModel(false);
        }}
        message={`Send email to ${row["to_email"]}.`}
        title={`Contact ${row["school_name"]}`}
        checkboxes={<SendCheckboxes row={row} />}
      />
    </>
  );
};

const EmailsList = ({ status, archived = false }: any) => {
  const translate = useTranslate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const filters = { status: status, archived: archived };
  const sort = { field: "created_at", order: "DESC" };
  const { total, /*error,*/ data, loading } = useGetList(
    "contact_emails",
    { perPage: rowsPerPage, page: page + 1 },
    sort,
    filters
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LinearLoading />;
  }

  let sortedData = data !== undefined ? Object.keys(data).reverse() : [];

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {table_fields.map((table_field, index) => (
                <TableCell key={index}>
                  {translate(`contacts.fields.${table_field}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData !== undefined &&
              sortedData.map((key, index) => {
                return <Row key={key} status={status} row={data[key]} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

const ContactsEmails = ({ ...props }: any) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function a11yProps(index: any) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const tabs = {
    0: {
      tab: "Pending",
      tab_panel: <EmailsList status={"pending"} />,
    },
    1: {
      tab: "Rejected",
      tab_panel: <EmailsList status={"rejected"} />,
    },
    2: {
      tab: "Sent",
      tab_panel: <EmailsList status={"sent"} />,
    },
    3: {
      tab: "Archived",
      tab_panel: <EmailsList archived={true} />,
    },
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
            >
              {Object.keys(tabs).map((key) => {
                return (
                  <Tab
                    tabIndex={Number(key)}
                    label={`${tabs[key].tab}`}
                    {...a11yProps(Number(key))}
                  />
                );
              })}
            </Tabs>

            {Object.keys(tabs).map((key) => {
              return (
                <TabPanel value={value} index={Number(key)}>
                  {tabs[key].tab_panel}
                </TabPanel>
              );
            })}
          </AppBar>
        </div>
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default ContactsEmails;
