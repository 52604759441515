import * as React from "react";
import { ShowView } from "react-admin";
import { SchoolTitle } from "./SchoolForm";
import { LeadsTabs } from "../leads/Leads";

const SchoolLeads = ({ ...props }: any) => {
  return (
    <ShowView {...props} component="div" title={<SchoolTitle />} actions={null}>
      <LeadsTabs school_id_input={props.id} {...props} />
    </ShowView>
  );
};

export default SchoolLeads;
