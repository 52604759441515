import * as React from "react";
import {
  FormWithRedirect,
  useUpdate,
  useNotify,
  useRefresh,
  useTranslate,
  Edit,
  SimpleForm,
} from "react-admin";
import { MySaveButton } from "../components";
import { Grid, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import OrganizationDashboardFields from "./OrganizationDashboardFields";
import OrganizationQuestions from "./OrganizationQuestions";
import OrganizationFaqs from "./OrganizationFaqs";
import OrganizationBookingStages from "./OrganizationBookingStages";
import OrganizationJobFields from "./OrganizationJobFields";
import OrganizationDashboardRecommendedCourses from "./OrganizationDashboardRecommendedCourses";

const OrganizationDashboard = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}) => {
  const [update_mode, setUpdateMode] = React.useState(false);
  const [update, { loading }] = useUpdate(`organizations`, props.id);
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = async (values) => {
    update(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setUpdateMode(false);
          refresh();
        },
        onFailure: ({ error }) => {
          setUpdateMode(false);
          notify(error.message, "error");
        },
      }
    );
  };

  const handleClickOpen = () => {
    setUpdateMode(true);
  };

  const handleClickClose = () => {
    setUpdateMode(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Edit {...props} title={<></>} actions={<></>} component="div">
          <SimpleForm toolbar={<></>} submitOnEnter={false} redirect="show">
            <FormWithRedirect
              resource={props.resource}
              save={handleSubmit}
              render={({ handleSubmitWithRedirect, pristine, saving }) => (
                <>
                  <OrganizationDashboardFields
                    disabled_level_1={update_mode ? disabled_level_1 : true}
                    disabled_level_2={update_mode ? disabled_level_2 : true}
                    {...props}
                  />
                  <OrganizationJobFields
                    disabled_level_1={update_mode ? disabled_level_1 : true}
                    disabled_level_2={update_mode ? disabled_level_2 : true}
                    {...props}
                  />
                  <OrganizationDashboardRecommendedCourses
                    disabled_level_1={update_mode ? disabled_level_1 : true}
                    disabled_level_2={update_mode ? disabled_level_2 : true}
                    {...props}
                  />
                  <OrganizationQuestions
                    disabled_level_1={update_mode ? disabled_level_1 : true}
                    disabled_level_2={update_mode ? disabled_level_2 : true}
                    {...props}
                  />
                  <OrganizationBookingStages
                    disabled_level_1={update_mode ? disabled_level_1 : true}
                    disabled_level_2={update_mode ? disabled_level_2 : true}
                    {...props}
                  />
                  <OrganizationFaqs
                    disabled_level_1={update_mode ? disabled_level_1 : true}
                    disabled_level_2={update_mode ? disabled_level_2 : true}
                    {...props}
                  />
                  {!update_mode && (
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Button onClick={() => handleClickOpen()}>
                          <EditIcon color={"primary"} />{" "}
                          {` ${translate(`ra.action.edit`)}`}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {update_mode && (
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={2}>
                        <MySaveButton
                          label="Save"
                          handleSubmitWithRedirect={handleSubmitWithRedirect}
                          saving={saving}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <Button
                          variant="contained"
                          onClick={() => handleClickClose()}
                        >
                          {translate(`common.cancel`)}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            />
          </SimpleForm>
        </Edit>
      </Grid>
    </Grid>
  );
};

export default OrganizationDashboard;
