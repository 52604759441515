import * as React from "react";
import { FC } from "react";
import { Edit, ShowProps } from "react-admin";
import CourseForm from "./CourseForm";

const CourseEdit: FC<ShowProps> = ({ ...props }) => {
  return (
    <Edit {...props} actions={<></>} title={" "}>
      <CourseForm {...props} action="edit" />
    </Edit>
  );
};

export default CourseEdit;
