import * as React from "react";
import { FC } from "react";
import {
  ReferenceManyField,
  SimpleShowLayout,
  TopToolbar,
  ShowView,
  usePermissions,
} from "react-admin";
import {
  AddRelationButtonTo,
  ListPagination,
  TextInputField,
} from "../components";
import { USER_ROLE_SUPER_ADMIN } from "../Constants";
import { CoursesDatagrid } from "../courses/CourseList";
import { SchoolTitle } from "./SchoolForm";
import { RelatedToolbarStyle } from "../styles";
import { useTranslate } from "react-admin";

const SchoolShowActions: FC<Omit<any, "children">> = ({
  disabled_level_1,
  disabled_level_2,
  setTerm,
  ...props
}) => {
  const classes = RelatedToolbarStyle();
  const translate = useTranslate();
  return (
    <TopToolbar className={classes.toolbar}>
      {disabled_level_2 === false && (
        <>
          <TextInputField
            resource={"resource"}
            source={"term"}
            label={translate("pos.search")}
            onChange={(event) => {
              const { value } = event.target;
              if (value.length < 3) {
                setTerm(undefined);
              } else {
                setTerm(value);
              }
            }}
          />
          <AddRelationButtonTo
            to={`/courses/create?school_id=${props.id}`}
            resource={`courses`}
          />
        </>
      )}
    </TopToolbar>
  );
};

const SchoolCourses = ({
  disabled_level_1,
  disabled_level_2,
  ...props
}: any) => {
  const { permissions = [] } = usePermissions();
  const [term, setTerm] = React.useState(undefined);

  return (
    <div>
      <ShowView
        {...props}
        component="div"
        title={<SchoolTitle />}
        actions={
          <SchoolShowActions
            disabled_level_1={disabled_level_1}
            disabled_level_2={disabled_level_2}
            setTerm={setTerm}
            {...props}
          />
        }
      >
        <SimpleShowLayout>
          <ReferenceManyField
            label=""
            reference="courses"
            target="related_school_id"
            sort={{ field: "short_name", order: "ASC" }}
            pagination={<ListPagination />}
            filter={{ term: term }}
          >
            <CoursesDatagrid
              canDelete={permissions.includes(USER_ROLE_SUPER_ADMIN)}
              fromSchool={true}
            />
          </ReferenceManyField>
        </SimpleShowLayout>
      </ShowView>
    </div>
  );
};

export default SchoolCourses;
